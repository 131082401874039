import { signFormActionTypes } from "./signForms.types";

const initialState = {
  signForms: [],
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case signFormActionTypes.GET_SIGN_FORMS:
    case signFormActionTypes.ASSIGN_FORM_REQUEST: {
      return { ...state, isLoading: true };
    }
    case signFormActionTypes.SIGN_FORMS_RECEIVED: {
      return { ...state, signForms: action.payload, isLoading: false };
    }
    case signFormActionTypes.ADD_SIGN_FORM: {
      return { ...state, isLoading: false };
    }
    case signFormActionTypes.SIGN_FORM_SAVED: {
      return {
        ...state,
      };
    }
    case signFormActionTypes.DELETE_SIGN_FORM: {
      const updatedState = state.signForms;
      const toUpdate = updatedState.data.filter(
        (signForm) => signForm._id != action.payload
      );
      return { ...state, signForms: { ...state.signForms, data: toUpdate } };
    }
    case signFormActionTypes.ASSIGN_FORM: {
      const {
        payload: { added, removed, signFormId, toSend, data },
      } = action;
      const updatedState = [...state.signForms.data];
      const foundIndex = updatedState.findIndex(
        (element) => element._id === signFormId
      );
      if (foundIndex == -1) {
        return { ...state, isLoading: false };
      }
      const found = { ...updatedState[foundIndex] };
      if (toSend) {
        found.assigned = data;
      } else {
        const removedMap = {};
        removed.data.map((element) => {
          removedMap[element._id] = true;
        });
        found.assigned.push(...added.data);
        found.assigned = found.assigned.filter(
          (element) => !removedMap[element._id]
        );
      }
      updatedState[foundIndex] = found;
      return {
        ...state,
        isLoading: false,
        signForms: { ...state.signForms, data: updatedState },
      };
    }
    case signFormActionTypes.SIGN_FORM_DELETED: {
      return { ...state };
    }

    default:
      return state;
  }
};
