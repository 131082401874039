import React, { useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  renderViewForm,
  renderEmployeeWorkingTimeView,
  renderKidNotes,
} from "./viewModal.helpers";
import {
  EditModal,
  EditModalTitle,
  useStyles,
  FormBodyView,
  FormBodyContainer,
} from "../EditingModal/EditingModal.styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { v4 as uuidv4 } from "uuid";
import i18n from "../../../../i18n";
const pluralize = require("pluralize");
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
      key={uuidv4()}
    >
      {value === index && (
        <Box key={uuidv4()} p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
export default function ViewModal({
  currentRow,
  viewOpen,
  changeViewTableRowState,
  tableType = "",
  noteMap,
  setNoteMap,
  schema,
}) {
  const classes = useStyles();
  const [tab, setTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Modal
      aria-labelledby="form-edit-popup"
      aria-describedby="appears-to-edit-form-data"
      className={classes.modal}
      open={viewOpen}
      onClose={() => changeViewTableRowState(false, {})}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 700,
      }}
    >
      <Fade in={viewOpen}>
        <EditModal>
          <div className={classes.root}>
            <AppBar className={classes.appBar} position="static">
              <Tabs
                className={classes.tabsBar}
                value={tab}
                onChange={handleChange}
                centered
              >
                <Tab label={pluralize.singular(tableType)} />
                {tableType === "employees" && (
                  <Tab label={i18n.t("working_hours")} />
                )}
                {tableType === "kids" && <Tab label={i18n.t("view_notes")} />}
              </Tabs>
            </AppBar>
            <TabPanel key={uuidv4()} value={tab} index={0}>
              <EditModalTitle>{tableType}</EditModalTitle>

              <FormBodyContainer>
                <FormBodyView>
                  {renderViewForm(currentRow, "", schema)}
                </FormBodyView>
              </FormBodyContainer>
            </TabPanel>
          </div>
          {tableType === "employees" && (
            <TabPanel key={uuidv4()} value={tab} index={1}>
              {renderEmployeeWorkingTimeView(tableType, currentRow.schedule)}
            </TabPanel>
          )}
          {tableType === "kids" && (
            <TabPanel key={uuidv4()} value={tab} index={1}>
              {renderKidNotes(tableType, currentRow.notes, noteMap, setNoteMap)}
            </TabPanel>
          )}
        </EditModal>
      </Fade>
    </Modal>
  );
}
