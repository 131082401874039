import { takeEvery, put, call } from "redux-saga/effects";
import { allergiesActionTypes } from "./allergies.types";
import {
  saveAllergies,
  deleteAllergy,
  editAllergy,
  addAllergy,
  requestFailed,
} from "./allergies.actions";
import axios from "../../axios";
const getData = async (payload) => {
  try {
    const response = await axios.get("/allergy", {
      params: { kidId: payload.kidId },
    });
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* fetchAllergiesAsync(payload) {
  try {
    const data = yield call(getData, payload);
    yield put(saveAllergies(data));
  } catch (e) {
    yield put(requestFailed());
  }
}

const deleteData = async (payload) => {
  try {
    const response = await axios.delete("/allergy", {
      params: { ids: payload.allergyId },
    });
    payload?.callbackMap?.success(response);
    return response.data._id;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* deleteAllergyAsync(payload) {
  try {
    const deleteVal = yield call(deleteData, payload);
    yield put(deleteAllergy(deleteVal));
  } catch (e) {
    yield put(requestFailed());
  }
}

const editData = async (payload) => {
  try {
    const response = await axios.put("/allergy", {
      _id: payload.payload._id,
      data: payload.payload.data,
    });
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* editAllergyAsync(payload) {
  try {
    const responseData = yield call(editData, payload);
    yield put(editAllergy(responseData));
  } catch (e) {
    yield put(requestFailed());
  }
}

const addData = async (payload) => {
  try {
    const response = await axios.post("/allergy", payload.payload);
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* addAllergyAsync(payload) {
  try {
    const responseData = yield call(addData, payload);
    yield put(addAllergy(responseData));
  } catch (e) {
    yield put(requestFailed());
  }
}

export function* watchAllergies() {
  yield takeEvery(allergiesActionTypes.GET_ALLERGIES, fetchAllergiesAsync);
  yield takeEvery(allergiesActionTypes.DELETE_ALLERGY_SAGA, deleteAllergyAsync);

  yield takeEvery(allergiesActionTypes.EDIT_ALLERGY_SAGA, editAllergyAsync);

  yield takeEvery(allergiesActionTypes.ADD_ALLERGY_SAGA, addAllergyAsync);
}
