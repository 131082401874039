import { langDropDownActionTypes } from "./langDropDown.types";
import i18n from "../../i18n";

const initialState = {
  language: "fr",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case langDropDownActionTypes.GET_LANGUAGE: {
      i18n.changeLanguage(action.language);
      sessionStorage.setItem("language", action.language);
      return { ...state, language: action.language };
    }
    default:
      return state;
  }
};
