import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 30%;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: aliceblue;
  border-radius: 6px;
  box-shadow: 20px 20px 60px #cccccc, -20px -20px 60px #f4f4f4;
`;

const LoginWrapper = styled.div`
  height: 100%;
  display: flex;
`;

const ForgotPassword = styled(Link)`
  text-decoration: underline;
  text-decoration-color: #12cccb;
  color: #12cccb;
  /* margin-left: 1em; */
  margin-top: 1em;
  &:hover {
    color: red; // <Thing> when hovered
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const LoginButtons = styled(Button)``;
export {
  LoginForm,
  LoginWrapper,
  ForgotPassword,
  LoginButtons,
  ButtonsWrapper,
};

export const Footers = styled.footer`
  width: 100%;
  background-color: #11161a;
  position: absolute;
  color: white;
  bottom: 0;
  left: 0;
  height: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const FooterLink = styled.a`
  color: white;
  text-decoration: none;
  align-self: center;
  border: 2px solid white;
  padding: 12px;
  border-radius: 8px;
  margin-right: 63px;
`;
