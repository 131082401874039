import React from "react";
import { EditActions, RequirementsItems } from "../Medical.styles";
import { Form, Field } from "react-final-form";
import { TextField } from "@material-ui/core";
import _ from "lodash";
import i18n from "../../../../i18n";
import PrimaryButton from "../../../Core/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../../Core/Buttons/SecondaryButton/SecondaryButton";
import Modal from "../../../Core/Modal/Modal";

export default function AddCondition({
  open,
  handleModalClose,
  startAddingRequirment,
  callbackHelper,
  kid,
}) {
  const requirementItemsMap = [
    { title: i18n.t("requirement"), value: "name" },
    { title: i18n.t("description"), value: "description" },
  ];

  const handleAddModalCancel = () => {
    handleModalClose("add");
  };
  const onSubmit = (values) => {
    console.log({ values });
    handleModalClose("add");
    callbackHelper(startAddingRequirment, { ...values, kidId: kid._id }, "add");
  };
  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = i18n.t("required");
    }
    if (!values.description) {
      errors.description = i18n.t("required");
    }
    return errors;
  };
  return (
    <Modal
      open={open}
      closeModal={() => handleAddModalCancel()}
      title={i18n.t("add_requirement")}
    >
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {requirementItemsMap.map(({ value, title }, i) => {
              return (
                <>
                  <Field
                    name={value}
                    key={`edit-requirement-${i}`}
                    render={({ input, meta }) => {
                      const error = meta.touched && meta.error;
                      return (
                        <RequirementsItems type={value} className="mb-2">
                          <TextField
                            label={title}
                            fullWidth
                            {...input}
                            error={error}
                            helperText={error ? error : ""}
                          />
                        </RequirementsItems>
                      );
                    }}
                  />
                </>
              );
            })}

            <EditActions>
              <SecondaryButton onClick={() => handleAddModalCancel()}>
                {i18n.t("cancel")}
              </SecondaryButton>
              <PrimaryButton className="ml-1" type="submit">
                {i18n.t("submit")}
              </PrimaryButton>
            </EditActions>
          </form>
        )}
      />
    </Modal>
  );
}
