import React from "react";
import Modal from "../Core/Modal/Modal";
import Form from "../Core/Form/Form";
import "./FormModal.css";
import AddKidForm from "./AddKidForm";
import KidsList from "./KidsList";
import Tabs from "../../components/Core/Tabs/Tabs";
import i18n from "../../i18n";
export default function FormModal({
  open,
  onSubmit,
  validateForm,
  schema,
  closeModal,
  values,
  title,
  onSubmitKid,
  validateFormKid,
  groupKids,
  deleteKidFromGroup,
  showKidFromGroup,
}) {
  const tabs = [
    {
      title: i18n.t("group"),
      value: "group",
      content: () => {
        return (
          <Form
            onSubmit={onSubmit}
            validateForm={validateForm}
            schema={schema}
            values={values}
          />
        );
      },
    },
  ];
  if (values?._id) {
    tabs.push({
      title: i18n.t("kids"),
      value: "kids",
      content: () => {
        return (
          <div>
            <AddKidForm
              validateFormKid={validateFormKid}
              onSubmitKid={onSubmitKid}
              kids={groupKids}
            />
            <div className="kids-list">
              <KidsList
                kids={values ? values.kids : []}
                deleteKidFromGroup={deleteKidFromGroup}
                showKidFromGroup={showKidFromGroup}
              />
            </div>
          </div>
        );
      },
    });
  }
  return (
    <Modal
      title={title ? `i18n.t("add") ${title}` : i18n.t("add")}
      open={open}
      closeModal={closeModal}
    >
      <Tabs tabs={tabs} />
    </Modal>
  );
}
