import { TextField } from "@material-ui/core";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Datepicker({
  placeholderText,
  selected,
  onChange,
  error,
  helperText,
  wrapperClassName,
  readOnly,
  disabled,
  ...rest
}) {
  return (
    <DatePicker
      placeholderText={placeholderText}
      selected={selected}
      onChange={onChange}
      wrapperClassName={wrapperClassName}
      dateFormat="MM/dd/yyyy"
      readOnly={readOnly}
      disabled={disabled}
      customInput={
        <TextField
          value={selected}
          className="full-width"
          readOnly
          {...rest}
          error={!!error}
          helperText={error || helperText}
        />
      }
    />
  );
}
