import styled from "styled-components/macro";

export const BusinessHoursWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
`;

export const ChangeSchedule = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: aliceblue;
  border: 1px solid black;
  &:hover {
    cursor: pointer;
    border-color: black;
    background-color: antiquewhite;
  }
`;
export const EditModalWrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 2em;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  column-gap: 20px;
`;
