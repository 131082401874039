import { takeEvery, put, call } from "redux-saga/effects";
import { guardiansActionTypes } from "./guardians.types";

import {
  saveGuardians,
  deleteGuardian,
  editGuardian,
  addGuardian,
  requestFailed,
} from "./guardians.actions";

import axios from "../../axios";

const getData = async (payload) => {
  const populateFields = { relations: ["guardians"], kidId: payload.kidId };
  try {
    const response = await axios.get("/guardian", {
      params: populateFields,
    });

    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* fetchGuardiansAsync(payload) {
  try {
    const data = yield call(getData, payload);
    yield put(saveGuardians(data));
  } catch (e) {
    yield put(requestFailed());
  }
}

const deleteData = async (payload) => {
  try {
    const response = await axios.delete("/guardian", {
      params: { ids: payload.guardianId },
    });
    payload?.callbackMap?.success(response);
    return response.data._id;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* deleteGuardianAsync(id) {
  try {
    const deleteVal = yield call(deleteData, id);
    yield put(deleteGuardian(deleteVal));
  } catch (e) {
    yield put(requestFailed());
  }
}

const editData = async (payload) => {
  try {
    const response = await axios.put("/guardian", {
      _id: payload.payload._id,
      data: payload.payload.data,
    });
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* editGuardianAsync(payload) {
  try {
    const responseData = yield call(editData, payload);
    yield put(editGuardian(responseData));
  } catch (e) {
    yield put(requestFailed());
  }
}

const addData = async (payload) => {
  try {
    const response = await axios.post("/guardian", payload.payload);
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* addGuardianAsync(payload) {
  try {
    const responseData = yield call(addData, payload);
    yield put(addGuardian(responseData));
  } catch (e) {
    yield put(requestFailed());
  }
}

export function* watchGuardians() {
  yield takeEvery(guardiansActionTypes.GET_GUARDIANS, fetchGuardiansAsync);
  yield takeEvery(
    guardiansActionTypes.DELETE_GUARDIAN_SAGA,
    deleteGuardianAsync
  );

  yield takeEvery(guardiansActionTypes.EDIT_GUARDIAN_SAGA, editGuardianAsync);

  yield takeEvery(guardiansActionTypes.ADD_GUARDIAN_SAGA, addGuardianAsync);
}
