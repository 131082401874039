export const activitiesActionTypes = {
  GET_ACTIVITIES: "GET_ACTIVITIES",
  SAVE_ACTIVITIES: "SAVE_ACTIVITIES",

  DELETE_ACTIVITY: "DELETE_ACTIVITY",
  DELETE_ACTIVITY_SAGA: "DELETE_ACTIVITY_SAGA",

  EDIT_ACTIVITY: "EDIT_ACTIVITY",
  EDIT_ACTIVITY_SAGA: "EDIT_ACTIVITY_SAGA",

  ADD_ACTIVITY: "ADD_ACTIVITY",
  ADD_ACTIVITY_SAGA: "ADD_ACTIVITY_SAGA",

  REQUEST_FAILED: "REQUEST_FAILED_ACTIVITY",
};
