import styled from "styled-components/macro";
import { makeStyles } from "@material-ui/core/styles";

const EditModal = styled.div`
  background-color: white;
  margin: 0;
  padding: 0;
  width: 60vw;
  height: 80vh;
`;

const EditModalTitle = styled.h2`
  margin: 0;
  padding: 0;
  text-align: center;
  padding: 2em;
`;

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: "grey",
  },
  tabsBar: {
    "& .MuiTabs-indicator": {
      // display: "none",
      backgroundColor: "orange",
    },
  },
}));

const FormBodyContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const FormBody = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 60%;
  /* gap: 1.5em; */
`;

const FormBodyView = styled(FormBody)`
  gap: 1.5em;
`;

const ErrorMessage = styled.div`
  min-height: 1em;
  padding: 0.2em 0 0 0.4em;
  color: red;
`;

export {
  EditModal,
  EditModalTitle,
  useStyles,
  FormBody,
  FormBodyContainer,
  ErrorMessage,
  FormBodyView,
};
export const CompanySignUpWrapper = styled.div`
  width: 87%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
`;
export const TableItem = styled.div`
  grid-column: ${({ type }) => {
    switch (type) {
      case "tagLine":
        return "2 / 5";
      case "email":
        return "1 / 3";
      case "phone":
        return "3 / 5";
      case "address":
        return "1 / 5";
      case "description":
        return "1 / 5";

      default:
        return "1 / 2";
    }
  }};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 36%;
  justify-content: flex-end;
  margin-top: 30px;
`;

export const CompanyRegistrationButtonsWrapper = styled.div`
  display: flex;
  width: 87%;
  justify-content: flex-end;
  margin-top: 30px;
`;
