import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import "./FormModal.css";
import i18n from "../../i18n";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export default function KidsList({
  kids,
  deleteKidFromGroup,
  showKidFromGroup,
}) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className="secondary">
          <TableRow>
            <TableCell align="left">{i18n.t("first_name")}</TableCell>
            <TableCell align="left">{i18n.t("last_name")}&nbsp;</TableCell>
            <TableCell align="left">{i18n.t("age")}&nbsp;</TableCell>
            <TableCell align="left">{i18n.t("action")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {kids.map((row) => (
            <TableRow key={row.name}>
              <TableCell align="left">{row.firstName}</TableCell>
              <TableCell align="left">{row.lastName}</TableCell>
              <TableCell align="left">{row.age}</TableCell>
              <TableCell align="left">
                <RemoveRedEyeIcon
                  className="clickable"
                  onClick={() => showKidFromGroup(row._id)}
                />
                &nbsp;&nbsp;&nbsp;
                <DeleteForeverIcon
                  className="clickable"
                  onClick={() => deleteKidFromGroup(row._id)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
