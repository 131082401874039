import React from "react";
import { Field } from "react-final-form";
import TextField from "@material-ui/core/TextField";
import { employeeRegFields } from "./EmployeeSignup.data";
import { ErrorMessage } from "./EmployeeSignUp.styles";
import { startCompleteRegisterEmployees } from "../../../redux/employees/employees.actions";
import _ from "lodash";
import store from "../../../redux/store";

const submitEmployeeInfo = (values, id) => {
  const noConfirm = _.omit(values, ["confirmPassword"]);
  const payload = { data: noConfirm, id };
  store.dispatch(startCompleteRegisterEmployees(payload));
};

const renderEmployeeInfoFields = () => {
  return employeeRegFields.map(({ value, title, fieldType }) => {
    return (
      <Field name={value} key={`dasdj-${value}`}>
        {({ input, meta }) => {
          switch (fieldType) {
            case "text":
            case "password":
            case "email":
              return (
                <>
                  <TextField
                    {...input}
                    label={title}
                    variant="outlined"
                    type={fieldType}
                  />
                  {meta.error && meta.touched ? (
                    <ErrorMessage>{meta.error}</ErrorMessage>
                  ) : (
                    <ErrorMessage />
                  )}
                </>
              );
            case "date":
              return (
                <>
                  <TextField
                    {...input}
                    label={title}
                    type="date"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {meta.error && meta.touched ? (
                    <ErrorMessage>{meta.error}</ErrorMessage>
                  ) : (
                    <ErrorMessage />
                  )}
                </>
              );

            default:
              return null;
          }
        }}
      </Field>
    );
  });
};

export { submitEmployeeInfo, renderEmployeeInfoFields };
