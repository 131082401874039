import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import ImageViewer from "react-simple-image-viewer";
import { PreviewNotesContainer, DeleteNote } from "./PreviewNotes.styles";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { StartDeletingKidNote } from "../../../redux/kids/kids.actions";
import i18n from "../../../i18n";
import moment from "moment";
{
  /* <div>{`note ${i}`}</div>
          {note.assets.map((asset) => {
            return (
              <>
                <h3>{asset.name}</h3>
                <img
                  src={asset.path}
                  alt="note asset"
                  width="50px"
                  height="50px"
                />
              </>
            );
          })} */
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  assets: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function PreviewNotes({ kidNotes }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  let images = [];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const renderPreviewNotes = () => {
    return kidNotes.map((note, i) => {
      let noteAssetsImages = note.assets.map((asset) => {
        return asset.path;
      });
      images.push(noteAssetsImages);
      return (
        <>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel1a-header"
            >
              <div>
                {i18n.t("note_desc", {
                  date: moment(note.createdAt).format("MM-DD-YYYY hh:mm A"),
                  createdBy: `${note.createdBy.firstName} ${note.createdBy.lastName}`,
                })}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.assets}>
                <DeleteNote
                  onClick={() => dispatch(StartDeletingKidNote(note._id))}
                >
                  X
                </DeleteNote>
                <Typography>{note.description}</Typography>
                <div>
                  {images[i].map((src, index) => (
                    <img
                      src={src}
                      onClick={() => openImageViewer(index)}
                      key={index}
                      style={{
                        width: "6rem",
                        height: "6rem",
                        objectFit: "contain",
                        margin: "2px",
                        cursor: "pointer",
                      }}
                      alt=""
                    />
                  ))}
                </div>
              </div>
              {isViewerOpen && (
                <ImageViewer
                  src={images[i]}
                  currentIndex={currentImage}
                  disableScroll={false}
                  closeOnClickOutside
                  onClose={closeImageViewer}
                />
              )}
            </AccordionDetails>
          </Accordion>
        </>
      );
    });
  };
  return <PreviewNotesContainer>{renderPreviewNotes()}</PreviewNotesContainer>;
}
