export const eventsActionTypes = {
  GET_EVENTS: "GET_EVENTS",
  SAVE_EVENTS: "SAVE_EVENTS",

  DELETE_EVENT: "DELETE_EVENT",
  DELETE_EVENT_SAGA: "DELETE_EVENT_SAGA",

  EDIT_EVENT: "EDIT_EVENT",
  EDIT_EVENT_SAGA: "EDIT_EVENT_SAGA",

  ADD_EVENT: "ADD_EVENT",
  ADD_EVENT_SAGA: "ADD_EVENT_SAGA",

  SET_ACTIVE_EVENT: "SET_ACTIVE_EVENT",
  CLEAR_ACTIVE_EVENT: "CLEAR_ACTIVE_EVENT",

  SET_EVENT_KIDS_SAGA: "SET_EVENT_KIDS_SAGA",
  SET_EVENT_KIDS: "SET_EVENT_KIDS",
};
