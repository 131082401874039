export const immunizationActionTypes = {
  GET_IMMUNIZATION_TYPES: "GET_IMMUNIZATION_TYPES",
  GET_IMMUNIZATION_TYPES_SAGA: "GET_IMMUNIZATION_TYPES_SAGA",
  ADD_IMMUNIZATION_TYPE: "ADD_IMMUNIZATION_TYPE",
  ADD_IMMUNIZATION_TYPE_SAGA: "ADD_IMMUNIZATION_TYPE_SAGA",
  UPDATING_IMMUNIZATION_TYPE: "UPADTING_IMMUNIZATION_TYPE",
  UPDATING_IMMUNIZATION_TYPE_SAGA: "UPADTING_IMMUNIZATION_TYPE_SAGA",
  DELETE_IMMUNIZATION_TYPE: "DELETE_IMMUNIZATION_TYPE",
  DELETE_IMMUNIZATION_TYPE_SAGA: "DELETE_IMMUNIZATION_TYPE_SAGA",

  REQUEST_FAILED: "REQUEST_FAILED_IMMUNIZATION_TYPE",
};
