import { signFormActionTypes } from "./signForms.types";
import { takeEvery, put, call } from "redux-saga/effects";
import axios from "../../axios";
import {
  signFormsReceived,
  signFormDeleted,
  assignForm,
} from "./signForm.actions";

const addData = async (payload) => {
  try {
    let data = new FormData();
    data.append("file", payload.payload.file);
    data.append("title", payload.payload.title);
    console.log(payload, "............add data....................");

    const response = await axios.post("/signForm/uploadFile", data);
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* addSignFormAsync(payload) {
  const formData = yield call(addData, payload);
  yield put({ type: "SIGN_FORM_SAVED", payload: formData });
}

const getData = async (payload) => {
  try {
    const response = await axios.get("/signForm", {
      params: { page: payload.payload, ...payload.params },
    });
    payload?.callbackMap?.success(response);
    return response.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* getSignFormAsync(payload) {
  const data = yield call(getData, payload);
  yield put(signFormsReceived(data));
}

const deleteData = async (payload) => {
  try {
    const response = await axios.delete("/signForm", {
      params: { ids: payload.payload },
    });
    payload?.callbackMap?.success(response);
    return response.data._id;
  } catch (e) {
    console.log(e);
    payload?.callbackMap?.failure(e);
  }
};

export function* deleteSignFormAsync(payload) {
  const data = yield call(deleteData, payload);
  yield put(signFormDeleted(data));
}

const assignForms = async (payload) => {
  try {
    const response = await axios.post("/signForm/assign", payload.payload);
    payload?.callbackMap?.success(response);
    return { ...response.data, toSend: payload.payload.toSend };
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* assignFormsSaga(payload) {
  const data = yield call(assignForms, payload);
  yield put(assignForm(data));
}

export function* watchSignForms() {
  yield takeEvery(signFormActionTypes.ADD_SIGN_FORM, addSignFormAsync);
  yield takeEvery(signFormActionTypes.GET_SIGN_FORMS, getSignFormAsync);
  yield takeEvery(signFormActionTypes.DELETE_SIGN_FORM, deleteSignFormAsync);
  yield takeEvery(signFormActionTypes.ASSIGN_FORM_REQUEST, assignFormsSaga);
}
