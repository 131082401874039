import React from "react";
import Button from "../../Core/Buttons/Add/AddButton";
import { openEmployeesAddModal } from "../../../redux/employees/employees.actions";
import { useDispatch } from "react-redux";
import i18n from "../../../i18n";

export default function EmployeeHeader() {
  const dispatch = useDispatch();
  return (
    <>
      <h2>{i18n.t("employees")}</h2>
      <Button onClick={() => dispatch(openEmployeesAddModal())}>
        {i18n.t("add_employee")}
      </Button>
    </>
  );
}
