import styled from "styled-components/macro";

export const KidsScheduleWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
`;

export const EditModalWrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 2em;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  column-gap: 20px;
`;
