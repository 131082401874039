import { takeEvery, put, call } from "redux-saga/effects";
import { eventsActionTypes } from "./events.types";
import {
  saveEvents,
  deleteEvent,
  editEvent,
  addEvent,
  setEventKids,
} from "./events.actions";
import axios from "../../axios";

const getData = async ({ params = {} }) => {
  const response = await axios.get("/event", {
    params,
  });
  return response.data.data;
};

export function* fetchEventsAsync(args) {
  const data = yield call(getData, args);
  yield put(saveEvents(data));
}

const deleteData = async ({ eventId }) => {
  const response = await axios.delete("/event", {
    params: { ids: eventId },
  });
  return response.data._id;
};

export function* deleteEventAsync(id) {
  const deleteVal = yield call(deleteData, id);
  yield put(deleteEvent(deleteVal));
}

const editData = async ({ _id, data }) => {
  const response = await axios.put("/event", {
    _id,
    data,
  });
  return response.data.data;
};

export function* editEventAsync({ payload }) {
  const responseData = yield call(editData, payload);
  yield put(editEvent(responseData));
}

const addData = async ({ payload }) => {
  const response = await axios.post("/event", payload);
  return response.data.data;
};

export function* addEventAsync(payload) {
  const responseData = yield call(addData, payload);
  yield put(addEvent(responseData));
}

const setKids = async (payload) => {
  try {
    const response = await axios.post("/event/setKids", payload.payload);
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* setEventKidsAsync(payload) {
  const data = yield call(setKids, payload);
  yield put(setEventKids(data));
}

export function* watchEvents() {
  yield takeEvery(eventsActionTypes.GET_EVENTS, fetchEventsAsync);
  yield takeEvery(eventsActionTypes.DELETE_EVENT_SAGA, deleteEventAsync);
  yield takeEvery(eventsActionTypes.EDIT_EVENT_SAGA, editEventAsync);
  yield takeEvery(eventsActionTypes.ADD_EVENT_SAGA, addEventAsync);
  yield takeEvery(eventsActionTypes.SET_EVENT_KIDS_SAGA, setEventKidsAsync);
}
