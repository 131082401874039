import billingActionTypes from "./billing.types";

export const getInvoices = (payload) => {
  return {
    type: billingActionTypes.GET_INVOICE,
    payload,
  };
};

export const getInvoicesRequest = (params, callbackMap) => {
  return {
    type: billingActionTypes.GET_INVOICE_SAGA,
    params,
    callbackMap,
  };
};

export const addInvoice = (payload) => {
  return {
    type: billingActionTypes.ADD_INVOICE,
    payload,
  };
};

export const addInvoicesRequest = (payload, callbackMap) => {
  return {
    type: billingActionTypes.ADD_INVOICE_SAGA,
    payload,
    callbackMap,
  };
};

export const updateInvoice = (payload) => {
  return {
    type: billingActionTypes.EDIT_INVOICE,
    payload,
  };
};

export const updateInvoicesRequest = (payload, callbackMap) => {
  return {
    type: billingActionTypes.EDIT_INVOICE_SAGA,
    payload,
    callbackMap,
  };
};

export const deleteInvoice = (payload) => {
  return {
    type: billingActionTypes.DELETE_INVOICE,
    payload,
  };
};

export const deleteInvoicesRequest = (params, callbackMap) => {
  return {
    type: billingActionTypes.DELETE_INVOICE_SAGA,
    params,
    callbackMap,
  };
};

export const getARAging = (payload) => {
  return {
    type: billingActionTypes.GET_AR_AGING,
    payload,
  };
};

export const getARAgingRequest = (payload, callbackMap) => {
  return {
    type: billingActionTypes.GET_AR_AGING_SAGA,
    payload,
    callbackMap,
  };
};

export const requestFailed = () => {
  return {
    type: billingActionTypes.REQUEST_FAILED,
  };
};
