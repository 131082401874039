import { allergiesActionTypes } from "./allergies.types";

const initialState = {
  allergies: [],
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case allergiesActionTypes.GET_ALLERGIES: {
      return { ...state, isLoading: true };
    }
    case allergiesActionTypes.SAVE_ALLERGIES: {
      return { ...state, allergies: payload, isLoading: false };
    }
    case allergiesActionTypes.DELETE_ALLERGY_SAGA: {
      return { ...state, isLoading: true };
    }
    case allergiesActionTypes.DELETE_ALLERGY: {
      const updatedState = state.allergies;

      const toUpdate = updatedState.filter((allergy) => allergy._id != payload);
      return { ...state, allergies: toUpdate, isLoading: false };
    }
    case allergiesActionTypes.EDIT_ALLERGY_SAGA: {
      return { ...state, isLoading: true };
    }
    case allergiesActionTypes.EDIT_ALLERGY: {
      const currentState = state.allergies;

      const toUpdate = currentState.map((allergy) => {
        return allergy._id == payload._id ? payload : allergy;
      });
      return { ...state, allergies: toUpdate, isLoading: false };
    }
    case allergiesActionTypes.ADD_ALLERGY_SAGA: {
      return { ...state, isLoading: true };
    }
    case allergiesActionTypes.ADD_ALLERGY: {
      const currentState = state.allergies;
      const toUpdate = [...currentState, payload];
      return { ...state, allergies: toUpdate, isLoading: false };
    }
    case allergiesActionTypes.REQUEST_FAILED: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
