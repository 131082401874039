import styled from "styled-components/macro";

export const Incident = styled.div`
  background: aliceblue;
  /* height: 4em; */
  border-radius: 7px;
  padding: 30px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

export const EditActions = styled.div`
  display: flex;
  width: 20%;
  justify-content: flex-end;
`;
export const Controls = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const AddIncidentFields = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
`;
