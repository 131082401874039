export const incidentsActionTypes = {
  GET_INCIDENTS: "GET_INCIDENTS",
  SAVE_INCIDENTS: "SAVE_INCIDENTS",

  DELETE_INCIDENT: "DELETE_INCIDENT",
  DELETE_INCIDENT_SAGA: "DELETE_INCIDENT_SAGA",

  EDIT_INCIDENT: "EDIT_INCIDENT",
  EDIT_INCIDENT_SAGA: "EDIT_INCIDENT_SAGA",

  ADD_INCIDENT: "ADD_INCIDENT",
  ADD_INCIDENT_SAGA: "ADD_INCIDENT_SAGA",

  REQUEST_FAILED: "REQUEST_FAILED_INCIDENT",
};
