import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { EditActions } from "../Medical/Medical.styles";
import { Form, Field } from "react-final-form";
import { TextField } from "@material-ui/core";
import _ from "lodash";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { AddIncidentFields } from "./Incident.styles";
import i18n from "../../../i18n";
import PrimaryButton from "../../Core/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../Core/Buttons/SecondaryButton/SecondaryButton";
import Modal from "../../Core/Modal/Modal";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
const IncidentItemsMap = {
  incident: "Incident",
};
export default function AddIncident({
  open,
  handleModalClose,
  startAddingIncident,
  callbackHelper,
  currentUser,
}) {
  const [incidentContent, setIncidentContent] = useState("");
  const classes = useStyles();
  const handleAddModalCancel = () => {
    handleModalClose("add");
  };
  const handleChange = (input) => {
    setIncidentContent(input);
  };
  const onSubmit = (values) => {
    callbackHelper(
      startAddingIncident,
      {
        name: values.incident,
        description: incidentContent,
        kidId: currentUser,
      },
      "add"
    );

    handleModalClose("add");
  };

  const validate = (values) => {
    const errors = {};

    if (!values.incident) {
      errors.incident = i18n.t("required");
    }

    return errors;
  };
  return (
    <Modal
      className={classes.modal}
      open={open}
      closeModal={() => handleAddModalCancel()}
      title={i18n.t("add_incident")}
    >
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <AddIncidentFields>
              {Object.keys(IncidentItemsMap).map((key, i) => {
                return (
                  <>
                    <Field
                      name={key}
                      key={`edit-requirement-${i}`}
                      render={({ input, meta }) => {
                        const error = meta.touched && meta.error;
                        return (
                          <TextField
                            label={i18n.t("incident")}
                            fullWidth
                            {...input}
                            error={error}
                            helperText={error ? error : ""}
                          />
                        );
                      }}
                    />
                  </>
                );
              })}
              <label className="mt-2">{i18n.t("description")}</label>
              <SunEditor onChange={handleChange} />
            </AddIncidentFields>
            <EditActions className="mt-2">
              <SecondaryButton onClick={() => handleAddModalCancel()}>
                {i18n.t("cancel")}
              </SecondaryButton>
              <PrimaryButton className="ml-1" type="submit">
                {i18n.t("submit")}
              </PrimaryButton>
            </EditActions>
          </form>
        )}
      />
    </Modal>
  );
}
