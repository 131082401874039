import { guardiansActionTypes } from "./guardians.types";
import _ from "lodash";
const initialState = {
  guardians: [],
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case guardiansActionTypes.GET_GUARDIANS:
    case guardiansActionTypes.DELETE_GUARDIAN_SAGA:
    case guardiansActionTypes.EDIT_GUARDIAN_SAGA:
    case guardiansActionTypes.ADD_GUARDIAN_SAGA: {
      return { ...state, isLoading: true };
    }
    case guardiansActionTypes.SAVE_GUARDIANS: {
      return { ...state, guardians: payload, isLoading: false };
    }
    case guardiansActionTypes.DELETE_GUARDIAN: {
      const updatedState = state.guardians;

      const toUpdate = updatedState.filter(
        (guardians) => guardians._id != payload
      );
      return { ...state, guardians: toUpdate, isLoading: false };
    }
    case guardiansActionTypes.EDIT_GUARDIAN: {
      const currentState = state.guardians;
      const toUpdate = currentState.map((guardian) => {
        return guardian._id == payload._id ? payload : guardian;
      });
      return { ...state, guardians: toUpdate, isLoading: false };
    }
    case guardiansActionTypes.ADD_GUARDIAN: {
      const currentState = [...state.guardians];
      const toUpdate = [...currentState, payload];
      return { ...state, guardians: toUpdate, isLoading: false };
    }

    default:
      return state;
  }
};
