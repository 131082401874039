export const guardiansActionTypes = {
  GET_GUARDIANS: "GET_GUARDIANS",
  SAVE_GUARDIANS: "SAVE_GUARDIANS",

  DELETE_GUARDIAN: "DELETE_GUARDIAN",
  DELETE_GUARDIAN_SAGA: "DELETE_GUARDIAN_SAGA",

  EDIT_GUARDIAN: "EDIT_GUARDIAN",
  EDIT_GUARDIAN_SAGA: "EDIT_GUARDIAN_SAGA",

  ADD_GUARDIAN: "ADD_GUARDIAN",
  ADD_GUARDIAN_SAGA: "ADD_GUARDIAN_SAGA",

  REQUEST_FAILED: "REQUEST_FAILED_GUARDIAN",
};
