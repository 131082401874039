import { logoutActionTypes } from "./logout.types";

export const startLogout = (payload) => {
  return {
    type: logoutActionTypes.LOGOUT_USER_SAGA,
    payload,
  };
};

export const logoutComplete = (payload) => {
  return {
    type: logoutActionTypes.LOGOUT_USER,
    payload,
  };
};

export const requestFailed = () => {
  return {
    type: logoutActionTypes.REQUEST_FAILURE,
  };
};
