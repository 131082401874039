import React, { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  startKidFetching,
  startEditingKid,
  startAddingKid,
  startDeletingKid,
} from "../../redux/kids/kids.actions";
import { selectKidItems } from "../../redux/kids/kids.selectors";
import Page from "../../components/Page/Page";
import Form from "../../components/Core/Form/Form";
import FormModal from "../../components/Core/FormModal/FormModal";
import Tabs from "../../components/Core/Tabs/Tabs";
import Modal from "../../components/Core/Modal/Modal";
import AddKidNote from "../../components/FileUploader/KidsFileUpload.component";
import Guardians from "../../components/Kids/Guardians/Guardians";
import Immunization from "../../components/Kids/Immunizations/Immunization";
import Medical from "../../components/Kids/Medical/Medical";
import Incidents from "../../components/Kids/Incidents/Incidents";

import ErrorHelper from "../../helpers/ErrorHelper";
import SuccessHelper from "../../helpers/SuccessHelper";
import i18n from "../../i18n";
import KidsSchedule from "../../components/KidsSchedule/KidsSchedule";
import Billing from "../../components/Kids/Billing/Billing";
import Invoices from "../../components/Kids/Invoices/Invoices";
import moment from "moment";

class kids extends Component {
  state = {
    editModalOpen: false,
    addModalOpen: false,
    editing: {},
  };
  schema = [
    { value: "firstName", title: i18n.t("first_name"), fieldType: "text" },
    { value: "lastName", title: i18n.t("last_name"), fieldType: "text" },
    { value: "age", title: i18n.t("age"), fieldType: "number" },
    { value: "dateOfBirth", title: i18n.t("date_of_birth"), fieldType: "date" },
    { value: "address", title: i18n.t("address"), fieldType: "address" },
    {
      value: "emergencyContactName",
      title: i18n.t("emergency_contact_name"),
      fieldType: "text",
    },
    {
      value: "emergencyContactAddress",
      title: i18n.t("emergency_contact_address"),
      fieldType: "text",
    },
    {
      value: "emergencyContactPhone",
      title: i18n.t("emergency_contact_phone"),
      fieldType: "text",
    },
    {
      value: "actions",
      title: i18n.t("actions"),
      fieldType: "actions",
      style: { width: 80 },
      actions: ["edit", "delete"],
    },
  ];

  async componentDidMount() {
    document.title = i18n.t("kids");
    new Promise((success, failure) => {
      this.props.startKidFetching(
        { success, failure },
        { relations: ["guardians", "notes", "allergies", "billing"] }
      );
    })
      .then((res) => {})
      .catch((err) => {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  }
  validateKid = (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = i18n.t("required");
    }
    if (!values.lastName) {
      errors.lastName = i18n.t("required");
    }
    if (!values.age) {
      errors.age = i18n.t("required");
    }

    return errors;
  };
  onAdd = (values) => {
    console.log({ values });
    new Promise((success, failure) => {
      this.props.startAddingKid({ success, failure }, values);
    })
      .then((res) => {
        this.setState({ addModalOpen: false });
        SuccessHelper.handleToastSuccess(i18n.t("successfully_created"), true);
      })
      .catch((err) => {
        this.setState({ isloading: false });
        console.log("something_went_wrong");
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  };

  onEdit = (data) => {
    new Promise((success, failure) => {
      this.props.startEditingKid(
        { success, failure },
        {
          _id: this.state.editing._id,
          data,
        }
      );
    })
      .then((res) => {
        SuccessHelper.handleToastSuccess(i18n.t("successfully_updated"), true);
      })
      .catch((err) => {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  };

  renderEditModal = () => {
    const { editing = {} } = this.state;
    return (
      <Modal
        title={i18n.t("edit_kid")}
        open={this.state.editModalOpen}
        closeModal={() => {
          this.setState({
            editing: null,
            editModalOpen: false,
          });
        }}
      >
        <Tabs
          tabs={[
            {
              title: i18n.t("general"),
              value: "general",
              content: () => {
                return (
                  <Form
                    onSubmit={this.onEdit}
                    validateForm={this.validateKid}
                    schema={this.schema}
                    values={editing}
                  />
                );
              },
            },
            {
              title: i18n.t("guardians"),
              value: "guardians",
              content: () => {
                return <Guardians kid={editing} />;
              },
            },
            {
              title: i18n.t("medical"),
              value: "medical",
              content: () => {
                return <Medical kid={editing} />;
              },
            },
            {
              title: i18n.t("immunizations"),
              value: "immunizations",
              content: () => {
                return <Immunization kid={editing} />;
              },
            },
            {
              title: i18n.t("reminders"),
              value: "reminders",
              content: () => {
                return null;
              },
            },
            {
              title: i18n.t("notes"),
              value: "notes",
              content: () => {
                return (
                  <AddKidNote
                    changeEditingTableRowState={({ notes }) => {
                      this.setState({
                        editing: {
                          ...this.state.editing,
                          notes,
                        },
                      });
                    }}
                    currentUser={this.state.editing?._id}
                    kidNotes={this.state.editing?.notes}
                  />
                );
              },
            },
            {
              title: i18n.t("incidents"),
              value: "incidents",
              content: () => {
                return (
                  <Incidents
                    changeEditingTableRowState={() => {}}
                    currentUser={this.state.editing?._id}
                  />
                );
              },
            },
            {
              title: i18n.t("schedule"),
              value: "schedule",
              content: () => {
                return (
                  <KidsSchedule
                    changeEditingTableRowState={({ schedule }) => {
                      console.log(schedule);
                      this.setState({
                        editing: {
                          ...this.state.editing,
                          schedule,
                        },
                      });
                    }}
                    schedule={this.state.editing.schedule}
                    kidId={this.state.editing._id}
                  />
                );
              },
            },
            {
              title: i18n.t("billing"),
              value: "billing",
              content: () => {
                return (
                  <Billing
                    changeEditingTableRowState={(billing) => {
                      this.setState({
                        editing: {
                          ...this.state.editing,
                          billing,
                        },
                      });
                    }}
                    billing={this.state.editing.billing}
                    kidId={this.state.editing._id}
                  />
                );
              },
            },
            {
              title: i18n.t("invoices"),
              value: "invoices",
              content: () => {
                return <Invoices kidId={this.state.editing._id} />;
              },
            },
          ]}
        />
      </Modal>
    );
  };

  renderAddModal = () => {
    return (
      <FormModal
        title="Kid"
        schema={this.schema}
        open={this.state.addModalOpen}
        onSubmit={this.onAdd}
        validateForm={this.validateKid}
        closeModal={() => {
          this.setState({
            addModalOpen: false,
          });
        }}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderEditModal()}
        {this.renderAddModal()}
        <Page
          title="kids"
          resource="Kids"
          data={this.props.kids.kids}
          onDelete={this.props.startDeletingKid}
          onEdit={(data) => {
            this.setState({
              editing: {
                ...data,
                dateOfBirth: moment(data.dateOfBirth).toDate(),
              },
              editModalOpen: true,
            });
          }}
          editOnRowClick
          onCreate={() => {
            this.setState({
              editing: {},
              addModalOpen: true,
            });
          }}
          schema={this.schema}
        ></Page>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startKidFetching: (callbackMap, ...args) =>
    dispatch(startKidFetching(callbackMap, ...args)),
  startDeletingKid: (id, callbackMap) =>
    dispatch(startDeletingKid(id, callbackMap)),
  startEditingKid: (callbackMap, ...args) =>
    dispatch(startEditingKid(callbackMap, ...args)),
  startAddingKid: (callbackMap, ...args) =>
    dispatch(startAddingKid(callbackMap, ...args)),
});

const mapStateToProps = createStructuredSelector({
  kids: selectKidItems,
});
export default connect(mapStateToProps, mapDispatchToProps)(kids);
