import React, { useState } from "react";
import { Form } from "react-final-form";
import {
  CompanySignUpWrapper,
  ButtonsWrapper,
  ErrorMessage,
  TableItem,
} from "./clientCompanyProfile.styles";
import { TextField } from "@material-ui/core";
import { Field } from "react-final-form";
import PlacesAutocomplete from "../../Core/PlacesAutocomplete/PlacesAutoComplete";
import SubmitButton from "../../Core/Buttons/SubmitButton";
import i18n from "../../../i18n";

export default function ClientCompanyProfile({
  currentCompany,
  handleSubmitCompanyProfile,
  daycare,
}) {
  const clientCompanyRegFields = [
    {
      value: "companyName",
      title: i18n.t("company_name"),
      fieldType: "text",
      name: "name",
    },
    {
      value: "companyTag",
      title: i18n.t("company_tagline"),
      fieldType: "text",
      name: "tagLine",
    },
    {
      value: "companyEmail",
      title: i18n.t("email_address"),
      fieldType: "email",
      name: "email",
    },
    {
      value: "phoneNo",
      title: i18n.t("phone_number"),
      fieldType: "text",
      name: "phone",
    },
    {
      value: "address",
      title: i18n.t("address"),
      fieldType: "address",
      name: "address",
    },
    {
      value: "companyDescription",
      title: i18n.t("description"),
      fieldType: "text",
      name: "description",
    },
  ];
  const submitClientCompanyInfo = (values) => {
    handleSubmitCompanyProfile(values);
  };
  const validateClientCompanyInfo = (values) => {
    const errors = {};
    if (!values.companyName) {
      errors.companyName = i18n.t("required");
    }
    if (!values.companyTag) {
      errors.companyTag = i18n.t("required");
    }
    if (!values.companyEmail) {
      errors.companyEmail = i18n.t("required");
    }
    if (!values.phoneNo) {
      errors.phoneNo = i18n.t("required");
    }
    if (!values.address) {
      errors.address = i18n.t("required");
    }
    if (!values.companyDescription) {
      errors.companyDescription = i18n.t("required");
    }
    return errors;
  };
  const renderClientCompanyInfoFields = () => {
    return clientCompanyRegFields.map(({ value, title, fieldType, name }) => {
      return (
        <Field
          initialValue={
            name == "address" ? daycare.address : currentCompany[value]
          }
          name={value}
          key={`dasdj-${value}`}
        >
          {({ input, meta }) => {
            switch (fieldType) {
              case "text":
              case "password":
              case "email":
                return (
                  <TableItem type={name}>
                    <TextField
                      {...input}
                      label={title}
                      type={fieldType}
                      fullWidth
                    />
                    {meta.error && meta.touched ? (
                      <ErrorMessage>{meta.error}</ErrorMessage>
                    ) : (
                      <ErrorMessage></ErrorMessage>
                    )}
                  </TableItem>
                );
              case "date":
                return (
                  <>
                    <TableItem type={name}>
                      <TextField
                        fullWidth
                        {...input}
                        label={title}
                        type="date"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {meta.error && meta.touched ? (
                        <ErrorMessage>{meta.error}</ErrorMessage>
                      ) : (
                        <ErrorMessage></ErrorMessage>
                      )}
                    </TableItem>
                  </>
                );
              case "address":
                return (
                  <>
                    <TableItem type={name}>
                      <PlacesAutocomplete
                        name="address"
                        input={input}
                        defaultAddress={daycare.address.displayAddress}
                      />
                      {meta.error && meta.touched ? (
                        <ErrorMessage>{meta.error}</ErrorMessage>
                      ) : (
                        <ErrorMessage></ErrorMessage>
                      )}
                    </TableItem>
                  </>
                );
              default:
                return null;
            }
          }}
        </Field>
      );
    });
  };

  return (
    <Form
      onSubmit={(values) => submitClientCompanyInfo(values)}
      validate={validateClientCompanyInfo}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          onSubmit={handleSubmit}
        >
          <h2 style={{ marginBottom: "20px" }}>{i18n.t("update_profile")}</h2>
          <CompanySignUpWrapper>
            {renderClientCompanyInfoFields("")}
          </CompanySignUpWrapper>
          <ButtonsWrapper>
            <SubmitButton
              style={{ marginLeft: "20px" }}
              type="submit"
              disabled={submitting}
            >
              {i18n.t("update_profile")}
            </SubmitButton>
          </ButtonsWrapper>
        </form>
      )}
    />
  );
}
