import { takeEvery, put, call } from "redux-saga/effects";
import { employeesActionTypes } from "./employees.types";
import {
  saveEmployees,
  deleteEmployee,
  editEmployee,
  addEmployee,
  editEmployeeSchedule,
  startRegisterEmployees,
  startCompleteRegisterEmployees,
} from "./employees.actions";
import axios from "../../axios";
import SuccessHelper from "../../helpers/SuccessHelper";
import ErrorHelper from "../../helpers/ErrorHelper";
import i18n from "../../i18n";
import Swal from "sweetalert2";

const getData = async () => {
  try {
    const response = await axios.get("/employee");
    if (response) {
      return response.data.data;
    }
  } catch (error) {
    ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
  }
};

export function* fetchEmployeesAsync() {
  const data = yield call(getData);
  yield put(saveEmployees(data));
}

const deleteData = async ({ employeeId }) => {
  try {
    const response = await axios.delete("/employee", {
      params: { ids: employeeId },
    });
    if (response.statusText == "OK") {
      SuccessHelper.handleToastSuccess(i18n.t("successfully_deleted"), true);
    }
    return response.data._id;
  } catch (error) {
    ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
  }
};

export function* deleteEmployeeAsync(id) {
  const deleteVal = yield call(deleteData, id);
  yield put(deleteEmployee(deleteVal));
}

const editData = async ({ _id, data }) => {
  try {
    const response = await axios.put("/employee", {
      _id,
      data,
    });

    if (response.statusText == "OK") {
      SuccessHelper.handleToastSuccess(i18n.t("successfully_updated"), true);
    }
    return response.data.data;
  } catch (error) {
    ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
  }
};

export function* editEmployeeAsync({ payload }) {
  const responseData = yield call(editData, payload);
  yield put(editEmployee(responseData));
}

const addData = async ({ payload }) => {
  try {
    const response = await axios.post("/employee", payload);
    if (response.statusText == "OK") {
      SuccessHelper.handleToastSuccess(i18n.t("successfully_created"), true);
    }
    return response.data.data;
  } catch (e) {
    ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
    console.log(e);
  }
};

export function* addEmployeeAsync(payload) {
  const responseData = yield call(addData, payload);
  yield put(addEmployee(responseData));
}

const editSchedule = async ({ _id, data }) => {
  try {
    const response = await axios.put("/employee/updateSchedule", {
      _id,
      data,
    });
    return response.data.data;
  } catch (error) {
    ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
  }
};

export function* editEmployeeScheduleAsync({ payload }) {
  const responseData = yield call(editSchedule, payload);
  yield put(editEmployeeSchedule(responseData));
}

const completeEmployeeReg = async ({ id, data }) => {
  const response = await axios.put("/employee/updateEmployee", {
    _id: id,
    data,
  });
  return response.data.data;
};

export function* completeEmployeeRegistrationAsync({ payload }) {
  const responseData = yield call(completeEmployeeReg, payload);
  yield put(editEmployeeSchedule(responseData));
}

const registerEmployee = async ({ _id, data }) => {
  // todo
  // const response = await axios.put("/employee/updateSchedule", {
  //   _id,
  //   data,
  // });
  // return response.data.data;
};

export function* registerEmployeeAsync({ payload }) {
  const responseData = yield call(registerEmployee, payload);
  yield put(startRegisterEmployees(responseData));
}

export function* watchEmployees() {
  yield takeEvery(employeesActionTypes.GET_EMPLOYEES, fetchEmployeesAsync);
  yield takeEvery(
    employeesActionTypes.DELETE_EMPLOYEE_SAGA,
    deleteEmployeeAsync
  );

  yield takeEvery(employeesActionTypes.EDIT_EMPLOYEE_SAGA, editEmployeeAsync);

  yield takeEvery(employeesActionTypes.ADD_EMPLOYEE_SAGA, addEmployeeAsync);
  yield takeEvery(
    employeesActionTypes.UPDATE_EMPLOYEE_SCHEDULE_SAGA,
    editEmployeeScheduleAsync
  );
  yield takeEvery(
    employeesActionTypes.REGISTER_EMPLOYEE_SAGA,
    registerEmployeeAsync
  );
  yield takeEvery(
    employeesActionTypes.REGISTER_EMPLOYEE_COMPLETE_SAGA,
    completeEmployeeRegistrationAsync
  );
}
