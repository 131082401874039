import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasicTable from "..//Core/BasicTable";
import i18n from "../../i18n";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DeleteHelper from "../../helpers/DeleteHelper";
import { deleteInvoicesRequest } from "../../redux/billing/billing.actions";
import EditInvoiceModal from "./EditInvoiceModal";

export default function InvoiceTable({ invoices = [] }) {
  const dispatch = useDispatch();
  const [editing, setEditing] = useState({});
  const [editOpen, setEditOpen] = useState(false);
  const onEdit = (value) => {
    setEditOpen(true);
    setEditing({
      ...value,
      to: moment(value.to).toDate(),
      from: moment(value.from).toDate(),
      dueDate: moment(value.dueDate).toDate(),
    });
  };

  return (
    <>
      <EditInvoiceModal
        open={editOpen}
        setOpen={setEditOpen}
        initValues={editing}
      />
      <BasicTable
        rows={invoices}
        onRowClick={onEdit}
        schema={[
          {
            header: i18n.t("amount"),
            field: "amount",
            accessor: ({ amount }) => `$ ${amount.toFixed(2)}`,
          },
          {
            header: i18n.t("due_date"),
            field: "dueDate",
            accessor: ({ dueDate }) => moment(dueDate).format("MM-DD-YYYY"),
          },
          {
            header: i18n.t("from"),
            field: "from",
            accessor: ({ from }) => moment(from).format("MM-DD-YYYY"),
          },
          {
            header: i18n.t("to"),
            field: "to",
            accessor: ({ to }) => moment(to).format("MM-DD-YYYY"),
          },
          {
            field: "action",
            style: { width: 80 },
            render: (value) => {
              return (
                <div className="row">
                  <DeleteForeverIcon
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      DeleteHelper.handleDelete(
                        i18n.t("delete_confirm"),
                        () => {
                          dispatch(deleteInvoicesRequest({ ids: value._id }));
                        }
                      );
                    }}
                    style={{
                      marginRight: "10px",
                      cursor: "pointer",
                      color: "#5089DE",
                    }}
                  ></DeleteForeverIcon>
                </div>
              );
            },
          },
        ]}
      />
    </>
  );
}
