import { createSelector } from "reselect";

const selectTestState = (state) => state.requirments;

export const selectCartItems = createSelector(
  [selectTestState],
  (requirments) => requirments
);

export const selectRequirmentsData = createSelector(
  [selectTestState],
  (requirments) => requirments.requirments
);
