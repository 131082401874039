import styled from "styled-components/macro";

export const EmployeesWrapper = styled.section`
  /* background-color: antiquewhite; */
  height: 100%;
  width: 60%;
  margin: auto;
  align-content: center;
  padding: 2em;
`;
export const EmployeeWrapper = styled.section`
  /* background-color: antiquewhite; */
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const EmployeeItem = styled.div`
  ${({ type }) => {
    switch (type) {
      case "editButton":
        return "align-self: flex-end; margin-right:7em;margin-bottom:2em";
      case "addButton":
        return "align-self: center; margin-bottom:2em";
      case "employee":
        return "justify-content: flex-end;";

      default:
        return "";
    }
  }};

  grid-column: ${({ type }) => {
    switch (type) {
      case "firstName":
        return "1 / 3";
      case "lastName":
        return "3 / 5";
      case "email":
        return "1 / 5";
      case "phoneNo":
        return "1 / 3";
      case "status":
        return "3 / 5";
      case "editButton":
        return "4 / 5";
      case "submit":
        return "4 / 5";

      default:
        return "1 / 2";
    }
  }};
  display: flex;
`;

export const EmployeeHeaderWrapper = styled.header`
  /* background-color: coral; */
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
export const FormWrapper = styled.form`
  /* background-color: coral; */
  display: grid;
  width: 80%;
  grid-template-columns: repeat(4, 1fr);
  align-content: center;
  gap: 3em;
  align-self: center;
`;
export const EmployeeSearchWrapper = styled.div`
  /* background-color: cadetblue; */
  height: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const EmployeeList = styled.div`
  /* background-color: darkcyan; */
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
export const EmployeeSearchBox = styled.div`
  margin-right: 16.4%;
`;
export const EmployeeModalContent = styled.div`
  background-color: cadetblue;
`;
export const EmployeeModalContainer = styled.div`
  background-color: white;
  height: 70%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 20%;
  margin: auto;
`;
