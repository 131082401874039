import React, { useState } from "react";
import FileUpload from "./FileUploader.component";
import { connect } from "react-redux";
import { startAddingKidNote } from "../../redux/kids/kids.actions";
import PreviewNotes from "../Kids/notes/PreviewNotes";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import PrimaryButton from "../Core/Buttons/PrimaryButton/PrimaryButton";
import { UploadForm } from "./file-upload.styles";
import i18n from "../../i18n";
function KidsFileUpload({
  startAddingKidNote,
  currentUser,
  changeEditingTableRowState,
  kidNotes,
}) {
  const [newUserInfo, setNewUserInfo] = useState({
    uploadedFiles: [],
    note: "",
  });
  const handleSubmit = (event) => {
    event.preventDefault();

    let formData = new FormData();
    for (let i = 0; i < newUserInfo.uploadedFiles.length; i++) {
      const element = newUserInfo.uploadedFiles[i];
      formData.append("files", element);
    }

    // formData.append("files", newUserInfo.uploadedFiles);
    formData.append("note", newUserInfo.note);
    formData.append("kidId", currentUser);

    startAddingKidNote(formData, changeEditingTableRowState);
    // changeEditingTableRowState(false, {});
  };
  const updateUploadedFiles = (files) => {
    setNewUserInfo({ ...newUserInfo, uploadedFiles: files });
  };

  const handleNotes = (event) => {
    setNewUserInfo({ ...newUserInfo, note: event.target.value });
  };
  return (
    <>
      <div>
        <UploadForm onSubmit={handleSubmit} id="uploadForm">
          <FileUpload
            //   accept=".jpg,.png,.jpeg,"\
            accept=".mp4,.jpeg,.png"
            label="Profile Image(s)"
            multiple
            updateFilesCb={updateUploadedFiles}
          />
          <TextareaAutosize
            aria-label="minimum height"
            minRows={5}
            placeholder={i18n.t("add_description")}
            name="notes"
            onChange={(e) => handleNotes(e)}
            form="uploadForm"
            style={{ width: "80%", margin: "auto" }}
          />
          <br />
          <div
            style={{ width: "80%", margin: "auto" }}
            className="row justify-content-end"
          >
            <PrimaryButton className="mb-2" type="submit">
              {i18n.t("upload")}
            </PrimaryButton>
          </div>
        </UploadForm>
      </div>

      <PreviewNotes {...{ kidNotes }} />
    </>
  );
}
const mapDispatchToProps = (dispatch) => ({
  startAddingKidNote: (payload, callback) =>
    dispatch(startAddingKidNote(payload, callback)),
});

export default connect(null, mapDispatchToProps)(KidsFileUpload);
