import React from "react";
import { Button } from "@material-ui/core";
import "./PrimaryButton.css";

export default ({ children, ...props }) => {
  return (
    <Button {...props} className={`PrimaryButton ${props.className}`}>
      {children}
    </Button>
  );
};
