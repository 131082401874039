import { businessHoursActionTypes } from "./businessHours.types";

const initialState = {
  businessHours: [],
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case businessHoursActionTypes.SAVE_BUSINESS_HOURS: {
      return { ...state, businessHours: payload, isLoading: false };
    }
    case businessHoursActionTypes.START_GET_BUSINESS_HOURS: {
      return { ...state, isLoading: true };
    }
    case businessHoursActionTypes.BUSINESS_HOURS_SAGA: {
      return { ...state, isLoading: true };
    }
    default:
      return state;
  }
};
