const clientCompanyRegFields = [
  {
    value: "companyName",
    title: "Company Name",
    fieldType: "text",
    name: "name",
  },
  {
    value: "companyTag",
    title: "Company Tagline",
    fieldType: "text",
    name: "tagLine",
  },
  {
    value: "companyEmail",
    title: "Email Address",
    fieldType: "email",
    name: "email",
  },
  { value: "phoneNo", title: "Phone Number", fieldType: "text", name: "phone" },
  {
    value: "companyAddress",
    title: "Address",
    fieldType: "address",
    name: "address",
  },
  {
    value: "companyDescription",
    title: "Description",
    fieldType: "text",
    name: "description",
  },
];

export { clientCompanyRegFields };
export default clientCompanyRegFields;
