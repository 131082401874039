import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../../i18n";
import SuccessHelper from "../../../helpers/SuccessHelper";
import Modal from "../../../components/Core/Modal/Modal";
import { addInvoicesRequest } from "../../../redux/billing/billing.actions";
import InvoiceTable from "../../../components/Billing/InvoiceTable";

const frequencyMomentMap = {
  Weekly: [1, "w"],
  "Bi-Weekly": [2, "w"],
  Monthly: [1, "M"],
};
export default function NewInvoiceModal({ open, setOpen, invoices }) {
  const dispatch = useDispatch();
  const [data, setData] = useState({});

  if (!invoices) {
    return null;
  }
  const submit = () => {
    console.log(data);
    dispatch(
      addInvoicesRequest(data, {
        success: () => {
          SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
          setOpen(false);
        },
      })
    );
  };
  return (
    <Modal
      open={open}
      closeModal={() => setOpen(false)}
      title={i18n.t("invoices")}
    >
      <InvoiceTable invoices={invoices.entries} />
    </Modal>
  );
}
