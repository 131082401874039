import { doctorsActionTypes } from "./doctors.types";

export const saveDoctors = (doctors) => {
  return {
    type: doctorsActionTypes.SAVE_DOCTORS,
    payload: doctors,
  };
};

export const startDoctorFetching = (callbackMap, payload) => {
  console.log(payload);
  return {
    type: doctorsActionTypes.GET_DOCTORS,
    callbackMap,
    payload,
  };
};

export const startDeletingDoctor = (callbackMap, id) => {
  return {
    type: doctorsActionTypes.DELETE_DOCTOR_SAGA,
    callbackMap,
    doctorId: id,
  };
};

export const deleteDoctor = (id) => {
  console.log("inside delete doctor ", id);
  return {
    type: doctorsActionTypes.DELETE_DOCTOR,
    payload: id,
  };
};

export const startEditingDoctor = (callbackMap, payload) => {
  return {
    type: doctorsActionTypes.EDIT_DOCTOR_SAGA,
    callbackMap,
    payload,
  };
};

export const editDoctor = (payload) => {
  return {
    type: doctorsActionTypes.EDIT_DOCTOR,
    payload,
  };
};

export const startAddingDoctor = (callbackMap, payload) => {
  return {
    type: doctorsActionTypes.ADD_DOCTOR_SAGA,
    callbackMap,
    payload,
  };
};

export const addDoctor = (payload) => {
  return {
    type: doctorsActionTypes.ADD_DOCTOR,
    payload,
  };
};

export const requestFailed = () => {
  return {
    type: doctorsActionTypes.REQUEST_FAILED,
  };
};
