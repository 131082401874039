//a component that has a reusable search component in it:
// props:
// user input (word to search) : string
// employees: array of objects
// return: list of filtered list of employees

import React from "react";
import Search from "../../Core/Search/Search";
import { EmployeeSearchBox } from "../Employee.styles";
import {
  filterEmployees,
  clearEmployeeSearch,
} from "../../../redux/employees/employees.actions";
import { useDispatch } from "react-redux";
import i18n from "../../../i18n";
export default function EmployeeSearch() {
  const dispatch = useDispatch();
  // call back function based on search submit.
  // based on the searched parameter, onSubmit the program update
  // the filtered list by triggering searchEmployee redux action
  // input: search phrase
  // function: triggers action
  // return: void

  const handleEmployeeSearch = (searched_phrase) => {
    dispatch(filterEmployees(searched_phrase));
  };

  const handleEmployeeChange = (e) => {
    if (e.target.value == "") {
      dispatch(clearEmployeeSearch());
    }
  };
  return (
    <EmployeeSearchBox>
      <Search
        placeHolder={i18n.t("search_employee")}
        handleSearch={handleEmployeeSearch}
        handleChange={handleEmployeeChange}
      />
    </EmployeeSearchBox>
  );
}
