import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  DoctorsWrapper,
  Back,
  DoctorCard,
  DoctorItem,
  Items,
  Actions,
  DoctorsControls,
} from "../Medical.styles";

import {
  startDoctorFetching,
  startAddingDoctor,
  startDeletingDoctor,
} from "../../../../redux/doctors/doctors.actions";

import ErrorHelper from "../../../../helpers/ErrorHelper";
import SuccessHelper from "../../../../helpers/SuccessHelper";
import i18n from "../../../../i18n";
import { selectDoctorsData } from "../../../../redux/doctors/doctors.selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import callbackHelper from "../../../../helpers/callbackHelper";

import EditDoctor from "./EditDoctor";
import AddDoctor from "./AddDoctor";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AddIcon from "@material-ui/icons/Add";

function Doctors(props) {
  const { doctors = [] } = props;
  const [modalState, setModalState] = useState({ edit: false, add: false });
  const [currentEditingDoc, setCurrentEditingDoc] = useState({});

  const handleDoctorEdit = (currentDoc) => {
    setModalState({ ...modalState, edit: true });
    setCurrentEditingDoc(currentDoc);
  };
  const handleDoctorDelete = (doc) => {
    setModalState({ ...modalState, delete: true });
    callbackHelper(props.startDeletingDoctor, doc._id, "delete");
  };

  useEffect(() => {
    callbackHelper(props.startDoctorFetching, {
      filters: { kidId: props.kid._id },
    });
  }, []);

  // useEffect(() => {
  //   setdoctorData(doctors);
  // }, [doctors]);

  const handleModalClose = (state) => {
    const newState = { ...modalState };
    newState[state] = false;
    setModalState(newState);
    setCurrentEditingDoc({});
  };
  const handleAddDoctor = () => {
    setModalState({ ...modalState, add: true });
  };
  return (
    <>
      <DoctorsWrapper>
        <DoctorsControls>
          <Back onClick={() => props.handleCategory("main")}>
            <KeyboardBackspaceIcon />
          </Back>
          <Back>
            <AddIcon onClick={() => handleAddDoctor("main")} />
          </Back>
        </DoctorsControls>
        <h1>{i18n.t("doctors")}</h1>
        {doctors.map((doc) => {
          return (
            <DoctorCard>
              <Items>
                <DoctorItem>
                  <span className="mr-1">{i18n.t("doctor_name")}</span>
                  <span>{doc.name}</span>
                </DoctorItem>
                <DoctorItem>
                  <span className="mr-1">{i18n.t("address")}</span>
                  {/* {console.log(typeof doc.address)} */}
                  <span>
                    {typeof doc.address == "string" ||
                    typeof doc.address == "undefined"
                      ? doc.address
                      : doc.address.displayAddress}
                  </span>
                </DoctorItem>
                <DoctorItem>
                  <span className="mr-1">{i18n.t("profession")}</span>
                  <span>{doc.profession}</span>
                </DoctorItem>
                <DoctorItem>
                  <span className="mr-1">{i18n.t("work_phone")}</span>
                  <span>{doc.workPhone}</span>
                </DoctorItem>
                <DoctorItem>
                  <span className="mr-1">{i18n.t("cell_phone")}</span>
                  <span>{doc.cellPhone}</span>
                </DoctorItem>
                <DoctorItem>
                  <span className="mr-1">{i18n.t("other_phone")}</span>
                  <span>{doc.otherPhone}</span>
                </DoctorItem>
                <DoctorItem>
                  <span className="mr-1">{i18n.t("email")}</span>
                  <span>{doc.email}</span>
                </DoctorItem>
              </Items>
              <Actions>
                <EditIcon onClick={() => handleDoctorEdit(doc)} />
                <DeleteForeverIcon onClick={() => handleDoctorDelete(doc)} />
              </Actions>
            </DoctorCard>
          );
        })}
      </DoctorsWrapper>
      {!_.isEmpty(currentEditingDoc) && (
        <EditDoctor
          open={modalState.edit}
          handleModalClose={(state) => handleModalClose(state)}
          {...{ currentEditingDoc }}
        />
      )}
      {modalState.add && (
        <AddDoctor
          open={modalState.add}
          handleModalClose={(state) => handleModalClose(state)}
          kid={props.kid}
        />
      )}
    </>
  );
}

const actions = {
  startDoctorFetching,
  startDeletingDoctor,
  startAddingDoctor,
};

const mapStateToProps = createStructuredSelector({
  doctors: selectDoctorsData,
});

export default connect(mapStateToProps, actions)(Doctors);
