import { logoutActionTypes } from "./logout.types";

const initialState = {
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case logoutActionTypes.LOGOUT_USER_SAGA: {
      return { isLoading: true };
    }
    case logoutActionTypes.LOGOUT_USER: {
      return { isLoading: false };
    }
    case logoutActionTypes.REQUEST_FAILURE: {
      return { isLoading: false };
    }
    default:
      return state;
  }
};
