import React, { useState } from "react";
import { BusinessHoursWrapper, ChangeSchedule } from "./BusinessHours.styles";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import EditHours from "./EditHours";
import i18n from "../../i18n";

export default function BusinessHoursComponent({ schedules }) {
  const [editSchedule, setEditSchedule] = useState(false);
  const handleModalClose = () => {
    setEditSchedule(false);
  };
  const handleModalOpen = () => {
    setEditSchedule(true);
  };
  const renderBusinessHours = () => {
    return (
      <>
        <div className="row justify-content-between align-items-center">
          <h2 style={{ textAlign: "center" }}>{i18n.t("business_hours")}</h2>
          <ChangeSchedule onClick={handleModalOpen}>
            <AddIcon />
          </ChangeSchedule>
        </div>
        <Table style={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
          <TableHead className="secondary">
            <TableRow>
              <TableCell align="center">{i18n.t("day")}</TableCell>
              <TableCell align="center">{i18n.t("start")}</TableCell>
              <TableCell align="center">{i18n.t("end")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schedules.map((schedule, i) => {
              return (
                schedule.active == true && (
                  <TableRow key={`day-${i}`}>
                    <TableCell align="center" component="th" scope="row">
                      {schedule.day}
                    </TableCell>
                    <TableCell align="center">{schedule.start}</TableCell>
                    <TableCell align="center">{schedule.end}</TableCell>
                  </TableRow>
                )
              );
            })}
          </TableBody>
        </Table>
      </>
    );
  };
  return (
    <BusinessHoursWrapper>
      {renderBusinessHours()}
      <EditHours open={editSchedule} {...{ handleModalClose, schedules }} />
    </BusinessHoursWrapper>
  );
}
