import React, { Component } from "react";
import TableGenerator from "../../components/Core/TableGenerator/TableGenerator/TableGenerator.component";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  startActivityFetching,
  startEditingActivity,
  startAddingActivity,
  startDeletingActivity,
} from "../../redux/activities/activities.actions";
import { selectCartItems } from "../../redux/activities/activities.selectors";
import Page from "../../components/Page/Page";
import FormModal from "../../components/Core/FormModal/FormModal";
import store from "../../redux/store";
import ErrorHelper from "../../helpers/ErrorHelper";
import SuccessHelper from "../../helpers/SuccessHelper";
import i18n from "../../i18n";
import { customHistory } from "../../routes/index";

class Activities extends Component {
  state = {
    editModalOpen: false,
    addModalOpen: false,
    editing: {},
  };
  schema = [
    { value: "name", title: i18n.t("name"), fieldType: "text" },
    {
      value: "status",
      title: i18n.t("status"),
      fieldType: "select",
      options: [
        { name: i18n.t("active"), value: "Active" },
        { name: i18n.t("inactive"), value: "Inactive" },
      ],
    },
    {
      value: "type",
      title: i18n.t("type"),
      fieldType: "select",
      options: [
        { name: i18n.t("indoor"), value: "Indoor" },
        { name: i18n.t("outdoor"), value: "Outdoor" },
      ],
    },
    { value: "description", title: i18n.t("description"), fieldType: "text" },
    { value: "notes", title: i18n.t("notes"), fieldType: "text" },
    {
      value: "materialNeeded",
      title: i18n.t("required_materials"),
      fieldType: "text",
    },
    { value: "minimumAge", title: i18n.t("minimum_age"), fieldType: "text" },
    {
      value: "actions",
      title: i18n.t("actions"),
      fieldType: "actions",
      style: { width: 80 },
      actions: ["edit", "delete"],
    },
  ];

  async componentDidMount() {
    document.title = i18n.t("activities");
    Promise.all([
      new Promise((success, failure) => {
        this.props.startActivityFetching({ success, failure });
      }),
    ])
      .then((res) => {
        console.log(res, "response ok ok..............");
      })
      .catch((err) => {
        this.setState({ isloading: false });
        console.log("something_went_wrong");
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  }

  validateActivity = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = i18n.t("required");
    }
    if (!values.status) {
      errors.status = i18n.t("required");
    }
    if (!values.type) {
      errors.type = i18n.t("required");
    }

    return errors;
  };
  onAdd = (values) => {
    new Promise((success, failure) => {
      this.props.startAddingActivity({ success, failure }, values);
    })
      .then((res) => {
        SuccessHelper.handleToastSuccess(i18n.t("successfully_created"), true);
        this.setState({
          editing: {},
          addModalOpen: false,
        });
      })
      .catch((err) => {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  };

  onEdit = (data) => {
    new Promise((success, failure) => {
      this.props.startEditingActivity(
        { success, failure },
        {
          _id: this.state.editing._id,
          data,
        }
      );
    })
      .then((res) => {
        SuccessHelper.handleToastSuccess(i18n.t("successfully_updated"), true);
      })
      .catch((err) => {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  };

  renderEditModal = () => {
    return (
      <FormModal
        schema={this.schema}
        open={this.state.editModalOpen}
        onSubmit={this.onEdit}
        validateForm={this.validateActivity}
        values={this.state.editing}
        closeModal={() => {
          this.setState({
            editing: {},
            editModalOpen: false,
          });
        }}
      />
    );
  };

  renderAddModal = () => {
    return (
      <FormModal
        title={i18n.t("activity")}
        schema={this.schema}
        open={this.state.addModalOpen}
        onSubmit={this.onAdd}
        validateForm={this.validateActivity}
        closeModal={() => {
          this.setState({
            addModalOpen: false,
          });
        }}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderEditModal()}
        {this.renderAddModal()}
        <Page
          title="activities"
          resource="Activity"
          data={this.props.activities.activities}
          onDelete={this.props.startDeletingActivity}
          onEdit={(data) => {
            this.setState({
              editing: data,
              editModalOpen: true,
            });
          }}
          editOnRowClick
          onCreate={() => {
            this.setState({
              editing: {},
              addModalOpen: true,
            });
          }}
          schema={this.schema}
        ></Page>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startActivityFetching: (callbackMap) =>
    dispatch(startActivityFetching(callbackMap)),
  startDeletingActivity: (id, callbackMap) =>
    dispatch(startDeletingActivity(id, callbackMap)),
  startEditingActivity: (callbackMap, ...args) =>
    dispatch(startEditingActivity(callbackMap, ...args)),
  startAddingActivity: (callbackMap, ...args) =>
    dispatch(startAddingActivity(callbackMap, ...args)),
});

const mapStateToProps = createStructuredSelector({
  activities: selectCartItems,
});
export default connect(mapStateToProps, mapDispatchToProps)(Activities);
