import React, { useState } from "react";
import { KidsScheduleWrapper } from "./KidsSchedule.styles";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import EditHours from "./EditHours";
import i18n from "../../i18n";
import { Button } from "@material-ui/core";

export default function KidsSchedule({
  schedule = [
    { isAttending: false, start: "00:00", end: "23:59" },
    { isAttending: false, start: "00:00", end: "23:59" },
    { isAttending: false, start: "00:00", end: "23:59" },
    { isAttending: false, start: "00:00", end: "23:59" },
    { isAttending: false, start: "00:00", end: "23:59" },
    { isAttending: false, start: "00:00", end: "23:59" },
    { isAttending: false, start: "00:00", end: "23:59" },
  ],
  kidId,
  changeEditingTableRowState,
}) {
  const [editSchedule, setEditSchedule] = useState(false);
  const handleModalClose = () => {
    setEditSchedule(false);
  };
  const handleModalOpen = () => {
    setEditSchedule(true);
  };
  const dayMap = [
    i18n.t("sunday"),
    i18n.t("monday"),
    i18n.t("tuesday"),
    i18n.t("wednesday"),
    i18n.t("thursday"),
    i18n.t("friday"),
    i18n.t("saturday"),
  ];
  const renderKidsSchedule = () => {
    return (
      <>
        <div className="row justify-content-between">
          <h2> {i18n.t("schedule")} </h2>
          <Button onClick={handleModalOpen}>{i18n.t("edit")}</Button>
        </div>
        <Table style={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
          <TableHead className="secondary">
            <TableRow>
              <TableCell align="center">{i18n.t("day")}</TableCell>
              <TableCell align="center">{i18n.t("start")}</TableCell>
              <TableCell align="center">{i18n.t("end")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schedule.map((element, i) => {
              return (
                element.isAttending && (
                  <TableRow key={`day-${i}`}>
                    <TableCell align="center" component="th" scope="row">
                      {i18n.t(dayMap[i])}
                    </TableCell>
                    <TableCell align="center">{element.start}</TableCell>
                    <TableCell align="center">{element.end}</TableCell>
                  </TableRow>
                )
              );
            })}
          </TableBody>
        </Table>
      </>
    );
  };
  return (
    <KidsScheduleWrapper>
      {renderKidsSchedule()}
      <EditHours
        open={editSchedule}
        changeEditingTableRowState={changeEditingTableRowState}
        {...{ handleModalClose, schedule, kidId }}
      />
    </KidsScheduleWrapper>
  );
}
