import { businessHoursActionTypes } from "./businessHours.types";

export const saveBusinessHours = (payload) => {
  return {
    type: businessHoursActionTypes.SAVE_BUSINESS_HOURS,
    payload,
  };
};

export const startGettingBusinessHours = () => {
  return {
    type: businessHoursActionTypes.START_GET_BUSINESS_HOURS,
  };
};

export const startSavingBusinessHours = (payload) => {
  return {
    type: businessHoursActionTypes.BUSINESS_HOURS_SAGA,
    payload,
  };
};
