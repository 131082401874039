import styled from "styled-components/macro";

export const ImmunizationRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 2em 2em;
  grid-template-areas:
    ". . ."
    ". . .";
  justify-items: center;
  width: 100%;
  height: 100%;
`;
export const ImmunizationCell = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ImmunizationCellTitle = styled.h4`
  padding: 0;
  margin: 0;
  margin-bottom: 16px;
`;

export const ImmunizationForm = styled.form`
  background: aliceblue;
  padding: 22px;
  margin-bottom: 20px;
`;

export const ImmunizationButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 22px;
`;

export const AddWrapper = styled.div`
  width: 500px;
  height: 60vh;
  padding: 20px;
`;
