import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import _ from "lodash";
import { EditActions, RequirementsItems } from "../Medical.styles";
import i18n from "../../../../i18n";
import PrimaryButton from "../../../Core/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../../Core/Buttons/SecondaryButton/SecondaryButton";
import Modal from "../../../Core/Modal/Modal";

export default function EditRequirement({
  open,
  handleModalClose,
  currentEditingRequirement,
  startEditingRequirment,
  callbackHelper,
}) {
  const requirementItemsMap = [
    { title: i18n.t("requirement"), value: "name" },
    { title: i18n.t("description"), value: "description" },
  ];
  const onSubmit = (values) => {
    console.log({ ...values, _id: currentEditingRequirement._id });
    // update redux
    callbackHelper(
      startEditingRequirment,
      {
        data: values,
        _id: currentEditingRequirement._id,
      },
      "update"
    );
    handleModalClose("edit");
  };

  const handleEditModalCancel = () => {
    handleModalClose("edit");
  };

  return (
    <Modal
      title={i18n.t("edit_requirement")}
      open={open}
      closeModal={() => handleEditModalCancel()}
    >
      <Form
        onSubmit={onSubmit}
        // validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {requirementItemsMap.map(({ title, value }, i) => {
              return (
                <>
                  <Field
                    name={value}
                    key={`edit-requirement-${i}`}
                    initialValue={currentEditingRequirement[value]}
                    render={({ input, meta }) => {
                      const error = meta.touched && meta.error;
                      return (
                        <RequirementsItems type={value} className="mb-2">
                          <TextField
                            label={title}
                            fullWidth
                            {...input}
                            error={error}
                            helperText={error ? error : ""}
                          />
                        </RequirementsItems>
                      );
                    }}
                  />
                </>
              );
            })}
            <EditActions>
              <SecondaryButton onClick={() => handleEditModalCancel()}>
                {i18n.t("cancel")}
              </SecondaryButton>
              <PrimaryButton className="ml-1" type="submit">
                {i18n.t("submit")}
              </PrimaryButton>
            </EditActions>
          </form>
        )}
      />
    </Modal>
  );
}
