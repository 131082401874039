import { takeEvery, put, call } from "redux-saga/effects";
import { kidsActionTypes } from "./kids.types";

import {
  saveKids,
  deleteKid,
  editKid,
  addKid,
  addingKidNote,
  deletingKidNote,
  deletingKidNoteAsset,
  setKidsSchedule,
  addKidAllergy,
  deleteKidAllergy,
  addKidsBilling,
  setActiveBilling,
} from "./kids.actions";

import axios from "../../axios";

const getData = async (payload) => {
  try {
    const response = await axios.get("/kid", { params: payload.params });
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* fetchKidsAsync(payload) {
  const data = yield call(getData, payload);

  yield put(saveKids(data));
}

const deleteData = async (payload) => {
  try {
    const response = await axios.delete("/kid", {
      params: { ids: payload.kidId },
    });
    payload?.callbackMap?.success(response);
    return response.data._id;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* deleteKidAsync(payload) {
  const deleteVal = yield call(deleteData, payload);
  yield put(deleteKid(deleteVal));
}

const deleteAllergyData = async (payload) => {
  try {
    const response = await axios.post("/kid/removeKidAllergy", payload.payload);
    payload?.callbackMap?.success(response);
    return response.data.kidAllergy;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* deleteKidAllergyAsync(payload) {
  const deleteVal = yield call(deleteAllergyData, payload);
  yield put(deleteKidAllergy(deleteVal));
}

const editData = async (payload) => {
  try {
    const response = await axios.put("/kid", {
      _id: payload.payload._id,
      data: payload.payload.data,
    });
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* editKidAsync(payload) {
  const responseData = yield call(editData, payload);
  yield put(editKid(responseData));
}

const addData = async (payload) => {
  try {
    const response = await axios.post("/kid", payload.payload);
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* addKidAsync(payload) {
  const responseData = yield call(addData, payload);
  yield put(addKid(responseData));
}

const addAllergyData = async (payload) => {
  try {
    const response = await axios.post("/kid/addKidAllergy", payload.payload);
    payload?.callbackMap?.success(response);
    return response.data.kidAllergy;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* addKidAllergyAsync(payload) {
  const responseData = yield call(addAllergyData, payload);
  yield put(addKidAllergy(responseData));
}

const addKidNote = async ({ payload }) => {
  const response = await axios.post("/kid/notes", payload);
  return response.data.data;
};

export function* addKidNotesAsync(payload) {
  const responseData = yield call(addKidNote, payload);
  payload.callback(responseData);

  yield put(addingKidNote(responseData));
}

const deleteKidNote = async ({ payload }) => {
  const response = await axios.delete("/kid/deleteNote", {
    params: { ids: payload },
  });
  return response.data.data;
};

export function* deleteKidNoteAsync(id) {
  const deleteVal = yield call(deleteKidNote, id);
  yield put(deletingKidNote(deleteVal));
}

const deleteKidNoteAsset = async ({ payload }) => {
  const response = await axios.delete("/kid/deleteAsset", {
    params: { ids: payload },
  });
  return response.data.data;
};

export function* deleteKidNoteAssetAsync(id) {
  const deleteVal = yield call(deleteKidNoteAsset, id);
  yield put(deletingKidNoteAsset(deleteVal));
}

const setKidsScheduleRequest = async ({ payload, callbackMap }) => {
  try {
    const response = await axios.post("/kid/setKidSchedule", {
      ...payload,
    });
    callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    callbackMap?.failure(e);
  }
};

export function* setKidsScheduleSaga(payload) {
  const res = yield call(setKidsScheduleRequest, payload);
  yield put(setKidsSchedule(res));
}

const addKidsBillingRequest = async ({ payload, callbackMap }) => {
  try {
    const response = await axios.post("/kid/addKidBilling", {
      ...payload,
    });
    callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    callbackMap?.failure(e);
  }
};

export function* setKidsBillingSaga(payload) {
  const res = yield call(addKidsBillingRequest, payload);
  yield put(addKidsBilling(res));
}

const setActiveBillingRequest = async ({ payload, callbackMap }) => {
  try {
    const response = await axios.post("/kid/setActiveBilling", {
      ...payload,
    });
    callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    callbackMap?.failure(e);
  }
};

export function* setActiveBillingSaga(payload) {
  const res = yield call(setActiveBillingRequest, payload);
  yield put(setActiveBilling(res));
}

export function* watchKids() {
  yield takeEvery(kidsActionTypes.GET_KIDS, fetchKidsAsync);
  yield takeEvery(kidsActionTypes.DELETE_KID_SAGA, deleteKidAsync);
  yield takeEvery(
    kidsActionTypes.DELETE_KID_ALLERGY_SAGA,
    deleteKidAllergyAsync
  );

  yield takeEvery(kidsActionTypes.EDIT_KID_SAGA, editKidAsync);

  yield takeEvery(kidsActionTypes.ADD_KID_SAGA, addKidAsync);
  yield takeEvery(kidsActionTypes.ADD_KID_ALLERGY_SAGA, addKidAllergyAsync);
  yield takeEvery(kidsActionTypes.ADD_KID_NOTES_SAGA, addKidNotesAsync);
  yield takeEvery(kidsActionTypes.DELETE_KID_NOTES_SAGA, deleteKidNoteAsync);
  yield takeEvery(
    kidsActionTypes.DELETE_KID_NOTES_ASSET_SAGA,
    deleteKidNoteAssetAsync
  );
  yield takeEvery(kidsActionTypes.SET_KIDS_SCHEDULE_SAGA, setKidsScheduleSaga);
  yield takeEvery(kidsActionTypes.ADD_KID_BILLING_SAGA, setKidsBillingSaga);
  yield takeEvery(
    kidsActionTypes.SET_ACTIVE_BILLING_SAGA,
    setActiveBillingSaga
  );
}
