import React from "react";
import { Form } from "react-final-form";
import {
  ClientRegistration,
  ClientRegForm,
  FormItemsGrid,
  ButtonsWrapper,
  TableItem,
  ErrorMessage,
} from "./clientPersonalReg.styles";
import Button from "../../Core/Buttons/SubmitButton";
import TextField from "@material-ui/core/TextField";
import { Field } from "react-final-form";
import PlacesAutocomplete from "../../Core/PlacesAutocomplete/PlacesAutoComplete";
import i18n from "../../../i18n";

export default function ClientPersonalProfile({
  currentUser,
  handleSubmitClientPersonalProfile,
}) {
  const clientPersonalRegFields = [
    { value: "firstName", title: i18n.t("first_name"), fieldType: "text" },
    { value: "lastName", title: i18n.t("last_name"), fieldType: "text" },
    { value: "email", title: i18n.t("email_address"), fieldType: "email" },
    { value: "phoneNo", title: i18n.t("phone_number"), fieldType: "text" },
    { value: "address", title: i18n.t("address"), fieldType: "address" },
  ];
  const validateClientPersonaProfile = (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = i18n.t("required");
    }
    if (!values.lastName) {
      errors.lastName = i18n.t("required");
    }
    if (!values.email) {
      errors.email = i18n.t("required");
    }
    if (!values.phoneNo) {
      errors.phoneNo = i18n.t("required");
    }
    if (!values.address) {
      errors.address = "Required";
    }
    return errors;
  };

  const submitClientPersonalInfo = (values) => {
    handleSubmitClientPersonalProfile(values);
  };

  const renderClientPersonalInfoFields = (userInfo) => {
    return clientPersonalRegFields.map(({ value, title, fieldType }, index) => {
      return (
        <Field initialValue={currentUser[value]} name={value}>
          {({ input, meta }) => {
            switch (fieldType) {
              case "text":
              case "email":
                return (
                  <TableItem type={value}>
                    <TextField
                      {...input}
                      label={title}
                      type={fieldType}
                      fullWidth
                    />
                    {meta.error && meta.touched ? (
                      <ErrorMessage>{meta.error}</ErrorMessage>
                    ) : (
                      <ErrorMessage></ErrorMessage>
                    )}
                  </TableItem>
                );
              case "date":
                return (
                  <TableItem type={value}>
                    <TextField
                      {...input}
                      label={title}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {meta.error && meta.touched ? (
                      <ErrorMessage>{meta.error}</ErrorMessage>
                    ) : (
                      <ErrorMessage></ErrorMessage>
                    )}
                  </TableItem>
                );
              case "address":
                if (
                  currentUser != {} &&
                  value &&
                  currentUser[value] &&
                  currentUser[value].displayAddress
                ) {
                  return (
                    <TableItem type={value}>
                      <PlacesAutocomplete
                        name="address"
                        input={input}
                        defaultAddress={currentUser[value].displayAddress}
                      />
                      {meta.error && meta.touched ? (
                        <ErrorMessage>{meta.error}</ErrorMessage>
                      ) : (
                        <ErrorMessage></ErrorMessage>
                      )}
                    </TableItem>
                  );
                }
                break;

              default:
                return null;
            }
          }}
        </Field>
      );
    });
  };

  return (
    <Form
      onSubmit={(v) => submitClientPersonalInfo(v)}
      validate={validateClientPersonaProfile}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <>
          <ClientRegistration>
            <ClientRegForm onSubmit={handleSubmit}>
              <h2 style={{ marginBottom: "2em" }}>
                {i18n.t("personal_profile")}
              </h2>
              <FormItemsGrid>
                {renderClientPersonalInfoFields(currentUser)}
              </FormItemsGrid>
              <ButtonsWrapper>
                <Button
                  style={{ marginLeft: "20px" }}
                  type="submit"
                  disabled={submitting}
                >
                  {i18n.t("update_profile")}
                </Button>
              </ButtonsWrapper>
            </ClientRegForm>
          </ClientRegistration>
        </>
      )}
    />
  );
}
