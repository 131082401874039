import { guardiansActionTypes } from "./guardians.types";

export const saveGuardians = (kids) => {
  return {
    type: guardiansActionTypes.SAVE_GUARDIANS,
    payload: kids,
  };
};

export const startGuardianFetching = (callbackMap, kidId) => {
  return {
    type: guardiansActionTypes.GET_GUARDIANS,
    callbackMap,
    kidId,
  };
};

export const startDeletingGuardian = (id, callbackMap) => {
  return {
    type: guardiansActionTypes.DELETE_GUARDIAN_SAGA,
    callbackMap,
    guardianId: id,
  };
};

export const deleteGuardian = (id) => {
  return {
    type: guardiansActionTypes.DELETE_GUARDIAN,
    payload: id,
  };
};

export const startEditingGuardian = (callbackMap, payload) => {
  return {
    type: guardiansActionTypes.EDIT_GUARDIAN_SAGA,
    callbackMap,
    payload,
  };
};

export const editGuardian = (payload) => {
  return {
    type: guardiansActionTypes.EDIT_GUARDIAN,
    payload,
  };
};

export const startAddingGuardian = (callbackMap, payload) => {
  return {
    type: guardiansActionTypes.ADD_GUARDIAN_SAGA,
    callbackMap,
    payload,
  };
};

export const addGuardian = (payload) => {
  return {
    type: guardiansActionTypes.ADD_GUARDIAN,
    payload,
  };
};

export const requestFailed = () => {
  return {
    type: guardiansActionTypes.REQUEST_FAILED,
  };
};
