import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

const EditModal = styled.div`
  background-color: white;
  margin: 0;
  padding: 0;
  width: 60vw;
  height: 80vh;
`;

const EditModalTitle = styled.h2`
  margin: 0;
  padding: 0;
  text-align: center;
  padding: 2em;
`;

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: "grey",
  },
  tabsBar: {
    "& .MuiTabs-indicator": {
      // display: "none",
      backgroundColor: "orange",
    },
  },
}));

const FormBodyContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const FormBody = styled.div`
  width: 90%;
  /* gap: 1.5em; */
`;

const FormBodyView = styled(FormBody)`
  gap: 1.5em;
`;

const ErrorMessage = styled.div`
  min-height: 1.9em;
  padding: 0.2em 0 0 0.4em;
  color: red;
`;

export {
  EditModal,
  EditModalTitle,
  useStyles,
  FormBody,
  FormBodyContainer,
  ErrorMessage,
  FormBodyView,
};
