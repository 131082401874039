import React from "react";
import { Form } from "react-final-form";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import "./DraggableDialog.css";
export default function EventModal({
  title,
  content,
  actions,
  onSubmit,
  handleClose,
  validateForm,
  className = "",
}) {
  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} className={`${className} ${props.className}`} />
      </Draggable>
    );
  }
  return (
    <div>
      <Dialog
        open
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        disableBackdropClick
        hideBackdrop
      >
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
          className="draggable-dialog-header"
        >
          {title}
        </DialogTitle>
        <Form
          onSubmit={onSubmit}
          validate={validateForm}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="spacing">
              <DialogContent>{content}</DialogContent>
              {actions ? <DialogActions>{actions}</DialogActions> : null}
            </form>
          )}
        />
      </Dialog>
    </div>
  );
}
