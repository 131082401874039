import { kidsActionTypes } from "./kids.types";

export const saveKids = (kids) => {
  return {
    type: kidsActionTypes.SAVE_KIDS,
    payload: kids,
  };
};

export const startKidFetching = (callbackMap, params) => {
  return {
    type: kidsActionTypes.GET_KIDS,
    callbackMap,
    params,
  };
};

export const startDeletingKid = (id, callbackMap) => {
  return {
    type: kidsActionTypes.DELETE_KID_SAGA,
    callbackMap,
    kidId: id,
  };
};

export const deleteKid = (id) => {
  return {
    type: kidsActionTypes.DELETE_KID,
    payload: id,
  };
};

export const startDeletingKidAllergy = (callbackMap, payload) => {
  return {
    type: kidsActionTypes.DELETE_KID_ALLERGY_SAGA,
    callbackMap,
    payload,
  };
};

export const deleteKidAllergy = (id) => {
  return {
    type: kidsActionTypes.DELETE_KID_ALLERGY,
    payload: id,
  };
};

export const startEditingKid = (callbackMap, payload) => {
  return {
    type: kidsActionTypes.EDIT_KID_SAGA,
    callbackMap,
    payload,
  };
};

export const editKid = (payload) => {
  return {
    type: kidsActionTypes.EDIT_KID,
    payload,
  };
};

export const startAddingKid = (callbackMap, payload) => {
  return {
    type: kidsActionTypes.ADD_KID_SAGA,
    callbackMap,
    payload,
  };
};

export const addKid = (payload) => {
  return {
    type: kidsActionTypes.ADD_KID,
    payload,
  };
};

export const startAddingKidAllergy = (callbackMap, payload) => {
  return {
    type: kidsActionTypes.ADD_KID_ALLERGY_SAGA,
    callbackMap,
    payload,
  };
};

export const addKidAllergy = (payload) => {
  return {
    type: kidsActionTypes.ADD_KID_ALLERGY,
    payload,
  };
};

export const startAddingKidNote = (payload, callback) => {
  return {
    type: kidsActionTypes.ADD_KID_NOTES_SAGA,
    payload,
    callback,
  };
};

export const addingKidNote = (payload) => {
  return {
    type: kidsActionTypes.ADD_KID_NOTES,
    payload,
  };
};
export const StartDeletingKidNote = (id) => {
  return {
    type: kidsActionTypes.DELETE_KID_NOTES_SAGA,
    payload: id,
  };
};
export const StartDeletingKidNoteAsset = (id) => {
  return {
    type: kidsActionTypes.DELETE_KID_NOTES_ASSET_SAGA,
    payload: id,
  };
};

export const deletingKidNote = (ids) => {
  return {
    type: kidsActionTypes.DELETE_KID_NOTES,
    payload: ids,
  };
};

export const deletingKidNoteAsset = (ids) => {
  return {
    type: kidsActionTypes.DELETE_KID_NOTES_ASSET,
    payload: ids,
  };
};

export const startSettingKidsSchedule = (payload, callbackMap) => {
  return {
    type: kidsActionTypes.SET_KIDS_SCHEDULE_SAGA,
    payload,
    callbackMap,
  };
};

export const setKidsSchedule = (payload) => {
  return {
    type: kidsActionTypes.SET_KIDS_SCHEDULE,
    payload,
  };
};

export const addKidsBillingRequest = (payload, callbackMap) => {
  return {
    type: kidsActionTypes.ADD_KID_BILLING_SAGA,
    payload,
    callbackMap,
  };
};

export const addKidsBilling = (payload) => {
  return {
    type: kidsActionTypes.ADD_KID_BILLING,
    payload,
  };
};

export const setActiveBillingRequest = (payload, callbackMap) => {
  return {
    type: kidsActionTypes.SET_ACTIVE_BILLING_SAGA,
    payload,
    callbackMap,
  };
};

export const setActiveBilling = (payload) => {
  return {
    type: kidsActionTypes.SET_ACTIVE_BILLING,
    payload,
  };
};
