import React from "react";
import { Form, Field } from "react-final-form";
import TextField from "@material-ui/core/TextField";
import SubmitButton from "../../components/Core/Buttons/SubmitButton";
import "./FormModal.css";
import { useSelector } from "react-redux";
import Autocomplete from "../Core/Autocomplete/Autocomplete";
import i18n from "../../i18n";

export default function AddKidForm({ validateFormKid, onSubmitKid }) {
  const kids = useSelector((s) => {
    return s.kids.kids.map((kid) => {
      return {
        name: kid.firstName + " " + kid.lastName,
        ...kid,
      };
    });
  });
  const onSubmit = ({ kids }) => {
    if (kids?.length) {
      onSubmitKid(kids);
    }
  };
  return (
    <Form
      onSubmit={onSubmit}
      validate={validateFormKid}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="kids"
            render={({ input, meta }) => (
              <div>
                <Autocomplete
                  {...input}
                  multiple
                  label={i18n.t("add_kids_to_group")}
                  options={kids || []}
                  error={meta.touched && meta.error}
                />
              </div>
            )}
          />
          <div className="kids-list"></div>
          <SubmitButton type="submit">{i18n.t("add_kid")}</SubmitButton>
        </form>
      )}
    />
  );
}
