import React, { useEffect, useState } from "react";
import BusinessHoursComponent from "../../components/BusinessHours/BusinessHours";
import { startGettingBusinessHours } from "../../redux/businessHours/businessHours.actions";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../i18n";
export default function BusinessHoursPage() {
  const dispatch = useDispatch();
  const schedules = useSelector((s) => s.businessHours.businessHours);
  useEffect(() => {
    document.title = i18n.t("business_hours");

    dispatch(startGettingBusinessHours());
  }, []);

  return (
    <>
      <BusinessHoursComponent {...{ schedules }} />
    </>
  );
}
