export default {
  GET_INVOICE: "GET_INVOICE",
  GET_INVOICE_SAGA: "GET_INVOICE_SAGA",

  DELETE_INVOICE: "DELETE_INVOICE",
  DELETE_INVOICE_SAGA: "DELETE_INVOICE_SAGA",

  EDIT_INVOICE: "EDIT_INVOICE",
  EDIT_INVOICE_SAGA: "EDIT_INVOICE_SAGA",

  ADD_INVOICE: "ADD_INVOICE",
  ADD_INVOICE_SAGA: "ADD_INVOICE_SAGA",

  GET_AR_AGING: "GET_AR_AGING",
  GET_AR_AGING_SAGA: "GET_AR_AGING_SAGA",

  REQUEST_FAILED: "BILLING_REQUEST_FAILED",
};
