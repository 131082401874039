import { combineReducers } from "redux";
import activitiesReducer from "../activities/activities.reducer";
import allergiesReducer from "../allergies/allergies.reducer";
import signFormReducer from "../signForm/signForms.reducer";
import mealsReducer from "../meals/meals.reducer";
import kidsReducer from "../kids/kids.reducer";
import groupsReducer from "../groups/groups.reducer";
import employeesReducer from "../employees/employees.reducer";
import clientReducer from "../authentication/ClientRegistration/clientRegistration.reducer";
import eventsReducer from "../events/events.reducer";
import langDropDownReducer from "../langDropDown/langDropDown.reducer";
import activeUserReducer from "../activeUser/activeUser.reducer";
import guardianReducer from "../guardians/guardians.reducer";
import immunizationReducer from "../Immunization/immunization.reducer";
import doctorReducer from "../doctors/doctors.reducer";
import conditionReducer from "../conditions/conditions.reducer";
import requirmentReducer from "../requirments/requirments.reducer";
import businessHoursReducer from "../businessHours/businessHours.reducer";
import incidentsReducer from "../incidents/incidents.reducer";
import logoutReducer from "../authentication/logout/logout.reducer";
import loginReducer from "../authentication/login/login.reducer";
import immunizationTypeReducer from "../immunizationType/immunizationType.reducer";
import attendance from "../attendance/attendance.reducer";
import billing from "../billing/billing.reducer";

export default combineReducers({
  activities: activitiesReducer,
  allergies: allergiesReducer,
  meals: mealsReducer,
  kids: kidsReducer,
  signForms: signFormReducer,
  groups: groupsReducer,
  employees: employeesReducer,
  clients: clientReducer,
  events: eventsReducer,
  language: langDropDownReducer,
  activeUser: activeUserReducer,
  guardian: guardianReducer,
  immunization: immunizationReducer,
  doctors: doctorReducer,
  conditions: conditionReducer,
  requirments: requirmentReducer,
  businessHours: businessHoursReducer,
  incidents: incidentsReducer,
  logout: logoutReducer,
  login: loginReducer,
  immunizationType: immunizationTypeReducer,
  attendance,
  billing,
});
