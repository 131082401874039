export const kidsActionTypes = {
  GET_KIDS: "GET_KIDS",
  SAVE_KIDS: "SAVE_KIDS",

  DELETE_KID: "DELETE_KID",
  DELETE_KID_SAGA: "DELETE_KID_SAGA",

  EDIT_KID: "EDIT_KID",
  EDIT_KID_SAGA: "EDIT_KID_SAGA",

  ADD_KID: "ADD_KID",
  ADD_KID_SAGA: "ADD_KID_SAGA",

  ADD_KID_NOTES: "ADD_KID_NOTES",
  ADD_KID_NOTES_SAGA: "ADD_KID_NOTES_SAGA",

  DELETE_KID_NOTES: "DELETE_KID_NOTES",
  DELETE_KID_NOTES_SAGA: "DELETE_KID_NOTES_SAGA",

  DELETE_KID_NOTES_ASSET: "DELETE_KID_NOTES_ASSET",
  DELETE_KID_NOTES_ASSET_SAGA: "DELETE_KID_NOTES_ASSET_SAGA",

  SET_KIDS_SCHEDULE: "SET_KIDS_SCHEDULE",
  SET_KIDS_SCHEDULE_SAGA: "SET_KIDS_SCHEDULE_SAGA",

  DELETE_KID_ALLERGY: "DELETE_KID_ALLERGY",
  DELETE_KID_ALLERGY_SAGA: "DELETE_KID_ALLERGY_SAGA",

  ADD_KID_ALLERGY: "ADD_KID_ALLERGY",
  ADD_KID_ALLERGY_SAGA: "ADD_KID_ALLERGY_SAGA",

  ADD_KID_BILLING: "ADD_KID_BILLING",
  ADD_KID_BILLING_SAGA: "ADD_KID_BILLING_SAGA",

  SET_ACTIVE_BILLING: "SET_KID_ACTIVE_BILLING",
  SET_ACTIVE_BILLING_SAGA: "SET_KID_ACTIVE_BILLING_SAGA",
};
