import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { TextField } from "@material-ui/core";

import i18n from "../../i18n";
import {
  getAttendanceRequest,
  saveAttendanceRequest,
} from "../../redux/attendance/attendance.actions";
import moment from "moment";
import Modal from "../../components/Core/Modal/Modal";

import BasicTable from "../../components/Core/BasicTable";
import { Checkbox } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import Datepicker from "../../components/Core/Datepicker/Datepicker";
import { useMemo } from "react";
import { useEffect } from "react";

const optionFormatter = (element) => ({ ...element, value: element._id });

export default function EventModal({ open, setOpen, defaultDate }) {
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const [attendanceMap, setAttendanceMap] = useState({});

  useEffect(() => {
    if (defaultDate && open) {
      setDate(defaultDate);
    }
  }, [defaultDate, open]);
  const kids = useSelector((s) => s.kids.kids);
  const formattedKids = useMemo(() => {
    return kids.map(optionFormatter);
  }, [kids]);
  const attendances = useSelector((s) => s.attendance.data || []);
  const [shouldBePresentList, shouldNoBePresentList] = useMemo(() => {
    const map = {};
    attendances.map((attendance) => {
      map[attendance.kidId] = attendance;
    });
    setAttendanceMap(map);
    const momented = moment(date);
    const dayOfWeekNumber = momented.day();
    const shouldBePresentList = [];
    const shouldNoBePresentList = [];
    const sortedKids = formattedKids.sort((a, b) => {
      const aSchedule = a.schedule?.[dayOfWeekNumber];
      const bSchedule = b.schedule?.[dayOfWeekNumber];
      if (!aSchedule?.isAttending && !bSchedule?.isAttending) {
        return 0;
      }
      if (!aSchedule?.isAttending) {
        return -1;
      }
      if (!bSchedule?.isAttending) {
        return 1;
      }
      const momentedAStart = moment(aSchedule.start, "HH:mm");
      const momentedBStart = moment(bSchedule.start, "HH:mm");
      if (momentedAStart.isSame(momentedBStart)) {
        return 0;
      }
      if (momentedAStart.isBefore(momentedBStart)) {
        return -1;
      }
      if (momentedAStart.isAfter(momentedBStart)) {
        return 1;
      }
    });
    sortedKids.map((kid) => {
      if (kid.schedule?.[dayOfWeekNumber]?.isAttending) {
        shouldBePresentList.push(kid);
      } else {
        shouldNoBePresentList.push(kid);
      }
    });

    return [shouldBePresentList, shouldNoBePresentList];
  }, [formattedKids, attendances]);

  useEffect(() => {
    const momented = moment(date);
    dispatch(getAttendanceRequest({ date: momented.format("MM-DD-YYYY") }));
  }, [date]);

  const handleCheckChange = (_id, e) => {
    handleAttendanceChange(_id);
    if (e.target.name == "absent") {
      attendanceMap[_id].absent = e.target.checked;
      attendanceMap[_id].present = false;
    } else {
      attendanceMap[_id].present = e.target.checked;
      attendanceMap[_id].absent = false;
    }
    setAttendanceMap({ ...attendanceMap });
  };
  const handleNoteChange = (_id, e) => {
    handleAttendanceChange(_id);
    attendanceMap[_id].note = e.target.value;
    setAttendanceMap({ ...attendanceMap });
  };
  const handleAttendanceChange = (_id) => {
    if (!attendanceMap[_id]) {
      attendanceMap[_id] = {
        kidId: _id,
        present: false,
        absent: false,
        note: "",
      };
    }
  };
  const handleDateChange = (date) => {
    setDate(date);
  };

  const handleSave = () => {
    const momented = moment(date);
    const entries = Object.values(attendanceMap);
    dispatch(
      saveAttendanceRequest({ entries, date: momented.format("MM-DD-YYYY") })
    );
  };

  if (!open) {
    return null;
  }
  console.log(attendanceMap);

  const renderTable = (row, hideTableHeader) => {
    return (
      <BasicTable
        rows={row}
        hideTableHeader={hideTableHeader}
        schema={[
          {
            header: i18n.t("name"),
            field: "name",
            style: { verticalAlign: "middle" },
            accessor: (val) => `${val.firstName || ""} ${val.lastName || ""}`,
          },
          {
            header: i18n.t("present"),
            style: { width: 80 },
            field: "present",
            render: (val) => {
              return (
                <Checkbox
                  checked={Boolean(attendanceMap[val._id]?.present)}
                  onChange={(e) => handleCheckChange(val._id, e)}
                  name={i18n.t("present")}
                  inputProps={{ "aria-label": "enrolled" }}
                />
              );
            },
          },
          {
            header: i18n.t("absent"),
            style: { width: 80 },
            field: "absent",
            render: (val) => {
              return (
                <Checkbox
                  checked={Boolean(attendanceMap[val._id]?.absent)}
                  onChange={(e) => handleCheckChange(val._id, e)}
                  name="absent"
                  inputProps={{ "aria-label": "enrolled" }}
                />
              );
            },
          },
          {
            header: i18n.t("note"),
            field: "note",
            style: { width: 120 },
            render: (val) => {
              return (
                <TextField
                  value={attendanceMap[val._id]?.note || ""}
                  onChange={(e) => handleNoteChange(val._id, e)}
                  name="note"
                />
              );
            },
          },
        ]}
      />
    );
  };

  return (
    <Modal
      title={i18n.t("attendance")}
      open={open}
      closeModal={() => setOpen(false)}
    >
      <div className="p-3">
        <div className="row justify-content-between align-items-center mb-2">
          <Datepicker
            wrapperClassName="w-240"
            selected={date}
            onChange={(date) => {
              handleDateChange(date);
            }}
          />
          <Button onClick={handleSave}>{i18n.t("save")}</Button>
        </div>
        {renderTable(shouldBePresentList)}
        {renderTable(shouldNoBePresentList, true)}
      </div>
    </Modal>
  );
}
