import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import i18n from "../../../i18n";
import { IconButton } from "@material-ui/core";
import Add from "@material-ui/icons/Add";

export default (props) => {
  const {
    id,
    label,
    error,
    addModal,
    options = [],
    multiple,
    onChange,
  } = props;
  let { value } = props;
  if (multiple) {
    value = value ? value : [];
  }

  const handleChange = (node, values) => {
    onChange(values);
  };
  const [addModalOpen, setAddModalOpen] = useState(false);
  return (
    <>
      {addModalOpen && addModal ? addModal : null}
      <Autocomplete
        {...props}
        id={id ? id : `${label}-multie-autocomplet`}
        getOptionLabel={(option) => option.name}
        disableCloseOnSelect
        options={options}
        value={value}
        onChange={handleChange}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={label}
              error={!!error}
              fullWidth
              helperText={error}
            />
          );
        }}
      />
    </>
  );
};
