export const allergiesActionTypes = {
  GET_ALLERGIES: "GET_ALLERGIES",
  SAVE_ALLERGIES: "SAVE_ALLERGIES",

  DELETE_ALLERGY: "DELETE_ALLERGY",
  DELETE_ALLERGY_SAGA: "DELETE_ALLERGY_SAGA",

  EDIT_ALLERGY: "EDIT_ALLERGY",
  EDIT_ALLERGY_SAGA: "EDIT_ALLERGY_SAGA",

  ADD_ALLERGY: "ADD_ALLERGY",
  ADD_ALLERGY_SAGA: "ADD_ALLERGY_SAGA",

  REQUEST_FAILED: "REQUEST_FAILED_ALLERGY",
};
