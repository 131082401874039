import ErrorHelper from "./ErrorHelper";
import SuccessHelper from "./SuccessHelper";
import i18n from "../i18n";

export default (dispatchAction, data = null, event = null) => {
  if (event == "delete") {
    SuccessHelper.handleDelete(i18n.t("are_you_sure"), (result) => {
      if (result.isConfirmed) {
        new Promise((success, failure) => {
          dispatchAction({ success, failure }, data);
        })
          .then((res) => {
            SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true);
          })
          .catch((err) => {
            ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
          });
      }
    });
  } else {
    new Promise((success, failure) => {
      dispatchAction({ success, failure }, data);
    })
      .then((res) => {
        if (event == "add") {
          SuccessHelper.handleSuccess(i18n.t("successfully_created"), true);
        } else if (event == "update") {
          SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true);
        }
      })
      .catch((err) => {
        console.log("err", err);
        // this.setState({ isloading: false });
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  }
};
