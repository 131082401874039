import React from "react";
import { EditActions } from "../Medical.styles";
import { Form, Field } from "react-final-form";
import { TextField } from "@material-ui/core";
import _ from "lodash";

import callbackHelper from "../../../../helpers/callbackHelper";
import i18n from "../../../../i18n";
import PrimaryButton from "../../../Core/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../../Core/Buttons/SecondaryButton/SecondaryButton";
import Modal from "../../../Core/Modal/Modal";

const conditionItemsMap = [
  { title: "Condition", name: "name" },
  { title: "Description", name: "description" },
];

export default function AddCondition({
  open,
  handleModalClose,
  kid,
  startAddingCondition,
}) {
  const handleAddModalCancel = () => {
    handleModalClose("add");
  };
  const onSubmit = (values) => {
    handleModalClose("add");
    callbackHelper(startAddingCondition, { ...values, kidId: kid._id }, "add");
  };
  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = i18n.t("required");
    }
    if (!values.description) {
      errors.description = i18n.t("required");
    }
    return errors;
  };
  return (
    <Modal
      open={open}
      closeModal={() => handleAddModalCancel()}
      title={i18n.t("add_condition")}
    >
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {conditionItemsMap.map(({ title, name }, i) => {
              return (
                <Field
                  name={name}
                  key={`edit-condition-${i}`}
                  render={({ input, meta }) => {
                    const error = meta.touched && meta.error;
                    return (
                      <TextField
                        label={title}
                        fullWidth
                        className="mb-2"
                        {...input}
                        error={error}
                        helperText={error ? error : ""}
                      />
                    );
                  }}
                />
              );
            })}
            <EditActions>
              <SecondaryButton onClick={() => handleAddModalCancel()}>
                {i18n.t("cancel")}
              </SecondaryButton>
              <PrimaryButton className="ml-1" type="submit">
                {i18n.t("submit")}
              </PrimaryButton>
            </EditActions>
          </form>
        )}
      />
    </Modal>
  );
}
