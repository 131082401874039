import { clientsActionTypes } from "./clientRegistration.types";

export const startAddingClientPersonalInfo = (payload) => {
  return {
    type: clientsActionTypes.ADD_CLIENT_PERSONAL_INFO_SAGA,
    payload,
  };
};

export const addClientPersonalInfo = (payload) => {
  return {
    type: clientsActionTypes.ADD_CLIENT_PERSONAL_INFO,
    payload,
  };
};

export const startAddingClientCompanyInfo = (payload) => {
  return {
    type: clientsActionTypes.ADD_CLIENT_COMPANY_INFO_SAGA,
    payload,
  };
};

export const addClientCompanyInfo = (payload) => {
  return {
    type: clientsActionTypes.ADD_CLIENT_COMPANY_INFO,
    payload,
  };
};

export const requestFailed = () => {
  return {
    type: clientsActionTypes.REQUEST_FAILED,
  };
};

export const updateClientPersonalInfo = (payload) => {
  return {
    type: clientsActionTypes.UPDATE_CLIENT_PERSONAL_INFO,
    payload,
  };
};

export const updateClientCompanyInfo = (payload) => {
  return {
    type: clientsActionTypes.UPDATE_CLIENT_COMPANY_INFO,
    payload,
  };
};

export const getDaycare = () => {
  return {
    type: clientsActionTypes.GET_DAYCARE,
  };
};

export const addCurrentDaycare = (payload) => {
  return {
    type: clientsActionTypes.ADD_CURRENT_DAYCARE_STATE,
    payload,
  };
};

export const getCurrentClient = () => {
  return {
    type: clientsActionTypes.GET_CURRENT_CLIENT_SAGA,
  };
};

export const addCurrentDaycareClient = (payload) => {
  return {
    type: clientsActionTypes.ADD_CURRENT_CLIENT_STATE,
    payload,
  };
};

export const clientUpdated = () => {
  return {
    type: clientsActionTypes.UPDATED_CLIENT_INFO,
  };
};

export const clientCompanyUpdated = () => {
  return {
    type: clientsActionTypes.UPDATED_CLIENT_COMPANY_INFO,
  };
};
