import React, { Component } from "react";
import { Form } from "react-final-form";
import {
  CompanySignUpWrapper,
  CompanyRegistrationButtonsWrapper,
} from "./clientCompanyReg.styles";
import {
  submitClientCompanyInfo,
  renderClientCompanyInfoFields,
} from "./ClientCompanySignUp.helpers";
import SubmitButton from "../../../components/Core/Buttons/PrimaryButton/PrimaryButton";
import i18n from "../../../i18n";
import { validateEmail } from "./../../../helpers/isEmail";

export default class ClientPersonalRegistration extends Component {
  componentDidMount() {
    document.title = i18n.t("company_registration");
  }
  validateClientCompanyInfo = (values) => {
    const errors = {};

    if (!values.companyName) {
      errors.companyName = i18n.t("required");
    }
    if (!values.companyTag) {
      errors.companyTag = i18n.t("required");
    }
    if (!values.companyEmail) {
      errors.companyEmail = i18n.t("required");
    }
    if (values.companyEmail && !validateEmail(values.companyEmail)) {
      errors.companyEmail = i18n.t("valid_email");
    }
    if (!values.phoneNo) {
      errors.phoneNo = i18n.t("required");
    }
    if (!values.companyAddress) {
      errors.companyAddress = i18n.t("required");
    }

    if (!values.companyDescription) {
      errors.companyDescription = i18n.t("required");
    }

    return errors;
  };
  render() {
    return (
      <Form
        onSubmit={(values) => submitClientCompanyInfo(values)}
        validate={this.validateClientCompanyInfo}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            onSubmit={handleSubmit}
          >
            <h2 style={{ marginBottom: "20px" }}>
              {i18n.t("register_company")}
            </h2>
            <CompanySignUpWrapper>
              {renderClientCompanyInfoFields("")}
            </CompanySignUpWrapper>
            <CompanyRegistrationButtonsWrapper>
              <SubmitButton type="submit" disabled={submitting}>
                {i18n.t("submit")}
              </SubmitButton>
            </CompanyRegistrationButtonsWrapper>
          </form>
        )}
      />
    );
  }
}
