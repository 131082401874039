import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLogin } from "../../helpers/isLogin";

export default function PublicRoute({
  component: Component,
  restricted,
  ...rest
}) {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={(props) => {
        return isLogin() && restricted ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
}
