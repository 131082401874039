import React, { useState } from "react";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import tableHeads from "./TableData";
import Button from "@material-ui/core/Button";
import EditingModal from "./EditingModal/EditingModal.component";
import ViewModal from "./viewModal/ViewModal.component";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import ErrorHelper from "../../../helpers/ErrorHelper";
import SuccessHelper from "../../../helpers/SuccessHelper";
import i18n from "../../../i18n";

import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MoreIcon from "@material-ui/icons/More";
export default function TableBodyGenerator({
  tableData,
  schema,
  tableType,
  onDelete,
  onEdit,
  editOnRowClick,
}) {
  const [editOpen, setEditOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [noteMap, setNoteMap] = React.useState({});

  const changeEditingTableRowState = (modalState, currentRow) => {
    setEditOpen(modalState);
    setCurrentRow(currentRow);
  };
  const changeViewTableRowState = (modalState, currentRow) => {
    setViewOpen(modalState);
    setCurrentRow(currentRow);
    let newMap = {};
    setNoteMap({ ...newMap });
  };
  const deleteActivityHandler = (id, e) => {
    e.stopPropagation();
    Promise.all([
      new Promise((success, failure) => {
        onDelete(id, { success, failure });
      }),
    ])
      .then((res) => {
        // this.setState({ isloading: false });
        SuccessHelper.handleToastSuccess(i18n.t("successfully_deleted"), true);
      })
      .catch((err) => {
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  };

  const renderTableBody = (tableData = []) => {
    return (
      <TableBody>
        {tableData.map((element, index) => (
          <TableRow
            key={uuidv4()}
            onClick={() => {
              if (onEdit && editOnRowClick) {
                onEdit(element);
              }
            }}
          >
            {renderTableField(element, index)}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  const renderTableField = (rowData = {}) => {
    return schema.map((element, index) => {
      const { value, title, fieldType, style = {} } = element;
      const { _id } = rowData;
      if (index < 2 || index >= schema.length - 1) {
        switch (fieldType) {
          case "actions":
            return (
              <TableCell key={uuidv4()} style={style}>
                {(element.actions || []).map((action) => {
                  switch (action) {
                    case "edit":
                      return (
                        <EditIcon
                          key={uuidv4()}
                          variant="outlined"
                          color="primary"
                          onClick={() => onEdit(rowData)}
                          style={{
                            marginRight: "10px",
                            cursor: "pointer",
                            color: "#5089DE",
                          }}
                        >
                          {i18n.t("edit")}
                        </EditIcon>
                      );
                    case "delete":
                      return (
                        <DeleteForeverIcon
                          key={uuidv4()}
                          variant="outlined"
                          color="primary"
                          onClick={(e) => deleteActivityHandler(_id, e)}
                          style={{
                            marginRight: "10px",
                            cursor: "pointer",
                            color: "#5089DE",
                            "&:hover": {
                              color: "#800080",
                            },
                          }}
                        >
                          {i18n.t("delete")}
                        </DeleteForeverIcon>
                      );
                    case "view":
                      return (
                        <MoreIcon
                          key={uuidv4()}
                          variant="outlined"
                          color="primary"
                          style={{
                            marginRight: "10px",
                            cursor: "pointer",
                            color: "#5089DE",
                          }}
                        >
                          {i18n.t("view")}
                        </MoreIcon>
                      );
                    default:
                      return null;
                  }
                })}
              </TableCell>
            );

          case "date":
            return (
              <TableCell key={uuidv4()}>
                {moment(rowData[value]).format("YYYY-MM-DD")}
              </TableCell>
            );

          default:
            return <TableCell key={uuidv4()}>{rowData[value]}</TableCell>;
        }
      }
    });
  };
  return (
    <>
      {renderTableBody(tableData)}
      <EditingModal
        {...{
          currentRow,
          editOpen,
          changeEditingTableRowState,
          tableType,
          schema,
        }}
      />
      <ViewModal
        {...{
          currentRow,
          viewOpen,
          changeViewTableRowState,
          tableType,
          noteMap,
          setNoteMap,
          schema,
        }}
      />
    </>
  );
}
