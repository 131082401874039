export const mealsActionTypes = {
  GET_MEALS: "GET_MEALS",
  SAVE_MEALS: "SAVE_MEALS",

  DELETE_MEAL: "DELETE_MEAL",
  DELETE_MEAL_SAGA: "DELETE_MEAL_SAGA",

  EDIT_MEAL: "EDIT_MEAL",
  EDIT_MEAL_SAGA: "EDIT_MEAL_SAGA",

  ADD_MEAL: "ADD_MEAL",
  ADD_MEAL_SAGA: "ADD_MEAL_SAGA",
};
