import React from "react";
import Loader from "../Loader/Loader";
import { useSelector } from "react-redux";

export default function ReduxLoader() {
  const isLoading = useSelector((s) => {
    return Boolean(
      Object.keys(s).find((piece) => {
        if (s[piece].isLoading) {
          // console.log(piece);
        }
        return s[piece].isLoading;
      })
    );
  });
  return <Loader isLoading={isLoading} />;
}
