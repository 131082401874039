import React, { Component } from "react";
import ClientPersonalRegistration from "./ClientPersonalRegistration.component";
export default class ClientPersonalRegistrationContainer extends Component {
  render() {
    return (
      <>
        <ClientPersonalRegistration />
      </>
    );
  }
}
