import React, { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  startGroupFetching,
  startEditingGroup,
  startAddingGroup,
  startDeletingGroup,
} from "../../redux/groups/groups.actions";
import { startKidFetching } from "../../redux/kids/kids.actions";
import { selectGroupItems } from "../../redux/groups/groups.selectors";
import Page from "../../components/Page/Page";
import FormModal from "../../components/KidGroups/FormModal";
import KidDetailsModal from "../../components/KidGroups/KidDetailsModal";
import ErrorHelper from "../../helpers/ErrorHelper";
import SuccessHelper from "../../helpers/SuccessHelper";
import i18n from "../../i18n";
import axios from "../../axios";

const validateKid = (values) => {};

class Groups extends Component {
  state = {
    editModalOpen: false,
    addModalOpen: false,
    kidDetailsModal: false,
    editing: {},
    groupKids: [],
    currentKidDetails: null,
  };
  schema = [
    { value: "name", title: i18n.t("name"), fieldType: "text" },
    { value: "description", title: i18n.t("description"), fieldType: "text" },
    {
      value: "status",
      title: i18n.t("status"),
      fieldType: "select",
      options: [
        { name: "Active", value: i18n.t("active") },
        { name: "Inactive", value: i18n.t("inactive") },
      ],
    },
    { value: "color", title: i18n.t("color"), fieldType: "color" },
    { value: "view", title: i18n.t("view"), fieldType: "viewButton" },
    { value: "edit", title: i18n.t("edit"), fieldType: "editButton" },
    { value: "minimumAge", title: i18n.t("minimum_age"), fieldType: "text" },
    {
      value: "actions",
      title: i18n.t("actions"),
      fieldType: "actions",
      style: { width: 80 },
      actions: ["edit", "delete"],
    },
  ];
  async componentDidMount() {
    document.title = i18n.t("groups");
    axios.get("/kid/populateKidGroup").then((response) => {
      this.setState({ groupKids: response.data.data });
    });
    this.props.startKidFetching();
    new Promise((success, failure) => {
      this.props.startGroupFetching({ success, failure });
    })
      .then((res) => {
        this.setState({ isloading: false });
      })
      .catch((err) => {
        this.setState({ isloading: false });
        console.log("something_went_wrong");
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  }

  validateGroup = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = i18n.t("required");
    }
    if (!values.status) {
      errors.status = i18n.t("required");
    }
    if (!values.description) {
      errors.description = i18n.t("required");
    }

    return errors;
  };
  onAdd = (values) => {
    new Promise((success, failure) => {
      this.props.startAddingGroup({ success, failure }, values);
    })
      .then((res) => {
        SuccessHelper.handleToastSuccess(i18n.t("successfully_created"), true);
      })
      .catch((err) => {
        this.setState({ isloading: false });
        console.log("something_went_wrong");
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  };

  showKidDetails = () => {
    if (this.state.editing) {
      const kid = this.state.editing.kids.find(
        (kid) => kid._id == this.state.currentKidDetails
      );
      this.setState({ currentKidDetails: kid });
    }
  };

  renderKidDetailsModal = () => {
    return (
      <KidDetailsModal
        open={this.state.kidDetailsModal}
        readOnly
        currentKidDetails={this.state.currentKidDetails}
        closeModal={() => {
          this.setState({
            kidDetailsModal: false,
          });
        }}
      />
    );
  };

  onEdit = (data) => {
    new Promise((success, failure) => {
      this.props.startEditingGroup(
        { success, failure },
        {
          _id: this.state.editing._id,
          data,
        }
      );
    })
      .then((res) => {
        SuccessHelper.handleToastSuccess(i18n.t("successfully_updated"), true);
      })
      .catch((err) => {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  };

  onAddKidToGroup = (data) => {
    const kids = data.map((kid) => {
      return kid._id;
    });
    axios
      .post("/kidsGroup/addKids", { _id: this.state.editing._id, kids })
      .then(async (response) => {
        const res = await axios.get("/kidsGroup", {
          params: { _id: this.state.editing._id },
        });
        this.setState({ editing: res.data.data });

        SuccessHelper.handleToastSuccess(i18n.t("successfully_updated"), true);
      })
      .catch((err) => {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  };

  deleteKidFromGroup = (id) => {
    axios
      .post("/kidsGroup/removeKids", {
        _id: this.state.editing._id,
        kids: [id],
      })
      .then((response) => {
        const group = {
          ...this.state.editing,
          kids: [...this.state.editing.kids],
        };
        group.kids = group.kids.filter((kid) => {
          return kid._id != id;
        });
        this.setState({ editing: group });

        SuccessHelper.handleToastSuccess(i18n.t("successfully_updated"), true);
      })
      .catch((err) => {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  };

  renderEditModal = () => {
    return (
      <FormModal
        schema={this.schema}
        open={this.state.editModalOpen}
        onSubmit={this.onEdit}
        onSubmitKid={this.onAddKidToGroup}
        validateFormKid={validateKid}
        values={this.state.editing}
        groupKids={this.state.groupKids}
        isGroupModal
        deleteKidFromGroup={this.deleteKidFromGroup}
        showKidFromGroup={(id) => {
          this.setState(
            { kidDetailsModal: true, currentKidDetails: id },
            () => {
              this.showKidDetails();
            }
          );
        }}
        closeModal={() => {
          this.setState({
            editing: {},
            editModalOpen: false,
          });
        }}
      />
    );
  };

  renderAddModal = () => {
    return (
      <FormModal
        schema={this.schema}
        title="Group"
        open={this.state.addModalOpen}
        onSubmit={this.onAdd}
        validateForm={this.validateGroup}
        closeModal={() => {
          this.setState({
            addModalOpen: false,
          });
        }}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderEditModal()}
        {this.renderAddModal()}
        {this.renderKidDetailsModal()}
        <Page
          title={i18n.t("groups")}
          resource="Groups"
          data={this.props.groups.groups}
          onDelete={this.props.startDeletingGroup}
          onEdit={(data) => {
            this.setState({
              editing: data,
              editModalOpen: true,
            });
          }}
          editOnRowClick
          onCreate={() => {
            this.setState({
              editing: {},
              addModalOpen: true,
            });
          }}
          schema={this.schema}
        ></Page>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startGroupFetching: (callbackMap) =>
    dispatch(startGroupFetching(callbackMap)),
  startDeletingGroup: (id, callbackMap) =>
    dispatch(startDeletingGroup(id, callbackMap)),
  startEditingGroup: (callbackMap, ...args) =>
    dispatch(startEditingGroup(callbackMap, ...args)),
  startAddingGroup: (callbackMap, ...args) =>
    dispatch(startAddingGroup(callbackMap, ...args)),
  startKidFetching: (...args) => dispatch(startKidFetching(...args)),
});

const mapStateToProps = createStructuredSelector({
  groups: selectGroupItems,
});
export default connect(mapStateToProps, mapDispatchToProps)(Groups);
