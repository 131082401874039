import React from "react";
import tableData from "./TableData";
import { v4 as uuidv4 } from "uuid";

import { TableCell, TableHead, TableRow, makeStyles } from "@material-ui/core";
import tableStyles from "./tableStyles";
const useStyles = makeStyles(tableStyles.tableStyles);

export default function TableHeadGenerator({ schema }) {
  const classes = useStyles();

  const renderTableHead = (schema) => {
    return (
      <TableHead className={[classes.tableHead, "secondary"]}>
        <TableRow>
          {schema.map(({ title, style = {} }, index) => {
            if (index < 2 || index >= schema.length - 1) {
              return (
                <TableCell key={uuidv4()} style={style}>
                  {title}
                </TableCell>
              );
            }
          })}
        </TableRow>
      </TableHead>
    );
  };
  return <>{renderTableHead(schema)}</>;
}
