import { activeUserActionTypes } from "./activeUser.types";

export const saveUserFetching = (activities) => {
  return {
    type: activeUserActionTypes.SAVE_USER,
    payload: activities,
  };
};

export const startActiveUserFetching = () => {
  return {
    type: activeUserActionTypes.GET_USER,
  };
};

export const setToken = (payload) => {
  return {
    type: activeUserActionTypes.SET_TOKEN,
    payload,
  };
};
