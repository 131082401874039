import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../i18n";
import axios from "../../axios";
import ErrorHelper from "../../helpers/ErrorHelper";
import SuccessHelper from "../../helpers/SuccessHelper";
import Modal from "../Core/Modal/Modal";
import Select from "../Core/Select";
import Datepicker from "../Core/Datepicker/Datepicker";
import DollarInput from "../Core/DollarInput/DollarInput";
import PrimaryButton from "../Core/Buttons/PrimaryButton/PrimaryButton";
import { addInvoicesRequest } from "../../redux/billing/billing.actions";
import SecondaryButton from "../Core/Buttons/SecondaryButton/SecondaryButton";

const frequencyMomentMap = {
  Weekly: [1, "w"],
  "Bi-Weekly": [2, "w"],
  Monthly: [1, "M"],
};
export default function NewInvoiceModal({ open, setOpen, kidId }) {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [errorMap, setErrorMap] = useState({});
  const kids = useSelector((s) => s.kids.kids);
  useEffect(() => {
    if (open) {
      setData({});
    }
  }, [open]);

  const getLatestInvoiceByKid = async (kidId) => {
    const activeBilling = (
      kids.find((element) => element._id == kidId)?.billing || []
    ).find((element) => element.status == "Active");
    const { data } = await axios.get("/daycareInvoice/getLatestInvoiceByKid", {
      params: { kidId },
    });
    const from = moment(data.data?.to || activeBilling?.startDate);

    setData({
      from: from.toDate(),
      to: from.add(...frequencyMomentMap[activeBilling.frequency]).toDate(),
      dueDate: from.toDate(),
      kidId,
      amount: activeBilling.amount,
    });
  };

  useEffect(() => {
    if (kidId) {
      getLatestInvoiceByKid(kidId);
    }
  }, [kidId, open]);

  const submit = () => {
    dispatch(
      addInvoicesRequest(data, {
        success: () => {
          SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
          setOpen(false);
        },
      })
    );
  };

  const validateForm = () => {
    const map = {};
    if (!data.dueDate) {
      map.dueDate = i18n.t("required");
    }
    if (!data.to) {
      map.to = i18n.t("required");
    }
    if (!data.from) {
      map.from = i18n.t("required");
    }
    if (!data.amount) {
      map.amount = i18n.t("required");
    }
    setErrorMap(map);
    if (!Object.keys(map).length) {
      submit();
    }
  };
  return (
    <Modal
      open={open}
      closeModal={() => setOpen(false)}
      title={i18n.t("new_invoice")}
    >
      {!kidId ? (
        <Select
          className="mb-2"
          options={kids.map((kid) => ({
            name: kid.firstName,
            value: kid._id,
            ...kid,
          }))}
          label={i18n.t("kid")}
          onChange={(e) => {
            getLatestInvoiceByKid(e.target.value);
          }}
        />
      ) : null}

      <Datepicker
        className="mb-2"
        name="dueDate"
        label={i18n.t("due_date")}
        selected={data.dueDate}
        fullWidth
        error={errorMap.dueDate}
        helperText={errorMap.dueDate}
        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
      />
      <Datepicker
        className="mb-2"
        name="from"
        label={i18n.t("from")}
        selected={data.from}
        fullWidth
        error={errorMap.from}
        helperText={errorMap.from}
        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
      />
      <Datepicker
        className="mb-2"
        name="to"
        label={i18n.t("to")}
        selected={data.to}
        fullWidth
        error={errorMap.to}
        helperText={errorMap.to}
        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
      />
      <DollarInput
        className="mb-2"
        name="amount"
        label={i18n.t("amount")}
        value={data.amount}
        error={errorMap.amount}
        helperText={errorMap.amount}
        onChange={(formated, e) => {
          setData({ ...data, [e.target.name]: formated });
        }}
      />
      <Select
        className="mb-2"
        name="paymentStatus"
        options={[
          {
            name: i18n.t("due"),
            value: "Due",
          },
          {
            name: i18n.t("paid"),
            value: "Paid",
          },
        ]}
        defaultValue="Due"
        excludeEmptyValue
        label={i18n.t("paymentStatus")}
        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
      />
      <div className="row justify-content-end">
        <SecondaryButton onClick={() => setOpen(false)}>
          {i18n.t("cancel")}
        </SecondaryButton>
        <PrimaryButton className="ml-1" onClick={validateForm}>
          {i18n.t("save")}
        </PrimaryButton>
      </div>
    </Modal>
  );
}
