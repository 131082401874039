import React, { useRef, useState } from "react";
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  RemoveFileIcon,
} from "./file-upload.styles";
import i18n from "../../i18n";
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000000;
const KILO_BYTES_PER_BYTE = 1000;

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  ...otherProps
}) => {
  const fileInputField = useRef(null);
  const [file, setFile] = useState({});
  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };
  const handleNewFileUpload = (e) => {
    const {
      files: [file],
    } = e.target;
    updateFilesCb(file);
    setFile(file);
  };

  return (
    <>
      <FileUploadContainer>
        <DragDropText>{i18n.t("drag_and_drop")}</DragDropText>
        <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
          <i className="fas fa-file-upload" />
          <span>{i18n.t("upload_a_file")}</span>
        </UploadFileBtn>
        <FormField
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          title=""
          value=""
          {...otherProps}
        />
      </FileUploadContainer>
      <FilePreviewContainer>
        <div style={{ textAlign: "center" }}>{file?.name}</div>
      </FilePreviewContainer>
    </>
  );
};

export default FileUpload;
