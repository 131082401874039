import { Checkbox, TextField } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import i18n from "../../i18n";

export default function Day({ day, dayIndex, onChange }) {
  const [isAttending, setIsAttending] = useState(day.isAttending);
  const [start, setStart] = useState(day.start);
  const [end, setEnd] = useState(day.end);

  const dayMap = [
    i18n.t("sunday"),
    i18n.t("monday"),
    i18n.t("tuesday"),
    i18n.t("wednesday"),
    i18n.t("thursday"),
    i18n.t("friday"),
    i18n.t("saturday"),
  ];
  useEffect(() => {
    onChange({ isAttending, start, end });
  }, [isAttending, start, end]);
  const setTimes = (start, end) => {
    let newStart = start;
    let newEnd = end;
    const momentedStart = moment(start, "HH:mm");
    const momentedEnd = moment(end, "HH:mm");
    if (momentedStart.isAfter(momentedEnd)) {
      const x = newStart;
      newStart = newEnd;
      newEnd = x;
    }
    setStart(newStart);
    setEnd(newEnd);
  };
  return (
    <>
      <div className="w-100 row justify-content-between align-items-center">
        <label>{i18n.t(dayMap[dayIndex])}</label>
        <Checkbox
          defaultChecked={Boolean(isAttending)}
          onChange={(e) => {
            setIsAttending(e.target.checked);
          }}
        />
      </div>
      <TextField
        label={i18n.t("start")}
        type="time"
        fullWidth
        value={start}
        max={end}
        onChange={(e) => {
          setStart(e.target.value);
        }}
        onBlur={(e) => {
          setTimes(e.target.value, end);
        }}
      />
      <TextField
        label={i18n.t("end")}
        type="time"
        fullWidth
        value={end}
        min={start}
        onChange={(e) => {
          setEnd(e.target.value);
        }}
        onBlur={(e) => {
          setTimes(start, e.target.value);
        }}
      />
    </>
  );
}
