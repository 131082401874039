import { all, call } from "redux-saga/effects";
import { watchActivities } from "../activities/activities.sagas";
import { watchAllergies } from "../allergies/allergies.sagas";
import { watchSignForms } from "../signForm/signForms.sagas";
import { watchMeals } from "../meals/meals.sagas";
import { watchKids } from "../kids/kids.sagas";
import { watchGroups } from "../groups/groups.sagas";
import { watchEmployees } from "../employees/employees.sagas";
import { watchClientRegistration } from "../authentication/ClientRegistration/clientRegistration.sagas";
import { watchUserLogin } from "../authentication/login/login.sagas";
import { watchEvents } from "../events/events.sagas";
import { watchActiveUser } from "../activeUser/activeUser.sagas";
import { watchUserLogout } from "../authentication/logout/logout.sagas";
import { watchGuardians } from "../guardians/guardians.sagas";
import { watchImmunization } from "../Immunization/immunization.sagas";
import { watchDoctors } from "../doctors/doctors.sagas";
import { watchConditions } from "../conditions/conditions.sagas";
import { watchRequirments } from "../requirments/requirments.sagas";
import { watchBusinessHours } from "../businessHours/businessHours.sagas";
import { watchIncidents } from "../incidents/incidents.sagas";
import { watchImmunizationTypes } from "../immunizationType/immunizationType.sagas";
import watchAttendance from "../attendance/attendance.sagas";
import watchBilling from "../billing/billing.sagas";

export default function* rootSaga() {
  yield all([
    call(watchActivities),
    call(watchAllergies),
    call(watchMeals),
    call(watchKids),
    call(watchGroups),
    call(watchEmployees),
    call(watchSignForms),
    call(watchClientRegistration),
    call(watchUserLogin),
    call(watchEvents),
    call(watchActiveUser),
    call(watchUserLogout),
    call(watchBusinessHours),
    call(watchGuardians),
    call(watchImmunization),
    call(watchDoctors),
    call(watchConditions),
    call(watchRequirments),
    call(watchIncidents),
    call(watchImmunizationTypes),
    call(watchAttendance),
    call(watchBilling),
  ]);
}
