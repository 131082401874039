import { mealsActionTypes } from "./meals.types";

export const saveMeals = (meals) => {
  return {
    type: mealsActionTypes.SAVE_MEALS,
    payload: meals,
  };
};

export const startMealFetching = (callbackMap) => {
  return {
    type: mealsActionTypes.GET_MEALS,
    callbackMap,
  };
};

export const startDeletingMeal = (id, callbackMap) => {
  return {
    type: mealsActionTypes.DELETE_MEAL_SAGA,
    callbackMap,
    mealId: id,
  };
};

export const deleteMeal = (id) => {
  return {
    type: mealsActionTypes.DELETE_MEAL,
    payload: id,
  };
};

export const startEditingMeal = (callbackMap, payload) => {
  return {
    type: mealsActionTypes.EDIT_MEAL_SAGA,
    callbackMap,
    payload,
  };
};

export const editMeal = (payload) => {
  return {
    type: mealsActionTypes.EDIT_MEAL,
    payload,
  };
};

export const startAddingMeal = (callbackMap, payload) => {
  return {
    type: mealsActionTypes.ADD_MEAL_SAGA,
    callbackMap,
    payload,
  };
};

export const addMeal = (payload) => {
  return {
    type: mealsActionTypes.ADD_MEAL,
    payload,
  };
};
