import styled from "styled-components/macro";
const backgroundColor = "#800080";
const textColor = "white";
export const IconItem = styled.div`
  height: 3em;
  width: 3em;
  background-color: ${backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  color: ${textColor};
  cursor: pointer;
`;
export const ListItemContainer = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 2fr 0.5fr;
  width: 75%;
  margin-top: 2em;
  &:last-child {
    margin-bottom: 2em;
  }
  &:first-child {
    margin-top: 4em;
  }
`;
export const ItemTitle = styled.div`
  background-color: ${backgroundColor};
  color: ${textColor};
  display: flex;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
`;
