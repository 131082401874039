import { conditionsActionTypes } from "./conditions.types";

export const saveConditions = (conditions) => {
  return {
    type: conditionsActionTypes.SAVE_CONDITIONS,
    payload: conditions,
  };
};

export const startConditionFetching = (callbackMap, params) => {
  return {
    type: conditionsActionTypes.GET_CONDITIONS,
    callbackMap,
    params,
  };
};

export const startDeletingCondition = (callbackMap, id) => {
  return {
    type: conditionsActionTypes.DELETE_CONDITION_SAGA,
    callbackMap,
    conditionId: id,
  };
};

export const deleteCondition = (id) => {
  console.log("inside delete condition ", id);
  return {
    type: conditionsActionTypes.DELETE_CONDITION,
    payload: id,
  };
};

export const startEditingCondition = (callbackMap, payload) => {
  console.log(payload, ".......action...payload.............");

  return {
    type: conditionsActionTypes.EDIT_CONDITION_SAGA,
    callbackMap,
    payload,
  };
};

export const editCondition = (payload) => {
  return {
    type: conditionsActionTypes.EDIT_CONDITION,
    payload,
  };
};

export const startAddingCondition = (callbackMap, payload) => {
  return {
    type: conditionsActionTypes.ADD_CONDITION_SAGA,
    callbackMap,
    payload,
  };
};

export const addCondition = (payload) => {
  return {
    type: conditionsActionTypes.ADD_CONDITION,
    payload,
  };
};

export const requestFailed = () => {
  return {
    type: conditionsActionTypes.REQUEST_FAILED,
  };
};
