import { immunizationActionTypes } from "./immunizationType.types";
import _ from "lodash";
const initialState = {
  immunizationTypes: [],
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case immunizationActionTypes.GET_IMMUNIZATION_TYPES: {
      return { ...state, immunizationTypes: payload, isLoading: false };
    }
    case immunizationActionTypes.ADD_IMMUNIZATION_TYPE: {
      const currentState = state.immunizationTypes;
      const toUpdate = [...currentState, payload];
      return { ...state, immunizationTypes: toUpdate, isLoading: false };
    }
    case immunizationActionTypes.UPDATING_IMMUNIZATION_TYPE: {
      const currentState = state.immunizationTypes;

      const toUpdate = currentState.map((element) => {
        return element._id == payload._id ? payload : element;
      });
      return { ...state, immunizationTypes: toUpdate, isLoading: false };
    }
    case immunizationActionTypes.DELETE_IMMUNIZATION_TYPE: {
      const updatedState = state.immunizationTypes;
      const toUpdate = updatedState.filter((imm) => imm._id != payload._id);
      return { ...state, immunizationTypes: toUpdate, isLoading: false };
    }
    case immunizationActionTypes.UPDATING_IMMUNIZATION_TYPE_SAGA:
    case immunizationActionTypes.DELETE_IMMUNIZATION_TYPE_SAGA:
    case immunizationActionTypes.ADD_IMMUNIZATION_TYPE_SAGA:
    case immunizationActionTypes.GET_IMMUNIZATION_TYPES_SAGA: {
      return { ...state, isLoading: true };
    }
    case immunizationActionTypes.REQUEST_FAILED: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
