import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import {
  DoctorsWrapper,
  ConditionCard,
  DoctorsControls,
  Back,
  Actions,
} from "../Medical.styles";
import AddCondition from "./AddCondition";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditCondition from "./EditCondition";

import {
  startConditionFetching,
  startAddingCondition,
  startDeletingCondition,
} from "../../../../redux/conditions/conditions.actions";
import { selectConditionsData } from "../../../../redux/conditions/conditions.selectors";
import { createStructuredSelector } from "reselect";
import callbackHelper from "../../../../helpers/callbackHelper";
import i18n from "../../../../i18n";

// </DoctorsWrapper>
function Conditions(props) {
  const [modalState, setModalState] = useState({ edit: false, add: false });
  const [currentEditingCondition, setCurrentEditingCondition] = useState({});
  const handleModalClose = (state) => {
    const newState = { ...modalState };
    newState[state] = false;
    setModalState(newState);
    setCurrentEditingCondition({});
  };
  const handleConditionEdit = (currentCondition) => {
    setModalState({ ...modalState, edit: true });
    setCurrentEditingCondition(_.omit(currentCondition, ["status", "__v"]));
    console.log(currentCondition, "currentCondition");
  };

  const handleAddCondition = () => {
    setModalState({ ...modalState, add: true });
  };
  const handleConditionDelete = (condition) => {
    setModalState({ ...modalState, delete: true });
    callbackHelper(props.startDeletingCondition, condition._id, "delete");
  };

  useEffect(() => {
    callbackHelper(props.startConditionFetching, {
      filters: { kidId: props.kid._id },
    });
  }, []);

  return (
    <>
      <DoctorsWrapper>
        <DoctorsControls>
          <Back onClick={() => props.handleCategory("main")}>
            <KeyboardBackspaceIcon />
          </Back>
          <Back>
            <AddIcon onClick={() => handleAddCondition("main")} />
          </Back>
        </DoctorsControls>
        <h3>{i18n.t("conditions")}</h3>
        {props.conditions.map((condition) => {
          return (
            <>
              <ConditionCard key={condition._id}>
                <div>{condition.name}</div>
                <div>{condition.description}</div>

                <Actions>
                  <EditIcon onClick={() => handleConditionEdit(condition)} />
                  <DeleteForeverIcon
                    onClick={() => handleConditionDelete(condition)}
                  />
                </Actions>
              </ConditionCard>
            </>
          );
        })}
        {!_.isEmpty(currentEditingCondition) && (
          <EditCondition
            open={modalState.edit}
            handleModalClose={(state) => handleModalClose(state)}
            {...{ currentEditingCondition }}
          />
        )}
        {modalState.add && (
          <AddCondition
            open={modalState.add}
            handleModalClose={(state) => handleModalClose(state)}
            kid={props.kid}
            startAddingCondition={props.startAddingCondition}
          />
        )}
      </DoctorsWrapper>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  startConditionFetching: (callbackMap, ...args) =>
    dispatch(startConditionFetching(callbackMap, ...args)),
  startDeletingCondition: (callbackMap, id) =>
    dispatch(startDeletingCondition(callbackMap, id)),
  startAddingCondition: (callbackMap, ...args) =>
    dispatch(startAddingCondition(callbackMap, ...args)),
});

const mapStateToProps = createStructuredSelector({
  conditions: selectConditionsData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Conditions);
