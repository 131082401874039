import React, { useState } from "react";
import i18n from "../../i18n";
import { useDispatch } from "react-redux";
import { getLanguage } from "../../redux/langDropDown/langDropDown.actions";
import Select from "react-select";
import TranslateIcon from "@material-ui/icons/Translate";
import styled from "styled-components/macro";
import { useEffect } from "react";
const languageOptionMap = {
  en: {
    value: "en",
    label: <div>English</div>,
  },
  fr: {
    value: "fr",
    label: <div>Français</div>,
  },
};
export default function LangDropDown() {
  const [lang, setLang] = useState(languageOptionMap.en);

  useEffect(() => {
    const key = localStorage.getItem("i18nextLng");
    if (key && key in languageOptionMap) {
      setLang(languageOptionMap[key]);
    }
  });

  const changeLanguage = (lng) => {
    setLang(lng);
    i18n.changeLanguage(lng.value);
  };

  const languageOptions = [languageOptionMap.en, languageOptionMap.fr];

  const style = {
    singleValue: (provided, state) => ({
      // ...provided,
      color: "white",
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused ? 0 : 0,
      backgroundColor: "#148DFF",
      // borderRadius: "7px",
      width: "100%",
      height: ".5em",
      // padding: ".3em",
      // border: "1px solid white",
      border: "none",
      "&:hover": {
        cursor: "pointer",
      },

      // backgroundColor: "red"
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      // backgroundColor: "red",
      // borderRadius: "50px",
      // border: "1px solid white",

      "&:hover": {
        cursor: "pointer",
      },

      // backgroundColor: "red"
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "white",
      "&:hover": {
        cursor: "pointer",
      },

      // backgroundColor: "red"
    }),

    container: (provided, state) => ({
      ...provided,
      backgroundColor: "#148DFF",
      borderRadius: "7px",
      "&:hover": {
        cursor: "pointer",
      },

      // backgroundColor: "red"
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      display: "none",
      "&:hover": {
        cursor: "pointer",
      },

      // backgroundColor: "red"
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      borderRadios: "50px",
      // "&:hover": {
      //   cursor: "pointer",
      // },

      // backgroundColor: "red"
    }),
  };
  const LanguageWrapper = styled.div`
    display: flex;
    align-items: center;
  `;
  return (
    <LanguageWrapper>
      <TranslateIcon style={{ color: "white", marginRight: "10px" }} />
      <Select
        value={lang}
        options={languageOptions}
        onChange={(value) => {
          changeLanguage(value);
        }}
        styles={style}
      />
    </LanguageWrapper>
  );
}
