import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
} from "@material-ui/core";
import { uniqueId } from "lodash";

export default function BasicTable({
  schema = [],
  rows = [],
  prependRows = [],
  appendRows = [],
  hideTableHeader,
  additionalKey = "",
  onRowClick = () => {},
}) {
  const renderTableHead = () => {
    return (
      <TableHead className="secondary">
        <TableRow>
          {schema.map(({ header, style }) => (
            <TableCell key={uniqueId()} style={style}>
              {header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const renderTableBody = (row, index) => {
    return schema.map(({ field, accessor: _accessor, render, style = {} }) => {
      let content;
      let accessor = _accessor;
      if (render) {
        content = render(row, index);
      } else {
        if (!_accessor) {
          accessor = () => row[field];
        }
        content = accessor(row, field);
      }
      return (
        <TableCell key={uniqueId()} style={style}>
          {content}
        </TableCell>
      );
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="activities Table" className="table">
        {!hideTableHeader ? renderTableHead(schema) : null}
        <TableBody>
          {prependRows}
          {rows.map((element, index) => (
            <TableRow key={uniqueId()} onClick={() => onRowClick(element)}>
              {renderTableBody(element, index)}
            </TableRow>
          ))}
          {appendRows}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
