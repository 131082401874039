import { activitiesActionTypes } from "./activities.types";

const initialState = {
  activities: [],
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case activitiesActionTypes.GET_ACTIVITIES: {
      return { ...state, isLoading: true };
    }
    case activitiesActionTypes.SAVE_ACTIVITIES: {
      return { ...state, activities: payload, isLoading: false };
    }
    case activitiesActionTypes.DELETE_ACTIVITY_SAGA: {
      return { ...state, isLoading: true };
    }
    case activitiesActionTypes.DELETE_ACTIVITY: {
      const updatedState = state.activities;

      const toUpdate = updatedState.filter(
        (activity) => activity._id != payload
      );
      return { ...state, activities: toUpdate, isLoading: false };
    }
    case activitiesActionTypes.EDIT_ACTIVITY_SAGA: {
      return { ...state, isLoading: true };
    }
    case activitiesActionTypes.EDIT_ACTIVITY: {
      const currentState = state.activities;

      const toUpdate = currentState.map((activity) => {
        return activity._id == payload._id ? payload : activity;
      });
      return { ...state, activities: toUpdate, isLoading: false };
    }
    case activitiesActionTypes.ADD_ACTIVITY_SAGA: {
      return { ...state, isLoading: true };
    }
    case activitiesActionTypes.ADD_ACTIVITY: {
      const currentState = state.activities;
      const toUpdate = [...currentState, payload];
      return { ...state, activities: toUpdate, isLoading: false };
    }
    case activitiesActionTypes.REQUEST_FAILED: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
