const validateKid = (values) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = "Required";
  }
  if (!values.lastName) {
    errors.lastName = "Required";
  }
  if (!values.age) {
    errors.age = "Required";
  }

  return errors;
};

const validateActivity = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Required";
  }
  if (!values.status) {
    errors.status = "Required";
  }
  if (!values.type) {
    errors.type = "Required";
  }

  return errors;
};

const validateMeal = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Required";
  }
  if (!values.ingredients) {
    errors.ingredients = "Required";
  }

  return errors;
};

const validateGroup = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Required";
  }
  if (!values.status) {
    errors.status = "Required";
  }
  if (!values.description) {
    errors.description = "Required";
  }

  return errors;
};

const validateEmployee = (values) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = "Required";
  }
  if (!values.lastName) {
    errors.lastName = "Required";
  }
  if (!values.email) {
    errors.email = "Required";
  }
  if (!values.phoneNo) {
    errors.phoneNo = "Required";
  }
  // if (!values.allowEditSchedule) {
  //   errors.allowEditSchedule = "Required";
  // }
  console.log({ errors });
  return errors;
};

const validateSignForms = (values) => {
  const errors = {};

  if (!values.title) {
    errors.title = "Required";
  }
  return errors;
};

const validateForm = (tableType, values) => {
  switch (tableType) {
    case "activities":
      return validateActivity(values);
    case "meals":
      return validateMeal(values);
    case "kids":
      return validateKid(values);
    case "groups":
      return validateGroup(values);
    case "signForms":
      return validateSignForms(values);
    case "employees":
      return validateEmployee(values);

    default:
      break;
  }
};
export { validateForm };
