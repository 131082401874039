import { createSelector } from "reselect";

const selectTestState = (state) => state.doctors;

export const selectCartItems = createSelector(
  [selectTestState],
  (doctors) => doctors
);

export const selectDoctorsData = createSelector(
  [selectTestState],
  (doctors) => doctors.doctors
);
