import billingActionTypes from "./billing.types";

import _ from "lodash";
const initialState = {
  data: [],
  arAgingReport: [],
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case billingActionTypes.GET_INVOICE_SAGA:
    case billingActionTypes.DELETE_INVOICE_SAGA:
    case billingActionTypes.EDIT_INVOICE_SAGA:
    case billingActionTypes.ADD_INVOICE_SAGA:
    case billingActionTypes.GET_AR_AGING_SAGA: {
      return { ...state, isLoading: true };
    }

    case billingActionTypes.GET_INVOICE: {
      return { ...state, data: payload, isLoading: false };
    }
    case billingActionTypes.DELETE_INVOICE: {
      const updatedState = state.data;
      const toUpdate = updatedState.filter(
        (element) => element._id != payload._id
      );
      let newArAgingReport = state.arAgingReport;
      newArAgingReport = newArAgingReport.map((element) => {
        if (element.kidId == payload.kidId) {
          const newItem = { kidId: payload.kidId };
          Object.keys(element).map((key) => {
            if (key != "kidId") {
              newItem[key] = { ...element[key] };
              newItem[key].entries = element[key].entries.filter(
                (entry) => entry._id != payload._id
              );
              newItem[key].totalDue = newItem[key].entries.reduce(
                (accum, entry) => accum + entry.amount,
                0
              );
            }
          });
          return newItem;
        }
        return element;
      });
      return {
        ...state,
        data: toUpdate,
        arAgingReport: newArAgingReport,
        isLoading: false,
      };
    }
    case billingActionTypes.EDIT_INVOICE: {
      const currentState = state.data;

      const toUpdate = currentState.map((element) => {
        return element._id == payload._id ? payload : element;
      });
      let newArAgingReport = state.arAgingReport;
      newArAgingReport = newArAgingReport.map((element) => {
        if (element.kidId == payload.kidId) {
          const newItem = { kidId: payload.kidId };
          Object.keys(element).map((key) => {
            if (key != "kidId") {
              newItem[key] = { ...element[key] };
              if (payload.paymentStatus == "Paid") {
                newItem[key].entries = element[key].entries.filter(
                  (entry) => entry._id != payload._id
                );
              } else {
                newItem[key].entries = element[key].entries.map((entry) => {
                  return entry._id == payload._id ? payload : entry;
                });
              }
              newItem[key].totalDue = newItem[key].entries.reduce(
                (accum, entry) => accum + entry.amount,
                0
              );
            }
          });
          return newItem;
        }
        return element;
      });
      return {
        ...state,
        data: toUpdate,
        arAgingReport: newArAgingReport,
        isLoading: false,
      };
    }
    case billingActionTypes.ADD_INVOICE: {
      const currentState = [...state.data];
      const toUpdate = [...currentState, payload];
      return { ...state, data: toUpdate, isLoading: false };
    }
    case billingActionTypes.GET_AR_AGING: {
      return { ...state, arAgingReport: payload, isLoading: false };
    }
    case billingActionTypes.REQUEST_FAILED: {
      return { ...state, isLoading: false };
    }

    default:
      return state;
  }
};
