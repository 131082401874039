import { immunizationActionTypes } from "./immunization.types";
import _ from "lodash";
const initialState = {
  immunizations: [
    // {
    //   name: "BCG",
    //   firstDose: "",
    //   secondDose: "2021-08-18",
    //   thirdDose: "2021-08-18",
    //   fourthDose: "2021-08-18",
    //   fifthDose: "2021-08-18",
    //   sixthDose: "2021-08-18",
    //   _id: "BCG",
    // },
    // {
    //   name: "COVID",
    //   firstDose: "",
    //   secondDose: "",
    //   thirdDose: "",
    //   fourthDose: "",
    //   fifthDose: "",
    //   sixthDose: "",
    //   _id: "covid",
    // },
  ],
  immunizationOptions: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case immunizationActionTypes.GET_IMMUNIZATIONS: {
      return { ...state, isLoading: true };
    }
    case immunizationActionTypes.SAVING_IMMUNIZATION: {
      return { ...state, immunization: payload, isLoading: true };
    }
    case immunizationActionTypes.DELETE_IMMUNIZATION_SAGA: {
      return { ...state, isLoading: true };
    }
    case immunizationActionTypes.SAVING_IMMUNIZATION_SAGA: {
      return { ...state, isLoading: false };
    }
    case immunizationActionTypes.GET_IMMUNIZATIONS_SAGA: {
      return { ...state, immunizations: payload, isLoading: false };
    }
    case immunizationActionTypes.DELETE_IMMUNIZATION: {
      const updatedState = state.immunizations;
      const toUpdate = updatedState.filter((imm) => imm._id != payload);
      return { ...state, immunizations: toUpdate, isLoading: false };
    }
    case immunizationActionTypes.SAVING_IMMUNIZATION_OPTIONS: {
      return { ...state, immunizationOptions: payload };
    }
    default:
      return state;
  }
};
