import React, { Component } from "react";
import { Form } from "react-final-form";
import Button from "@material-ui/core/Button";

import {
  submitEmployeeInfo,
  renderEmployeeInfoFields,
} from "./EmployeeSignUp.helpers";
import i18n from "../../../i18n";
export default class ClientPersonalRegistration extends Component {
  componentDidMount() {
    document.title = i18n.t("employee_registration");
  }
  validateEmployeeInfo = (values) => {
    const errors = {};

    if (!values.password) {
      errors.password = i18n.t("required");
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = i18n.t("required");
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = i18n.t("passwords_must_match");
    }
    if (!values.address) {
      errors.address = i18n.t("required");
    }

    return errors;
  };
  render() {
    const param = this.props.location.search;
    const userToReg = param.substr(param.indexOf("=") + 1).trim();
    console.log(userToReg);

    return (
      <>
        <Form
          onSubmit={(values) => submitEmployeeInfo(values, userToReg)}
          validate={this.validateEmployeeInfo}
          render={({ handleSubmit, form, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              {renderEmployeeInfoFields("")}
              <div className="buttons">
                <Button
                  type="button"
                  onClick={form.reset}
                  disabled={submitting || pristine}
                  className="secondary"
                >
                  {i18n.t("reset_form")}
                </Button>
                <Button type="submit" disabled={submitting} className="primary">
                  {i18n.t("submit")}
                </Button>
              </div>
            </form>
          )}
        />
      </>
    );
  }
}
