import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import PrivateRoute from "../components/Routers/PrivateRoute.component";
import PublicRoute from "../components/Routers/PublicRoute.component";
import CompanySignUpRoute from "../components/Routers/CompanySignupRoute";

import AppTemplate from "../components/AppTemplate/AppTemplate";
import Test from "../components/FileUploader/KidsFileUpload.component";

import Activities from "../pages/Activities/Activities";
import Allergies from "../pages/Allergies/Allergies";
import ImmunizationTypes from "../pages/ImmunizationTypes/ImmunizationTypes.component";
import SignForm from "../pages/SignForm/SignForm";
import Kids from "../pages/Kids/Kids";
import Employees from "../pages/Employees/Employees";
import Meals from "../pages/Meals/Meals";
import Groups from "../pages/Groups/Groups.component";
import ClientPersonalRegistration from "../pages/Authentication/ClientPersonalSignup/ClientPersonalRegistration.container";
import LoginForm from "../pages/Authentication/LoginForm/LoginForm.component";
import ClientCompanySignUp from "../pages/Authentication/ClientCompanySignUp/ClientCompanySignUp.component";
import EmployeesComponent from "../pages/Authentication/EmployeeSignup/EmployeeSignup.component";
import CalendarView from "../pages/CalendarView/CalendarView.component";
import BusinessHours from "../pages/BusinessHours/BusinessHoursPage";
import DisplayEmployee from "../components/Employees/components/DisplayEmployee";
import ClientPersonalProfilePage from "../pages/clientProfile/personal/ClientPersonalProfilePage";
import ClientCompanyProfilePage from "../pages/clientProfile/company/ClientCompanyProfilePage";
import Dashboard from "../components/Dashboard/Dashboard";
import Security from "../pages/Security/Security";
import Billing from "../pages/Billing/Billing";

export const customHistory = createBrowserHistory();
customHistory.listen((_) => {
  window.scrollTo(0, 0);
});

// Routes For Navigation
class MyRoutes extends React.Component {
  render() {
    return (
      <Router history={customHistory}>
        <AppTemplate
          currentClient={this.props.currentClient}
          token={this.props.token}
          history={customHistory}
        >
          <Switch>
            {/* Private Routes*/}
            <PrivateRoute path="/dashboard" component={Dashboard} exact />
            <PrivateRoute path="/activities" component={Activities} exact />
            <PrivateRoute path="/allergies" component={Allergies} exact />
            <PrivateRoute
              path="/ImmunizationTypes"
              component={ImmunizationTypes}
              exact
            />
            <PrivateRoute path="/kids" component={Kids} exact />s
            <PrivateRoute path="/sign-form" component={SignForm} exact />
            <PrivateRoute path="/meals" component={Meals} exact />
            <PrivateRoute path="/groups" component={Groups} exact />
            <PrivateRoute path="/employees" component={Employees} exact />
            <PrivateRoute path="/calendar" component={CalendarView} exact />
            <PrivateRoute path="/file-upload" component={Test} exact />
            <PrivateRoute
              path="/company-profile"
              component={ClientCompanyProfilePage}
              exact
            />
            <PrivateRoute
              path="/personal-profile"
              component={ClientPersonalProfilePage}
              exact
            />
            <PrivateRoute
              path="/business-hours"
              component={BusinessHours}
              exact
            />
            <PrivateRoute
              path="/employees/employee/:id"
              component={DisplayEmployee}
              exact
            />
            <PrivateRoute path="/security" component={Security} exact />
            {/* Public not restricted Routes*/}
            <PublicRoute
              component={ClientPersonalRegistration}
              restricted="false"
              path="/client-registration"
              exact
            />
            {/* Public restricted Routes*/}
            <CompanySignUpRoute
              path="/company-signup"
              component={ClientCompanySignUp}
              restricted="true"
              exact
            />
            <PublicRoute
              component={LoginForm}
              restricted="true"
              path="/"
              exact
            />
            <PublicRoute
              component={EmployeesComponent}
              restricted="true"
              path="/employee-complete-registration"
              exact
            />
            <PrivateRoute
              component={Billing}
              restricted="false"
              path="/billing"
              exact
            />
          </Switch>
        </AppTemplate>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.activeUser.token,
  currentClient: state.clients.currentClient,
});

export default connect(mapStateToProps, {})(MyRoutes);
