import { takeEvery, put, call } from "redux-saga/effects";
import { incidentsActionTypes } from "./incidents.types";
import {
  saveIncidents,
  deleteIncident,
  editIncident,
  addIncident,
  requestFailed,
} from "./incidents.actions";
import axios from "../../axios";
const getData = async (payload) => {
  try {
    const response = await axios.get("/incident", {});
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* fetchIncidentsAsync(payload) {
  console.log("i am inside get data...........");
  try {
    const data = yield call(getData, payload);
    yield put(saveIncidents(data));
  } catch (e) {
    yield put(requestFailed());
  }
}

const deleteData = async (payload) => {
  try {
    const response = await axios.delete("/incident", {
      params: { ids: payload.incidentId },
    });
    payload?.callbackMap?.success(response);
    return response.data._id;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* deleteIncidentAsync(payload) {
  try {
    const deleteVal = yield call(deleteData, payload);
    yield put(deleteIncident(deleteVal));
  } catch (e) {
    yield put(requestFailed());
  }
}

const editData = async (payload) => {
  console.log("payload.......", payload);
  try {
    const response = await axios.put("/incident", {
      _id: payload.payload._id,
      data: payload.payload.data,
    });
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* editIncidentAsync(payload) {
  try {
    const responseData = yield call(editData, payload);
    yield put(editIncident(responseData));
  } catch (e) {
    yield put(requestFailed());
  }
}

const addData = async (payload) => {
  try {
    const response = await axios.post("/incident", payload.payload);
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* addIncidentAsync(payload) {
  try {
    const responseData = yield call(addData, payload);
    yield put(addIncident(responseData));
  } catch (e) {
    yield put(requestFailed());
  }
}
export function* watchIncidents() {
  yield takeEvery(incidentsActionTypes.GET_INCIDENTS, fetchIncidentsAsync);
  yield takeEvery(
    incidentsActionTypes.DELETE_INCIDENT_SAGA,
    deleteIncidentAsync
  );

  yield takeEvery(incidentsActionTypes.EDIT_INCIDENT_SAGA, editIncidentAsync);

  yield takeEvery(incidentsActionTypes.ADD_INCIDENT_SAGA, addIncidentAsync);
}
