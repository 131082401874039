import { kidsActionTypes } from "./kids.types";
import _ from "lodash";
const initialState = {
  kids: [],
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case kidsActionTypes.GET_KIDS: {
      return { ...state, isLoading: true };
    }
    case kidsActionTypes.SAVE_KIDS: {
      return { ...state, kids: payload, isLoading: false };
    }
    case kidsActionTypes.DELETE_KID: {
      const updatedState = state.kids;

      const toUpdate = updatedState.filter((kids) => kids._id != payload);
      return { ...state, kids: toUpdate, isLoading: false };
    }
    case kidsActionTypes.DELETE_KID_SAGA: {
      return { ...state, isLoading: true };
    }

    case kidsActionTypes.DELETE_KID_ALLERGY_SAGA: {
      return { ...state, isLoading: true };
    }
    case kidsActionTypes.DELETE_KID_ALLERGY: {
      const currentState = [...state.kids].map((kid) => {
        if (kid._id == payload.kidId) {
          return {
            ...kid,
            allergies: kid.allergies.filter(
              (allergy) => allergy._id != payload._id
            ),
          };
        }
        return kid;
      });
      return { ...state, kids: currentState, isLoading: false };
    }
    case kidsActionTypes.EDIT_KID: {
      const currentState = state.kids;

      const toUpdate = currentState.map((kid) => {
        return kid._id == payload._id ? payload : kid;
      });
      return { ...state, kids: toUpdate, isLoading: false };
    }
    case kidsActionTypes.EDIT_KID_SAGA: {
      return { ...state, isLoading: true };
    }
    case kidsActionTypes.ADD_KID: {
      const currentState = [...state.kids];
      const toUpdate = [...currentState, payload];
      return { ...state, kids: toUpdate, isLoading: false };
    }
    case kidsActionTypes.ADD_KID_SAGA: {
      return { ...state, isLoading: true };
    }
    case kidsActionTypes.ADD_KID_ALLERGY: {
      const currentState = [...state.kids].map((kid) => {
        if (kid._id == payload.kidId) {
          return {
            ...kid,
            allergies: [...kid.allergies, payload],
          };
        }
        return kid;
      });
      return { ...state, kids: currentState, isLoading: false };
    }
    case kidsActionTypes.ADD_KID_ALLERGY_SAGA: {
      return { ...state, isLoading: true };
    }

    case kidsActionTypes.ADD_KID_NOTES: {
      const currentState = [...state.kids];
      const toUpdate = currentState.map((kid) => {
        return kid._id === payload._id ? payload : kid;
      });
      return { ...state, kids: toUpdate, isLoading: false };
    }

    case kidsActionTypes.ADD_KID_NOTES_SAGA: {
      return { ...state, isLoading: true };
    }

    case kidsActionTypes.DELETE_KID_NOTES_ASSET: {
      const { kidId, noteId, assetId } = payload;

      const currentState = [...state.kids];
      const kidToChange = _.find(currentState, (o) => o._id === kidId);
      const noteToChange = _.find([...kidToChange.notes], ["_id", noteId]);
      const changedAssets = noteToChange.assets.filter(
        (asset) => asset._id !== assetId
      );
      const kidIndex = _.findIndex(currentState, (kid) => kid._id === kidId);
      const noteIndex = _.findIndex([...kidToChange.notes], ["_id", noteId]);
      currentState[kidIndex].notes[noteIndex].assets = changedAssets;
      return { ...state, kids: [...currentState], isLoading: false };
    }

    case kidsActionTypes.DELETE_KID_NOTES_ASSET_SAGA: {
      return { ...state, isLoading: true };
    }
    case kidsActionTypes.DELETE_KID_NOTES: {
      // console.log("here at reducer", payload);
      // console.log("shahryarram");
      const { kidId, noteId } = payload;
      const currentState = [...state.kids];
      const kidToChange = _.find(currentState, (kid) => kid._id === kidId);
      const changedNotes = kidToChange.notes.filter(
        (note) => note._id !== noteId
      );
      const kidIndex = _.findIndex(currentState, (kid) => kid._id === kidId);
      currentState[kidIndex].notes = changedNotes;

      return { ...state, kids: [...currentState], isLoading: false };
    }

    case kidsActionTypes.DELETE_KID_NOTES_SAGA: {
      return { ...state, isLoading: true };
    }

    case kidsActionTypes.SET_KIDS_SCHEDULE_SAGA: {
      return { ...state, isLoading: true };
    }

    case kidsActionTypes.SET_KIDS_SCHEDULE: {
      const newKids = [...state.kids].map((kid) => {
        return kid._id == payload._id
          ? { ...kid, schedule: payload.schedule }
          : kid;
      });
      return { ...state, isLoading: false, kids: newKids };
    }
    case kidsActionTypes.ADD_KID_BILLING_SAGA:
    case kidsActionTypes.SET_ACTIVE_BILLING_SAGA: {
      return { ...state, isLoading: true };
    }
    case kidsActionTypes.ADD_KID_BILLING:
    case kidsActionTypes.SET_ACTIVE_BILLING: {
      const newKids = [...state.kids].map((kid) => {
        return kid._id == payload[0].kidId ? { ...kid, billing: payload } : kid;
      });
      return { ...state, isLoading: false, kids: newKids };
    }

    default:
      return state;
  }
};
