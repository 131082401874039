import { takeEvery, put, call } from "redux-saga/effects";
import { activeUserActionTypes } from "./activeUser.types";
import { saveUserFetching } from "./activeUser.actions";
import axios from "../../axios";

const getData = async () => {
  const response = await axios.get("/activity");
  return response.data.data;
};

export function* fetchActivitiesAsync() {
  const data = yield call(getData);
  yield put(saveUserFetching(data));
}

export function* watchActiveUser() {
  yield takeEvery(activeUserActionTypes.GET_USER, fetchActivitiesAsync);
}
