import { groupsActionTypes } from "./groups.types";

const initialState = {
  groups: [],
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case groupsActionTypes.GET_GROUPS: {
      return { ...state, isLoading: true };
    }
    case groupsActionTypes.SAVE_GROUPS: {
      return { ...state, groups: payload, isLoading: false };
    }
    case groupsActionTypes.DELETE_GROUP: {
      const updatedState = state.groups;

      const toUpdate = updatedState.filter((group) => group._id != payload);
      return { ...state, groups: toUpdate, isLoading: false };
    }
    case groupsActionTypes.DELETE_GROUP_SAGA: {
      return { ...state, isLoading: true };
    }
    case groupsActionTypes.EDIT_GROUP: {
      const currentState = state.groups;

      const toUpdate = currentState.map((group) => {
        return group._id == payload._id ? payload : group;
      });
      return { ...state, groups: toUpdate, isLoading: false };
    }
    case groupsActionTypes.EDIT_GROUP_SAGA: {
      return { ...state, isLoading: true };
    }
    case groupsActionTypes.ADD_GROUP: {
      const currentState = state.groups;
      const toUpdate = [...currentState, payload];
      return { ...state, groups: toUpdate, isLoading: false };
    }
    case groupsActionTypes.ADD_GROUP_SAGA: {
      return { ...state, isLoading: true };
    }
    default:
      return state;
  }
};
