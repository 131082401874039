import { requirmentsActionTypes } from "./requirments.types";

const initialState = {
  requirments: [],
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case requirmentsActionTypes.GET_REQUIRMENTS: {
      console.log("get requirments reducers........");
      return { ...state, isLoading: true };
    }
    case requirmentsActionTypes.SAVE_REQUIRMENTS: {
      return { ...state, requirments: payload, isLoading: false };
    }
    case requirmentsActionTypes.DELETE_REQUIRMENT_SAGA: {
      return { ...state, isLoading: true };
    }
    case requirmentsActionTypes.DELETE_REQUIRMENT: {
      const updatedState = state.requirments;

      const toUpdate = updatedState.filter(
        (requirment) => requirment._id != payload
      );
      return { ...state, requirments: toUpdate, isLoading: false };
    }
    case requirmentsActionTypes.EDIT_REQUIRMENT_SAGA: {
      return { ...state, isLoading: true };
    }
    case requirmentsActionTypes.EDIT_REQUIRMENT: {
      const currentState = state.requirments;

      const toUpdate = currentState.map((requirment) => {
        return requirment._id == payload._id ? payload : requirment;
      });
      return { ...state, requirments: toUpdate, isLoading: false };
    }
    case requirmentsActionTypes.ADD_REQUIRMENT_SAGA: {
      return { ...state, isLoading: true };
    }
    case requirmentsActionTypes.ADD_REQUIRMENT: {
      const currentState = state.requirments;
      const toUpdate = [...currentState, payload];
      return { ...state, requirments: toUpdate, isLoading: false };
    }
    case requirmentsActionTypes.REQUEST_FAILED: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
