import { requirmentsActionTypes } from "./requirments.types";

export const saveRequirments = (requirments) => {
  return {
    type: requirmentsActionTypes.SAVE_REQUIRMENTS,
    payload: requirments,
  };
};

export const startRequirmentFetching = (callbackMap, params) => {
  return {
    type: requirmentsActionTypes.GET_REQUIRMENTS,
    callbackMap,
    params,
  };
};

export const startDeletingRequirment = (callbackMap, id) => {
  return {
    type: requirmentsActionTypes.DELETE_REQUIRMENT_SAGA,
    callbackMap,
    requirmentId: id,
  };
};

export const deleteRequirment = (id) => {
  console.log("inside delete requirment ", id);
  return {
    type: requirmentsActionTypes.DELETE_REQUIRMENT,
    payload: id,
  };
};

export const startEditingRequirment = (callbackMap, payload) => {
  return {
    type: requirmentsActionTypes.EDIT_REQUIRMENT_SAGA,
    callbackMap,
    payload,
  };
};

export const editRequirment = (payload) => {
  return {
    type: requirmentsActionTypes.EDIT_REQUIRMENT,
    payload,
  };
};

export const startAddingRequirment = (callbackMap, payload) => {
  return {
    type: requirmentsActionTypes.ADD_REQUIRMENT_SAGA,
    callbackMap,
    payload,
  };
};

export const addRequirment = (payload) => {
  return {
    type: requirmentsActionTypes.ADD_REQUIRMENT,
    payload,
  };
};

export const requestFailed = () => {
  return {
    type: requirmentsActionTypes.REQUEST_FAILED,
  };
};
