import { immunizationActionTypes } from "./immunization.types";

export const startImmunizationFetching = () => {
  return {
    type: immunizationActionTypes.GET_IMMUNIZATIONS,
  };
};

export const saveImmunization = (payload) => {
  return {
    type: immunizationActionTypes.SAVING_IMMUNIZATION,
    payload,
  };
};

export const savedImmunization = (payload) => {
  return {
    type: immunizationActionTypes.SAVING_IMMUNIZATION_SAGA,
    payload,
  };
};

export const startDeletingImmunization = (callbackMap, id) => {
  return {
    type: immunizationActionTypes.DELETE_IMMUNIZATION_SAGA,
    callbackMap,
    immunizationId: id,
  };
};

export const deleteImmunization = (id) => {
  console.log("delete activity..", id);
  return {
    type: immunizationActionTypes.DELETE_IMMUNIZATION,
    payload: id,
  };
};

export const getImmunizationsSaga = (payload) => {
  return {
    type: immunizationActionTypes.GET_IMMUNIZATIONS_SAGA,
    payload,
  };
};

export const saveImmunizationOptions = (payload) => {
  return {
    type: immunizationActionTypes.SAVING_IMMUNIZATION_OPTIONS,
    payload,
  };
};
