import { Field } from "react-final-form";
import React, { useState } from "react"; // this one is here because final form needs it
import TextField from "@material-ui/core/TextField";
import { ErrorMessage } from "./EditingModal.styles";
import store from "../../../../redux/store";
import ColorPicker from "material-ui-color-picker";
import { startEditingActivity } from "../../../../redux/activities/activities.actions";
import { startEditingMeal } from "../../../../redux/meals/meals.actions";
import { startEditingKid } from "../../../../redux/kids/kids.actions";
import {
  startEditingEmployee,
  startEditingEmployeeSchedule,
} from "../../../../redux/employees/employees.actions";
import tableHeads from "../TableData";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import Checkbox from "@material-ui/core/Checkbox";
import { v4 as uuidv4 } from "uuid";

const onSubmit = async (tableType, id, values, schedule) => {
  switch (tableType) {
    case "activities":
      store.dispatch(
        startEditingActivity({
          _id: id,
          data: values,
        })
      );
      break;
    case "meals":
      store.dispatch(
        startEditingMeal({
          _id: id,
          data: values,
        })
      );
      break;
    case "kids":
      store.dispatch(
        startEditingKid({
          _id: id,
          data: values,
        })
      );
      break;
    case "employees": {
      if (values !== null) {
        store.dispatch(
          startEditingEmployee({
            _id: id,
            data: values,
          })
        );
      } else {
        store.dispatch(
          startEditingEmployeeSchedule({
            _id: id,
            data: schedule,
          })
        );
      }

      break;
    }
    default:
      break;
  }
};

const renderEditForm = (currentRow, sample, schema) => {
  return schema.map(
    ({ value, title, fieldType }, index) =>
      index < schema.length - 2 && (
        <Field
          key={`editKid-${index}`}
          initialValue={
            fieldType == "date"
              ? moment(currentRow[value]).format("YYYY-MM-DD")
              : currentRow[value]
          }
          name={value}
        >
          {({ input, meta }) => {
            switch (fieldType) {
              case "text":
                return (
                  <>
                    <TextField
                      className={sample}
                      {...input}
                      label={title}
                      key={uuidv4()}
                    />
                    {meta.error && meta.touched ? (
                      <ErrorMessage>{meta.error}</ErrorMessage>
                    ) : (
                      <ErrorMessage></ErrorMessage>
                    )}
                  </>
                );
              case "date":
                return (
                  <>
                    <TextField
                      className={sample}
                      {...input}
                      label={title}
                      type="date"
                      // variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      key={uuidv4()}
                    />
                    {meta.error && meta.touched ? (
                      <ErrorMessage>{meta.error}</ErrorMessage>
                    ) : (
                      <ErrorMessage></ErrorMessage>
                    )}
                  </>
                );
              case "color":
                return (
                  <>
                    <ColorPicker
                      className={sample}
                      {...input}
                      label={title}
                      name="color"
                      defaultValue="#000"
                      // variant="outlined"
                    />
                    {meta.error && meta.touched ? (
                      <ErrorMessage>{meta.error}</ErrorMessage>
                    ) : (
                      <ErrorMessage></ErrorMessage>
                    )}
                  </>
                );

              default:
                break;
            }
          }}
        </Field>
      )
  );
};
const handleDateChange = (date, setSchedule, schedule, day, timeType) => {
  const timeSlotLength = 5;

  const startTime = moment(date).format("HH:mm");

  if (startTime) {
    let hour = startTime.split(":")[0];
    let minute = parseInt(startTime.split(":")[1]);
    const remainder = minute % timeSlotLength;
    if (remainder) {
      minute =
        minute -
        remainder +
        ((minute % timeSlotLength) / timeSlotLength > 0.5 ? timeSlotLength : 0);
      if (minute >= 60) {
        hour++;
        minute %= 60;
      }
      const time = `${hour}:${minute < 10 ? "0" + minute : minute}`;

      const newSchedule = schedule;
      newSchedule[day][timeType] = time;
      // setSchedule([...newSchedule]);
    }
  }
};

const handleDayChange = (e, setSchedule, schedule, day) => {
  const newSchedule = [...schedule];
  newSchedule[day].isWorking = e.target.checked;
  setSchedule([...newSchedule]);
};

const renderEmployeeWorkingTime = (tableType, schedule, setSchedule) => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {schedule &&
          schedule.map((day, i) => {
            return (
              <>
                <span style={{ margin: "0", display: "block" }} key={uuidv4()}>
                  <Checkbox
                    checked={day.isWorking}
                    onChange={(e) =>
                      handleDayChange(e, setSchedule, schedule, i)
                    }
                    inputProps={{ "aria-label": "primary checkbox" }}
                    key={uuidv4()}
                  />
                  {daysOfWeek[i]}
                </span>
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  key={uuidv4()}
                  value={moment(day.start, "HH:mm").toDate()}
                  onChange={(date) =>
                    handleDateChange(date, setSchedule, schedule, i, "start")
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                  disabled={!day.isWorking}
                />
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  key={uuidv4()}
                  value={moment(day.end, "HH:mm").toDate()}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                  onChange={(date) =>
                    handleDateChange(date, setSchedule, schedule, i, "end")
                  }
                  disabled={!day.isWorking}
                />
              </>
            );
          })}
      </MuiPickersUtilsProvider>
    </>
  );
};

export { onSubmit, renderEditForm, renderEmployeeWorkingTime };
