import React from "react";
import { useSelector } from "react-redux";

export default function LoggedOutOnly({ children }) {
  const token = useSelector((s) => s.activeUser.token);
  if (token) {
    return null;
  }

  return children;
}
