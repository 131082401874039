export const signFormActionTypes = {
  GET_SIGN_FORMS: "GET_SIGN_FORMS",
  SIGN_FORMS_RECEIVED: "SIGN_FORMS_RECEIVED",

  ADD_SIGN_FORM: "ADD_SIGN_FORM",
  SIGN_FORM_SAVED: "SIGN_FORM_SAVED",

  DELETE_SIGN_FORM: "DELETE_SIGN_FORM",
  SIGN_FORM_DELETED: "SIGN_FORM_DELETED",

  ASSIGN_FORM_REQUEST: "ASSIGN_FORM_REQUEST",
  ASSIGN_FORM: "ASSIGN_FORM",
};
