import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { EditModalWrapper, Row } from "./KidsSchedule.styles";
import _ from "lodash";
import { useState } from "react";
import { useEffect } from "react";
import Day from "./DayHour";
import { startSettingKidsSchedule } from "../../redux/kids/kids.actions";
import { useDispatch } from "react-redux";
import SuccessHelper from "../../helpers/SuccessHelper";
import ErrorHelper from "../../helpers/ErrorHelper";
import i18n from "../../i18n";
import PrimaryButton from "../Core/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../Core/Buttons/SecondaryButton/SecondaryButton";
import Modal from "../Core/Modal/Modal";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function EditHours({
  open,
  handleModalClose,
  schedule,
  kidId,
  changeEditingTableRowState,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [localSchedule, setLocalSchedule] = useState([]);
  useEffect(() => {
    setLocalSchedule(schedule);
  }, [open, schedule]);
  const handleAddModalCancel = () => {
    handleModalClose();
  };
  const onSubmit = async () => {
    dispatch(
      startSettingKidsSchedule(
        { schedule: localSchedule, _id: kidId },
        {
          success: ({ data }) => {
            SuccessHelper.handleToastSuccess(
              i18n.t("successfully_updated"),
              true
            );
            changeEditingTableRowState(data.data);
            handleModalClose();
          },
          failure: () =>
            ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true),
        }
      )
    );
  };
  const onChange = (dayIndex, day) => {
    localSchedule[dayIndex] = day;
    setLocalSchedule([...localSchedule]);
  };
  return (
    <Modal
      className={classes.modal}
      open={open}
      title={i18n.t("edit_schedule")}
      closeModal={() => handleAddModalCancel()}
    >
      {localSchedule.map((day, i) => {
        return (
          <Row key={`hours-${i}`}>
            <Day
              {...{ day }}
              dayIndex={i}
              onChange={(day) => onChange(i, day)}
            />
          </Row>
        );
      })}
      <div className="row justify-content-end mt-2">
        <SecondaryButton onClick={() => handleAddModalCancel()}>
          {i18n.t("cancel")}
        </SecondaryButton>
        <PrimaryButton className="ml-1" onClick={onSubmit}>
          {i18n.t("submit")}
        </PrimaryButton>
      </div>
    </Modal>
  );
}
