import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import axios from "../../axios";
import FormTable from "./FormTable";
import {
  addSignForm,
  getSignForms,
  deleteSignForm,
  assignFormRequest,
} from "../../redux/signForm/signForm.actions";
import { startGroupFetching } from "../../redux/groups/groups.actions";
import FileUploadModal from "./FileUploadModal";

import ErrorHelper from "../../helpers/ErrorHelper";
import SuccessHelper from "../../helpers/SuccessHelper";

import i18n from "../../i18n";
import KidGroupAccordian from "./kidGroupAccordian";
import callbackHelper from "../../helpers/callbackHelper";
import DeleteHelper from "../../helpers/DeleteHelper";

class SignForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      assignToGroupModal: false,
      showForms: true,
    };
    this.currentFile = null;
    this.checkedGroups = [];

    this.handleUploadFileClick = this.handleUploadFileClick.bind(this);

    this.handleAssignToGroupClick = this.handleAssignToGroupClick.bind(this);
    this.submitFormHandler = this.submitFormHandler.bind(this);
    this.changeFormHandler = this.changeFormHandler.bind(this);
    this.deleteFormHandler = this.deleteFormHandler.bind(this);
    this.downloadAssetHandler = this.downloadAssetHandler.bind(this);
    this.submitGroups = this.submitGroups.bind(this);
    this.getPaginateData = this.getPaginateData.bind(this);
  }
  async componentDidMount() {
    document.title = i18n.t("sign_form");
    new Promise((success, failure) => {
      this.props.getSignForms({ success, failure }, 1, {
        relations: ["assigned"],
      });
    })
      .then((res) => {
        // this.setState({ isloading: false });
      })
      .catch((err) => {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });

    if (!this.props.groups.length) {
      new Promise((success, failure) => {
        this.props.startGroupFetching({ success, failure });
      })
        .then((res) => {
          // this.setState({ isloading: false });
        })
        .catch((err) => {
          this.setState({ isloading: false });
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
        });
    }
  }
  getPaginateData(event, page) {
    Promise.all([
      new Promise((success, failure) => {
        this.props.getSignForms({ success, failure }, page, {
          relations: ["assigned"],
        });
      }),
    ])
      .then((res) => {})
      .catch((err) => {
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      })
      .finally(() => {
        this.setState({ isloading: false });
      });
  }
  handleUploadFileClick() {
    if (this.state.assignToGroupModal != null) {
      this.setState({
        assignToGroupModal: null,
      });
    }
    this.setState({
      openModal: !this.state.openModal,
    });
  }

  handleAssignToGroupClick(form) {
    this.setState({
      assignToGroupModal: form,
    });
  }

  changeFormHandler(file) {
    this.currentFile = file;
  }
  async downloadAssetHandler(row) {
    let pathArr = row.path.split("/");
    let path = pathArr[pathArr.length - 1];
    const response = await axios.post("/signForm/downloadFile", {
      path,
    });
    // Create blob link to download
    const url = window.URL.createObjectURL(
      new Blob([new Uint8Array(response.data.file.data)])
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", path);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  }
  deleteFormHandler(id) {
    Promise.all([
      new Promise((success, failure) => {
        this.props.deleteSignForm(id, { success, failure });
      }),
    ])
      .then((res) => {
        this.setState({ isloading: false });
        SuccessHelper.handleToastSuccess(i18n.t("successfully_deleted"), true);
      })
      .catch((err) => {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  }
  submitFormHandler({ title, file }) {
    Promise.all([
      new Promise((success, failure) => {
        this.props.saveForm(
          {
            title,
            file,
          },
          { success, failure }
        );
      }),
    ])
      .then((res) => {
        this.setState({ isloading: false, openModal: false });
        SuccessHelper.handleToastSuccess(i18n.t("successfully_created"), true);
      })
      .catch((err) => {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  }

  async submitGroups(toAdd, toRemove, toSend) {
    this.props.assignFormRequest(
      {
        signFormId: this.state.assignToGroupModal._id,
        toAdd,
        toRemove,
        toSend,
      },
      {
        success: () => {
          this.setState({
            assignToGroupModal: null,
            openModal: false,
          });
          SuccessHelper.handleToastSuccess(
            i18n.t("successfully_updated"),
            true
          );
        },
      }
    );
  }
  render() {
    return (
      <div>
        <div className="mb-2 row justify-content-between">
          {/* <Button
              onClick={() =>
                this.setState({ showForms: !this.state.showForms })
              }
              variant="contained"
              color={this.state.showForms ? "primary" : "secondary"}
            >
              {this.state.showForms ? "Show Assigned Forms" : "Show All Forms"}
            </Button> */}
          <Button
            onClick={() => this.setState({ openModal: !this.state.openModal })}
            variant="contained"
          >
            Upload File
          </Button>
        </div>
        {/* {this.state.showForms ? (
            <FormTable
              data={this.props.signForms.signForms}
              deleteForm={this.deleteFormHandler}
              downloadAsset={this.downloadAssetHandler}
              handleAssignToGroup={this.handleAssignToGroupClick}
              getPaginateData={this.getPaginateData}
            />
          ) : (
            <div>
              <AssignFormTable data={this.state.assignedSignedForms} />
            </div>
          )} */}
        <FormTable
          data={this.props.signForms.signForms}
          deleteForm={(id) => {
            DeleteHelper.handleDelete(i18n.t("are_you_sure"), () => {
              this.deleteFormHandler(id);
            });
            // callbackHelper(this.deleteFormHandler, id, "delete");
          }}
          downloadAsset={this.downloadAssetHandler}
          handleAssignToGroup={this.handleAssignToGroupClick}
          getPaginateData={this.getPaginateData}
        />
        <FileUploadModal
          openModal={this.state.openModal}
          modalClick={this.handleUploadFileClick}
          submitFormHandler={this.submitFormHandler}
        />
        <KidGroupAccordian
          form={this.state.assignToGroupModal}
          groups={this.props.groups}
          submitGroups={this.submitGroups}
          handleCloseModal={() => {
            this.setState({ assignToGroupModal: null });
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    signForms: state.signForms,
    groups: state.groups.groups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveForm: (formData, mapCallBack) =>
      dispatch(addSignForm(formData, mapCallBack)),
    getSignForms: (mapCallBack, page = 1, params) =>
      dispatch(getSignForms(page, mapCallBack, params)),
    deleteSignForm: (payload, callbackMap) =>
      dispatch(deleteSignForm(payload, callbackMap)),
    startGroupFetching: (callbackMap) =>
      dispatch(startGroupFetching(callbackMap)),
    assignFormRequest: (...args) => dispatch(assignFormRequest(...args)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignForm);
