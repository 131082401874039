import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLogin } from "../../helpers/isLogin";
import { hasCompany } from "../../helpers/hasCompany";
export default function CompanySignUpRoute({
  component: Component,
  restricted,
  ...rest
}) {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={(props) => {
        return isLogin() && restricted ? (
          hasCompany() ? (
            <Redirect to="/" />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
}
