export const groupsActionTypes = {
  GET_GROUPS: "GET_GROUPS",
  SAVE_GROUPS: "SAVE_GROUPS",

  DELETE_GROUP: "DELETE_GROUP",
  DELETE_GROUP_SAGA: "DELETE_GROUP_SAGA",

  EDIT_GROUP: "EDIT_GROUP",
  EDIT_GROUP_SAGA: "EDIT_GROUP_SAGA",

  ADD_GROUP: "ADD_GROUP",
  ADD_GROUP_SAGA: "ADD_GROUP_SAGA",
};
