import { clientsActionTypes } from "./clientRegistration.types";

const initialState = {
  clients: [],
  currentClient: {},
  isLoading: false,
  currentClientDaycare: {},
  currentUserProfile: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case clientsActionTypes.ADD_CLIENT_PERSONAL_INFO: {
      return { ...state, currentClient: payload, isLoading: false };
    }
    case clientsActionTypes.ADD_CLIENT_PERSONAL_INFO_SAGA: {
      return { ...state, isLoading: true };
    }
    case clientsActionTypes.ADD_CLIENT_COMPANY_INFO: {
      return { ...state, currentClient: payload, isLoading: false };
    }
    case clientsActionTypes.ADD_CLIENT_COMPANY_INFO_SAGA: {
      return { ...state, isLoading: true };
    }
    case clientsActionTypes.REQUEST_FAILED: {
      return { ...state, isLoading: false };
    }
    case clientsActionTypes.GET_DAYCARE: {
      return { ...state, isLoading: true };
    }
    case clientsActionTypes.GET_CURRENT_CLIENT_SAGA: {
      return { ...state, isLoading: true };
    }
    case clientsActionTypes.UPDATE_CLIENT_PERSONAL_INFO: {
      return { ...state, isLoading: true };
    }
    case clientsActionTypes.UPDATE_CLIENT_COMPANY_INFO: {
      return { ...state, isLoading: true };
    }
    case clientsActionTypes.UPDATED_CLIENT_INFO: {
      return { ...state, isLoading: false };
    }
    case clientsActionTypes.UPDATED_CLIENT_COMPANY_INFO: {
      return { ...state, isLoading: false };
    }
    case clientsActionTypes.ADD_CURRENT_DAYCARE_STATE: {
      return { ...state, currentClientDaycare: payload, isLoading: false };
    }
    case clientsActionTypes.ADD_CURRENT_CLIENT_STATE: {
      return { ...state, currentUserProfile: payload, isLoading: false };
    }
    default:
      return state;
  }
};
