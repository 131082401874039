import styled from "styled-components/macro";

export const MedicalMain = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 2em 2em;
  grid-template-areas:
    ". ."
    ". .";
  justify-items: center;
  padding: 0;
  padding: 2em;
`;

export const MedicalCategories = styled.div`
  background: aliceblue;
  height: 10em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  &:hover {
    cursor: pointer;
    background: antiquewhite;
  }
`;

export const DoctorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Back = styled.div`
  align-self: flex-start;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: aliceblue;
  border: 1px solid black;
  &:hover {
    cursor: pointer;
    border-color: black;
    background-color: antiquewhite;
  }
`;

export const DoctorCard = styled.div`
  display: grid;
  grid-template-columns: 7fr 1fr;
  grid-template-rows: 1fr;
  gap: 0em 0px;
  grid-template-areas: ". .";
  width: 80%;
  background-color: aliceblue;
  padding: 20px;
`;

export const ConditionCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr;
  gap: 0em 0px;
  grid-template-areas: ". .";
  width: 80%;
  background-color: aliceblue;
  padding: 20px;
`;

export const DoctorItem = styled.div`
  margin-bottom: 10px;
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;
export const Actions = styled.div`
  display: flex;
  max-height: 14%;
  justify-content: space-evenly;
  &:hover {
    cursor: pointer;
  }
`;

export const EditFields = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: repeat(4, 1fr);
  gap: 1em;
  grid-template-areas:
    ". ."
    "ad ad"
    ". ."
    ". .";
  width: 100%;
  height: 100%;
`;
export const DoctorsItems = styled.div`
  grid-column: ${({ type }) => {
    switch (type) {
      case "name":
        return "1 / 2";
      case "email":
        return "2 / 5";
      case "profession":
        return "1 / 3";
      case "address":
        return "1 / 5";
      case "workPhone":
        return "3 / 5";
      case "cellPhone":
        return "1 / 3";
      case "otherPhone":
        return "3 / 5";

      default:
        return "1 / 2";
    }
  }};
`;
export const AddFields = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  grid-template-areas: ". .";
  justify-items: center;
  width: 100%;
  height: 50%;
`;
export const EditActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const DoctorsControls = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const RequirementsItems = styled.div`
  grid-column: ${({ type }) => {
    switch (type) {
      case "name":
        return "1 / 3";
      case "description":
        return "1/ -1";

      default:
        return "1 / -1";
    }
  }};
`;
