import { conditionsActionTypes } from "./conditions.types";

const initialState = {
  conditions: [],
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case conditionsActionTypes.GET_CONDITIONS: {
      console.log("get conditions reducers........");
      return { ...state, isLoading: true };
    }
    case conditionsActionTypes.SAVE_CONDITIONS: {
      return { ...state, conditions: payload, isLoading: false };
    }
    case conditionsActionTypes.DELETE_CONDITION_SAGA: {
      return { ...state, isLoading: true };
    }
    case conditionsActionTypes.DELETE_CONDITION: {
      const updatedState = state.conditions;

      const toUpdate = updatedState.filter(
        (condition) => condition._id != payload
      );
      return { ...state, conditions: toUpdate, isLoading: false };
    }
    case conditionsActionTypes.EDIT_CONDITION_SAGA: {
      return { ...state, isLoading: true };
    }
    case conditionsActionTypes.EDIT_CONDITION: {
      const currentState = state.conditions;

      const toUpdate = currentState.map((condition) => {
        return condition._id == payload._id ? payload : condition;
      });
      return { ...state, conditions: toUpdate, isLoading: false };
    }
    case conditionsActionTypes.ADD_CONDITION_SAGA: {
      return { ...state, isLoading: true };
    }
    case conditionsActionTypes.ADD_CONDITION: {
      const currentState = state.conditions;
      const toUpdate = [...currentState, payload];
      return { ...state, conditions: toUpdate, isLoading: false };
    }
    case conditionsActionTypes.REQUEST_FAILED: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
