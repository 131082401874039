import { activeUserActionTypes } from "./activeUser.types";

const initialState = {
  activeUser: {},
  token: null,
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case activeUserActionTypes.GET_USER: {
      return { ...state, isLoading: true };
    }
    case activeUserActionTypes.SAVE_USER: {
      return { ...state, activeUser: payload, isLoading: false };
    }
    case activeUserActionTypes.SET_TOKEN: {
      return { ...state, token: payload };
    }
    default:
      return state;
  }
};
