import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

export default function PaginationOutlined(props) {
  return (
    <div>
      <Pagination
        onChange={props.getPaginateData}
        count={props.totalPages}
        variant="outlined"
        color="primary"
      />
    </div>
  );
}
