import { incidentsActionTypes } from "./incidents.types";

const initialState = {
  incidents: [],
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case incidentsActionTypes.GET_INCIDENTS: {
      return { ...state, isLoading: true };
    }
    case incidentsActionTypes.SAVE_INCIDENTS: {
      return { ...state, incidents: payload, isLoading: false };
    }
    case incidentsActionTypes.DELETE_INCIDENT_SAGA: {
      return { ...state, isLoading: true };
    }
    case incidentsActionTypes.DELETE_INCIDENT: {
      const updatedState = state.incidents;

      const toUpdate = updatedState.filter(
        (incident) => incident._id != payload
      );
      return { ...state, incidents: toUpdate, isLoading: false };
    }
    case incidentsActionTypes.EDIT_INCIDENT_SAGA: {
      return { ...state, isLoading: true };
    }
    case incidentsActionTypes.EDIT_INCIDENT: {
      const currentState = state.incidents;

      const toUpdate = currentState.map((incident) => {
        return incident._id == payload._id ? payload : incident;
      });
      return { ...state, incidents: toUpdate, isLoading: false };
    }
    case incidentsActionTypes.ADD_INCIDENT_SAGA: {
      return { ...state, isLoading: true };
    }
    case incidentsActionTypes.ADD_INCIDENT: {
      const currentState = state.incidents;
      const toUpdate = [...currentState, payload];
      return { ...state, incidents: toUpdate, isLoading: false };
    }
    case incidentsActionTypes.REQUEST_FAILED: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
