import React, { useEffect, useState } from "react";
import ClientPersonalProfile from "../../../components/Profiles/clientProfile/ClientPersonalProfile";
import {
  updateClientPersonalInfo,
  getCurrentClient,
} from "../../../redux/authentication/ClientRegistration/clientRegistration.actions";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../../i18n";
export default function ClientPersonalProfilePage() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.clients.currentUserProfile);
  useEffect(() => {
    document.title = i18n.t("personal_profile");
    async function sendApiRequest() {
      dispatch(getCurrentClient());
    }
    sendApiRequest();
  }, []);

  const handleSubmitClientPersonalProfile = async (payload) => {
    dispatch(
      updateClientPersonalInfo({
        data: payload,
        _id: currentUser._id,
      })
    );
  };

  return (
    <>
      {currentUser != {} && (
        <>
          <ClientPersonalProfile
            {...{ currentUser, handleSubmitClientPersonalProfile }}
          />
        </>
      )}
    </>
  );
}
