import React, { Component, useState } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import {
  Drawer as MUIDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import i18n from "../../i18n";
import LoggedInOnly from "../LoggedInOnly/LoggedInOnly";
import LoggedOutOnly from "../LoggedOutOnly/LoggedOutOnly";
import NavBar from "../NavBar/NavBar";
import { hasCompany } from "../../helpers/hasCompany";
import icons from "../../assets/Icons";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/Inbox";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

// variables
const drawerWidth = 245;
const ImgIcon = ({ src }) => {
  return <img src={src} style={{ width: 24, height: 24 }} />;
};
const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: drawerWidth,
    top: "80px",
  },
}));
const SiteMenu = ({ handleNavigation }) => {
  const classes = useStyles();
  const [open, setOpen] = useState({
    businessSettings: false,
    kidsSection: false,
    medical: false,
    staffManagement: false,
    menusCal: false,
  });
  const handleClick = (groupName) => {
    let newOpen = { ...open };
    newOpen[groupName] = !open[groupName];
    setOpen(newOpen);
  };
  const menuItems = [
    {
      groupName: i18n.t("business_settings"),
      groupValue: "businessSettings",
      icon: <ImgIcon src={icons.businessSettingsIcon} />,
      groupMenuItems: [
        {
          menuTitle: i18n.t("personal_profile"),
          icon: <ImgIcon src={icons.userIcon} />,
          display: "/personal-profile",
        },
        {
          menuTitle: i18n.t("company_profile"),
          icon: <ImgIcon src={icons.companyIcon} />,
          display: "/company-profile",
        },
        {
          menuTitle: i18n.t("business_hours"),
          icon: <ImgIcon src={icons.timeIcon} />,
          display: "/business-hours",
        },
        {
          menuTitle: i18n.t("security"),
          icon: <ImgIcon src={icons.shieldIcon} />,
          display: "/security",
        },
        {
          menuTitle: i18n.t("billing"),
          icon: <ImgIcon src={icons.billingIcon} />,
          display: "/billing",
        },
      ],
    },
    {
      groupName: i18n.t("kids_section"),
      groupValue: "kidsSection",
      icon: <ImgIcon src={icons.kidsSectionIcon} />,

      groupMenuItems: [
        {
          menuTitle: i18n.t("kids"),
          icon: <ImgIcon src={icons.kidsIcon} />,
          display: "/kids",
        },
        {
          menuTitle: i18n.t("groups"),
          icon: <ImgIcon src={icons.kidsIcon} />,
          display: "/groups",
        },
        {
          menuTitle: i18n.t("upload_forms"),
          icon: <ImgIcon src={icons.formsIcon} />,
          display: "/sign-form",
        },
      ],
    },
    {
      groupName: i18n.t("medical"),
      groupValue: "medical",
      icon: <ImgIcon src={icons.medicalSectionIcon} />,

      groupMenuItems: [
        {
          menuTitle: i18n.t("allergies"),
          icon: <ImgIcon src={icons.medicalIcon} />,
          display: "/allergies",
        },
        {
          menuTitle: i18n.t("immunization_types"),
          icon: <ImgIcon src={icons.medicalIcon} />,
          display: "/immunizationTypes",
        },
      ],
    },
    {
      groupName: i18n.t("staff_management"),
      groupValue: "staffManagement",
      icon: <ImgIcon src={icons.staffManagementIcon} />,
      groupMenuItems: [
        {
          menuTitle: i18n.t("employees"),
          icon: <ImgIcon src={icons.teamIcon} />,
          display: "/employees",
        },
      ],
    },
    {
      groupName: i18n.t("menus_calendars"),
      groupValue: "menusCal",
      icon: <ImgIcon src={icons.menusCalendarIcon} />,

      groupMenuItems: [
        {
          menuTitle: i18n.t("activities"),
          icon: <ImgIcon src={icons.activitiesIcon} />,
          display: "/activities",
        },
        {
          menuTitle: i18n.t("meals"),
          icon: <ImgIcon src={icons.mealsIcon} />,
          display: "/meals",
        },
        {
          menuTitle: i18n.t("calendar"),
          icon: <ImgIcon src={icons.calendarIcon} />,
          display: "/calendar",
        },
      ],
    },
  ];

  return (
    hasCompany() && (
      <MuiThemeProvider>
        <MUIDrawer
          variant="permanent"
          anchor="left"
          className={classes.drawer}
          // style={{ width: drawerWidth }}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <List className="drawerList">
            {menuItems.map((text, index) => {
              const { groupName, groupMenuItems, groupValue, icon } = text;
              return (
                <>
                  <ListItem
                    key={_.uniqueId()}
                    button
                    onClick={() => handleClick(groupValue)}
                  >
                    <ListItemIcon>{icon ? icon : <InboxIcon />}</ListItemIcon>
                    <ListItemText primary={groupName} />
                    {open[groupValue] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse
                    key={_.uniqueId()}
                    in={open[groupValue]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {(groupMenuItems || []).map((groupItem) => {
                        const { menuTitle, icon, display } = groupItem;
                        return (
                          <ListItem
                            onClick={() => {
                              handleNavigation(display);
                            }}
                            button
                            style={{ paddingLeft: "2em" }}
                          >
                            <ListItemIcon>{icon}</ListItemIcon>
                            <ListItemText primary={menuTitle} />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </>
              );
            })}
          </List>
        </MUIDrawer>
      </MuiThemeProvider>
    )
  );
};

export default class AppTemplate extends Component {
  state = {
    isOpen: false,
    routeAddress: "",
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };
  handleParentsList = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    return (
      <>
        <NavBar />
        <LoggedInOnly>
          <SiteMenu handleNavigation={this.handleNavigation} />
          <main
            style={{
              marginLeft: hasCompany() ? drawerWidth : "",
              position: "relative",
              top: "80px",
              height: "calc(100vh - 120px)",
            }}
          >
            {this.props.children}
          </main>
        </LoggedInOnly>
        <LoggedOutOnly>
          <main>{this.props.children}</main>
        </LoggedOutOnly>
      </>
    );
  }
}
