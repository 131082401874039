import moment from "moment";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasicTable from "../../components/Core/BasicTable";
import PrimaryButton from "../../components/Core/Buttons/PrimaryButton/PrimaryButton";
import FormModal from "../../components/Core/FormModal/FormModal";
import i18n from "../../i18n";
import { getARAgingRequest } from "../../redux/billing/billing.actions";
import { startKidFetching } from "../../redux/kids/kids.actions";
import axios from "../../axios";
import ErrorHelper from "../../helpers/ErrorHelper";
import NewInvoiceModal from "../../components/Billing/NewInvoiceModal";
import InvoicesModal from "./components/InvoicesModal";
import "./Billing.css";

export default function Billing() {
  const dispatch = useDispatch();
  const [addOpen, setAddOpen] = useState(false);
  const [activeId, setActiveId] = useState(false);
  const [activeKey, setActiveKey] = useState(false);
  const kids = useSelector((s) => s.kids.kids);
  const kidMap = useMemo(() => {
    const map = {};
    kids.map((kid) => {
      map[kid._id] = kid;
    });
    return map;
  }, [kids]);
  const invoices = useSelector((s) => s.billing.arAgingReport);
  useEffect(() => {
    dispatch(getARAgingRequest());
    dispatch(startKidFetching(null, { relations: ["billing"] }));
  }, []);
  const activeInvoices = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const found = invoices.find((invoice) => invoice.kidId == activeId);
    return found[activeKey];
  }, [invoices, activeId, activeKey]);

  const renderInvoiceButton = (element, key) => {
    return (
      <PrimaryButton
        onClick={() => {
          if (element) {
            setActiveId(element._id);
            setActiveKey(key);
          }
        }}
        className="no-wrap"
      >
        $ {(element?.totalDue || 0).toFixed(2)}
      </PrimaryButton>
    );
  };
  return (
    <div className="Page Billing" style={{ width: "88%", margin: "auto" }}>
      <div className="row justify-content-between mb-2">
        <div className="title">{i18n.t("billing")}</div>
        <PrimaryButton onClick={() => setAddOpen(true)}>
          {i18n.t("new")}
        </PrimaryButton>
      </div>
      <BasicTable
        rows={invoices}
        schema={[
          {
            header: i18n.t("kid"),
            field: "kid",
            accessor: ({ kidId }) =>
              `${kidMap[kidId]?.firstName} ${kidMap[kidId]?.lastName}`,
          },
          {
            header: i18n.t("current"),
            field: "current",
            accessor: ({ current }) => renderInvoiceButton(current, "current"),
          },
          {
            header: i18n.t("30_days"),
            field: "days30",
            accessor: ({ days30 }) => renderInvoiceButton(days30, "days30"),
          },
          {
            header: i18n.t("60_days"),
            field: "days60",
            accessor: ({ days60 }) => renderInvoiceButton(days60, "days60"),
          },
          {
            header: i18n.t("90_days"),
            field: "days90",
            accessor: ({ days90 }) => renderInvoiceButton(days90, "days90"),
          },
          {
            header: i18n.t("120_days_and_more"),
            field: "days120AndMore",
            accessor: ({ days120AndMore }) =>
              renderInvoiceButton(days120AndMore, "days120AndMore"),
          },
        ]}
      />
      <NewInvoiceModal open={addOpen} setOpen={setAddOpen} />
      <InvoicesModal
        open={activeId}
        setOpen={setActiveId}
        invoices={activeInvoices}
      />
    </div>
  );
}
