import { loginActionTypes } from "./login.types";

export const startLogin = (payload) => {
  return {
    type: loginActionTypes.LOGIN_USER_SAGA,
    payload,
  };
};

export const loggedIn = (payload) => {
  return {
    type: loginActionTypes.LOGIN_USER,
    payload,
  };
};

export const requestFailed = () => {
  return {
    type: loginActionTypes.REQUEST_FAILED,
  };
};
