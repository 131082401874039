import React from "react";
import { ErrorMessage } from "../EditingModal/EditingModal.styles";
import TextField from "@material-ui/core/TextField";
import tableHeads from "../TableData";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import Checkbox from "@material-ui/core/Checkbox";
import { v4 as uuidv4 } from "uuid";
import ModalImage from "react-modal-image";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemText from "@material-ui/core/ListItemText";
import {
  StartDeletingKidNote,
  StartDeletingKidNoteAsset,
} from "../../../../redux/kids/kids.actions";
import store from "../../../../redux/store";

import styled from "styled-components";

const NoteWrapper = styled.div`
  /* display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: aliceblue;
  padding: 15px;
  border-radius: 9px;
  margin-bottom: 1em; */
`;
const DownloadLink = styled.a`
  text-decoration: none;
  color: darkred;
`;

const NoteHeaderButton = styled.button`
  background-color: #777;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
`;

const NoteContent = styled.div`
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #f1f1f1;
`;

const renderViewForm = (currentRow, sample, schema = []) => {
  return schema.map(({ value, title, fieldType }, index) => {
    if (index < schema.length - 2) {
      switch (fieldType) {
        case "text":
          return (
            <>
              <TextField
                className={sample}
                label={title}
                inputProps={{
                  defaultValue: currentRow[value],
                  readOnly: true,
                }}
                variant="outlined"
              />
            </>
          );
        case "date":
          return (
            <>
              <TextField
                className={sample}
                label={title}
                type="date"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  defaultValue: currentRow[value],
                  readOnly: true,
                }}
              />
            </>
          );

        default:
          break;
      }
    }
  });
};

const renderEmployeeWorkingTimeView = (tableType, schedule) => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {schedule &&
          schedule.map((day, i) => {
            if (day.isWorking) {
              return (
                <>
                  <span
                    style={{ margin: "0", display: "block" }}
                    key={uuidv4()}
                  >
                    {daysOfWeek[i]}
                  </span>
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    key={uuidv4()}
                    value={moment(day.start, "HH:mm").toDate()}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                    disabled={day.isWorking}
                  />
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    key={uuidv4()}
                    value={moment(day.end, "HH:mm").toDate()}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                    disabled={day.isWorking}
                  />
                </>
              );
            }
          })}
      </MuiPickersUtilsProvider>
    </>
  );
};

const handleButtonClick = (e) => {};
const renderKidNotes = (tableType, notes = [], noteMap, setNoteMap) => {
  const handleClick = (id) => {
    let newMap = noteMap;
    newMap[id] = !newMap[id];
    setNoteMap({ ...newMap });
  };

  const handleAssetDelete = (id) => {
    console.log(id);
    store.dispatch(StartDeletingKidNoteAsset(id));
  };
  const handleNoteDelete = (id) => {
    // console.log({ id });
    store.dispatch(StartDeletingKidNote(id));
  };
  return notes.map((note) => {
    return (
      <>
        <ListItem button onClick={() => handleClick(note._id)}>
          {/* <ListItemIcon><InboxIcon /></ListItemIcon> */}
          <ListItemText primary="title" />
          {noteMap[note._id] ? <ExpandLess /> : <ExpandMore />}
          <span
            style={{
              marginLeft: "10px",
              backgroundColor: "antiquewhite",
              padding: "5px",
            }}
            onClick={() => handleNoteDelete(note._id)}
          >
            X
          </span>
        </ListItem>
        <Collapse in={noteMap[note._id]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button>
              <div>{note.description}</div>
            </ListItem>
            {note.assets.map((asset) => {
              return (
                <div onClick={() => handleAssetDelete(asset._id)}>
                  {asset.name}
                </div>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  });
};
export { renderViewForm, renderEmployeeWorkingTimeView, renderKidNotes };

// <NoteWrapper key={uuidv4()}>
{
  /* <NoteHeaderButton onClick={(e)=> handleButtonClick(e)} type="button">Open Collapsible</NoteHeaderButton>
        <NoteContent class="content">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </NoteContent> */
}
{
  /* <div style={{ width: "100px", height: "100px" }}>
          <ModalImage small={note.path} large={note.path} alt="Hello World!" />
        </div>
        <h3>{note.name}</h3> */
}
{
  /* <img width="50px" height="50px" src={note.path} /> */
}
{
  /* <h4>
          <DownloadLink href={note.path} target="_blank">
            Download File
          </DownloadLink>
        </h4> */
}
// </NoteWrapper>
