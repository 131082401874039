import { createSelector } from "reselect";

const selectTestState = (state) => state.allergies;

export const selectCartItems = createSelector(
  [selectTestState],
  (allergies) => allergies
);

export const selectAllergiessData = createSelector(
  [selectTestState],
  (allergies) => allergies.allergies
);
