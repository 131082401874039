import React, { useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Form } from "react-final-form";
import {
  EditModal,
  EditModalTitle,
  useStyles,
  FormBody,
  FormBodyContainer,
} from "./EditingModal.styles";
import {
  onSubmit,
  renderEditForm,
  renderEmployeeWorkingTime,
} from "./EditItem.helpers";
import { renderKidsNotes, submitNotes } from "./kidNotes.helpers";
import { validateForm } from "../validationFunctions.helpers";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { v4 as uuidv4 } from "uuid";
import Button from "@material-ui/core/Button";
import AddKidNote from "../../../FileUploader/KidsFileUpload.component";
import PreviewNotes from "../../../Kids/notes/PreviewNotes";
import i18n from "../../../../i18n";
const pluralize = require("pluralize");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box key={uuidv4()} p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function EditingModal({
  currentRow,
  editOpen,
  changeEditingTableRowState,
  tableType = "",
  schema,
}) {
  const classes = useStyles();
  const [tab, setTab] = React.useState(0);
  const [schedule, setSchedule] = React.useState([]);
  useEffect(() => {
    setSchedule(JSON.parse(JSON.stringify(currentRow.schedule || [])));
  }, [currentRow]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  return (
    <>
      <Modal
        aria-labelledby="form-edit-popup"
        aria-describedby="appears-to-edit-form-data"
        className={classes.modal}
        open={editOpen}
        // open={true}
        onClose={() => {
          setSchedule([]);
          changeEditingTableRowState(false, {});
          setTab(0);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 700,
        }}
      >
        <Fade in={editOpen}>
          {/* <Fade in={true}> */}
          <EditModal>
            {/* <EditModalTitle>Edit {tableType}</EditModalTitle> */}
            <>
              <div className={classes.root}>
                <AppBar className={classes.appBar} position="static">
                  <Tabs
                    className={classes.tabsBar}
                    value={tab}
                    onChange={handleChange}
                    centered
                  >
                    <Tab key={uuidv4()} label={pluralize.singular(tableType)} />
                    {tableType === "employees" && (
                      <Tab label={i18n.t("working_hours")} />
                    )}
                    {tableType === "kids" && (
                      <Tab label={i18n.t("add_notes")} />
                    )}
                  </Tabs>
                </AppBar>
                <TabPanel key={uuidv4()} value={tab} index={0}>
                  <Form
                    onSubmit={(...args) => {
                      onSubmit(tableType, currentRow._id, ...args);
                      changeEditingTableRowState(false, {});
                    }}
                    validate={(...args) => validateForm(tableType, ...args)}
                    render={({
                      handleSubmit,
                      form,
                      submitting,
                      pristine,
                      values,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <FormBodyContainer>
                          <FormBody>
                            {renderEditForm(currentRow, classes.sample, schema)}
                          </FormBody>
                        </FormBodyContainer>
                        <div className="buttons">
                          <button type="submit" disabled={submitting}>
                            Edit {tableType}
                          </button>
                          <button
                            type="button"
                            onClick={form.reset}
                            disabled={submitting || pristine}
                          >
                            {i18n.t("reset_form")}
                          </button>
                        </div>
                        <pre>{JSON.stringify(values, 0, 2)}</pre>
                      </form>
                    )}
                  />
                </TabPanel>
                {tableType === "employees" && (
                  <TabPanel key={uuidv4()} value={tab} index={1}>
                    {renderEmployeeWorkingTime(
                      tableType,
                      schedule,
                      setSchedule
                    )}
                    <Button
                      onClick={() => {
                        onSubmit(tableType, currentRow._id, null, schedule);
                        changeEditingTableRowState(false, {});
                      }}
                    >
                      {i18n.t("save_schedule")}
                    </Button>
                  </TabPanel>
                )}
                {tableType === "kids" && (
                  <TabPanel key={uuidv4()} value={tab} index={1}>
                    <AddKidNote
                      render={() => console.log({ currentRow })}
                      {...{ changeEditingTableRowState }}
                      currentUser={currentRow._id}
                      kidNotes={currentRow}
                    />
                  </TabPanel>
                )}
              </div>
            </>
          </EditModal>
        </Fade>
      </Modal>
    </>
  );
}
