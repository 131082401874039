import { takeEvery, put, call } from "redux-saga/effects";
import { loginActionTypes } from "./login.types";
import { addClientPersonalInfo } from "../ClientRegistration/clientRegistration.actions";
import axios from "../../../axios";
import { customHistory } from "../../../routes/index";
import { setToken } from "../../activeUser/activeUser.actions";
import { requestFailed, loggedIn } from "./login.actions";

const loginUser = async ({ payload }) => {
  try {
    const response = await axios.post("/daycare/login", payload);
    return response.data.data;
  } catch (error) {
    return null;
  }
};

export function* loginUserAsync(payload) {
  const responseData = yield call(loginUser, payload);
  if (responseData) {
    sessionStorage.setItem("currentUserToken", responseData.access_token);
    sessionStorage.setItem("currentUser", JSON.stringify(responseData));
    customHistory.push("/company-signup");

    yield put(setToken(responseData.access_token));
    yield put(addClientPersonalInfo(responseData));
    yield put(loggedIn());
  } else {
    yield put(requestFailed());
  }
}
export function* watchUserLogin() {
  yield takeEvery(loginActionTypes.LOGIN_USER_SAGA, loginUserAsync);
}
