import Swal from "sweetalert2";

class DeleteHelper {
  handleDelete(msg, callback = () => {}) {
    Swal.fire({
      title: msg,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `Don't Delete`,
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
  }
}

export default new DeleteHelper();
