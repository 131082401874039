export const conditionsActionTypes = {
  GET_CONDITIONS: "GET_CONDITIONS",
  SAVE_CONDITIONS: "SAVE_CONDITIONS",

  DELETE_CONDITION: "DELETE_CONDITION",
  DELETE_CONDITION_SAGA: "DELETE_CONDITION_SAGA",

  EDIT_CONDITION: "EDIT_CONDITION",
  EDIT_CONDITION_SAGA: "EDIT_CONDITION_SAGA",

  ADD_CONDITION: "ADD_CONDITION",
  ADD_CONDITION_SAGA: "ADD_CONDITION_SAGA",

  REQUEST_FAILED: "REQUEST_FAILED_CONDITION",
};
