export const immunizationActionTypes = {
  GET_IMMUNIZATIONS: "GET_IMMUNIZATIONS",
  GET_IMMUNIZATIONS_SAGA: "GET_IMMUNIZATIONS_SAGA",
  SAVING_IMMUNIZATION: "SAVING_IMMUNIZATION",
  SAVING_IMMUNIZATION_SAGA: "SAVING_IMMUNIZATION_SAGA",
  DELETE_IMMUNIZATION: "DELETE_IMMUNIZATION",
  DELETE_IMMUNIZATION_SAGA: "DELETE_IMMUNIZATION_SAGA",

  SAVING_IMMUNIZATION_OPTIONS: "SAVING_IMMUNIZATION_OPTIONS",
};
