export const doctorsActionTypes = {
  GET_DOCTORS: "GET_DOCTORS",
  SAVE_DOCTORS: "SAVE_DOCTORS",

  DELETE_DOCTOR: "DELETE_DOCTOR",
  DELETE_DOCTOR_SAGA: "DELETE_DOCTOR_SAGA",

  EDIT_DOCTOR: "EDIT_DOCTOR",
  EDIT_DOCTOR_SAGA: "EDIT_DOCTOR_SAGA",

  ADD_DOCTOR: "ADD_DOCTOR",
  ADD_DOCTOR_SAGA: "ADD_DOCTOR_SAGA",

  REQUEST_FAILED: "REQUEST_FAILED_DOCTOR",
};
