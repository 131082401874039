import { createSelector } from "reselect";

const selectTestState = (state) => state.incidents;

export const selectCartItems = createSelector(
  [selectTestState],
  (incidents) => incidents
);

export const selectIncidentsData = createSelector(
  [selectTestState],
  (incidents) => incidents.incidents
);
