const clientPersonalRegFields = [
  { value: "firstName", title: "First Name", fieldType: "text" },
  { value: "lastName", title: "Last Name", fieldType: "text" },
  { value: "email", title: "Email Address", fieldType: "email" },
  { value: "phoneNo", title: "Phone Number", fieldType: "text" },
  { value: "password", title: "Password", fieldType: "password" },
  {
    value: "confirmPassword",
    title: "Confirm Password",
    fieldType: "password",
  },
  { value: "address", title: "Address", fieldType: "address" },
];

export { clientPersonalRegFields };
export default clientPersonalRegFields;
