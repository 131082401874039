import { takeEvery, put, call } from "redux-saga/effects";
import { conditionsActionTypes } from "./conditions.types";
import {
  saveConditions,
  deleteCondition,
  editCondition,
  addCondition,
  requestFailed,
} from "./conditions.actions";
import axios from "../../axios";
const getData = async (payload) => {
  try {
    const response = await axios.get("/condition", { params: payload.params });
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* fetchConditionsAsync(payload) {
  try {
    const data = yield call(getData, payload);
    yield put(saveConditions(data));
  } catch (e) {
    yield put(requestFailed());
  }
}

const deleteData = async (payload) => {
  try {
    const response = await axios.delete("/condition", {
      params: { ids: payload.conditionId },
    });
    payload?.callbackMap?.success(response);
    return response.data._id;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* deleteConditionAsync(payload) {
  try {
    const deleteVal = yield call(deleteData, payload);
    yield put(deleteCondition(deleteVal));
  } catch (e) {
    yield put(requestFailed());
  }
}

const editData = async (payload) => {
  try {
    const response = await axios.put("/condition", {
      _id: payload.payload._id,
      data: payload.payload.data,
    });
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* editConditionAsync(payload) {
  try {
    const responseData = yield call(editData, payload);
    yield put(editCondition(responseData));
  } catch (e) {
    yield put(requestFailed());
  }
}

const addData = async (payload) => {
  try {
    const response = await axios.post("/condition", payload.payload);
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* addConditionAsync(payload) {
  try {
    const responseData = yield call(addData, payload);
    yield put(addCondition(responseData));
  } catch (e) {
    yield put(requestFailed());
  }
}
export function* watchConditions() {
  yield takeEvery(conditionsActionTypes.GET_CONDITIONS, fetchConditionsAsync);
  yield takeEvery(
    conditionsActionTypes.DELETE_CONDITION_SAGA,
    deleteConditionAsync
  );

  yield takeEvery(
    conditionsActionTypes.EDIT_CONDITION_SAGA,
    editConditionAsync
  );

  yield takeEvery(conditionsActionTypes.ADD_CONDITION_SAGA, addConditionAsync);
}
