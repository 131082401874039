import React, { useState, useEffect } from "react";

import { TextField } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import AddIcon from "@material-ui/icons/Add";
import { Incident, EditActions, Controls } from "./Incident.styles";
import EditIcon from "@material-ui/icons/Edit";
import { Back } from "../Medical/Medical.styles";
import EditIncident from "./EditIncident";
import _ from "lodash";
import AddIncident from "./AddIncident";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import {
  startIncidentFetching,
  startAddingIncident,
  startDeletingIncident,
  startEditingIncident,
} from "../../../redux/incidents/incidents.actions";
import { selectIncidentsData } from "../../../redux/incidents/incidents.selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import callbackHelper from "../../../helpers/callbackHelper";
import i18n from "../../../i18n";
const kidIncidents = [
  { name: "incident name", description: "<p>hallo</p>", _id: "incident1" },
  { name: "incident name2", description: "<p>hallo</p>", _id: "incident2" },
];
function Incidents(props) {
  const [currentEditingIncident, setCurrentEditingIncident] = useState({});
  const [incidents, setIncidents] = useState(kidIncidents);
  const [modalState, setModalState] = useState({ edit: false, add: false });

  // const handleChange = (content) => {
  //   console.log(content); //Get Content Inside Editor
  //   setCurrentContent(content);
  // };
  const handleAddIncident = () => {
    setModalState({ ...modalState, add: true });
  };
  const handleModalClose = (state) => {
    const newState = { ...modalState };
    newState[state] = false;
    setModalState(newState);
    setCurrentEditingIncident({});
  };
  const handleIncidentEdit = (currentCondition) => {
    setModalState({ ...modalState, edit: true });
    setCurrentEditingIncident(currentCondition);
  };

  const handleIncidentDelete = (incident) => {
    callbackHelper(props.startDeletingIncident, incident._id, "delete");
  };

  useEffect(() => {
    callbackHelper(props.startIncidentFetching);
  }, []);

  return (
    <div>
      <Controls>
        <h2> {i18n.t("incidents")} </h2>
        <Back>
          <AddIcon
            style={{ margin: "auto" }}
            onClick={() => handleAddIncident("main")}
          />
        </Back>
      </Controls>
      {(props.incidents || []).map((incident) => {
        return (
          <Incident>
            <span style={{ width: "80%" }}>{incident.name}</span>
            <EditActions>
              <button
                style={{
                  background: "none",
                  color: "inherit",
                  border: "none",
                  padding: 0,
                  font: "inherit",
                  cursor: "pointer",
                  outline: "inherit",
                }}
                type="submit"
              >
                <EditIcon onClick={() => handleIncidentEdit(incident)} />
                <DeleteForeverIcon
                  onClick={() => handleIncidentDelete(incident)}
                />
              </button>
            </EditActions>
          </Incident>
        );
      })}
      {!_.isEmpty(currentEditingIncident) && (
        <EditIncident
          open={modalState.edit}
          handleModalClose={(state) => handleModalClose(state)}
          {...{ currentEditingIncident }}
          startEditingIncident={props.startEditingIncident}
          callbackHelper={callbackHelper}
        />
      )}
      {modalState.add && (
        <AddIncident
          open={modalState.add}
          handleModalClose={(state) => handleModalClose(state)}
          startAddingIncident={props.startAddingIncident}
          callbackHelper={callbackHelper}
          currentUser={props.currentUser}
        />
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  startIncidentFetching: (callbackMap) =>
    dispatch(startIncidentFetching(callbackMap)),
  startDeletingIncident: (callbackMap, id) =>
    dispatch(startDeletingIncident(callbackMap, id)),
  startAddingIncident: (callbackMap, ...args) =>
    dispatch(startAddingIncident(callbackMap, ...args)),
  startEditingIncident: (callbackMap, ...args) =>
    dispatch(startEditingIncident(callbackMap, ...args)),
});

const mapStateToProps = createStructuredSelector({
  incidents: selectIncidentsData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Incidents);
