import { takeEvery, put, call } from "redux-saga/effects";
import { groupsActionTypes } from "./groups.types";
import { saveGroups, deleteGroup, editGroup, addGroup } from "./groups.actions";
import axios from "../../axios";

const getData = async (payload) => {
  try {
    const populateFields = { relations: ["kids"] };
    const response = await axios.get("/kidsGroup", {
      params: populateFields,
    });
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* fetchGroupsAsync(payload) {
  const data = yield call(getData, payload);
  yield put(saveGroups(data));
}

const deleteData = async (payload) => {
  try {
    const response = await axios.delete("/kidsGroup", {
      params: { ids: payload.groupId },
    });
    payload?.callbackMap?.success(response);
    return response.data._id;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* deleteGroupAsync(payload) {
  const deleteVal = yield call(deleteData, payload);
  yield put(deleteGroup(deleteVal));
}

const editData = async (payload) => {
  try {
    const response = await axios.put("/kidsGroup", {
      _id: payload.payload._id,
      data: payload.payload.data,
    });
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* editGroupAsync(payload) {
  const responseData = yield call(editData, payload);
  yield put(editGroup(responseData));
}

const addData = async (payload) => {
  try {
    const response = await axios.post("/kidsGroup", payload.payload);
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* addGroupAsync(payload) {
  const responseData = yield call(addData, payload);
  yield put(addGroup(responseData));
}
export function* watchGroups() {
  yield takeEvery(groupsActionTypes.GET_GROUPS, fetchGroupsAsync);
  yield takeEvery(groupsActionTypes.DELETE_GROUP_SAGA, deleteGroupAsync);

  yield takeEvery(groupsActionTypes.EDIT_GROUP_SAGA, editGroupAsync);

  yield takeEvery(groupsActionTypes.ADD_GROUP_SAGA, addGroupAsync);
}
