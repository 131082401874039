import React, { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  startMealFetching,
  startEditingMeal,
  startAddingMeal,
  startDeletingMeal,
} from "../../redux/meals/meals.actions";
import { startAllergyFetching } from "../../redux/allergies/allergies.actions";
import { selectMealItems } from "../../redux/meals/meals.selectors";
import { selectAllergiessData } from "../../redux/allergies/allergies.selectors";
import Page from "../../components/Page/Page";
import FormModal from "../../components/Core/FormModal/FormModal";

import ErrorHelper from "../../helpers/ErrorHelper";
import SuccessHelper from "../../helpers/SuccessHelper";
import i18n from "../../i18n";

class Meals extends Component {
  state = {
    editModalOpen: false,
    addModalOpen: false,
    editing: {},
  };

  schema = [
    { value: "name", title: i18n.t("name"), fieldType: "text" },
    { value: "ingredients", title: i18n.t("ingredients"), fieldType: "text" },
    {
      value: "allergies",
      title: i18n.t("allergies"),
      fieldType: "multiAutocomplete",
    },
    {
      value: "actions",
      title: i18n.t("actions"),
      fieldType: "actions",
      style: { width: 80 },
      actions: ["edit", "delete"],
    },
  ];
  async componentDidMount() {
    document.title = i18n.t("meals");
    this.props.startAllergyFetching();
    new Promise((success, failure) => {
      this.props.startMealFetching({ success, failure });
    })
      .then((res) => {
        this.setState({ isloading: false });
      })
      .catch((err) => {
        this.setState({ isloading: false });
        console.log("something_went_wrong");
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  }

  validateMeal = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = i18n.t("required");
    }
    if (!values.ingredients) {
      errors.ingredients = i18n.t("required");
    }

    return errors;
  };

  onAdd = (values) => {
    new Promise((success, failure) => {
      this.props.startAddingMeal(
        { success, failure },
        { ...values, allergies: values.allergies.map((allergy) => allergy._id) }
      );
    })
      .then((res) => {
        this.setState({ isloading: false });
        SuccessHelper.handleToastSuccess(i18n.t("successfully_created"), true);
      })
      .catch((err) => {
        this.setState({ isloading: false });
        console.log("something_went_wrong");
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  };

  onEdit = (data) => {
    new Promise((success, failure) => {
      this.props.startEditingMeal(
        { success, failure },
        {
          _id: this.state.editing._id,
          data: {
            ...data,
            allergies: data.allergies.map((allergy) => allergy._id),
          },
        }
      );
    })
      .then((res) => {
        this.setState({ isloading: false });
        SuccessHelper.handleToastSuccess(i18n.t("successfully_updated"), true);
      })
      .catch((err) => {
        this.setState({ isloading: false });
        console.log("something_went_wrong");
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  };

  renderEditModal = () => {
    const _schema = [...this.schema];
    _schema[2].options = this.props.allergies;
    return (
      <FormModal
        schema={this.schema}
        open={this.state.editModalOpen}
        onSubmit={this.onEdit}
        validateForm={this.validateMeal}
        values={this.state.editing}
        closeModal={() => {
          this.setState({
            editing: {},
            editModalOpen: false,
          });
        }}
      />
    );
  };

  renderAddModal = () => {
    const _schema = [...this.schema];
    _schema[2].options = this.props.allergies;
    return (
      <FormModal
        schema={this.schema}
        open={this.state.addModalOpen}
        onSubmit={this.onAdd}
        validateForm={this.validateMeal}
        closeModal={() => {
          this.setState({
            addModalOpen: false,
          });
        }}
      />
    );
  };

  render() {
    const allergyMap = {};
    this.props.allergies.map((allergy) => {
      allergyMap[allergy._id] = allergy;
    });
    return (
      <>
        {this.renderEditModal()}
        {this.renderAddModal()}
        <Page
          title={i18n.t("meals")}
          resource="Meals"
          data={this.props.meals.meals}
          onDelete={this.props.startDeletingMeal}
          onEdit={(data) => {
            this.setState({
              editing: {
                ...data,
                allergies: data.allergies.map(
                  (allergy) => allergyMap[allergy] || {}
                ),
              },
              editModalOpen: true,
            });
          }}
          editOnRowClick
          onCreate={() => {
            this.setState({
              editing: {},
              addModalOpen: true,
            });
          }}
          schema={this.schema}
        ></Page>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startMealFetching: (callbackMap) => dispatch(startMealFetching(callbackMap)),
  startDeletingMeal: (id, callbackMap) =>
    dispatch(startDeletingMeal(id, callbackMap)),
  startEditingMeal: (callbackMap, ...args) =>
    dispatch(startEditingMeal(callbackMap, ...args)),
  startAddingMeal: (callbackMap, ...args) =>
    dispatch(startAddingMeal(callbackMap, ...args)),
  startAllergyFetching: (...args) => dispatch(startAllergyFetching(...args)),
});

const mapStateToProps = createStructuredSelector({
  meals: selectMealItems,
  allergies: selectAllergiessData,
});
export default connect(mapStateToProps, mapDispatchToProps)(Meals);
