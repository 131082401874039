import React from "react";
import { IconItem, ListItemContainer, ItemTitle } from "./ListItem.styles";

export default function ListItem({
  title,
  children,
  itemId,
  onOpen,
  onDelete,
}) {
  return (
    <ListItemContainer>
      <IconItem>{children[0]}</IconItem>
      <ItemTitle onClick={() => onOpen(itemId)}>
        <span style={{ marginLeft: "2em" }}>{title}</span>
      </ItemTitle>
      <IconItem type="delete" onClick={() => onDelete(itemId)}>
        {children[1]}
      </IconItem>
    </ListItemContainer>
  );
}
