import React from "react";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoaderWrapper = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
export default function Loader({ isLoading }) {
  if (!isLoading) {
    return null;
  }
  return (
    <LoaderWrapper>
      <CircularProgress style={{ color: "white" }} size={130} thickness={4.2} />
    </LoaderWrapper>
  );
}
