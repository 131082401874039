import React from "react";
import { Field, Form } from "react-final-form";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import Select from "../../components/Core/Select";
import SecondaryButton from "../../components/Core/Buttons/SecondaryButton/SecondaryButton";

import DraggableDialog from "../../components/DraggableDialog/DraggableDialog";

import i18n from "../../i18n";
import {
  clearActiveEvent,
  startDeletingEvent,
  startEditingEvent,
  startAddingEvent,
  startSettingEventKids,
} from "../../redux/events/events.actions";
import moment from "moment";
import Swal from "sweetalert2";
import TextArea from "../../components/Core/TextArea/TextArea";
import Autocomplete from "../../components/Core/Autocomplete/Autocomplete";
import Tabs from "../../components/Core/Tabs/Tabs";
import BasicTable from "../../components/Core/BasicTable";
import { DialogContent } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { Chip } from "@material-ui/core";
import Datepicker from "../../components/Core/Datepicker/Datepicker";
import { useMemo } from "react";
import { useState } from "react";
import ErrorHelper from "../../helpers/ErrorHelper";
import SuccessHelper from "../../helpers/SuccessHelper";
import PrimaryButton from "../../components/Core/Buttons/PrimaryButton/PrimaryButton";
import ColorPicker from "material-ui-color-picker";

const validateForm = (values) => {
  const errors = {};
  if (!values.checkIn) {
    errors.checkIn = i18n.t("start_time_required");
  }
  if (!values.checkOut) {
    errors.checkOut = i18n.t("end_time_required");
  }
  if (!values.activityIds?.length && !values.manualActivity) {
    errors.activityIds = i18n.t("activity_required");
    errors.manualActivity = i18n.t("activity_required");
  }

  return errors;
};

const opetionFormatter = (element) => ({ ...element, value: element._id });

export default function EventModal({ dates = {}, onAttendanceClick }) {
  const dispatch = useDispatch();

  const [selectedKidMap, setSelectedKidMap] = useState(null);
  const [tab, setTab] = useState(0);

  const meals = useSelector((s) => s.meals.meals.map(opetionFormatter));
  const activities = useSelector((s) => {
    return s.activities.activities.map(opetionFormatter);
  });
  const groups = useSelector((s) => s.groups.groups.map(opetionFormatter));
  const activeEvent = useSelector((s) => {
    const event = s.events.active;
    if (!event) {
      return null;
    }
    return {
      ...event,
      checkIn: moment(event.checkIn).format("YYYY-MM-DDTHH:mm"),
      checkOut: moment(event.checkOut).format("YYYY-MM-DDTHH:mm"),
    };
  });
  const kids = useSelector((s) => s.kids.kids.map(opetionFormatter));
  const employees = useSelector((s) => {
    return s.employees.employees.map((element) => ({
      ...element,
      name: `${element.firstName} ${element.lastName}`,
      value: element._id,
    }));
  });

  const onSubmit = (ev) => {
    let data = { ...ev };
    data.mealIds = data.mealIds.map((meal) => meal._id);
    const date = moment(data.date);
    const checkIn = moment(
      `${date.format("MM-DD-YYYY")} ${data.checkIn}`,
      "MM-DD-YYYY HH:mm"
    ).toDate();
    const checkOut = moment(
      `${date.format("MM-DD-YYYY")} ${data.checkOut}`,
      "MM-DD-YYYY HH:mm"
    ).toDate();
    delete data.date;
    data.checkIn = checkIn;
    data.checkOut = checkOut;
    if (activeEvent._id) {
      return dispatch(startEditingEvent({ data, _id: activeEvent._id }));
    }
    dispatch(startAddingEvent(data));
  };
  const handleClose = () => {
    setSelectedKidMap(null);
    dispatch(clearActiveEvent());
    setTab(0);
  };

  const handleDelete = () => {
    Swal.fire({
      title: i18n.t("are_you_sure_delete"),
      text: i18n.t("cannot_reverse"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        dispatch(startDeletingEvent(activeEvent._id));
      }
    });
  };

  const [date, checkIn, checkOut] = useMemo(() => {
    let checkIn;
    let checkOut;
    if (activeEvent?._id) {
      if (!selectedKidMap) {
        const toSet = {};
        activeEvent.kids.map((kid) => {
          toSet[kid._id] = true;
        });
        setSelectedKidMap({ ...toSet });
      }
      checkIn = moment(activeEvent.checkIn);
      checkOut = moment(activeEvent.checkOut);
    } else if (dates) {
      checkIn = moment(dates.checkIn);
      checkOut = moment(dates.checkOut);
    } else {
      checkIn = moment(moment.now());
      checkOut = moment(moment.now());
    }
    return [
      checkIn.toDate(),
      checkIn.format("HH:mm"),
      checkOut.format("HH:mm"),
    ];
  }, [dates, activeEvent]);

  if (!activeEvent) {
    return null;
  }
  const actions = (
    <div className="row justify-content-between w-100">
      <div>
        <PrimaryButton onClick={handleDelete}>{i18n.t("delete")}</PrimaryButton>
        <PrimaryButton onClick={() => onAttendanceClick(date)} className="ml-1">
          {i18n.t("attendance")}
        </PrimaryButton>
      </div>
      <div>
        <PrimaryButton type="submit">{i18n.t("save")}</PrimaryButton>
      </div>
    </div>
  );

  const Container = ({ children }) => {
    if (activeEvent._id) {
      return (
        <div className="negative-margin">
          <Tabs
            activeTab={tab}
            setActiveTab={setTab}
            tabs={[
              {
                title: "details",
                value: "details",
                content: () => {
                  return (
                    <Form
                      onSubmit={onSubmit}
                      validate={validateForm}
                      render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="spacing">
                          <DialogContent>{children}</DialogContent>
                          <DialogActions>{actions}</DialogActions>
                        </form>
                      )}
                    />
                  );
                },
              },
              {
                title: "participants",
                value: "participants",
                content: () => {
                  return (
                    <div>
                      <div className="row mb-2">
                        {groups.map((group) => {
                          return (
                            <Chip
                              key={group._id}
                              className="group-chip"
                              onClick={() => {
                                group.kids.map((kid) => {
                                  selectedKidMap[kid._id] = true;
                                });
                                setSelectedKidMap({ ...selectedKidMap });
                              }}
                              style={{
                                backgroundColor: "#e1e1e1",
                                margin: 4,
                                cursor: "pointer",
                              }}
                              size="small"
                              label={group.name}
                            />
                          );
                        })}
                      </div>
                      <BasicTable
                        rows={kids}
                        schema={[
                          {
                            header: i18n.t("scheduled"),
                            style: { width: 80 },
                            field: "scheduled",
                            render: (val) => {
                              return (
                                <Checkbox
                                  checked={Boolean(
                                    selectedKidMap?.[val._id] || false
                                  )}
                                  onChange={() => {
                                    if (selectedKidMap?.[val._id]) {
                                      delete selectedKidMap[val._id];
                                    } else {
                                      selectedKidMap[val._id] = true;
                                    }

                                    setSelectedKidMap({
                                      ...selectedKidMap,
                                    });
                                  }}
                                  inputProps={{ "aria-label": "enrolled" }}
                                />
                              );
                            },
                          },
                          {
                            header: i18n.t("name"),
                            field: "name",
                            style: { verticalAlign: "middle" },
                            accessor: (val) =>
                              `${val.firstName || ""} ${val.lastName || ""}`,
                          },
                        ]}
                      />
                      <div className="row justify-content-end">
                        <Button
                          color="primary"
                          onClick={() => {
                            dispatch(
                              startSettingEventKids(
                                {
                                  kids: Object.keys(selectedKidMap),
                                  _id: activeEvent._id,
                                },
                                {
                                  success: () =>
                                    SuccessHelper.handleToastSuccess(
                                      i18n.t("successfully_updated"),
                                      true
                                    ),
                                  failure: () =>
                                    ErrorHelper.handleErrors(
                                      i18n.t("something_went_wrong"),
                                      true
                                    ),
                                }
                              )
                            );
                          }}
                        >
                          {i18n.t("save")}
                        </Button>
                      </div>
                    </div>
                  );
                },
              },
            ]}
          />
        </div>
      );
    }
    return <>{children}</>;
  };

  return (
    <div>
      <DraggableDialog
        title={
          <div className="row justify-content-between">
            <div>{i18n.t("edit_event")}</div>
            <SecondaryButton onClick={handleClose}>
              {i18n.t("close")}
            </SecondaryButton>
          </div>
        }
        className="half-container"
        content={
          <Container>
            <div className="negative-margin">
              <Field name="date" initialValue={date}>
                {({ input, meta }) => {
                  const error = meta.touched && meta.error;
                  return (
                    <Datepicker
                      {...input}
                      selected={input.value}
                      fullWidth
                      error={error}
                      helperText={error ? meta.error : null}
                    />
                  );
                }}
              </Field>
              <Field name="checkIn" initialValue={checkIn}>
                {({ input, meta }) => {
                  const error = meta.touched && meta.error;
                  return (
                    <TextField
                      {...input}
                      label="Start Time"
                      type="time"
                      // defaultValue="2017-05-24T10:30"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={error}
                      helperText={error ? meta.error : null}
                    />
                  );
                }}
              </Field>

              <Field name="checkOut" initialValue={checkOut}>
                {({ input, meta }) => {
                  const error = meta.touched && meta.error;
                  return (
                    <TextField
                      {...input}
                      label={i18n.t("end_time")}
                      type="time"
                      // defaultValue="2017-05-24T10:30"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={error}
                      helperText={error ? meta.error : null}
                    />
                  );
                }}
              </Field>
              <Field name="name" initialValue={activeEvent.name}>
                {({ input, meta }) => {
                  const error = meta.touched && meta.error;
                  return (
                    <TextField
                      {...input}
                      label={i18n.t("name")}
                      fullWidth
                      error={error}
                      helperText={error ? meta.error : null}
                    />
                  );
                }}
              </Field>
              <Field
                name={i18n.t("description")}
                initialValue={activeEvent.description}
                render={({ input, meta }) => {
                  const error = meta.error && meta.touched;
                  return (
                    <TextArea
                      {...input}
                      label="Description"
                      error={error}
                      fullWidth
                      helperText={error ? meta.error : null}
                    />
                  );
                }}
              />
              <Field
                name="color"
                initialValue={activeEvent.color}
                render={({ input, meta }) => {
                  const error = meta.error && meta.touched;
                  return (
                    <ColorPicker
                      {...input}
                      label={i18n.t("color")}
                      name="color"
                      defaultValue="#000"
                      fullWidth
                      error={error}
                      helperText={meta.error}
                      TextFieldProps={{
                        value: input.value,
                      }}
                    />
                  );
                }}
              />
              <Field
                name="activityIds"
                initialValue={activeEvent.activityIds || []}
                render={({ input, meta }) => {
                  const error = meta.touched && meta.error;
                  return (
                    <Autocomplete
                      {...input}
                      multiple
                      label={i18n.t("activities")}
                      options={activities}
                      error={error}
                    />
                  );
                }}
              />
              <Field
                name="manualActivity"
                initialValue={activeEvent.manualActivity}
                render={({ input, meta }) => {
                  const error = meta.touched && meta.error;
                  return (
                    <TextField
                      {...input}
                      label={i18n.t("manual_activity")}
                      // defaultValue="2017-05-24T10:30"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={error}
                      helperText={error ? meta.error : null}
                    />
                  );
                }}
              />
              <Field
                name="employeeId"
                initialValue={activeEvent.employeeId}
                render={({ input, meta }) => {
                  const error = meta.touched && meta.error;
                  return (
                    <Select
                      {...input}
                      fullWidth
                      options={employees}
                      label={i18n.t("employee")}
                      error={error}
                    />
                  );
                }}
              />
              <Field
                name="mealIds"
                initialValue={activeEvent.mealIds || []}
                render={({ input, meta }) => {
                  const error = meta.touched && meta.error;
                  return (
                    <Autocomplete
                      {...input}
                      multiple
                      label={i18n.t("meals")}
                      options={meals}
                      error={error}
                    />
                  );
                }}
              />
              <Field
                name="notes"
                initialValue={activeEvent.notes}
                render={({ input, meta }) => {
                  const error = meta.error && meta.touched;
                  return (
                    <TextArea
                      {...input}
                      label={i18n.t("notes")}
                      error={error}
                      fullWidth
                      helperText={error ? meta.error : null}
                    />
                  );
                }}
              />
            </div>
          </Container>
        }
        actions={activeEvent._id ? null : actions}
        onSubmit={onSubmit}
        handleClose={handleClose}
        validateForm={validateForm}
      />
    </div>
  );
}
