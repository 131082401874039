import React from "react";
import Modal from "../Core/Modal/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import "./FormModal.css";
import i18n from "../../i18n";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function KidDetailsModal({
  open,
  closeModal,
  currentKidDetails,
}) {
  const classes = useStyles();

  return (
    <Modal title="Details" open={open} closeModal={closeModal}>
      {currentKidDetails ? (
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <div className="p-3">
                {i18n.t("first_name")}:&nbsp; {currentKidDetails.firstName}
              </div>
              <div className="p-3">
                {i18n.t("last_name")}:&nbsp; {currentKidDetails.lastName}
              </div>
              <div className="p-3">
                {i18n.t("age")}:&nbsp; {currentKidDetails.age}
              </div>
              <div className="p-3">
                {i18n.t("date_of_birth")}:&nbsp; {currentKidDetails.dateOfBirth}
              </div>
              <div className="p-3">
                {i18n.t("emergency_contact_name")}:&nbsp;{" "}
                {currentKidDetails.emergencyContactName}
              </div>
              <div className="p-3">
                {i18n.t("emergency_contact_address")}:&nbsp;{" "}
                {currentKidDetails.emergencyContactAddress}
              </div>
              <div className="p-3">
                {i18n.t("emergency_contact_phone")}:&nbsp;{" "}
                {currentKidDetails.emergencyContactPhone}
              </div>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </Modal>
  );
}
