import { takeEvery, put, call } from "redux-saga/effects";
import { doctorsActionTypes } from "./doctors.types";
import {
  saveDoctors,
  deleteDoctor,
  editDoctor,
  addDoctor,
  requestFailed,
} from "./doctors.actions";
import axios from "../../axios";
const getData = async (payload) => {
  try {
    const response = await axios.get("/doctor", {
      params: payload.payload,
    });
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* fetchDoctorsAsync(payload) {
  try {
    const data = yield call(getData, payload);
    yield put(saveDoctors(data));
  } catch (e) {
    yield put(requestFailed());
  }
}

const deleteData = async (payload) => {
  try {
    const response = await axios.delete("/doctor", {
      params: { ids: payload.doctorId },
    });
    payload?.callbackMap?.success(response);
    return response.data._id;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* deleteDoctorAsync(payload) {
  try {
    const deleteVal = yield call(deleteData, payload);
    yield put(deleteDoctor(deleteVal));
  } catch (e) {
    yield put(requestFailed());
  }
}

const editData = async (payload) => {
  try {
    const response = await axios.put("/doctor", {
      _id: payload.payload._id,
      data: payload.payload.data,
    });
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* editDoctorAsync(payload) {
  try {
    const responseData = yield call(editData, payload);
    yield put(editDoctor(responseData));
  } catch (e) {
    yield put(requestFailed());
  }
}

const addData = async (payload) => {
  try {
    const response = await axios.post("/doctor", payload.payload);
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* addDoctorAsync(payload) {
  try {
    const responseData = yield call(addData, payload);
    yield put(addDoctor(responseData));
  } catch (e) {
    yield put(requestFailed());
  }
}
export function* watchDoctors() {
  yield takeEvery(doctorsActionTypes.GET_DOCTORS, fetchDoctorsAsync);
  yield takeEvery(doctorsActionTypes.DELETE_DOCTOR_SAGA, deleteDoctorAsync);

  yield takeEvery(doctorsActionTypes.EDIT_DOCTOR_SAGA, editDoctorAsync);

  yield takeEvery(doctorsActionTypes.ADD_DOCTOR_SAGA, addDoctorAsync);
}
