import { takeEvery, put, call } from "redux-saga/effects";
import { mealsActionTypes } from "./meals.types";
import { saveMeals, deleteMeal, editMeal, addMeal } from "./meals.actions";
import axios from "../../axios";

const getData = async (payload) => {
  try {
    const response = await axios.get("/meal");
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* fetchMealsAsync(payload) {
  const data = yield call(getData, payload);
  yield put(saveMeals(data));
}

const deleteData = async (payload) => {
  try {
    const response = await axios.delete("/meal", {
      params: { ids: payload.mealId },
    });
    payload?.callbackMap?.success(response);
    return response.data._id;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* deleteMealAsync(payload) {
  const deleteVal = yield call(deleteData, payload);
  yield put(deleteMeal(deleteVal));
}

const editData = async (payload) => {
  try {
    const response = await axios.put("/meal", {
      _id: payload.payload._id,
      data: payload.payload.data,
    });
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* editMealAsync(payload) {
  const responseData = yield call(editData, payload);
  yield put(editMeal(responseData));
}

const addData = async (payload) => {
  try {
    const response = await axios.post("/meal", payload.payload);
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* addMealAsync(payload) {
  const responseData = yield call(addData, payload);
  yield put(addMeal(responseData));
}
export function* watchMeals() {
  yield takeEvery(mealsActionTypes.GET_MEALS, fetchMealsAsync);
  yield takeEvery(mealsActionTypes.DELETE_MEAL_SAGA, deleteMealAsync);

  yield takeEvery(mealsActionTypes.EDIT_MEAL_SAGA, editMealAsync);

  yield takeEvery(mealsActionTypes.ADD_MEAL_SAGA, addMealAsync);
}
