import { doctorsActionTypes } from "./doctors.types";

const initialState = {
  doctors: [],
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case doctorsActionTypes.GET_DOCTORS: {
      return { ...state, isLoading: true };
    }
    case doctorsActionTypes.SAVE_DOCTORS: {
      return { ...state, doctors: payload, isLoading: false };
    }
    case doctorsActionTypes.DELETE_DOCTOR_SAGA: {
      return { ...state, isLoading: true };
    }
    case doctorsActionTypes.DELETE_DOCTOR: {
      const updatedState = state.doctors;
      const toUpdate = updatedState.filter((doctor) => doctor._id != payload);
      return { ...state, doctors: toUpdate, isLoading: false };
    }
    case doctorsActionTypes.EDIT_DOCTOR_SAGA: {
      return { ...state, isLoading: true };
    }
    case doctorsActionTypes.EDIT_DOCTOR: {
      const currentState = state.doctors;

      const toUpdate = currentState.map((doctor) => {
        return doctor._id == payload._id ? payload : doctor;
      });
      return { ...state, doctors: toUpdate, isLoading: false };
    }
    case doctorsActionTypes.ADD_DOCTOR_SAGA: {
      return { ...state, isLoading: true };
    }
    case doctorsActionTypes.ADD_DOCTOR: {
      const currentState = state.doctors;
      const toUpdate = [...currentState, payload];
      return { ...state, doctors: toUpdate, isLoading: false };
    }
    case doctorsActionTypes.REQUEST_FAILED: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
