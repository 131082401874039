import { takeEvery, put, call } from "redux-saga/effects";
import { clientsActionTypes } from "./clientRegistration.types";
import {
  addClientPersonalInfo,
  addClientCompanyInfo,
  requestFailed,
  addCurrentDaycare,
  addCurrentDaycareClient,
  clientUpdated,
  clientCompanyUpdated,
} from "./clientRegistration.actions";
import axios from "../../../axios";
import { customHistory } from "../../../routes/index";
import SuccessHelper from "../../../helpers/SuccessHelper";
import ErrorHelper from "../../../helpers/ErrorHelper";
import { setToken } from "../../activeUser/activeUser.actions";
import { loggedIn } from "../login/login.actions";

const addClientPersonal = async ({ payload }) => {
  try {
    const response = await axios.post("/daycare/signup", payload);
    if (response.data.success === true) {
      return response.data.data;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export function* addClientPersonalInfoAsync(payload) {
  const responseData = yield call(addClientPersonal, payload);
  if (responseData) {
    sessionStorage.setItem("currentUserToken", responseData.access_token);
    sessionStorage.setItem("currentUser", JSON.stringify(responseData));
    SuccessHelper.handleToastSuccess("Successfully Registered", true);

    yield put(setToken(responseData.access_token));
    yield put(addClientPersonalInfo(responseData));
    yield put(loggedIn());
    customHistory.push("/company-signup");
  } else {
    ErrorHelper.handleErrors("Failed! something went wrong.", true);
    yield put(requestFailed());
  }
}

const updateClientPersonal = async ({ payload }) => {
  try {
    const response = await axios.put("/user", payload);
    SuccessHelper.handleToastSuccess("Successfully updated", true);

    return response.data.data;
  } catch (error) {
    ErrorHelper.handleErrors("Update Failed", true);
    return null;
  }
};

export function* updateClientPersonalInfoAsync(payload) {
  const responseData = yield call(updateClientPersonal, payload);
  if (responseData !== null) {
    yield put(clientUpdated());
  } else {
    yield put(requestFailed());
  }
}

const updateClientCompany = async ({ payload }) => {
  try {
    const response = await axios.put("/daycare", payload);
    SuccessHelper.handleToastSuccess("Successfully updated", true);

    return response.data.data;
  } catch (error) {
    ErrorHelper.handleErrors("Update Failed", true);
    return null;
  }
};

export function* updateClientCompanyInfoAsync(payload) {
  const responseData = yield call(updateClientCompany, payload);

  if (responseData !== null) {
    yield put(clientCompanyUpdated());
  } else {
    yield put(requestFailed());
  }
}

const addClientCompany = async ({ payload }) => {
  try {
    const response = await axios.post("/daycare/companyRegistration", payload);
    if (response.data.success) {
      const loggedInUser = JSON.parse(sessionStorage.getItem("currentUser"));
      let updatedUser = {
        ...loggedInUser,
        company: response.data.client,
        isCompanyProfile: true,
        isEmployeeProfile: true,
      };
      sessionStorage.setItem("currentUser", JSON.stringify(updatedUser));
      SuccessHelper.handleToastSuccess("Successfully registered", true);
      return updatedUser;
    }
    return null;
  } catch (error) {
    ErrorHelper.handleErrors("Request Failed", true);
    return null;
  }
};

export function* addClientCompanyInfoAsync(payload) {
  const responseData = yield call(addClientCompany, payload);
  if (responseData !== null) {
    yield put(addClientCompanyInfo(responseData));
    yield put(setToken(responseData.access_token));
    customHistory.push("/dashboard");
  } else {
    yield put(requestFailed());
  }
}

const getDaycareApi = async () => {
  try {
    const response = await axios.get("/daycare");
    return response.data.data;
  } catch (error) {
    //todo show error popup to user
    console.log(error);
    return null;
  }
};

export function* getDaycareAsync() {
  const responseData = yield call(getDaycareApi);
  if (responseData !== null) {
    yield put(addCurrentDaycare(responseData));
  } else {
    yield put(requestFailed());
  }
}

const getCurrentClientApi = async () => {
  try {
    const response = await axios.get("/user");
    return response.data.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export function* getCurrentClientAsync() {
  const responseData = yield call(getCurrentClientApi);
  if (responseData !== null) {
    yield put(addCurrentDaycareClient(responseData));
  } else {
    yield put(requestFailed());
  }
}

export function* watchClientRegistration() {
  yield takeEvery(
    clientsActionTypes.ADD_CLIENT_PERSONAL_INFO_SAGA,
    addClientPersonalInfoAsync
  );
  yield takeEvery(
    clientsActionTypes.ADD_CLIENT_COMPANY_INFO_SAGA,
    addClientCompanyInfoAsync
  );
  yield takeEvery(
    clientsActionTypes.UPDATE_CLIENT_PERSONAL_INFO,
    updateClientPersonalInfoAsync
  );
  yield takeEvery(
    clientsActionTypes.UPDATE_CLIENT_COMPANY_INFO,
    updateClientCompanyInfoAsync
  );
  yield takeEvery(clientsActionTypes.GET_DAYCARE, getDaycareAsync);
  yield takeEvery(
    clientsActionTypes.GET_CURRENT_CLIENT_SAGA,
    getCurrentClientAsync
  );
}
