import { takeEvery, put, call } from "redux-saga/effects";
import { immunizationActionTypes } from "./immunization.types";
import {
  saveImmunization,
  getImmunizationsSaga,
  deleteImmunization,
  savedImmunization,
} from "./immunization.actions";
import axios from "../../axios";
const getData = async () => {
  try {
    const response = await axios.get("/immunization", {});
    // payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    // payload?.callbackMap?.failure(e);
  }
};

export function* fetchImmunizationAsync() {
  try {
    const data = yield call(getData);
    yield put(getImmunizationsSaga(data));
  } catch (e) {
    // yield put(requestFailed());
  }
}

const addData = async (payload) => {
  try {
    const response = await axios.post("/immunization", payload.payload);
    // payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    // payload?.callbackMap?.failure(e);
  }
};

export function* addImmunizationAsync(payload) {
  console.log(payload, ".............payload...............");
  try {
    const responseData = yield call(addData, payload);
    yield put(savedImmunization(responseData));
  } catch (e) {
    // yield put(requestFailed());
  }
}

const deleteData = async (payload) => {
  try {
    console.log("payload...del........", payload);
    const response = await axios.delete("/immunization", {
      params: { ids: payload.immunizationId },
    });
    payload?.callbackMap?.success(response);
    return response.data._id;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* deleteImmunizationAsync(payload) {
  try {
    const deleteVal = yield call(deleteData, payload);
    yield put(deleteImmunization(deleteVal));
  } catch (e) {
    // yield put(requestFailed());
  }
}

export function* watchImmunization() {
  yield takeEvery(
    immunizationActionTypes.GET_IMMUNIZATIONS,
    fetchImmunizationAsync
  );
  yield takeEvery(
    immunizationActionTypes.SAVING_IMMUNIZATION,
    addImmunizationAsync
  );
  yield takeEvery(
    immunizationActionTypes.DELETE_IMMUNIZATION_SAGA,
    deleteImmunizationAsync
  );
}
