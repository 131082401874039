import { signFormActionTypes } from "./signForms.types";

export const addSignForm = (signForm, callbackMap) => {
  return {
    type: signFormActionTypes.ADD_SIGN_FORM,
    callbackMap,
    payload: signForm,
  };
};

export const getSignForms = (page, callbackMap, params) => {
  return {
    type: signFormActionTypes.GET_SIGN_FORMS,
    callbackMap,
    payload: page,
    params,
  };
};

export const deleteSignForm = (id, callbackMap) => {
  return {
    type: signFormActionTypes.DELETE_SIGN_FORM,
    callbackMap,
    payload: id,
  };
};

export const signFormDeleted = (id) => {
  return {
    type: signFormActionTypes.SIGN_FORM_DELETED,
    payload: id,
  };
};

export const signFormsReceived = (id) => {
  return {
    type: signFormActionTypes.SIGN_FORMS_RECEIVED,
    payload: id,
  };
};

export const assignFormRequest = (payload, callbackMap) => {
  return {
    type: signFormActionTypes.ASSIGN_FORM_REQUEST,
    payload,
    callbackMap,
  };
};

export const assignForm = (payload) => {
  return {
    type: signFormActionTypes.ASSIGN_FORM,
    payload,
  };
};
