import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import i18n from "../../../i18n";
import {
  addKidsBillingRequest,
  setActiveBillingRequest,
} from "../../../redux/kids/kids.actions";
import BasicTable from "../../Core/BasicTable";
import PrimaryButton from "../../Core/Buttons/PrimaryButton/PrimaryButton";
import FormModal from "../../Core/FormModal/FormModal";

export default function Billing({
  changeEditingTableRowState,
  billing,
  kidId,
}) {
  const dispatch = useDispatch();
  const addBilling = (values) => {
    dispatch(
      addKidsBillingRequest(
        { ...values, kidId },
        {
          success: ({ data }) => {
            console.log(data);
            changeEditingTableRowState(data.data);
          },
        }
      )
    );
  };
  const handleSetActive = ({ _id, startDate }) => {
    dispatch(
      setActiveBillingRequest(
        { _id, startDate },
        {
          success: ({ data }) => {
            changeEditingTableRowState(data.data);
          },
        }
      )
    );
  };

  const [addOpen, setAddOpen] = useState(false);
  const [active, setActive] = useState(null);
  console.log(active);
  return (
    <div>
      <div className="row justify-content-end mb-2">
        <PrimaryButton onClick={() => setAddOpen(true)}>
          {i18n.t("add")}
        </PrimaryButton>
      </div>
      <BasicTable
        rows={billing}
        schema={[
          {
            header: i18n.t("amount"),
            field: "amount",
            accessor: ({ amount }) => `$ ${amount.toFixed(2)}`,
          },
          {
            header: i18n.t("frequency"),
            field: "frequency",
            accessor: ({ frequency }) => i18n.t(frequency),
          },
          {
            header: i18n.t("start_date"),
            field: "start_date",
            accessor: ({ startDate }) => moment(startDate).format("MM-DD-YYYY"),
          },
          {
            header: i18n.t("status"),
            field: "status",
          },
          {
            style: { width: 100 },
            field: "actions",
            render: (data) => {
              if (data.status == "Active") {
                return null;
              }
              return (
                <PrimaryButton
                  onClick={() => {
                    const toSet = {
                      ...data,
                      startDate: moment(data.startDate).toDate(),
                    };
                    setActive(toSet);
                  }}
                >
                  {i18n.t("set_active")}
                </PrimaryButton>
              );
            },
          },
        ]}
      />
      <FormModal
        open={active}
        onSubmit={handleSetActive}
        validateForm={(values) => {
          const errors = {};
          if (!values.startDate) {
            errors.startDate = i18n.t("required");
          }

          return errors;
        }}
        schema={[
          { value: "_id", fieldType: null },
          { value: "startDate", title: "start_date", fieldType: "date" },
        ]}
        closeModal={() => setActive(null)}
        values={active}
        title={i18n.t("set_active")}
      ></FormModal>
      <FormModal
        open={addOpen}
        onSubmit={addBilling}
        validateForm={(values) => {
          const errors = {};
          if (!values.startDate) {
            errors.startDate = i18n.t("required");
          }
          if (!values.amount) {
            errors.amount = i18n.t("required");
          }
          if (!values.frequency) {
            errors.frequency = i18n.t("required");
          }
          if (!values.status) {
            errors.status = i18n.t("required");
          }

          return errors;
        }}
        schema={[
          { value: "startDate", title: "start_date", fieldType: "date" },
          { value: "amount", title: "amount", fieldType: "dollar" },
          {
            value: "frequency",
            title: "frequency",
            fieldType: "select",
            options: [
              { name: i18n.t("Weekly"), value: "Weekly" },
              { name: i18n.t("Bi-Weekly"), value: "Bi-Weekly" },
              { name: i18n.t("Montly"), value: "Montly" },
            ],
          },
          {
            value: "status",
            title: "Status",
            fieldType: "select",
            options: [
              { name: "Active", value: "Active" },
              { name: "Inactive", value: "Inactive" },
            ],
          },
        ]}
        closeModal={() => setAddOpen(false)}
        values={{}}
        title={i18n.t("billing")}
      ></FormModal>
    </div>
  );
}
