import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import frLocale from "@fullcalendar/core/locales/fr";
import enLocale from "@fullcalendar/core/locales/en-nz";
import i18n from "../../i18n";
import { useDispatch, useSelector } from "react-redux";

import EventModal from "./EventModal";
import AttendanceModal from "./AttendanceModal";

import "./CalendarView.css";

import { startActivityFetching } from "../../redux/activities/activities.actions";
import { startMealFetching } from "../../redux/meals/meals.actions";
import { startGroupFetching } from "../../redux/groups/groups.actions";
import { startKidFetching } from "../../redux/kids/kids.actions";
import { startEmployeeFetching } from "../../redux/employees/employees.actions";
import {
  startEventFetching,
  setActiveEvent,
} from "../../redux/events/events.actions";

import moment from "moment";
import { useMemo } from "react";
import { Button } from "@material-ui/core";
import PrimaryButton from "../../components/Core/Buttons/PrimaryButton/PrimaryButton";

const getContrastYIQ = (hexcolor) => {
  if (hexcolor) {
    let color = hexcolor.replace("#", "");
    const r = parseInt(color.substr(0, 2), 16);
    const g = parseInt(color.substr(2, 2), 16);
    const b = parseInt(color.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "black" : "white";
  }
  return "#000";
};

const opetionFormatter = (element) => ({ ...element, value: element._id });
export default function ActivityCalendar() {
  const dispatch = useDispatch();
  const [dates, setDates] = useState({});
  const [attendanceModalOpen, setAttendanceModalOpen] = useState(false);
  const [attendanceDate, setAttendanceDate] = useState();

  useEffect(() => {
    document.title = i18n.t("calendar");
    dispatch(startActivityFetching());
    dispatch(startMealFetching());
    dispatch(startGroupFetching());
    dispatch(startEventFetching({ relations: ["kids"] }));
    dispatch(startKidFetching(null, { relations: ["allergies"] }));
    dispatch(startEmployeeFetching());
  }, []);
  const language = useSelector((s) => s.language?.language);
  const events = useSelector((s) => {
    return s.events.events;
  });

  const groupMap = useSelector((s) => {
    const groupMap = {};
    s.groups.groups.map((group) => {
      groupMap[group._id] = group;
    });
    return groupMap;
  });

  const activityMap = useSelector((s) => {
    const activityMap = {};
    s.activities.activities.map((activity) => {
      activityMap[activity._id] = activity;
    });
    return activityMap;
  });

  const mealMap = useSelector((s) => {
    const mealMap = {};
    s.meals.meals.map((meal) => {
      mealMap[meal._id] = meal;
    });
    return mealMap;
  });

  const formattedEvents = useMemo(() => {
    return events.map((event) => {
      const color = event.color || "#000000";
      return {
        title: event.name || i18n.t("event"),
        start: event.checkIn,
        end: event.checkOut,
        backgroundColor: color,
        textColor: getContrastYIQ(color),
        event: {
          ...event,
          color,
          activityIds: event.activityIds.map((activityId) => {
            return opetionFormatter(activityMap[activityId] || {});
          }),
          mealIds: event.mealIds.map((mealId) => {
            return opetionFormatter(mealMap[mealId] || {});
          }),
        },
      };
    });
  }, [mealMap, groupMap, events]);
  const onEventMouseEnter = (e) => {};
  const onEventMouseLeave = (e) => {};
  const onEvenClick = (e) => {
    setDates();
    dispatch(setActiveEvent(e.event._def.extendedProps.event));
  };
  return (
    <div className="CalendarView">
      <div className="CalendarContainer">
        <div className="CalendarWrapper">
          <FullCalendar
            events={formattedEvents}
            eventMouseEnter={onEventMouseEnter}
            eventMouseLeave={onEventMouseLeave}
            eventClick={onEvenClick}
            slotDuration="00:15:00"
            contentHeight={3400}
            expandRows
            allDaySlot={false}
            stickyHeaderDates
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            locales={[frLocale, enLocale]}
            locale={language}
            slotEventOverlap={false}
            dateClick={(e) => {
              const date = moment(e.date);
              setDates({
                checkIn: date.format("YYYY-MM-DDTHH:mm"),
                checkOut: date.add(60, "m").format("YYYY-MM-DDTHH:mm"),
              });
              dispatch(setActiveEvent({}));
            }}
            customButtons={{
              myCustomButton: {
                text: i18n.t("add_event"),
                click: () => {
                  dispatch(setActiveEvent({}));
                },
              },
            }}
            headerToolbar={{
              center: "myCustomButton",
              right: "prev,next",
              left: "timeGridDay,timeGridWeek,dayGridMonth",
            }}
          />
        </div>
      </div>
      <div className="row justify-content-end mt-1">
        <PrimaryButton
          onClick={() => {
            setAttendanceModalOpen(true);
          }}
        >
          {i18n.t("attendance")}
        </PrimaryButton>
      </div>
      <AttendanceModal
        setOpen={(e) => {
          setAttendanceModalOpen(e);
          setAttendanceDate();
        }}
        defaultDate={attendanceDate}
        open={attendanceModalOpen}
      />
      <EventModal
        dates={dates}
        onAttendanceClick={(date) => {
          setAttendanceDate(date);
          setAttendanceModalOpen(true);
        }}
      />
    </div>
  );
}
