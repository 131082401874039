import React from "react";
import "./styles.scss";
import i18n from "../../../i18n";
export default function Search({ placeHolder, handleSearch, handleChange }) {
  const submitSearch = (e) => {
    e.preventDefault();
    handleSearch(e.target[0].value);
  };
  return (
    <div className="search-form">
      <form
        onChange={(e) => handleChange(e)}
        onSubmit={(e) => submitSearch(e)}
        role="search"
      >
        <label htmlFor="search">Search for stuff</label>
        <input id="search" type="search" placeholder={placeHolder} required />
        <button type="submit">{i18n.t("go")}</button>
      </form>
    </div>
  );
}
