import { activitiesActionTypes } from "./activities.types";

export const saveActivities = (activities) => {
  return {
    type: activitiesActionTypes.SAVE_ACTIVITIES,
    payload: activities,
  };
};

export const startActivityFetching = (callbackMap) => {
  return {
    type: activitiesActionTypes.GET_ACTIVITIES,
    callbackMap,
  };
};

export const startDeletingActivity = (id, callbackMap) => {
  return {
    type: activitiesActionTypes.DELETE_ACTIVITY_SAGA,
    callbackMap,
    activityId: id,
  };
};

export const deleteActivity = (id) => {
  console.log("delete activity..", id);
  return {
    type: activitiesActionTypes.DELETE_ACTIVITY,
    payload: id,
  };
};

export const startEditingActivity = (callbackMap, payload) => {
  return {
    type: activitiesActionTypes.EDIT_ACTIVITY_SAGA,
    callbackMap,
    payload,
  };
};

export const editActivity = (payload) => {
  return {
    type: activitiesActionTypes.EDIT_ACTIVITY,
    payload,
  };
};

export const startAddingActivity = (callbackMap, payload) => {
  return {
    type: activitiesActionTypes.ADD_ACTIVITY_SAGA,
    callbackMap,
    payload,
  };
};

export const addActivity = (payload) => {
  return {
    type: activitiesActionTypes.ADD_ACTIVITY,
    payload,
  };
};

export const requestFailed = () => {
  return {
    type: activitiesActionTypes.REQUEST_FAILED,
  };
};
