import React from "react";
import { Field } from "react-final-form";
import TextField from "@material-ui/core/TextField";
import { clientPersonalRegFields } from "./clientPersonalReg.data";
import { ErrorMessage } from "./clientPersonalReg.styles";
import { startAddingClientPersonalInfo } from "../../../redux/authentication/ClientRegistration/clientRegistration.actions";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import PlacesAutocomplete from "../../../components/Core/PlacesAutocomplete/PlacesAutoComplete";
import store from "../../../redux/store";
import { TableItem } from "./clientPersonalReg.styles";
// import { KeyboardReturnTwoTone } from "@material-ui/icons";
// /daycare/signup
const submitClientPersonalInfo = (values) => {
  const payload = _.omit(values, ["confirmPassword"]);
  payload["role"] = 3;
  store.dispatch(startAddingClientPersonalInfo({ ...payload }));
};

const renderClientPersonalInfoFields = (sample) => {
  return clientPersonalRegFields.map(({ value, title, fieldType }, index) => {
    return (
      <Field name={value} key={`dasdj-${value}`}>
        {({ input, meta }) => {
          switch (fieldType) {
            case "text":
            case "password":
            case "email":
              return (
                <TableItem type={value}>
                  <TextField
                    className={sample}
                    {...input}
                    label={title}
                    type={fieldType}
                    fullWidth
                  />
                  {meta.error && meta.touched ? (
                    <ErrorMessage>{meta.error}</ErrorMessage>
                  ) : (
                    <ErrorMessage></ErrorMessage>
                  )}
                </TableItem>
              );
            case "date":
              return (
                <TableItem type={value}>
                  <TextField
                    className={sample}
                    {...input}
                    label={title}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {meta.error && meta.touched ? (
                    <ErrorMessage>{meta.error}</ErrorMessage>
                  ) : (
                    <ErrorMessage></ErrorMessage>
                  )}
                </TableItem>
              );
            case "address":
              return (
                <TableItem type={value}>
                  <PlacesAutocomplete name="address" input={input} />
                  {meta.error && meta.touched ? (
                    <ErrorMessage>{meta.error}</ErrorMessage>
                  ) : (
                    <ErrorMessage></ErrorMessage>
                  )}
                </TableItem>
              );

            default:
              return null;
          }
        }}
      </Field>
    );
  });
};
export { submitClientPersonalInfo, renderClientPersonalInfoFields };
