import React from "react";
import "./Page.css";
import i18n from "../../i18n";
import AddButton from "../../components/Core/Buttons/Add/AddButton";
import TableGenerator from "../Core/TableGenerator/TableGenerator/TableGenerator.component";
import SuccessHelper from "../../helpers/SuccessHelper";

export default (props) => {
  const {
    title,
    resource,
    data,
    onDelete,
    onEdit = () => {},
    onCreate = () => {},
    schema,
    editOnRowClick,
  } = props;

  const onDeleteWrapper = (...e) => {
    SuccessHelper.handleDelete(i18n.t("are_you_sure"), (result) => {
      if (result.isConfirmed) {
        onDelete(...e);
      }
    });
  };

  return (
    <div className="Page" style={{ width: "88%", margin: "auto" }}>
      <div className="row justify-content-between align-items-center">
        <div className="title">{i18n.t(title)}</div>
        <div>
          <AddButton onClick={onCreate}>
            {i18n.t("add_resource", { resource })}
          </AddButton>
        </div>
      </div>
      <TableGenerator
        tableData={data}
        onDelete={onDeleteWrapper}
        onEdit={onEdit}
        schema={schema}
        editOnRowClick={editOnRowClick}
      />
    </div>
  );
};
