import { attendanceActionTypes } from "./attendance.types";

export const getAttendanceRequest = (params, callbackMap) => {
  return {
    type: attendanceActionTypes.GET_ATTENDANCE_SAGA,
    params,
    callbackMap,
  };
};

export const getAttendance = (payload) => {
  return {
    type: attendanceActionTypes.GET_ATTENDANCE,
    payload,
  };
};

export const saveAttendanceRequest = (payload, callbackMap) => {
  return {
    type: attendanceActionTypes.SAVE_ATTENDANCE_SAGA,
    payload,
    callbackMap,
  };
};

export const saveAttendance = (payload) => {
  return {
    type: attendanceActionTypes.SAVE_ATTENDANCE,
    payload,
  };
};

export const requestFailed = () => {
  return {
    type: attendanceActionTypes.REQUEST_FAILED,
  };
};
