import React, { useState } from "react";
import {
  EmployeesWrapper,
  EmployeeHeaderWrapper,
  EmployeeSearchWrapper,
  EmployeeList,
} from "./Employee.styles";
import EmployeeHeader from "./components/EmployeeHeader";
import EmployeeSearch from "./components/EmployeeSearch";
import DisplayEmployees from "./components/DisplayEmployees";
import EmployeeModal from "./components/EmployeeModal";
import AddEmployeeModal from "./components/AddEmployeeModal";

export default function MainEmployees() {
  return (
    <EmployeesWrapper>
      <EmployeeHeaderWrapper>
        <EmployeeHeader />
      </EmployeeHeaderWrapper>
      <EmployeeSearchWrapper>
        <EmployeeSearch />
      </EmployeeSearchWrapper>
      <EmployeeList>
        <DisplayEmployees />
      </EmployeeList>
      <EmployeeModal />
      <AddEmployeeModal />
    </EmployeesWrapper>
  );
}
