import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLogin } from "../../helpers/isLogin";
import { hasCompany } from "../../helpers/hasCompany";
export default function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLogin()) {
          if (hasCompany()) {
            return <Component {...props} />;
          }
          return <Redirect to="/company-signup" />;
        }
        return <Redirect to="/" />;
      }}
    />
  );
}
