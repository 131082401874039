const employeeRegFields = [
  { value: "password", title: "Password", fieldType: "password" },
  {
    value: "confirmPassword",
    title: "Confirm Password",
    fieldType: "password",
  },
  { value: "address", title: "Address", fieldType: "text" },
];

export { employeeRegFields };
export default employeeRegFields;
