import React from "react";
import { Field } from "react-final-form";
import TextField from "@material-ui/core/TextField";
import { clientCompanyRegFields } from "./ClientCompanySignUp.data";
import {
  ErrorMessage,
  CompanySignupWrapper,
  TableItem,
} from "./clientCompanyReg.styles";
import { startAddingClientCompanyInfo } from "../../../redux/authentication/ClientRegistration/clientRegistration.actions";
import _ from "lodash";
import store from "../../../redux/store";
import PlacesAutocomplete from "../../../components/Core/PlacesAutocomplete/PlacesAutoComplete";

const submitClientCompanyInfo = (values) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  // const accessToken = JSON.parse(sessionStorage.getItem("currentUserToken"));

  // const payload = { accessToken, userId: currentUser._id, company: values };
  const payload = {
    accessToken: currentUser.access_token,
    userId: currentUser._id,
    company: values,
  };

  store.dispatch(startAddingClientCompanyInfo(payload));
};
// const validateClientCompanyInfo = (values) => {
//   const errors = {};

//   if (!values.companyName) {
//     errors.companyName = i18n.t("required");
//   }
//   if (!values.companyTag) {
//     errors.companyTag = i18n.t("required");
//   }
//   if (!values.companyEmail) {
//     errors.companyEmail = i18n.t("required");
//   }
//   if (values.companyEmail && !validateEmail(values.companyEmail)) {
//     errors.companyEmail = i18n.t("valid_email");
//   }
//   if (!values.phoneNo) {
//     errors.phoneNo = i18n.t("required");
//   }
//   if (!values.companyAddress) {
//     errors.companyAddress = i18n.t("required");
//   }

//   if (!values.companyDescription) {
//     errors.companyDescription = i18n.t("required");
//   }

//   return errors;
// };
const renderClientCompanyInfoFields = () => {
  return clientCompanyRegFields.map(({ value, title, fieldType, name }) => {
    return (
      <Field name={value} key={`dasdj-${value}`}>
        {({ input, meta }) => {
          switch (fieldType) {
            case "text":
            case "password":
            case "email":
              return (
                <TableItem type={name}>
                  <TextField
                    {...input}
                    label={title}
                    type={fieldType}
                    fullWidth
                  />
                  {meta.error && meta.touched ? (
                    <ErrorMessage>{meta.error}</ErrorMessage>
                  ) : (
                    <ErrorMessage></ErrorMessage>
                  )}
                </TableItem>
              );
            case "date":
              return (
                <>
                  <TableItem type={name}>
                    <TextField
                      fullWidth
                      {...input}
                      label={title}
                      type="date"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {meta.error && meta.touched ? (
                      <ErrorMessage>{meta.error}</ErrorMessage>
                    ) : (
                      <ErrorMessage></ErrorMessage>
                    )}
                  </TableItem>
                </>
              );
            case "address":
              return (
                <>
                  <TableItem type={name}>
                    <PlacesAutocomplete name="address" input={input} />
                    {meta.error && meta.touched ? (
                      <ErrorMessage>{meta.error}</ErrorMessage>
                    ) : (
                      <ErrorMessage></ErrorMessage>
                    )}
                  </TableItem>
                </>
              );

            default:
              return null;
          }
        }}
      </Field>
    );
  });
};

export { submitClientCompanyInfo, renderClientCompanyInfoFields };
