export const requirmentsActionTypes = {
  GET_REQUIRMENTS: "GET_REQUIRMENTS",
  SAVE_REQUIRMENTS: "SAVE_REQUIRMENTS",

  DELETE_REQUIRMENT: "DELETE_REQUIRMENT",
  DELETE_REQUIRMENT_SAGA: "DELETE_REQUIRMENT_SAGA",

  EDIT_REQUIRMENT: "EDIT_REQUIRMENT",
  EDIT_REQUIRMENT_SAGA: "EDIT_REQUIRMENT_SAGA",

  ADD_REQUIRMENT: "ADD_REQUIRMENT",
  ADD_REQUIRMENT_SAGA: "ADD_REQUIRMENT_SAGA",

  REQUEST_FAILED: "REQUEST_FAILED_REQUIRMENT",
};
