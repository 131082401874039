import { takeEvery, put, call } from "redux-saga/effects";
import { immunizationActionTypes } from "./immunizationType.types";
import {
  addImmunization,
  deleteImmunization,
  getImmunizations,
  editImmunization,
  requestFailed,
} from "./immunizationType.actions";

import axios from "../../axios";
const getData = async (payload) => {
  try {
    const response = await axios.get("/immunizationType", {
      params: payload.payload,
    });
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* fetchImmunizationTypes(payload) {
  try {
    const data = yield call(getData, payload);
    yield put(getImmunizations(data));
  } catch (e) {
    yield put(requestFailed());
  }
}

const deleteData = async (payload) => {
  try {
    const response = await axios.delete("/immunizationType", {
      params: { ids: payload.id },
    });
    payload?.callbackMap?.success(response);
    return response.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* deleteImmunizationTypes(payload) {
  try {
    const deleteVal = yield call(deleteData, payload);
    yield put(deleteImmunization(deleteVal));
  } catch (e) {
    yield put(requestFailed());
  }
}

const editData = async (payload) => {
  try {
    const response = await axios.put("/immunizationType", {
      _id: payload.payload._id,
      data: payload.payload.data,
    });
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* editImmunizationTypes(payload) {
  try {
    const responseData = yield call(editData, payload);
    yield put(editImmunization(responseData));
  } catch (e) {
    yield put(requestFailed());
  }
}

const addData = async (payload) => {
  try {
    const response = await axios.post("/immunizationType", payload.payload);
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* addImmunizationTypes(payload) {
  try {
    const responseData = yield call(addData, payload);
    yield put(addImmunization(responseData));
  } catch (e) {
    yield put(requestFailed());
  }
}
export function* watchImmunizationTypes() {
  yield takeEvery(
    immunizationActionTypes.GET_IMMUNIZATION_TYPES_SAGA,
    fetchImmunizationTypes
  );
  yield takeEvery(
    immunizationActionTypes.DELETE_IMMUNIZATION_TYPE_SAGA,
    deleteImmunizationTypes
  );

  yield takeEvery(
    immunizationActionTypes.UPDATING_IMMUNIZATION_TYPE_SAGA,
    editImmunizationTypes
  );

  yield takeEvery(
    immunizationActionTypes.ADD_IMMUNIZATION_TYPE_SAGA,
    addImmunizationTypes
  );
}
