import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import SecondaryButton from "../Buttons/SecondaryButton/SecondaryButton";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import i18n from "../../../i18n";
import "./Modal.css";
const EditModal = styled.div`
  background-color: white;
  margin: 0;
  padding: 0;
  width: 60vw;
  max-height: 80vh;
  overflow-y: auto;
`;

const EditModalTitle = styled.h2`
  margin: 0;
`;

const EditModalContentContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100% - 56px);
`;

const ModalHeader = styled.div`
  width: 100%;
`;

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: "grey",
  },
  tabsBar: {
    "& .MuiTabs-indicator": {
      // display: "none",
      backgroundColor: "orange",
    },
  },
}));

export default function _Modal({
  title,
  content,
  children,
  open,
  closeModal,
  ...rest
}) {
  const classes = useStyles();
  if (!open) {
    return null;
  }
  return (
    <Modal
      aria-labelledby="form-add-popup"
      aria-describedby="appears-to-add-form-data"
      className={classes.modal}
      open={open}
      onClose={() => {
        closeModal();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 700,
      }}
      {...rest}
    >
      <div className="core-modal">
        <Fade in={open}>
          <EditModal>
            <ModalHeader className="modal-header">
              <div className="row justify-content-between align-items-center p-2">
                {title ? <EditModalTitle>{title}</EditModalTitle> : null}
                <SecondaryButton onClick={() => closeModal()}>
                  {i18n.t("close")}
                </SecondaryButton>
              </div>
            </ModalHeader>
            <EditModalContentContainer>
              <div className="p-2">{children || content}</div>
            </EditModalContentContainer>
          </EditModal>
        </Fade>
      </div>
    </Modal>
  );
}
