import React from "react";
import { Field } from "react-final-form";
import TextField from "@material-ui/core/TextField";
import { v4 as uuidv4 } from "uuid";
import { ErrorMessage } from "../ClientPersonalSignup/clientPersonalReg.styles";
import { startLogin } from "../../../redux/authentication/login/login.actions";
import store from "../../../redux/store";
const loginUser = (values) => {
  store.dispatch(startLogin(values));
};
const loginFields = [
  { value: "email", title: "Email", fieldType: "email" },
  { value: "password", title: "Password", fieldType: "password" },
];
const renderLoginFormFields = () => {
  return loginFields.map(({ value, title, fieldType }) => {
    return (
      <Field name={value} key={`login-${value}`}>
        {({ input, meta }) => {
          switch (fieldType) {
            case "text":
            case "password":
            case "email":
              return (
                <>
                  <TextField
                    {...input}
                    label={title}
                    variant="outlined"
                    type={fieldType}
                  />
                  {meta.error && meta.touched ? (
                    <ErrorMessage>{meta.error}</ErrorMessage>
                  ) : (
                    <ErrorMessage></ErrorMessage>
                  )}
                </>
              );
            case "date":
              return (
                <>
                  <TextField
                    {...input}
                    label={title}
                    type="date"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {meta.error && meta.touched ? (
                    <ErrorMessage>{meta.error}</ErrorMessage>
                  ) : (
                    <ErrorMessage></ErrorMessage>
                  )}
                </>
              );

            default:
              break;
          }
        }}
      </Field>
    );
  });
};

export { loginUser, renderLoginFormFields };
