const activitiesIcon = require("./activities.png").default;
const billingIcon = require("./billing.png").default;
const calendarIcon = require("./calendar.png").default;
const companyIcon = require("./company.png").default;
const formsIcon = require("./forms.png").default;
const kidsIcon = require("./kids.png").default;
const mealsIcon = require("./meals.png").default;
const medicalIcon = require("./medical.png").default;
const shieldIcon = require("./shield.png").default;
const teamIcon = require("./team.png").default;
const timeIcon = require("./time.png").default;
const userIcon = require("./user.png").default;
const businessSettingsIcon = require("./business_settings.png").default;
const kidsSectionIcon = require("./kids_section.png").default;
const medicalSectionIcon = require("./medical_section.png").default;
const menusCalendarIcon = require("./menus_calendars.png").default;
const staffManagementIcon = require("./staff_management.png").default;

export default {
  activitiesIcon,
  billingIcon,
  calendarIcon,
  companyIcon,
  formsIcon,
  kidsIcon,
  mealsIcon,
  medicalIcon,
  shieldIcon,
  teamIcon,
  timeIcon,
  userIcon,
  businessSettingsIcon,
  kidsSectionIcon,
  medicalSectionIcon,
  menusCalendarIcon,
  staffManagementIcon,
};
