import { takeEvery, put, call } from "redux-saga/effects";
import { attendanceActionTypes } from "./attendance.types";
import {
  getAttendance,
  saveAttendance,
  requestFailed,
} from "./attendance.actions";
import axios from "../../axios";

const getData = async ({ params, callbackMap }) => {
  try {
    const response = await axios.get("./attendance", { params });
    callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    callbackMap?.failure(e);
  }
};

function* getSaga(payload) {
  try {
    const data = yield call(getData, payload);
    yield put(getAttendance(data));
  } catch (e) {
    payload?.callbackMap?.failure(e);
    yield put(requestFailed());
  }
}

const addData = async (payload) => {
  try {
    const response = await axios.post("/attendance", payload.payload);
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    throw e;
  }
};

function* saveSaga(payload) {
  try {
    const responseData = yield call(addData, payload);
    yield put(saveAttendance(responseData));
  } catch (e) {
    payload?.callbackMap?.failure(e);
    yield put(requestFailed());
  }
}

export default function* watchAttendance() {
  yield takeEvery(attendanceActionTypes.GET_ATTENDANCE_SAGA, getSaga);
  yield takeEvery(attendanceActionTypes.SAVE_ATTENDANCE_SAGA, saveSaga);
}
