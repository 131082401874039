import { takeEvery, put, call } from "redux-saga/effects";
import { requirmentsActionTypes } from "./requirments.types";
import {
  saveRequirments,
  deleteRequirment,
  editRequirment,
  addRequirment,
  requestFailed,
} from "./requirments.actions";
import axios from "../../axios";
const getData = async (payload) => {
  try {
    const response = await axios.get("/requirment", { params: payload.params });
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* fetchRequirmentsAsync(payload) {
  try {
    const data = yield call(getData, payload);
    yield put(saveRequirments(data));
  } catch (e) {
    yield put(requestFailed());
  }
}

const deleteData = async (payload) => {
  try {
    const response = await axios.delete("/requirment", {
      params: { ids: payload.requirmentId },
    });
    payload?.callbackMap?.success(response);
    return response.data._id;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* deleteRequirmentAsync(payload) {
  try {
    const deleteVal = yield call(deleteData, payload);
    yield put(deleteRequirment(deleteVal));
  } catch (e) {
    yield put(requestFailed());
  }
}

const editData = async (payload) => {
  try {
    const response = await axios.put("/requirment", {
      _id: payload.payload._id,
      data: payload.payload.data,
    });
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* editRequirmentAsync(payload) {
  try {
    const responseData = yield call(editData, payload);
    yield put(editRequirment(responseData));
  } catch (e) {
    yield put(requestFailed());
  }
}

const addData = async (payload) => {
  try {
    const response = await axios.post("/requirment", payload.payload);
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* addRequirmentAsync(payload) {
  try {
    const responseData = yield call(addData, payload);
    yield put(addRequirment(responseData));
  } catch (e) {
    yield put(requestFailed());
  }
}
export function* watchRequirments() {
  yield takeEvery(
    requirmentsActionTypes.GET_REQUIRMENTS,
    fetchRequirmentsAsync
  );
  yield takeEvery(
    requirmentsActionTypes.DELETE_REQUIRMENT_SAGA,
    deleteRequirmentAsync
  );

  yield takeEvery(
    requirmentsActionTypes.EDIT_REQUIRMENT_SAGA,
    editRequirmentAsync
  );

  yield takeEvery(
    requirmentsActionTypes.ADD_REQUIRMENT_SAGA,
    addRequirmentAsync
  );
}
