import React, { useEffect, useMemo, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PrimaryButton from "../../components/Core/Buttons/PrimaryButton/PrimaryButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KidGroupTable from "./KidGroupTable";
import { v4 as uuidv4 } from "uuid";
import i18n from "../../i18n";
import Modal from "../../components/Core/Modal/Modal";

export default function KidGroupAccordian({
  groups = [],
  submitGroups,
  form,
  handleCloseModal,
}) {
  const [expandedMap, setExpandedMap] = useState({});
  const [checkedMap, setCheckedMap] = useState({});
  const [sentMap, setSentMap] = useState({});
  const [initiallyCheckMap, setInitiallyCheckMap] = useState({});

  useEffect(() => {
    const map = {};
    const newSentMap = {};
    form?.assigned?.map((element) => {
      if (element.status != "Active") {
        newSentMap[element.kidId] = true;
      }
      map[element.kidId] = true;
    });
    setCheckedMap({ ...checkedMap, ...map });
    setSentMap({ ...newSentMap });
    setInitiallyCheckMap(map);
  }, [form]);

  const filteredGroups = groups.filter((group) => group.kids.length);
  const groupCheckStatusMap = useMemo(() => {
    const map = {};
    filteredGroups.map((group) => {
      let numberChecked = 0;
      group.kids.map((kid) => {
        if (checkedMap[kid._id]) {
          numberChecked++;
        }
      });
      if (!numberChecked) {
        map[group._id] = "unchecked";
      } else if (numberChecked == group.kids.length) {
        map[group._id] = "checked";
      } else {
        map[group._id] = "indeterminate";
      }
    });
    return map;
  }, [filteredGroups, checkedMap]);

  const onChangeGroupHandler = (event, group) => {
    let newStatus = true;
    if (groupCheckStatusMap[group._id] == "checked") {
      newStatus = false;
    }
    const newMap = {};
    group.kids.map((kid) => {
      if (!sentMap[kid._id]) {
        newMap[kid._id] = newStatus;
      }
    });
    setCheckedMap({ ...checkedMap, ...newMap });
  };

  const handleChange = (panel) => () => {
    if (expandedMap[panel]) {
      delete expandedMap[panel];
    } else {
      expandedMap[panel] = true;
    }
    setExpandedMap({ ...expandedMap });
  };
  const onKidChecked = (event, kid) => {
    if (!sentMap[kid._id]) {
      if (event.target.checked) {
        setCheckedMap({ ...checkedMap, [kid._id]: true });
      } else {
        delete checkedMap[kid._id];
        setCheckedMap({ ...checkedMap });
      }
    }
  };
  const handleSubmit = (toSend) => {
    const toAdd = [];
    const toRemove = [];
    Object.keys(checkedMap).map((kidId) => {
      if (!initiallyCheckMap[kidId]) {
        toAdd.push(kidId);
      }
    });
    form.assigned.map((element) => {
      if (!checkedMap[element.kidId]) {
        toRemove.push(element.kidId);
      }
    });
    submitGroups(toAdd, toRemove, toSend);
  };
  const handleSubmitAndSend = () => {
    handleSubmit(true);
  };
  return (
    <Modal
      open={!!form}
      closeModal={handleCloseModal}
      title={i18n.t("assignments")}
    >
      <div className="mx-2">
        {filteredGroups.map((row) => (
          <Accordion
            key={uuidv4()}
            expanded={expandedMap[row._id]}
            onChange={handleChange(row._id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions3-content"
              id="additional-actions3-header"
            >
              <FormControlLabel
                key={uuidv4()}
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <Checkbox
                    onChange={(event) => {
                      onChangeGroupHandler(event, row);
                    }}
                    checked={groupCheckStatusMap[row._id] == "checked"}
                    indeterminate={
                      groupCheckStatusMap[row._id] == "indeterminate"
                    }
                  />
                }
                label={row.name}
              />
            </AccordionSummary>
            <AccordionDetails>
              <KidGroupTable
                groupId={row._id}
                kids={row.kids}
                onKidChecked={onKidChecked}
                checkedMap={checkedMap}
                sentMap={sentMap}
              ></KidGroupTable>
            </AccordionDetails>
          </Accordion>
        ))}

        <div className="row justify-content-end mt-2">
          <PrimaryButton onClick={() => handleSubmit()}>
            {i18n.t("submit")}
          </PrimaryButton>
          <PrimaryButton onClick={handleSubmitAndSend} className="ml-2">
            {i18n.t("submit_and_send")}
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
}
