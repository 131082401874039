import { immunizationActionTypes } from "./immunizationType.types";

export const startImmunizationFetching = () => {
  return {
    type: immunizationActionTypes.GET_IMMUNIZATION_TYPES_SAGA,
  };
};

export const getImmunizations = (payload) => {
  return {
    type: immunizationActionTypes.GET_IMMUNIZATION_TYPES,
    payload,
  };
};

export const addImmunization = (payload) => {
  return {
    type: immunizationActionTypes.ADD_IMMUNIZATION_TYPE,
    payload,
  };
};

export const startAddingImmunization = (payload, callbackMap) => {
  return {
    type: immunizationActionTypes.ADD_IMMUNIZATION_TYPE_SAGA,
    payload,
    callbackMap,
  };
};

export const startDeletingImmunization = (id, callbackMap) => {
  return {
    type: immunizationActionTypes.DELETE_IMMUNIZATION_TYPE_SAGA,
    callbackMap,
    id,
  };
};

export const deleteImmunization = (id) => {
  return {
    type: immunizationActionTypes.DELETE_IMMUNIZATION_TYPE,
    payload: id,
  };
};

export const startEditingImmunization = (payload, callbackMap) => {
  return {
    type: immunizationActionTypes.UPDATING_IMMUNIZATION_TYPE_SAGA,
    callbackMap,
    payload,
  };
};

export const editImmunization = (id) => {
  return {
    type: immunizationActionTypes.UPDATING_IMMUNIZATION_TYPE,
    payload: id,
  };
};

export const requestFailed = () => {
  return {
    type: immunizationActionTypes.REQUEST_FAILED,
  };
};
