import React, { useEffect, useState } from "react";
import ClientCompanyProfile from "../../../components/Profiles/clientProfile/ClientCompanyProfile";
import {
  updateClientCompanyInfo,
  getDaycare,
} from "../../../redux/authentication/ClientRegistration/clientRegistration.actions";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../../i18n";

export default function ClientCompanyProfilePage() {
  const dispatch = useDispatch();
  const currentCompany = useSelector(
    (state) => state.clients.currentClientDaycare
  );
  useEffect(() => {
    document.title = i18n.t("company_profile");
    async function sendApiRequest() {
      dispatch(getDaycare());
    }
    sendApiRequest();
  }, []);

  const handleSubmitCompanyProfile = async (payload) => {
    let address2 = payload.address;
    payload.companyAddress = address2.displayAddress;
    let personalEmail = payload.companyEmail;
    let personalPhoneNumber = payload.phoneNo;
    let dataToSend = {};
    dataToSend.company = payload;
    dataToSend.address = address2;
    dataToSend.personalEmail = personalEmail;
    dataToSend.personalPhoneNumber = personalPhoneNumber;
    dispatch(
      updateClientCompanyInfo({
        data: dataToSend,
        _id: currentCompany._id,
      })
    );
  };

  return (
    <>
      {currentCompany.company && (
        <ClientCompanyProfile
          currentCompany={currentCompany.company}
          daycare={currentCompany}
          {...{ handleSubmitCompanyProfile }}
        />
      )}
    </>
  );
}
