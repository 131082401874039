import React, { Component } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import MyRoutes from "./routes";
import "./App.css";
import { withNamespaces } from "react-i18next";
import i18n from "./i18n";
import AppWrapper from "./components/AppWrapper/AppWrapper.component";
import ReduxLoader from "./components/ReduxLoader/ReduxLoader";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <AppWrapper>
          <ReduxLoader />
          <MyRoutes t={this.props.t} />
        </AppWrapper>
      </Provider>
    );
  }
}
export default withNamespaces()(App);
