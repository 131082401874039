import React from "react";
import {
  FormBody,
  FormBodyContainer,
} from "../TableGenerator/EditingModal/EditingModal.styles";
import FieldsGenerator from "../FieldsGenerator/FieldsGenerator";
import { Form } from "react-final-form";
import { Button } from "@material-ui/core";
import PrimaryButton from "../Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton/SecondaryButton";
import i18n from "../../../i18n";
export default function _Form({
  onSubmit,
  validateForm,
  schema,
  values,
  onCancel,
}) {
  return (
    <div style={{ padding: "8px 4px" }}>
      <Form
        onSubmit={(...args) => {
          onSubmit(...args);
        }}
        validate={validateForm}
        render={({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit} className="form-modal">
            <FormBodyContainer>
              <FormBody>
                <FieldsGenerator schema={schema} values={values} />
                <div className="buttons">
                  <SecondaryButton onClick={onCancel} disabled={submitting}>
                    {i18n.t("cancel")}
                  </SecondaryButton>
                  <PrimaryButton
                    type="submit"
                    disabled={submitting}
                    className="ml-1"
                  >
                    {i18n.t("submit")}
                  </PrimaryButton>
                </div>
              </FormBody>
            </FormBodyContainer>
          </form>
        )}
      />
    </div>
  );
}
