import { takeEvery, put, call } from "redux-saga/effects";
import { businessHoursActionTypes } from "./businessHours.types";
import { saveBusinessHours } from "./businessHours.actions";
import axios from "../../axios";
import SuccessHelper from "../../helpers/SuccessHelper";
import ErrorHelper from "../../helpers/ErrorHelper";
import i18n from "../../i18n";
const getData = async () => {
  const response = await axios.get("/businessHour", {});

  return response.data.businessHours;
};

export function* fetchBusinessHoursAsync() {
  try {
    const data = yield call(getData);
    yield put(saveBusinessHours(data));
  } catch (e) {
    console.log(e);
  }
}
const saveData = async ({ payload }) => {
  const response = await axios.put("/businessHour", payload);
  if (response.data.success) {
    SuccessHelper.handleSuccess(i18n.t("successfully_update"), true);
    return payload;
  }
  ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
};
export function* saveBusinessHoursAsync(payload) {
  try {
    const data = yield call(saveData, payload);
    yield put(saveBusinessHours(data));
  } catch (e) {
    console.log(e);
  }
}

export function* watchBusinessHours() {
  yield takeEvery(
    businessHoursActionTypes.START_GET_BUSINESS_HOURS,
    fetchBusinessHoursAsync
  );
  yield takeEvery(
    businessHoursActionTypes.BUSINESS_HOURS_SAGA,
    saveBusinessHoursAsync
  );
}
