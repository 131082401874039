import { takeEvery, put, call } from "redux-saga/effects";
import billingActionTypes from "./billing.types";

import {
  getInvoices,
  addInvoice,
  deleteInvoice,
  updateInvoice,
  requestFailed,
  getARAging,
} from "./billing.actions";

import axios from "../../axios";

const addRequest = async (payload) => {
  try {
    const response = await axios.post("/daycareInvoice", payload.payload);
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
    throw e;
  }
};

export function* addSaga(payload) {
  try {
    const response = yield call(addRequest, payload);
    yield put(addInvoice(response));
  } catch (e) {
    yield put(requestFailed());
  }
}

const getRequest = async (payload) => {
  try {
    const response = await axios.get("/daycareInvoice", {
      params: payload.params,
    });
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
    throw e;
  }
};

export function* getSaga(payload) {
  try {
    const response = yield call(getRequest, payload);
    yield put(getInvoices(response));
  } catch (e) {
    yield put(requestFailed());
  }
}

const deleteRequest = async (payload) => {
  try {
    const response = await axios.delete("/daycareInvoice", {
      params: payload.params,
    });
    payload?.callbackMap?.success(response);
    return response.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
    throw e;
  }
};

export function* deleteSaga(payload) {
  try {
    const response = yield call(deleteRequest, payload);
    yield put(deleteInvoice(response));
  } catch (e) {
    yield put(requestFailed());
  }
}

const updateRequest = async (payload) => {
  try {
    const response = await axios.put("/daycareInvoice", payload.payload);
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
    throw e;
  }
};

export function* updateSaga(payload) {
  try {
    const response = yield call(updateRequest, payload);
    yield put(updateInvoice(response));
  } catch (e) {
    yield put(requestFailed());
  }
}

const getARRequest = async (payload) => {
  try {
    const response = await axios.get("/daycareInvoice/ARAging", {
      params: payload.params,
    });
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
    throw e;
  }
};

export function* getARSaga(payload) {
  try {
    const response = yield call(getARRequest, payload);
    yield put(getARAging(response));
  } catch (e) {
    yield put(requestFailed());
  }
}

export default function* watch() {
  yield takeEvery(billingActionTypes.ADD_INVOICE_SAGA, addSaga);
  yield takeEvery(billingActionTypes.GET_INVOICE_SAGA, getSaga);
  yield takeEvery(billingActionTypes.DELETE_INVOICE_SAGA, deleteSaga);
  yield takeEvery(billingActionTypes.EDIT_INVOICE_SAGA, updateSaga);
  yield takeEvery(billingActionTypes.GET_AR_AGING_SAGA, getARSaga);
}
