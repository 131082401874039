import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import {
  DoctorsWrapper,
  ConditionCard,
  DoctorsControls,
  Back,
  Actions,
} from "../Medical.styles";
import {
  startAllergyFetching,
  startEditingAllergy,
  startAddingAllergy,
  startDeletingAllergy,
} from "../../../../redux/allergies/allergies.actions";

import { startDeletingKidAllergy } from "../../../../redux/kids/kids.actions";
import callbackHelper from "../../../../helpers/callbackHelper";

import AddCondition from "./AddAllergy";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { selectAllergiessData } from "../../../../redux/allergies/allergies.selectors";
import { createStructuredSelector } from "reselect";
import { connect, useSelector } from "react-redux";
import i18n from "../../../../i18n";
// </DoctorsWrapper>
function Allergies(props) {
  const [modalState, setModalState] = useState({ edit: false, add: false });
  const kids = useSelector((s) => s.kids.kids);
  const handleModalClose = (state) => {
    const newState = { ...modalState };
    newState[state] = false;
    setModalState(newState);
  };
  useEffect(() => {
    props.startAllergyFetching(null, props.kid._id);
  }, []);
  const activeKid = useMemo(() => {
    return kids.find((kid) => kid._id == props.kid._id);
  }, [props.kid, kids]);

  const handleConditionDelete = (allergy) => {
    callbackHelper(
      props.startDeletingKidAllergy,
      { _id: allergy._id, kidId: props.kid._id },
      "delete"
    );
  };

  const handleAddCondition = () => {
    setModalState({ ...modalState, add: true });
  };
  return (
    <>
      <DoctorsWrapper>
        <DoctorsControls>
          <Back onClick={() => props.handleCategory("main")}>
            <KeyboardBackspaceIcon />
          </Back>
          <Back>
            <AddIcon onClick={() => handleAddCondition()} />
          </Back>
        </DoctorsControls>
        <h3>{i18n.t("allergies")}</h3>
        {(activeKid.allergies || []).map((allergy) => {
          return (
            <>
              <ConditionCard key={allergy._id}>
                <div>{allergy.allergyId.name}</div>
                <div>{allergy.allergyId.description}</div>

                <Actions>
                  <DeleteForeverIcon
                    onClick={() => handleConditionDelete(allergy)}
                  />
                </Actions>
              </ConditionCard>
            </>
          );
        })}
        {modalState.add && (
          <AddCondition
            open={modalState.add}
            handleModalClose={(state) => handleModalClose(state)}
            kid={activeKid}
          />
        )}
      </DoctorsWrapper>
    </>
  );
}
const mapDispatchToProps = (dispatch) => ({
  startAllergyFetching: (callbackMap, kidId) =>
    dispatch(startAllergyFetching(callbackMap, kidId)),
  startDeletingAllergy: (callbackMap, id) =>
    dispatch(startDeletingAllergy(callbackMap, id)),
  startDeletingKidAllergy: (callbackMap, id) =>
    dispatch(startDeletingKidAllergy(callbackMap, id)),
  startEditingAllergy: (callbackMap, ...args) =>
    dispatch(startEditingAllergy(callbackMap, ...args)),
  startAddingAllergy: (callbackMap, ...args) =>
    dispatch(startAddingAllergy(callbackMap, ...args)),
});

const mapStateToProps = createStructuredSelector({
  allergies: selectAllergiessData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Allergies);
