import { TextField } from "@material-ui/core";
import ColorPicker from "material-ui-color-picker";
import React from "react";
import { Field, Form } from "react-final-form";
import Select from "../Select";
import TextArea from "../TextArea/TextArea";
import PlacesAutocomplete from "../PlacesAutocomplete/PlacesAutoComplete";
import Autocomplete from "../Autocomplete/Autocomplete";
import DollarInput from "../DollarInput/DollarInput";
import Datepicker from "../Datepicker/Datepicker";
import i18n from "../../../i18n";

export default function Modal({ schema = [], values = {} }) {
  const renderFieldType = (
    { input, meta },
    title,
    fieldType,
    styles,
    options = [],
    defaultAddress,
    handleChange
  ) => {
    const onChange = (...e) => {
      handleChange(...e);
      input.onChange(...e);
    };
    const error = meta.error && meta.touched;
    switch (fieldType) {
      case "text": {
        return (
          <TextField
            className={styles}
            {...input}
            label={title}
            type={fieldType}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            error={error}
            helperText={meta.error}
            onChange={onChange}
          />
        );
      }
      case "number": {
        return (
          <TextField
            className={styles}
            {...input}
            label={title}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            error={error}
            helperText={meta.error}
            onChange={onChange}
          />
        );
      }
      case "textarea": {
        return (
          <TextArea
            className={styles}
            {...input}
            label={title}
            fullWidth
            error={error}
            helperText={meta.error}
            onChange={onChange}
          />
        );
      }
      case "color": {
        return (
          <ColorPicker
            className={styles}
            {...input}
            label={title}
            name="color"
            defaultValue="#000"
            fullWidth
            error={error}
            helperText={meta.error}
            TextFieldProps={{
              value: input.value,
            }}
            onChange={onChange}
          />
        );
      }
      case "address": {
        return (
          <PlacesAutocomplete
            defaultAddress={defaultAddress?.displayAddress}
            name="address"
            input={input}
            onChange={onChange}
          />
        );
      }
      case "select": {
        return (
          <Select
            {...input}
            fullWidth
            options={options}
            label={title}
            error={error && meta.error}
            onChange={onChange}
          />
        );
      }
      case "yesNoSelect": {
        let copy = { ...input };
        if (!copy.value) {
          copy.value = false;
        }
        return (
          <Select
            {...copy}
            fullWidth
            options={[
              { value: true, name: i18n.t("yes") },
              { value: false, name: i18n.t("no") },
            ]}
            defaultValue={false}
            excludeEmptyValue
            label={title}
            error={error}
            helperText={meta.error}
            onChange={onChange}
          />
        );
      }
      case "multiAutocomplete": {
        return (
          <Autocomplete
            {...input}
            multiple
            label={title}
            options={options}
            error={error}
            helperText={meta.error}
            onChange={onChange}
          />
        );
      }
      case "dollar": {
        const error = meta.touched && meta.error;
        return (
          <DollarInput
            {...input}
            label={title}
            error={error}
            helperText={error ? meta.error : null}
            onChange={onChange}
          />
        );
      }
      case "date": {
        const error = meta.touched && meta.error;
        return (
          <Datepicker
            {...input}
            label={title}
            selected={input.value}
            fullWidth
            error={error}
            helperText={error ? meta.error : null}
            onChange={onChange}
          />
        );
      }
      default:
        return null;
    }
  };

  const renderField = (
    { value, title, fieldType, styles, options, onChange = () => {} },
    index
  ) => {
    return (
      <Field key={`addItem-${index}`} name={value} initialValue={values[value]}>
        {(data) =>
          renderFieldType(
            data,
            title,
            fieldType,
            styles,
            options,
            values[value],
            onChange
          )
        }
      </Field>
    );
  };

  return (
    <div className="fields-generator">
      {schema.map((element, index) => {
        return renderField(element, index);
      })}
    </div>
  );
}
