import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import _ from "lodash";

import { EditFields, EditActions, DoctorsItems } from "../Medical.styles";

import { startEditingDoctor } from "../../../../redux/doctors/doctors.actions";
import { connect } from "react-redux";

import callbackHelper from "../../../../helpers/callbackHelper";
import i18n from "../../../../i18n";
import PrimaryButton from "../../../Core/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../../Core/Buttons/SecondaryButton/SecondaryButton";
import Modal from "../../../Core/Modal/Modal";
import PlacesAutocomplete from "../../../Core/PlacesAutocomplete/PlacesAutoComplete";

function EditDoctor({
  open,
  handleModalClose,
  currentEditingDoc,
  startEditingDoctor,
}) {
  const doctorItemsMap = [
    { title: i18n.t("doctor_name"), value: "name" },
    { title: i18n.t("email"), value: "email" },
    { title: i18n.t("address"), value: "address" },
    { title: i18n.t("profession"), value: "profession" },
    { title: i18n.t("work_phone"), value: "workPhone" },
    { title: i18n.t("cell_phone"), value: "cellPhone" },
    { title: i18n.t("other_phone"), value: "otherPhone" },
  ];
  const onSubmit = (values) => {
    console.log({ ...values, _id: currentEditingDoc._id });
    callbackHelper(
      startEditingDoctor,
      { data: values, _id: currentEditingDoc._id },
      "update"
    );
    // update redux
    handleModalClose("edit");
  };
  const handleEditModalCancel = () => {
    handleModalClose("edit");
  };
  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = i18n.t("required");
    }
    if (!values.email) {
      errors.email = i18n.t("required");
    }
    if (!values.workPhone) {
      errors.workPhone = i18n.t("required");
    }
    return errors;
  };
  return (
    <Modal
      title={i18n.t("edit_doctor")}
      open={open}
      closeModal={() => handleEditModalCancel()}
    >
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <EditFields>
              {doctorItemsMap.map(({ value, title }, i) => {
                console.log({ currentEditingDoc });
                // name == "address" ? daycare.address : currentCompany[value]
                return (
                  <>
                    <Field
                      name={value}
                      key={`edit-doctor-${i}`}
                      initialValue={currentEditingDoc[value]}
                      render={({ input, meta }) => {
                        const error = meta.touched && meta.error;
                        switch (value) {
                          case "address":
                            return (
                              <DoctorsItems type={value} className="mb-2">
                                <PlacesAutocomplete
                                  name="address"
                                  input={input}
                                  defaultAddress={
                                    currentEditingDoc[value].displayAddress
                                  }
                                />
                              </DoctorsItems>
                            );

                          default:
                            return (
                              <DoctorsItems type={value} className="mb-2">
                                <TextField
                                  label={title}
                                  fullWidth
                                  {...input}
                                  error={error}
                                  helperText={error ? error : ""}
                                />
                              </DoctorsItems>
                            );
                        }
                        // return (
                        //   <DoctorsItems type={value} className="mb-2">
                        //     <TextField
                        //       label={title}
                        //       fullWidth
                        //       {...input}
                        //       error={error}
                        //       helperText={error ? error : ""}
                        //     />
                        //   </DoctorsItems>
                        // );
                      }}
                    />
                  </>
                );
              })}
            </EditFields>
            <EditActions>
              <SecondaryButton onClick={() => handleEditModalCancel()}>
                {i18n.t("cancel")}
              </SecondaryButton>
              <PrimaryButton className="ml-1" type="submit">
                {i18n.t("submit")}
              </PrimaryButton>
            </EditActions>
          </form>
        )}
      />
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => ({
  startEditingDoctor: (callbackMap, ...args) =>
    dispatch(startEditingDoctor(callbackMap, ...args)),
});

export default connect(null, mapDispatchToProps)(EditDoctor);
