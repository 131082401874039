import React from "react";
import ListItem from "../../Core/listItem/ListItem";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import PersonIcon from "@material-ui/icons/Person";
import {
  openEmployeesModal,
  startDeletingEmployee,
} from "../../../redux/employees/employees.actions";
import { useDispatch } from "react-redux";
import DeleteHelper from "../../../helpers/DeleteHelper";
import i18n from "../../../i18n";
export default function EmployeeItem({ employee }) {
  const dispatch = useDispatch();

  const handleEmployeeDelete = (id) => {
    DeleteHelper.handleDelete(i18n.t("employee_delete_message"), () => {
      dispatch(startDeletingEmployee(id));
    });
  };

  return (
    <ListItem
      title={`${employee["firstName"]} ${employee["lastName"]} `}
      itemId={employee["_id"]}
      onOpen={(id) => dispatch(openEmployeesModal(id))}
      onDelete={(id) => handleEmployeeDelete(id)}
    >
      <PersonIcon style={{ fontSize: 30 }} />
      <DeleteForeverIcon style={{ fontSize: 25 }} />
    </ListItem>
  );
}
