import { employeesActionTypes } from "./employees.types";
import { normalize } from "../../helpers/normalize";

const initialState = {
  employees: [],
  isLoading: false,
  allEmployees: [],
  employee_modal: false,
  currentEmployee: {},
  addEmployeeModal: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case employeesActionTypes.GET_EMPLOYEES: {
      return { ...state, isLoading: true };
    }
    case employeesActionTypes.SAVE_EMPLOYEES: {
      return {
        ...state,
        employees: payload,
        allEmployees: payload,
        isLoading: false,
      };
    }
    case employeesActionTypes.DELETE_EMPLOYEE_SAGA: {
      return { ...state, isLoading: true };
    }
    case employeesActionTypes.DELETE_EMPLOYEE: {
      const updatedState = state.employees;

      const toUpdate = updatedState.filter(
        (employee) => employee._id != payload
      );
      return { ...state, employees: toUpdate, isLoading: false };
    }

    case employeesActionTypes.EDIT_EMPLOYEE: {
      let toUpdate;
      const currentState = state.employees;
      if (payload.status === "Active") {
        toUpdate = currentState.map((employee) => {
          return employee._id == payload._id ? payload : employee;
        });
      } else {
        toUpdate = currentState.filter((employee) => {
          return employee._id !== payload._id && employee;
        });
      }

      return {
        ...state,
        employees: toUpdate,
        isLoading: false,
        employee_modal: false,
        currentEmployee: {},
      };
    }

    case employeesActionTypes.EDIT_EMPLOYEE_SAGA: {
      return { ...state, isLoading: true };
    }

    case employeesActionTypes.ADD_EMPLOYEE: {
      let toUpdate;
      if (payload.status == "Active") {
        const currentState = state.employees;
        toUpdate = [...currentState, payload];
      } else {
        toUpdate = state.employees;
      }
      return {
        ...state,
        employees: toUpdate,
        addEmployeeModal: false,
        isLoading: false,
      };
    }

    case employeesActionTypes.ADD_EMPLOYEE_SAGA: {
      return { ...state, isLoading: true };
    }
    case employeesActionTypes.UPDATE_EMPLOYEE_SCHEDULE: {
      const currentState = state.employees;
      const toUpdate = currentState.map((employee) => {
        return employee._id == payload._id ? payload : employee;
      });
      return { ...state, employees: toUpdate, isLoading: false };
    }
    case employeesActionTypes.UPDATE_EMPLOYEE_SCHEDULE_SAGA: {
      return { ...state, isLoading: true };
    }
    case employeesActionTypes.OPEN_EMPLOYEE_MODAL: {
      const currentEmployee = state.employees.find(
        ({ _id }) => _id === payload
      );

      return { ...state, employee_modal: true, currentEmployee };
    }
    case employeesActionTypes.CLOSE_EMPLOYEE_MODAL: {
      return {
        ...state,
        employee_modal: false,
        currentEmployee: {},
      };
    }

    case employeesActionTypes.CLOSE_ADD_EMPLOYEE_MODAL: {
      return {
        ...state,
        addEmployeeModal: false,
      };
    }

    case employeesActionTypes.OPEN_ADD_EMPLOYEE_MODAL: {
      return {
        ...state,
        addEmployeeModal: true,
      };
    }

    case employeesActionTypes.CLEAR_SEARCH: {
      return {
        ...state,
        employees: [...state.allEmployees],
      };
    }

    case employeesActionTypes.FILTER_EMPLOYEES: {
      let employees = [...state.employees];
      const toUpdate = employees.filter((employee) => {
        if (
          normalize(employee.firstName).includes(normalize(payload)) === true ||
          normalize(employee.lastName).includes(normalize(payload)) === true
        ) {
          return employee;
        }
      });
      return {
        ...state,
        employees: toUpdate,
      };
      // return { ...state, employees: toUpdate, isLoading: false };
    }

    default:
      return state;
  }
};
