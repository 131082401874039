import styled from "styled-components/macro";

export const PreviewNotesContainer = styled.div`
  margin: auto;
`;

export const DeleteNote = styled.div`
  position: absolute;
  right: 45px;
  cursor: pointer;
`;
