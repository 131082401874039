import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startAddingEmployee } from "../../../redux/employees/employees.actions";
import _ from "lodash";
import { uniqueId } from "lodash";
import { EmployeeWrapper, EmployeeItem, FormWrapper } from "../Employee.styles";
import Button from "../../Core/Buttons/PrimaryButton/PrimaryButton";
import { Form, Field } from "react-final-form";
import { TextField } from "@material-ui/core";
import EditButton from "../../Core/Buttons/Add/AddButton";
import Select from "../../Core/Select/index";
import i18n from "../../../i18n";

export default function AddEmployee() {
  const EmployeeItems = [
    { value: "firstName", title: i18n.t("first_name") },
    { value: "lastName", title: i18n.t("last_name") },
    { value: "email", title: i18n.t("email_address") },
    { value: "phoneNo", title: i18n.t("phone_number") },
    { value: "status", title: i18n.t("status") },
  ];

  const dispatch = useDispatch();
  const onSubmit = (values) => {
    dispatch(startAddingEmployee(values));
  };
  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = i18n.t("required");
    }
    if (!values.lastName) {
      errors.lastName = i18n.t("required");
    }
    if (!values.email) {
      errors.email = i18n.t("required");
    }
    if (!values.phoneNo) {
      errors.phoneNo = i18n.t("required");
    }
    return errors;
  };

  useEffect(() => {
    document.title = i18n.t("add_employee");
  }, []);

  const renderAddEmployeeForm = () => {
    return EmployeeItems.map(({ value, title }) => {
      return (
        <Field
          name={value}
          render={({ input, meta }) => {
            switch (value) {
              case "status":
                return (
                  <EmployeeItem type={value}>
                    <Select
                      {...input}
                      label={title}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      helperText={meta.touched && meta.error}
                      options={[
                        { name: i18n.t("active"), value: "Active" },
                        { name: i18n.t("inactive"), value: "Inactive" },
                      ]}
                    />
                  </EmployeeItem>
                );

              default:
                return (
                  <EmployeeItem type={value}>
                    <TextField
                      {...input}
                      label={title}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={meta.touched && meta.error}
                      fullWidth
                    />
                  </EmployeeItem>
                );
            }
          }}
        />
      );
    });
  };
  return (
    <EmployeeWrapper>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <FormWrapper onSubmit={handleSubmit}>
            {renderAddEmployeeForm()}
            <EmployeeItem
              style={{ minHeight: "3em", gridColumn: 4, justifyContent: "end" }}
            >
              <Button type="submit">{i18n.t("submit")}</Button>
            </EmployeeItem>
          </FormWrapper>
        )}
      />
    </EmployeeWrapper>
  );
}
