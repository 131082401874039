import React from "react";
import { TextField } from "@material-ui/core";

export default (props) => {
  const {
    id,
    label,
    value,
    defaultValue,
    onChange = () => {},
    error,
    fullWidth,
    rows = 3,
  } = props;

  return (
    <TextField
      {...props}
      label={label}
      labelId={label}
      id={id ? id : `${label}-text-area`}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      fullWidth={fullWidth}
      multiline
      rows={rows}
      error={!!error}
      helperText={error}
    />
  );
};
