import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Field } from "react-final-form";
import {
  saveImmunization,
  startImmunizationFetching,
  startDeletingImmunization,
} from "../../../redux/Immunization/immunization.actions";
import {
  startImmunizationFetching as startImmunizationTypeFetching,
  startAddingImmunization as startAddingImmunizationType,
} from "../../../redux/immunizationType/immunizationType.actions";

import {
  ImmunizationRow,
  ImmunizationCell,
  ImmunizationCellTitle,
  ImmunizationForm,
  ImmunizationButtonGroup,
  AddWrapper,
} from "./immunizations.styles";
import TextField from "@material-ui/core/TextField";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Button from "@material-ui/core/Button";
import CreatableSelect from "react-select/creatable";

import ErrorHelper from "../../../helpers/ErrorHelper";
import SuccessHelper from "../../../helpers/SuccessHelper";
import i18n from "../../../i18n";

import _ from "lodash";
const useStyles = makeStyles({
  addModal: {
    background: "white",
    height: "60vh",
    width: "50%",
    margin: "auto",

    // marginRight: "10px",
    // "&:hover": {
    //   color: "#f00",
    //   cursor: "pointer",
    // },
  },
  addButton: {
    marginBottom: "20px",
  },
});
const createOption = (label) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ""),
});

export default function Immunization(props) {
  const [addVax, setAddVax] = useState(false);
  const dispatch = useDispatch();

  const immunizations = useSelector((s) => {
    return s.immunization.immunizations;
  });

  const [immunizationData, setimmunizationData] = useState(immunizations);
  const [immunizationTypes, immunizationTypeMap] = useSelector((s) => {
    const map = {};
    return [
      s.immunizationType.immunizationTypes.map((element) => {
        map[element._id] = element.name;
        return {
          label: element.name,
          value: element._id,
        };
      }),
      map,
    ];
  });
  const [currentVax, setCurrentVax] = useState(null);
  //   useEffect()
  useEffect(() => {
    dispatch(startImmunizationFetching());
    dispatch(startImmunizationTypeFetching());
  }, []);

  useEffect(() => {
    setimmunizationData(immunizations);
  }, [immunizations]);
  const classes = useStyles();

  const handleAddNewVax = () => {
    const newState = [...immunizationData];
    const newVax = {
      firstDose: "",
      secondDose: "",
      thirdDose: "",
      fourthDose: "",
      fifthDose: "",
      sixthDose: "",
      immunizationTypeId: currentVax.value,
    };
    setimmunizationData([...immunizationData, newVax]);
    // const toUpdate = newState.filter((s) => (s._id == merge._id ? merge : s));
  };

  const submitImmunization = (value, data) => {
    value.immunizationTypeId = data.immunizationTypeId;
    if (data._id) value._id = data._id;
    dispatch(saveImmunization({ ...value, kidId: props.kid._id }));
  };
  const handleCreate = (inputValue) => {
    const newOption = createOption(inputValue);
    dispatch(startAddingImmunizationType({ name: inputValue }));
  };
  const handleVaxOptions = (newValue) => {
    setCurrentVax(newValue);
  };

  const deleteImmunization = (data) => {
    new Promise((success, failure) => {
      SuccessHelper.handleDelete(i18n.t("are_you_sure"), (result) => {
        if (result.isConfirmed) {
          dispatch(startDeletingImmunization({ success, failure }, data._id));
        }
      });
    })
      .then((res) => {
        SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true);
      })
      .catch((err) => {
        // this.setState({ isloading: false });
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  };
  console.log(immunizationTypeMap);
  const renderImmunization = () => {
    return (
      <>
        <CreatableSelect
          isClearable
          // isDisabled={isLoading}
          // isLoading={isLoading}
          onChange={handleVaxOptions}
          onCreateOption={handleCreate}
          options={immunizationTypes}
          value={currentVax}
        />
        <Button
          className={classes.addButton}
          variant="contained"
          color="primary"
          onClick={() => handleAddNewVax()}
        >
          ADD new
        </Button>
        {immunizationData.map((data) => {
          console.log(data, "immunizationData");
          return (
            <Form
              onSubmit={(value) => submitImmunization(value, data)}
              render={({ handleSubmit }) => (
                <ImmunizationForm onSubmit={handleSubmit}>
                  <ImmunizationCellTitle>
                    {immunizationTypeMap?.[data["immunizationTypeId"]]}
                  </ImmunizationCellTitle>
                  <ImmunizationRow>
                    {Object.keys(
                      _.omit(data, "_id", "name", "kidId", "__v", "status")
                    ).map((d) => {
                      return (
                        <Field
                          name={d}
                          initialValue={data[d]}
                          render={({ input, meta }) => (
                            <ImmunizationCell>
                              <span>
                                {d} {i18n.t("dose ")}
                              </span>
                              <TextField fullWidth type="date" {...input} />
                              {meta.touched && meta.error && (
                                <span>{meta.error}</span>
                              )}
                            </ImmunizationCell>
                          )}
                        />
                      );
                    })}
                  </ImmunizationRow>
                  <ImmunizationButtonGroup>
                    <button
                      style={{
                        background: "none",
                        color: "inherit",
                        border: "none",
                        padding: 0,
                        font: "inherit",
                        cursor: "pointer",
                        outline: "inherit",
                      }}
                      type="submit"
                    >
                      <DoneAllIcon />
                    </button>
                    <DeleteForeverIcon
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      onClick={() => deleteImmunization(data)}
                    />
                  </ImmunizationButtonGroup>
                </ImmunizationForm>
              )}
            />
          );
        })}
        {/* <div onClick={submitImmunization}>submit</div> */}
      </>
    );
    // return immunizations.map((immunization) => <h3>{immunization.type}</h3>);
  };
  return renderImmunization();
}
