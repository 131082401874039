import { createSelector } from "reselect";

const selectTestState = (state) => state.activities;

export const selectCartItems = createSelector(
  [selectTestState],
  (activities) => activities
);

export const selectActivitiesData = createSelector(
  [selectTestState],
  (activities) => activities.activities
);
