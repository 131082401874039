import { createSelector } from "reselect";

const selectTestState = (state) => state.conditions;

export const selectCartItems = createSelector(
  [selectTestState],
  (conditions) => conditions
);

export const selectConditionsData = createSelector(
  [selectTestState],
  (conditions) => conditions.conditions
);
