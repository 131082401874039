import { takeEvery, put, call } from "redux-saga/effects";
import { activitiesActionTypes } from "./activities.types";
import {
  saveActivities,
  deleteActivity,
  editActivity,
  addActivity,
  requestFailed,
} from "./activities.actions";
import axios from "../../axios";
const getData = async (payload) => {
  try {
    const response = await axios.get("./activity", {});
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* fetchActivitiesAsync(payload) {
  try {
    const data = yield call(getData, payload);
    yield put(saveActivities(data));
  } catch (e) {
    yield put(requestFailed());
  }
}

const deleteData = async (payload) => {
  try {
    const response = await axios.delete("/activity", {
      params: { ids: payload.activityId },
    });
    payload?.callbackMap?.success(response);
    return response.data._id;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* deleteActivityAsync(payload) {
  try {
    const deleteVal = yield call(deleteData, payload);
    console.log({ deleteVal });
    yield put(deleteActivity(deleteVal));
  } catch (e) {
    yield put(requestFailed());
  }
}

const editData = async (payload) => {
  try {
    const response = await axios.put("/activity", {
      _id: payload.payload._id,
      data: payload.payload.data,
    });
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* editActivityAsync(payload) {
  try {
    const responseData = yield call(editData, payload);
    yield put(editActivity(responseData));
  } catch (e) {
    yield put(requestFailed());
  }
}

const addData = async (payload) => {
  try {
    const response = await axios.post("/activity", payload.payload);
    payload?.callbackMap?.success(response);
    return response.data.data;
  } catch (e) {
    payload?.callbackMap?.failure(e);
  }
};

export function* addActivityAsync(payload) {
  try {
    const responseData = yield call(addData, payload);
    yield put(addActivity(responseData));
  } catch (e) {
    yield put(requestFailed());
  }
}
export function* watchActivities() {
  yield takeEvery(activitiesActionTypes.GET_ACTIVITIES, fetchActivitiesAsync);
  yield takeEvery(
    activitiesActionTypes.DELETE_ACTIVITY_SAGA,
    deleteActivityAsync
  );

  yield takeEvery(activitiesActionTypes.EDIT_ACTIVITY_SAGA, editActivityAsync);

  yield takeEvery(activitiesActionTypes.ADD_ACTIVITY_SAGA, addActivityAsync);
}
