import { attendanceActionTypes } from "./attendance.types";

const initialState = {
  data: [],
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case attendanceActionTypes.GET_ATTENDANCE_SAGA:
    case attendanceActionTypes.SAVE_ATTENDANCE_SAGA: {
      return { ...state, isLoading: true };
    }
    case attendanceActionTypes.GET_ATTENDANCE: {
      return { ...state, data: payload, isLoading: false };
    }
    case attendanceActionTypes.SAVE_ATTENDANCE: {
      return { ...state, data: payload, isLoading: false };
    }
    case attendanceActionTypes.REQUEST_FAILED: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
