import React, { Component, useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import i18n from "../../../i18n";
import ErrorHelper from "../../../helpers/ErrorHelper";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components/macro";

const AddressSuggestions = styled.div`
  position: flex;
  background-color: aliceblue;
  padding: 10px;
  width: 100%;
  &:hover {
    background-color: #8055b5;
  }
`;
const AddressItem = styled.span`
  cursor: pointer;
`;

const renameProperty = (obj, oldPropertyName, newPropertyName) => {
  Object.defineProperty(
    obj,
    newPropertyName,
    Object.getOwnPropertyDescriptor(obj, oldPropertyName)
  );
  delete obj[oldPropertyName];
};

export default function CustomPlacesAutocomplete({
  addressSelected,
  defaultAddress = "",
  className,
  helperText,
  addressError,
  error,
  name,
  input,
}) {
  const [address, setAddress] = useState("");
  const [addressMap, setAddressMap] = useState({});
  useEffect(() => {
    setAddress(defaultAddress);
  }, []);

  const onAddressSelect = (address) => {
    const requireComponentsMap = {
      street_number: true,
      route: true,
      locality: true,
      administrative_area_level_1: true,
      country: true,
      postal_code: true,
    };
    let addressMap = {
      street_number: undefined,
      route: undefined,
      locality: undefined,
      administrative_area_level_1: undefined,
      country: undefined,
      postal_code: undefined,
    };
    geocodeByAddress(address)
      .then((results) => {
        const shortNameMap = {};
        results[0].address_components.map((component) => {
          const relevant = component.types.find(
            (type) => requireComponentsMap[type]
          );
          if (relevant && !addressMap[relevant]) {
            addressMap[relevant] = component.long_name;
            shortNameMap[relevant] = component.short_name;
          }
        });

        if (addressMap.street_number && addressMap.route) {
          const newAddress = { ...addressMap };
          renameProperty(newAddress, "administrative_area_level_1", "province");
          renameProperty(newAddress, "locality", "city");
          getLatLng(results[0]).then((latLng) => {
            const geoLocationsAdded = _.merge(newAddress, latLng);
            setAddress(results[0].formatted_address);
            geoLocationsAdded.displayAddress = results[0].formatted_address;
            input.onChange(geoLocationsAdded);
            setAddressMap(geoLocationsAdded);
          });
        } else {
          ErrorHelper.handleErrors(i18n.t("invalid_address"), true);
        }
      })
      .catch((error) => console.error("Error", error));
  };

  return (
    <PlacesAutocomplete
      value={address}
      id="address"
      onChange={(e) => {
        setAddress(e);
      }}
      onSelect={(e) => {
        onAddressSelect(e);
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <>
          <TextField
            label={i18n.t("address")}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            value={addressMap}
            name={name}
            {...getInputProps({
              className: "w-100",
            })}
            helperText={addressError}
            error={!!addressError}
          />

          <div>
            {suggestions.map((suggestion) => {
              return (
                <AddressSuggestions
                  key={uuidv4()}
                  {...getSuggestionItemProps(suggestion)}
                >
                  <AddressItem>{suggestion.description}</AddressItem>
                </AddressSuggestions>
              );
            })}
          </div>
        </>
      )}
    </PlacesAutocomplete>
  );
}
