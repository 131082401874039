import { TextField } from "@material-ui/core";
import axios from "../../axios";
import React, { Component } from "react";
import { Field, Form } from "react-final-form";
import PrimaryButton from "../../components/Core/Buttons/PrimaryButton/PrimaryButton";

import ErrorHelper from "../../helpers/ErrorHelper";
import SuccessHelper from "../../helpers/SuccessHelper";
import i18n from "../../i18n";

export default class Security extends Component {
  state = {
    isLoading: false,
  };
  componentDidMount() {
    document.title = i18n.t("security");
  }

  changePassword = (values) => {
    console.log(values);
    this.setState({ isLoading: true });
    axios
      .post("/daycare/changePassword", values)
      .then(() => {
        SuccessHelper.handleToastSuccess("password_changed");
      })
      .catch(() => {
        ErrorHelper.handleErrors("something_went_wrong");
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  validate = (values) => {
    const errors = {};

    if (!values.password) {
      errors.password = i18n.t("required");
    }
    if (!values.newPassword) {
      errors.newPassword = i18n.t("required");
    }
    if (!values.confirmNewPassword) {
      errors.confirmNewPassword = i18n.t("required");
    } else if (values.newPassword != values.confirmNewPassword) {
      errors.newPassword = i18n.t("passwords_must_match");
      errors.confirmNewPassword = i18n.t("passwords_must_match");
    }

    return errors;
  };

  render() {
    return (
      <div className="Page" style={{ width: "88%", margin: "auto" }}>
        <div className="row justify-content-between align-items-center">
          <div className="title">{i18n.t("change_password")}</div>
        </div>
        <Form
          onSubmit={this.changePassword}
          validate={this.validate}
          render={({ handleSubmit }) => (
            <>
              <Field
                name="password"
                render={({ input, meta }) => {
                  const error = meta.error && meta.touched;
                  return (
                    <TextField
                      {...input}
                      className="mt-2"
                      label={i18n.t("old_password")}
                      type="password"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      error={error}
                      helperText={error && meta.error}
                    />
                  );
                }}
              />
              <Field
                name="newPassword"
                render={({ input, meta }) => {
                  const error = meta.error && meta.touched;
                  return (
                    <TextField
                      {...input}
                      className="mt-2"
                      label={i18n.t("new_password")}
                      type="password"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      error={error}
                      helperText={error && meta.error}
                    />
                  );
                }}
              />
              <Field
                name="confirmNewPassword"
                render={({ input, meta }) => {
                  const error = meta.error && meta.touched;
                  return (
                    <TextField
                      {...input}
                      className="mt-2"
                      label={i18n.t("confirm_new_password")}
                      type="password"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      error={error}
                      helperText={error && meta.error}
                    />
                  );
                }}
              />
              <div className="row justify-content-end mt-2">
                <PrimaryButton onClick={handleSubmit}>
                  {i18n.t("submit")}
                </PrimaryButton>
              </div>
            </>
          )}
        />
      </div>
    );
  }
}
