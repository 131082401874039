import { eventsActionTypes } from "./events.types";

export const saveEvents = (events) => {
  return {
    type: eventsActionTypes.SAVE_EVENTS,
    payload: events,
  };
};

export const startEventFetching = (params) => {
  return {
    type: eventsActionTypes.GET_EVENTS,
    params,
  };
};

export const startDeletingEvent = (id) => {
  return {
    type: eventsActionTypes.DELETE_EVENT_SAGA,
    eventId: id,
  };
};

export const deleteEvent = (id) => {
  return {
    type: eventsActionTypes.DELETE_EVENT,
    payload: id,
  };
};

export const startEditingEvent = (payload) => {
  return {
    type: eventsActionTypes.EDIT_EVENT_SAGA,
    payload,
  };
};

export const editEvent = (payload) => {
  return {
    type: eventsActionTypes.EDIT_EVENT,
    payload,
  };
};

export const startAddingEvent = (payload) => {
  return {
    type: eventsActionTypes.ADD_EVENT_SAGA,
    payload,
  };
};

export const addEvent = (payload) => {
  return {
    type: eventsActionTypes.ADD_EVENT,
    payload,
  };
};

export const startSettingEventKids = (payload, callbackMap) => {
  return {
    type: eventsActionTypes.SET_EVENT_KIDS_SAGA,
    payload,
    callbackMap,
  };
};

export const setEventKids = (payload) => {
  return {
    type: eventsActionTypes.SET_EVENT_KIDS,
    payload,
  };
};

export const setActiveEvent = (payload) => {
  return {
    type: eventsActionTypes.SET_ACTIVE_EVENT,
    payload,
  };
};

export const clearActiveEvent = (payload) => {
  return {
    type: eventsActionTypes.CLEAR_ACTIVE_EVENT,
    payload,
  };
};
