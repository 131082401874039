import React, { Component } from "react";
import { Form } from "react-final-form";
import Button from "../../../components/Core/Buttons/PrimaryButton/PrimaryButton";
import {
  submitClientPersonalInfo,
  renderClientPersonalInfoFields,
} from "./clientPersonalReg.helpers";
import { ButtonsWrapper } from "../ClientCompanySignUp/clientCompanyReg.styles";
import {
  ClientRegistration,
  ClientRegForm,
  FormItemsGrid,
} from "./clientPersonalReg.styles";
import { validateEmail } from "../../../helpers/isEmail";
import i18n from "../../../i18n";
export default class ClientPersonalRegistration extends Component {
  componentDidMount() {
    document.title = i18n.t("client_registration");
  }
  validateClientPersonalInfo = (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = i18n.t("required");
    }
    if (!values.lastName) {
      errors.lastName = i18n.t("required");
    }
    if (!values.email) {
      errors.email = i18n.t("required");
    }
    if (values.email && !validateEmail(values.email)) {
      errors.email = i18n.t("invalid_email");
    }
    if (!values.phoneNo) {
      errors.phoneNo = i18n.t("required");
    }
    if (!values.password) {
      errors.password = i18n.t("required");
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = i18n.t("required");
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = i18n.t("passwords_must_match");
    }
    if (!values.address) {
      errors.address = i18n.t("required");
    }
    return errors;
  };
  render() {
    return (
      <>
        <Form
          onSubmit={(values) => submitClientPersonalInfo(values)}
          validate={this.validateClientPersonalInfo}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <ClientRegistration>
              <ClientRegForm onSubmit={handleSubmit}>
                <FormItemsGrid>
                  {renderClientPersonalInfoFields("")}
                </FormItemsGrid>
                <ButtonsWrapper>
                  <Button
                    style={{ marginLeft: "20px" }}
                    type="submit"
                    disabled={submitting}
                  >
                    {i18n.t("register")}
                  </Button>
                </ButtonsWrapper>
              </ClientRegForm>
            </ClientRegistration>
          )}
        />
      </>
    );
  }
}
