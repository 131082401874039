import React, { useState } from "react";
import { Button } from "./addButton.styles";
import i18n from "../../../../i18n";
export default function AddButton({
  children,
  onClick,
  btnColor = "teal",
  labelColor,
  disabled,
  type,
  style,
  ...props
}) {
  const [hover, setHover] = useState(false);
  const toggleHover = () => {
    setHover(!hover);
  };
  return (
    <Button
      style={style}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      {...props}
      type={type}
      onClick={!disabled ? onClick : () => {}}
      hover={hover}
    >
      {children || i18n.t("submit")}
    </Button>
  );
}
