import { takeEvery, put, call } from "redux-saga/effects";
import { logoutActionTypes } from "./logout.types";
import { addClientPersonalInfo } from "../ClientRegistration/clientRegistration.actions";
import axios from "../../../axios";
import { customHistory } from "../../../routes/index";
import { setToken } from "../../activeUser/activeUser.actions";
import { logoutComplete, requestFailed } from "./logout.actions";

const logoutUser = async ({ payload }) => {
  try {
    const response = await axios.post("/daycare/logout", payload);

    return response;
  } catch (error) {
    return null;
  }
};

export function* logoutUserAsync(payload) {
  const responseData = yield call(logoutUser, payload);
  if (responseData) {
    sessionStorage.removeItem("currentUserToken");
    sessionStorage.removeItem("currentUser");
    yield put(logoutComplete());
    yield put(setToken());
    yield put(addClientPersonalInfo());
    customHistory.push("/");
  } else {
    yield put(requestFailed());
  }
}
export function* watchUserLogout() {
  yield takeEvery(logoutActionTypes.LOGOUT_USER_SAGA, logoutUserAsync);
}
