import styled from "styled-components/macro";

export const Button = styled.button`
  padding: 15px 40px;
  background: #000;
  border: 0;
  color: #fff;
  text-transform: uppercase;
  font-family: helvetica;
  font-size: 12px;
  letter-spacing: 3px;
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  display: block;
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  &::after {
    width: 30px;
    height: 1px;
    background: white;
    transform: translateX(-3px);
    margin-top: 0px;
  }
  &::before {
    content: "";
    transform: rotate(-135deg) translateX(50%);
    width: 11px;
    height: 11px;
    background: transparent;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    margin-top: -1px;
  }
  ${({ hover }) => {
    return hover ? `padding: 15px 60px 15px 20px;` : ``;
  }};
  &:hover::after,
  &:hover::before {
    opacity: 1;
    right: 15px;
  }
`;
