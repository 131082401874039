import React from "react";
import tableStyles from "../tableStyles";
import TableHeadGenerator from "../TableHeadGenerator.component";
import TableBodyGenerator from "../TableBodyGenerator.component";
import { Table, TableContainer, Paper, makeStyles } from "@material-ui/core";
import AddItem from "../AddItem/AddItem.component";

const useStyles = makeStyles(tableStyles.tableStyles);

export default function TableGenerator({
  schema,
  tableType,
  tableData,
  onDelete,
  onEdit,
  editOnRowClick,
}) {
  const classes = useStyles();
  return (
    <>
      {/* <AddItem {...{ tableType }} /> */}
      <TableContainer component={Paper}>
        <Table aria-label="Table">
          <TableHeadGenerator {...{ schema, tableType }} />
          <TableBodyGenerator
            {...{
              schema,
              tableType,
              tableData,
              onDelete,
              onEdit,
              editOnRowClick,
            }}
          />
        </Table>
      </TableContainer>
    </>
  );
}
