import React, { Component } from "react";
import TableGenerator from "../../../components/Core/TableGenerator/TableGenerator/TableGenerator.component";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  startGuardianFetching,
  startAddingGuardian,
  startDeletingGuardian,
  startEditingGuardian,
} from "../../../redux/guardians/guardians.actions";
import { selectCartItems } from "../../../redux/guardians/guardians.selectors";
import Page from "../../../components/Page/Page";
import FormModal from "../../../components/Core/FormModal/FormModal";
import store from "../../../redux/store";

import ErrorHelper from "../../../helpers/ErrorHelper";
import SuccessHelper from "../../../helpers/SuccessHelper";
import i18n from "../../../i18n";

class Guardian extends Component {
  schema = [
    { value: "firstName", title: i18n.t("first_name"), fieldType: "text" },
    { value: "lastName", title: i18n.t("first_name"), fieldType: "text" },
    { value: "relationship", title: i18n.t("relationship"), fieldType: "text" },
    { value: "email", title: i18n.t("email_address"), fieldType: "text" },
    { value: "phoneNo", title: i18n.t("phone_number"), fieldType: "text" },
    {
      value: "actions",
      title: i18n.t("actions"),
      fieldType: "actions",
      style: { width: 80 },
      actions: ["edit", "delete"],
    },
  ];

  schemaForm = [
    {
      value: "firstName",
      title: i18n.t("first_name"),
      fieldType: "text",
    },
    {
      value: "lastName",
      title: i18n.t("last_name"),
      fieldType: "text",
    },
    {
      value: "phoneNo",
      title: i18n.t("phone_number"),
      fieldType: "text",
    },
    {
      value: "email",
      title: i18n.t("email_address"),
      fieldType: "text",
    },
    {
      value: "relationship",
      title: i18n.t("relationship"),
      fieldType: "text",
    },
    // {
    //   title: "Home Address",
    //   fieldType: "title",
    // },
    {
      value: "address",
      title: i18n.t("address"),
      fieldType: "text",
    },
    {
      value: "city",
      title: i18n.t("city"),
      fieldType: "text",
    },
    {
      value: "province",
      title: i18n.t("province"),
      fieldType: "text",
    },
    {
      value: "country",
      title: i18n.t("country"),
      fieldType: "text",
    },
    {
      value: "postalCode",
      title: i18n.t("postal_code"),
      fieldType: "text",
    },
    {
      title: "WorkAddress",
      fieldType: "title",
    },
    {
      value: "workPhoneNo",
      title: i18n.t("work_phone_number"),
      fieldType: "text",
    },
    {
      value: "homePhoneNo",
      title: i18n.t("home_phone_number"),
      fieldType: "text",
    },
    {
      value: "primaryGuardian",
      title: i18n.t("primary_guardian"),
      fieldType: "yesNoSelect",
    },
    {
      value: "emailDailyReports",
      title: i18n.t("email_daily_report"),
      fieldType: "yesNoSelect",
    },
    {
      value: "allowedToPickUp",
      title: i18n.t("allow_pickup"),
      fieldType: "yesNoSelect",
    },
    {
      value: "notes",
      title: i18n.t("notes"),
      fieldType: "textarea",
    },
  ];

  state = {
    editModalOpen: false,
    addModalOpen: false,
    editing: {},
  };
  async componentDidMount() {
    new Promise((success, failure) => {
      this.props.startGuardianFetching(
        { success, failure },
        this.props.kid._id
      );
    })
      .then((res) => {})
      .catch((err) => {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  }
  validateGuardian = (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = i18n.t("required");
    }
    if (!values.lastName) {
      errors.lastName = i18n.t("required");
    }
    if (!values.phoneNo) {
      errors.phoneNo = i18n.t("required");
    }
    if (!values.email) {
      errors.email = i18n.t("required");
    }

    return errors;
  };
  onAdd = (values) => {
    new Promise((success, failure) => {
      this.props.startAddingGuardian(
        { success, failure },
        { kidId: this.props.kid._id, ...values }
      );
    })
      .then((res) => {
        this.setState({
          addModalOpen: false,
        });
        SuccessHelper.handleToastSuccess(i18n.t("successfully_created"), true);
      })
      .catch((err) => {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  };

  onEdit = (data) => {
    new Promise((success, failure) => {
      this.props.startEditingGuardian(
        { success, failure },
        {
          _id: this.state.editing._id,
          data,
        }
      );
    })
      .then((res) => {
        SuccessHelper.handleToastSuccess(i18n.t("successfully_updated"), true);
      })
      .catch((err) => {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  };

  renderEditModal = () => {
    return (
      <FormModal
        schema={this.schemaForm}
        open={this.state.editModalOpen}
        onSubmit={this.onEdit}
        validateForm={this.validateGuardian}
        values={this.state.editing}
        closeModal={() => {
          this.setState({
            editing: {},
            editModalOpen: false,
          });
        }}
      />
    );
  };

  renderAddModal = () => {
    return (
      <FormModal
        schema={this.schemaForm}
        open={this.state.addModalOpen}
        onSubmit={this.onAdd}
        validateForm={this.validateGuardian}
        values={{
          allowedToPickUp: false,
          emailDailyReports: false,
          primaryGuardian: false,
        }}
        closeModal={() => {
          this.setState({
            addModalOpen: false,
          });
        }}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderEditModal()}
        {this.renderAddModal()}
        <Page
          title={i18n.t("guardians")}
          resource="Guardian"
          data={this.props.guardians ? this.props.guardians : []}
          onDelete={this.props?.startDeletingGuardian}
          onEdit={(data) => {
            this.setState({
              editing: data,
              editModalOpen: true,
            });
          }}
          editOnRowClick
          onCreate={() => {
            this.setState({
              editing: {},
              addModalOpen: true,
            });
          }}
          schema={this.schema}
        ></Page>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  startGuardianFetching: (callbackMap, id) =>
    dispatch(startGuardianFetching(callbackMap, id)),
  startDeletingGuardian: (id, callbackMap) =>
    dispatch(startDeletingGuardian(id, callbackMap)),
  startAddingGuardian: (callbackMap, ...args) =>
    dispatch(startAddingGuardian(callbackMap, ...args)),
  startEditingGuardian: (callbackMap, ...args) =>
    dispatch(startEditingGuardian(callbackMap, ...args)),
});

const mapStateToProps = (state) => {
  return { guardians: state.guardian.guardians };
};

export default connect(mapStateToProps, mapDispatchToProps)(Guardian);
