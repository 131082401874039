import React from "react";
import Modal from "../Modal/Modal";
import Form from "../Form/Form";
import "./FormModal.css";
import i18n from "../../../i18n";
export default function FormModal({
  open,
  onSubmit,
  validateForm,
  schema,
  closeModal,
  values,
  title,
}) {
  return (
    <Modal title={title ? title : "add"} open={open} closeModal={closeModal}>
      <Form
        onSubmit={onSubmit}
        validateForm={validateForm}
        schema={schema}
        values={values}
        onCancel={closeModal}
      />
    </Modal>
  );
}
