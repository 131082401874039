import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import _ from "lodash";

import { EditActions } from "../Medical.styles";

import { startEditingCondition } from "../../../../redux/conditions/conditions.actions";
import { connect } from "react-redux";

import callbackHelper from "../../../../helpers/callbackHelper";
import i18n from "../../../../i18n";
import PrimaryButton from "../../../Core/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../../Core/Buttons/SecondaryButton/SecondaryButton";
import Modal from "../../../Core/Modal/Modal";

function EditCondition({
  open,
  handleModalClose,
  currentEditingCondition,
  startEditingCondition,
}) {
  const conditionItemsMap = [
    { title: i18n.t("condition"), name: "name" },
    { title: i18n.t("description"), name: "description" },
  ];
  const onSubmit = (values) => {
    callbackHelper(
      startEditingCondition,
      { data: values, _id: currentEditingCondition._id },
      "update"
    );
    handleModalClose("edit");
  };

  const handleEditModalCancel = () => {
    handleModalClose("edit");
  };

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = i18n.t("required");
    }
    if (!values.description) {
      errors.description = i18n.t("required");
    }
    return errors;
  };

  return (
    <Modal
      open={open}
      title={i18n.t("edit_condition")}
      closeModal={() => handleEditModalCancel()}
    >
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {conditionItemsMap.map(({ title, name }, i) => {
              return (
                <Field
                  name={name}
                  key={`edit-condition-${i}`}
                  initialValue={currentEditingCondition[name]}
                  render={({ input, meta }) => {
                    const error = meta.touched && meta.error;
                    return (
                      <TextField
                        label={title}
                        fullWidth
                        className="mb-2"
                        {...input}
                        error={error}
                        helperText={error ? error : ""}
                      />
                    );
                  }}
                />
              );
            })}
            <EditActions>
              <SecondaryButton onClick={() => handleEditModalCancel()}>
                {i18n.t("cancel")}
              </SecondaryButton>
              <PrimaryButton className="ml-1" type="submit">
                {i18n.t("submit")}
              </PrimaryButton>
            </EditActions>
          </form>
        )}
      />
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => ({
  startEditingCondition: (callbackMap, ...args) =>
    dispatch(startEditingCondition(callbackMap, ...args)),
});

export default connect(null, mapDispatchToProps)(EditCondition);
