const tableStyles = {
  tableHead: {
    backgroundColor: "#5EC618",
    coolor: "white",
    // backgroundColor: "#DEC550",
  },
  mainWrapper: {
    width: "100%",
    marginTop: "15vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  addActivity: {
    alignSelf: "flex-end",
    padding: "20px",
    margin: "5%",
    borderRadius: "5px",
    border: "1px grey solid",
    cursor: "pointer",
    "&:hover": {
      background: "grey",
      color: "white",
      borderColor: "white",
    },
  },
  deleteButton: {
    cursor: "pointer",
    "&:hover": {
      background: "grey",
      color: "white",
    },
  },
};

export default { tableStyles };
