import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import _ from "lodash";
import { EditActions } from "../Medical/Medical.styles";
import { AddIncidentFields } from "./Incident.styles";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import i18n from "../../../i18n";
import PrimaryButton from "../../Core/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../Core/Buttons/SecondaryButton/SecondaryButton";
import Modal from "../../Core/Modal/Modal";
import moment from "moment";

export default function EditRequirement({
  open,
  handleModalClose,
  currentEditingIncident,
  startEditingIncident,
  callbackHelper,
}) {
  const [incidentContent, setIncidentContent] = useState("");

  const onSubmit = (values) => {
    console.log({
      ...values,
      _id: currentEditingIncident._id,
      content: incidentContent,
    });
    callbackHelper(
      startEditingIncident,
      {
        data: values,
        _id: currentEditingIncident._id,
      },
      "update"
    );
    // update redux
    handleModalClose("edit");
  };
  const handleChange = (input) => {
    setIncidentContent(input);
  };
  const handleEditModalCancel = () => {
    handleModalClose("edit");
  };
  const validate = (values) => {
    const errors = {};

    if (!values.incident) {
      errors.incident = i18n.t("required");
    }

    return errors;
  };
  return (
    <Modal
      open={open}
      title={i18n.t("edit_incident")}
      closeModal={() => handleEditModalCancel()}
    >
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <AddIncidentFields>
              <Field
                name="name"
                initialValue={currentEditingIncident.name}
                render={({ input, meta }) => {
                  const error = meta.touched && meta.error;
                  return (
                    <TextField
                      label={i18n.t("incident")}
                      fullWidth
                      {...input}
                      error={error}
                      helperText={error ? error : ""}
                    />
                  );
                }}
              />
              <label className="mt-2">{i18n.t("description")}</label>
              <SunEditor
                defaultValue={currentEditingIncident.description}
                onChange={handleChange}
              />
              <TextField
                className="mt-2"
                label={i18n.t("created_by")}
                value={currentEditingIncident.createdBy?.firstName}
                fullWidth
                inputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                className="mt-2"
                label={i18n.t("created_date")}
                value={
                  currentEditingIncident.createdAt &&
                  moment(currentEditingIncident.createdAt).format(
                    "MM-DD-YYYY hh:mm a"
                  )
                }
                fullWidth
                inputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                className="mt-2"
                label={i18n.t("updated_by")}
                fullWidth
                value={currentEditingIncident.updatedBy?.firstName}
                inputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                className="mt-2"
                label={i18n.t("updated_date")}
                value={
                  currentEditingIncident.updatedDate &&
                  moment(currentEditingIncident.updatedDate).format(
                    "MM-DD-YYYY hh:mm a"
                  )
                }
                fullWidth
                inputProps={{
                  readOnly: true,
                }}
              />
            </AddIncidentFields>
            <EditActions className="mt-2">
              <SecondaryButton onClick={() => handleEditModalCancel()}>
                {i18n.t("cancel")}
              </SecondaryButton>
              <PrimaryButton className="ml-1" type="submit">
                {i18n.t("submit")}
              </PrimaryButton>
            </EditActions>
          </form>
        )}
      />
    </Modal>
  );
}
