import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { EditFields, EditActions, DoctorsItems } from "../Medical.styles";
import { Form, Field } from "react-final-form";
import { TextField } from "@material-ui/core";
import _ from "lodash";

import { startAddingDoctor } from "../../../../redux/doctors/doctors.actions";
import { connect } from "react-redux";

import callbackHelper from "../../../../helpers/callbackHelper";
import i18n from "../../../../i18n";
import PrimaryButton from "../../../Core/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../../Core/Buttons/SecondaryButton/SecondaryButton";
import Modal from "../../../Core/Modal/Modal";
import CustomPlacesAutocomplete from "../../../Core/PlacesAutocomplete/PlacesAutoComplete";
function AddDoctor({ open, handleModalClose, startAddingDoctor, kid }) {
  const handleAddModalCancel = () => {
    handleModalClose("add");
  };
  const doctorItemsMap = [
    { title: "Doctor Name", value: "name" },
    { title: "Email", value: "email" },
    { title: "Address", value: "address" },
    { title: "Profession", value: "profession" },
    { title: "Work Phone", value: "workPhone" },
    { title: "Cell Phone", value: "cellPhone" },
    { title: "Other Phone", value: "otherPhone" },
  ];
  const onSubmit = (values) => {
    handleModalClose("add");
    callbackHelper(startAddingDoctor, { ...values, kidId: kid._id }, "add");
  };
  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = i18n.t("required");
    }
    if (!values.email) {
      errors.email = i18n.t("required");
    }
    if (!values.workPhone) {
      errors.workPhone = i18n.t("required");
    }
    return errors;
  };
  return (
    <Modal
      open={open}
      title={i18n.t("add_doctor")}
      closeMOdal={() => handleAddModalCancel()}
    >
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <EditFields>
              {doctorItemsMap.map(({ title, value }, i) => {
                return (
                  <>
                    <Field
                      name={value}
                      key={`edit-doctor-${i}`}
                      render={({ input, meta }) => {
                        const error = meta.touched && meta.error;

                        switch (value) {
                          case "address":
                            return (
                              <DoctorsItems type={value} className="mb-2">
                                <CustomPlacesAutocomplete
                                  name="address"
                                  input={input}
                                />
                                {/* <placesAutoComplete
                                  label={title}
                                  fullWidth
                                  {...input}
                                  error={error}
                                  helperText={error ? error : ""}
                                /> */}
                              </DoctorsItems>
                            );

                          default:
                            return (
                              <DoctorsItems type={value} className="mb-2">
                                <TextField
                                  label={title}
                                  fullWidth
                                  {...input}
                                  error={error}
                                  helperText={error ? error : ""}
                                />
                              </DoctorsItems>
                            );
                        }
                      }}
                    />
                  </>
                );
              })}
            </EditFields>
            <EditActions>
              <SecondaryButton onClick={() => handleAddModalCancel()}>
                {i18n.t("cancel")}
              </SecondaryButton>
              <PrimaryButton className="ml-1" type="submit">
                {i18n.t("submit")}
              </PrimaryButton>
            </EditActions>
          </form>
        )}
      />
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => ({
  startAddingDoctor: (callbackMap, ...args) =>
    dispatch(startAddingDoctor(callbackMap, ...args)),
});

export default connect(null, mapDispatchToProps)(AddDoctor);
