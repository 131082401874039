import React, { useState, useEffect } from "react";
import EmployeeItem from "./EmployeeItem";
import { useDispatch, useSelector } from "react-redux";
import {
  startEmployeeFetching,
  filterEmployees,
} from "../../../redux/employees/employees.actions";
// connected to filteredEmployees state of redux
// when ever the filtered updates, the list updates
// displays the filtered list

import { uniqueId } from "lodash";

export default function DisplayEmployees() {
  const dispatch = useDispatch();

  const employees = useSelector((state) => state.employees.employees);

  useEffect(() => {
    document.title = `Employees`;
    dispatch(startEmployeeFetching());
  }, []);

  const renderEmployees = () => {
    return (
      <>
        {employees.map((employee) => {
          return <EmployeeItem key={uniqueId()} {...{ employee }} />;
        })}
      </>
    );
  };

  return <>{renderEmployees()}</>;
}
