import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import BasicTable from "../../components/Core/BasicTable";
import i18n from "../../i18n";

export default function KidGroupTable({
  checkedMap,
  sentMap,
  onKidChecked,
  kids: rows,
  groupId,
}) {
  return (
    <BasicTable
      rows={rows}
      additionalKey={`${groupId}-`}
      schema={[
        {
          field: "name",
          style: { width: 80 },
          accessor: (val) => {
            return (
              <Checkbox
                onChange={(event) => onKidChecked(event, val)}
                inputProps={{ "aria-label": "secondary checkbox" }}
                checked={checkedMap[val._id]}
                disabled={sentMap[val._id]}
              />
            );
          },
        },
        {
          header: i18n.t("name"),
          field: "name",
          accessor: (val) => `${val.firstName} ${val.lastName}`,
        },
        {
          header: i18n.t("age"),
          field: "age",
        },
        {
          header: i18n.t("address"),
          field: "address",
          accessor: (val) => {
            const { address } = val;
            if (!address) {
              return "";
            }
            const {
              street_number = "",
              route = "",
              country = "",
              postal_code = "",
              province = "",
            } = address;
            return `${street_number} ${route} ${country} ${province} ${postal_code}`;
          },
        },
      ]}
    />
  );
}
