import React, { useState } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "../../components/Core/Modal/Modal";
import i18n from "../../i18n";
import { Form, Field } from "react-final-form";
import TextField from "@material-ui/core/TextField";
import PrimaryButton from "../../components/Core/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../components/Core/Buttons/SecondaryButton/SecondaryButton";
import FileUpload from "./FileUploader";

export default function FileUploadModal({
  openModal,
  modalClick,
  submitFormHandler,
  closeModal,
}) {
  const [file, setFile] = useState({});
  const validateForm = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = i18n.t("required");
    }

    return errors;
  };
  return (
    <div>
      <Modal
        open={openModal}
        closeModal={modalClick}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        title={i18n.t("upload_file")}
      >
        <Fade in={openModal}>
          <Form
            onSubmit={(values) => {
              submitFormHandler({ ...values, file });
            }}
            validate={validateForm}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Field name="title" component={TextField} label="Title">
                    {({ input, meta }) => {
                      const error = meta.error && meta.touched;
                      return (
                        <TextField
                          {...input}
                          label={i18n.t("title")}
                          error={error}
                          fullWidth
                          helperText={error ? meta.error : null}
                        />
                      );
                    }}
                  </Field>
                  <FileUpload
                    label="Upload Forms"
                    updateFilesCb={(file) => {
                      setFile(file);
                    }}
                  />
                  <div className="row justify-content-end">
                    <SecondaryButton onClick={closeModal}>
                      {i18n.t("cancel")}
                    </SecondaryButton>
                    <PrimaryButton type="submit" className="ml-1">
                      {i18n.t("submit")}
                    </PrimaryButton>
                  </div>
                </div>
              </form>
            )}
          />
        </Fade>
      </Modal>
    </div>
  );
}
