import React, { useState } from "react";
import Doctors from "./Doctors/Doctors";
import Conditions from "./Conditions/Conditions";
import Allergies from "./Allergies/Allergies";
import Requirements from "./Requirements/Requirements";
import { MedicalMain, MedicalCategories } from "./Medical.styles";
import { useSelector } from "react-redux";
import i18n from "../../../i18n";

export default function Medical(props) {
  const [currentCategory, setCurrentCategory] = useState("main");

  const handleCategory = (category) => {
    setCurrentCategory(category);
  };
  switch (currentCategory) {
    case "main":
      return (
        <MedicalMain>
          <MedicalCategories onClick={() => handleCategory("doctors")}>
            <h4>{i18n.t("doctors")}</h4>
          </MedicalCategories>
          <MedicalCategories onClick={() => handleCategory("conditions")}>
            <h4>{i18n.t("conditions")}</h4>
          </MedicalCategories>
          <MedicalCategories onClick={() => handleCategory("allergies")}>
            <h4>{i18n.t("allergies")}</h4>
          </MedicalCategories>
          <MedicalCategories onClick={() => handleCategory("requirments")}>
            <h4>{i18n.t("requirements")}</h4>
          </MedicalCategories>
        </MedicalMain>
      );
    case "doctors":
      return <Doctors {...{ handleCategory, kid: props.kid }} />;
    case "conditions":
      return <Conditions {...{ handleCategory, kid: props.kid }} />;
    case "allergies":
      return <Allergies {...{ handleCategory, kid: props.kid }} />;
    case "requirments":
      return <Requirements {...{ handleCategory, kid: props.kid }} />;
    default:
      break;
  }
}
