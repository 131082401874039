export const employeesActionTypes = {
  GET_EMPLOYEES: "GET_EMPLOYEES",
  SAVE_EMPLOYEES: "SAVE_EMPLOYEES",

  DELETE_EMPLOYEE: "DELETE_EMPLOYEE",
  DELETE_EMPLOYEE_SAGA: "DELETE_EMPLOYEE_SAGA",

  EDIT_EMPLOYEE: "EDIT_EMPLOYEE",
  EDIT_EMPLOYEE_SAGA: "EDIT_EMPLOYEE_SAGA",

  ADD_EMPLOYEE: "ADD_EMPLOYEE",
  ADD_EMPLOYEE_SAGA: "ADD_EMPLOYEE_SAGA",

  UPDATE_EMPLOYEE_SCHEDULE: "UPDATE_EMPLOYEE_SCHEDULE",
  UPDATE_EMPLOYEE_SCHEDULE_SAGA: "UPDATE_EMPLOYEE_SCHEDULE_SAGA",

  REGISTER_EMPLOYEE: "REGISTER_EMPLOYEE",
  REGISTER_EMPLOYEE_SAGA: "REGISTER_EMPLOYEE_SAGA",

  REGISTER_EMPLOYEE_COMPLETE_SAGA: "REGISTER_EMPLOYEE_COMPLETE_SAGA",

  FILTER_EMPLOYEES: "FILTER_EMPLOYEES",
  OPEN_EMPLOYEE_MODAL: "OPEN_EMPLOYEE_MODAL",
  CLOSE_EMPLOYEE_MODAL: "CLOSE_EMPLOYEE_MODAL",
  CLEAR_SEARCH: "CLEAR_SEARCH",

  OPEN_ADD_EMPLOYEE_MODAL: "OPEN_ADD_EMPLOYEE_MODAL",
  CLOSE_ADD_EMPLOYEE_MODAL: "CLOSE_ADD_EMPLOYEE_MODAL",
};
