import { mealsActionTypes } from "./meals.types";

const initialState = {
  meals: [],
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case mealsActionTypes.GET_MEALS: {
      return { ...state, isLoading: true };
    }
    case mealsActionTypes.SAVE_MEALS: {
      return { ...state, meals: payload, isLoading: false };
    }
    case mealsActionTypes.DELETE_MEAL: {
      const updatedState = state.meals;

      const toUpdate = updatedState.filter((meal) => meal._id != payload);
      return { ...state, meals: toUpdate, isLoading: false };
    }
    case mealsActionTypes.DELETE_MEAL_SAGA: {
      return { ...state, isLoading: true };
    }

    case mealsActionTypes.EDIT_MEAL: {
      const currentState = state.meals;

      const toUpdate = currentState.map((meal) => {
        return meal._id == payload._id ? payload : meal;
      });
      return { ...state, meals: toUpdate, isLoading: false };
    }
    case mealsActionTypes.EDIT_MEAL_SAGA: {
      return { ...state, isLoading: true };
    }
    case mealsActionTypes.ADD_MEAL: {
      const currentState = state.meals;
      const toUpdate = [...currentState, payload];
      return { ...state, meals: toUpdate, isLoading: false };
    }
    case mealsActionTypes.ADD_MEAL_SAGA: {
      return { ...state, isLoading: true };
    }
    default:
      return state;
  }
};
