import { allergiesActionTypes } from "./allergies.types";

export const saveAllergies = (allergies) => {
  return {
    type: allergiesActionTypes.SAVE_ALLERGIES,
    payload: allergies,
  };
};

export const startAllergyFetching = (callbackMap, kidId = null) => {
  return {
    type: allergiesActionTypes.GET_ALLERGIES,
    callbackMap,
    kidId,
  };
};

export const startDeletingAllergy = (callbackMap, id) => {
  return {
    type: allergiesActionTypes.DELETE_ALLERGY_SAGA,
    callbackMap,
    allergyId: id,
  };
};

export const deleteAllergy = (id) => {
  return {
    type: allergiesActionTypes.DELETE_ALLERGY,
    payload: id,
  };
};

export const startEditingAllergy = (callbackMap, payload) => {
  return {
    type: allergiesActionTypes.EDIT_ALLERGY_SAGA,
    callbackMap,
    payload,
  };
};

export const editAllergy = (payload) => {
  return {
    type: allergiesActionTypes.EDIT_ALLERGY,
    payload,
  };
};

export const startAddingAllergy = (callbackMap, payload) => {
  return {
    type: allergiesActionTypes.ADD_ALLERGY_SAGA,
    callbackMap,
    payload,
  };
};

export const addAllergy = (payload) => {
  return {
    type: allergiesActionTypes.ADD_ALLERGY,
    payload,
  };
};

export const requestFailed = () => {
  return {
    type: allergiesActionTypes.REQUEST_FAILED,
  };
};
