import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../../i18n";
import { getInvoicesRequest } from "../../../redux/billing/billing.actions";
import InvoiceTable from "../../Billing/InvoiceTable";
import NewInvoiceModal from "../../Billing/NewInvoiceModal";
import PrimaryButton from "../../Core/Buttons/PrimaryButton/PrimaryButton";

export default function Invoices({ kidId }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInvoicesRequest({ filter: { kidId } }));
  }, []);

  const invoices = useSelector((s) => s.billing.data);
  const [addOpen, setAddOpen] = useState(false);

  return (
    <div>
      <div className="row justify-content-end mb-2">
        <PrimaryButton onClick={() => setAddOpen(true)}>
          {i18n.t("add")}
        </PrimaryButton>
      </div>
      <InvoiceTable invoices={invoices} />
      <NewInvoiceModal open={addOpen} setOpen={setAddOpen} kidId={kidId} />
    </div>
  );
}
