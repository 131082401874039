import React, { Component } from "react";
import { connect } from "react-redux";
import {
  startImmunizationFetching,
  startDeletingImmunization,
  startEditingImmunization,
  startAddingImmunization,
} from "../../redux/immunizationType/immunizationType.actions";
import Page from "../../components/Page/Page";
import FormModal from "../../components/Core/FormModal/FormModal";
import axios from "../../axios";
import store from "../../redux/store";

import ErrorHelper from "../../helpers/ErrorHelper";
import SuccessHelper from "../../helpers/SuccessHelper";
import i18n from "../../i18n";

class ImmunizationTpes extends Component {
  state = {
    editModalOpen: false,
    addModalOpen: false,
    editing: {},
    immunizationTypes: [],
  };
  schema = [
    { value: "name", title: i18n.t("name"), fieldType: "text" },

    {
      value: "actions",
      title: i18n.t("actions"),
      fieldType: "actions",
      style: { width: 80 },
      actions: ["edit", "delete"],
    },
  ];

  async componentDidMount() {
    document.title = i18n.t("immunization_types");
    this.props.startImmunizationFetching();
  }
  validateAllergy = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = i18n.t("required");
    }
    // if (!values.type) {
    //   errors.type = "Required";
    // }

    return errors;
  };
  onAdd = (values) => {
    this.props.startAddingImmunization(values, {
      success: () => {
        this.setState({
          addModalOpen: false,
        });
        SuccessHelper.handleSuccess(i18n.t("successfully_created"), true);
      },
      failure: () => {
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      },
    });
  };
  onDelete = (ids) => {
    this.props.startDeletingImmunization(ids, {
      success: () => {
        this.setState({
          addModalOpen: false,
        });
        SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true);
      },
      failure: () => {
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      },
    });
  };

  onEdit = (data) => {
    this.props.startEditingImmunization(
      {
        _id: this.state.editing._id,
        data,
      },
      {
        success: () => {
          this.setState({
            editModalOpen: false,
          });
          SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true);
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
        },
      }
    );
  };

  renderEditModal = () => {
    return (
      <FormModal
        schema={this.schema}
        open={this.state.editModalOpen}
        onSubmit={this.onEdit}
        validateForm={this.validateAllergy}
        values={this.state.editing}
        closeModal={() => {
          this.setState({
            editing: {},
            editModalOpen: false,
          });
        }}
      />
    );
  };

  renderAddModal = () => {
    return (
      <FormModal
        schema={this.schema}
        open={this.state.addModalOpen}
        onSubmit={this.onAdd}
        validateForm={this.validateAllergy}
        closeModal={() => {
          this.setState({
            addModalOpen: false,
          });
        }}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderEditModal()}
        {this.renderAddModal()}
        <Page
          title="ImmunizationTypes"
          resource="ImmunizationType"
          data={this.props.immunizationTypes}
          onDelete={this.onDelete}
          onEdit={(data) => {
            this.setState({
              editing: data,
              editModalOpen: true,
            });
          }}
          editOnRowClick
          onCreate={() => {
            this.setState({
              editing: {},
              addModalOpen: true,
            });
          }}
          schema={this.schema}
        ></Page>
      </>
    );
  }
}

const actions = {
  startImmunizationFetching,
  startDeletingImmunization,
  startEditingImmunization,
  startAddingImmunization,
};

const mapStateToProps = (s) => ({
  immunizationTypes: s.immunizationType.immunizationTypes,
});
export default connect(mapStateToProps, actions)(ImmunizationTpes);
