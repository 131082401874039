import { incidentsActionTypes } from "./incidents.types";

export const saveIncidents = (incidents) => {
  return {
    type: incidentsActionTypes.SAVE_INCIDENTS,
    payload: incidents,
  };
};

export const startIncidentFetching = (callbackMap) => {
  console.log("i am in action");
  return {
    type: incidentsActionTypes.GET_INCIDENTS,
    callbackMap,
  };
};

export const startDeletingIncident = (callbackMap, id) => {
  return {
    type: incidentsActionTypes.DELETE_INCIDENT_SAGA,
    callbackMap,
    incidentId: id,
  };
};

export const deleteIncident = (id) => {
  console.log("inside delete incident ", id);
  return {
    type: incidentsActionTypes.DELETE_INCIDENT,
    payload: id,
  };
};

export const startEditingIncident = (callbackMap, payload) => {
  return {
    type: incidentsActionTypes.EDIT_INCIDENT_SAGA,
    callbackMap,
    payload,
  };
};

export const editIncident = (payload) => {
  return {
    type: incidentsActionTypes.EDIT_INCIDENT,
    payload,
  };
};

export const startAddingIncident = (callbackMap, payload) => {
  return {
    type: incidentsActionTypes.ADD_INCIDENT_SAGA,
    callbackMap,
    payload,
  };
};

export const addIncident = (payload) => {
  return {
    type: incidentsActionTypes.ADD_INCIDENT,
    payload,
  };
};

export const requestFailed = () => {
  return {
    type: incidentsActionTypes.REQUEST_FAILED,
  };
};
