import { employeesActionTypes } from "./employees.types";

export const saveEmployees = (employees) => {
  return {
    type: employeesActionTypes.SAVE_EMPLOYEES,
    payload: employees,
  };
};

export const startEmployeeFetching = () => {
  return {
    type: employeesActionTypes.GET_EMPLOYEES,
  };
};

export const startDeletingEmployee = (id) => {
  return {
    type: employeesActionTypes.DELETE_EMPLOYEE_SAGA,
    employeeId: id,
  };
};

export const deleteEmployee = (id) => {
  return {
    type: employeesActionTypes.DELETE_EMPLOYEE,
    payload: id,
  };
};

export const startEditingEmployee = (payload) => {
  return {
    type: employeesActionTypes.EDIT_EMPLOYEE_SAGA,
    payload,
  };
};

export const editEmployee = (payload) => {
  return {
    type: employeesActionTypes.EDIT_EMPLOYEE,
    payload,
  };
};

export const startAddingEmployee = (payload) => {
  return {
    type: employeesActionTypes.ADD_EMPLOYEE_SAGA,
    payload,
  };
};

export const addEmployee = (payload) => {
  return {
    type: employeesActionTypes.ADD_EMPLOYEE,
    payload,
  };
};

export const startEditingEmployeeSchedule = (payload) => {
  return {
    type: employeesActionTypes.UPDATE_EMPLOYEE_SCHEDULE_SAGA,
    payload,
  };
};

export const editEmployeeSchedule = (payload) => {
  return {
    type: employeesActionTypes.UPDATE_EMPLOYEE_SCHEDULE,
    payload,
  };
};

export const startRegisterEmployees = (payload) => {
  return {
    type: employeesActionTypes.REGISTER_EMPLOYEE_SAGA,
    payload,
  };
};

export const startCompleteRegisterEmployees = (payload) => {
  return {
    type: employeesActionTypes.REGISTER_EMPLOYEE_COMPLETE_SAGA,
    payload,
  };
};

export const filterEmployees = (payload) => {
  return {
    type: employeesActionTypes.FILTER_EMPLOYEES,
    payload,
  };
};

export const openEmployeesModal = (employeeId) => {
  return {
    type: employeesActionTypes.OPEN_EMPLOYEE_MODAL,
    payload: employeeId,
  };
};

export const clearEmployeeSearch = () => {
  return {
    type: employeesActionTypes.CLEAR_SEARCH,
  };
};

export const closeEmployeesModal = (employeeId) => {
  return {
    type: employeesActionTypes.CLOSE_EMPLOYEE_MODAL,
    payload: employeeId,
  };
};

export const closeEmployeesAddModal = () => {
  return {
    type: employeesActionTypes.CLOSE_ADD_EMPLOYEE_MODAL,
  };
};

export const openEmployeesAddModal = () => {
  return {
    type: employeesActionTypes.OPEN_ADD_EMPLOYEE_MODAL,
  };
};
