import styled from "styled-components/macro";
import { makeStyles } from "@material-ui/core/styles";

const EditModal = styled.div`
  background-color: white;
  margin: 0;
  padding: 0;
  width: 60vw;
  height: 80vh;
`;

const EditModalTitle = styled.h2`
  margin: 0;
  padding: 0;
  text-align: center;
  padding: 2em;
`;

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: "grey",
  },
  tabsBar: {
    "& .MuiTabs-indicator": {
      // display: "none",
      backgroundColor: "orange",
    },
  },
}));

const FormBodyContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const FormBody = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 60%;
  /* gap: 1.5em; */
`;

const FormBodyView = styled(FormBody)`
  gap: 1.5em;
`;

const ErrorMessage = styled.p`
  min-height: 1.7em;
  padding: 0.2em 0 0 0.4em;
  color: red;
  margin: 0;
  width: 60%;
`;

const ClientRegistration = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  height: 100%;
  margin: auto;
`;
const ClientRegForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: aliceblue;
  border-radius: 6px;
  box-shadow: 20px 20px 60px #cccccc, -20px -20px 60px #f4f4f4;
  margin-top: 12vh;
  padding: 10vh 0;
`;
const FormItemsGrid = styled.form`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 2em;
`;

export const TableItem = styled.div`
  padding: 0 2.5em;
  grid-column: ${({ type }) => {
    switch (type) {
      case "firstName":
        return "1 / 3";
      case "lastName":
        return "3 / 5";
      case "email":
        return "1 / 3";
      case "address":
        return "1 / 5";
      case "phoneNo":
        return "3 / 5";
      case "password":
        return "1 / 3";
      case "confirmPassword":
        return "3 / 5";

      default:
        return "1 / 2";
    }
  }};
`;
export {
  EditModal,
  EditModalTitle,
  useStyles,
  FormBody,
  FormBodyContainer,
  ErrorMessage,
  FormBodyView,
  ClientRegistration,
  ClientRegForm,
  FormItemsGrid,
};
