import { loginActionTypes } from "./login.types";

const initialState = {
  isLoading: false,
};

export default (state = initialState, { type }) => {
  switch (type) {
    case loginActionTypes.LOGIN_USER_SAGA: {
      return { ...state, isLoading: true };
    }
    case loginActionTypes.LOGIN_USER: {
      return { ...state, isLoading: false };
    }
    case loginActionTypes.REQUEST_FAILED: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
