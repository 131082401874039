import React from "react";
import { EditActions } from "../Medical.styles";
import { Form, Field } from "react-final-form";
import _ from "lodash";
import { startAddingKidAllergy } from "../../../../redux/kids/kids.actions";
import { connect, useDispatch, useSelector } from "react-redux";

import callbackHelper from "../../../../helpers/callbackHelper";
import i18n from "../../../../i18n";
import PrimaryButton from "../../../Core/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../../Core/Buttons/SecondaryButton/SecondaryButton";
import Modal from "../../../Core/Modal/Modal";
import { useEffect } from "react";
import Select from "../../../Core/Select";

function AddCondition({ open, handleModalClose, startAddingKidAllergy, kid }) {
  const allergies = useSelector((s) => s.allergies.allergies);
  const handleAddModalCancel = () => {
    handleModalClose("add");
  };
  const onSubmit = (values) => {
    callbackHelper(startAddingKidAllergy, { ...values, kidId: kid._id }, "add");
  };
  const validate = (values) => {
    const errors = {};

    if (!values.allergyId) {
      errors.allergyId = i18n.t("required");
    }
    return errors;
  };
  return (
    <Modal
      open={open}
      closeModal={() => handleAddModalCancel()}
      title="Add Allergies"
    >
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="allergyId"
              key="allergy"
              render={({ input, meta }) => {
                const error = meta.touched && meta.error;
                return (
                  <Select
                    {...input}
                    className="mb-2"
                    fullWidth
                    options={allergies.map((allergy) => ({
                      ...allergy,
                      value: allergy._id,
                    }))}
                    label={i18n.t("allergies")}
                    error={error}
                  />
                );
              }}
            />
            <EditActions>
              <SecondaryButton onClick={() => handleAddModalCancel()}>
                {i18n.t("cancel")}
              </SecondaryButton>
              <PrimaryButton className="ml-1" type="submit">
                {i18n.t("submit")}
              </PrimaryButton>
            </EditActions>
          </form>
        )}
      />
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => ({
  startAddingKidAllergy: (callbackMap, ...args) =>
    dispatch(startAddingKidAllergy(callbackMap, ...args)),
});

export default connect(null, mapDispatchToProps)(AddCondition);
