import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { v4 as uuidv4 } from "uuid";
import { TableFooter } from "@material-ui/core";
// import { TablePagination } from "@material-ui/core";
import TablePagination from "../../components/Pagination/Pagination.component";
import i18n from "../../i18n";

export default function FormTable(props) {
  const rows = props.data.data ? props.data.data : [];
  const { totalPages, currentPage } = props.data;
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead className="secondary">
          <TableRow>
            <TableCell>{i18n.t("title")}</TableCell>
            <TableCell align="right">{i18n.t("type")}</TableCell>
            <TableCell align="right">{i18n.t("action")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={uuidv4()}>
              <TableCell component="th" scope="row">
                {row.title}
              </TableCell>
              <TableCell align="right">{row.type}</TableCell>
              <TableCell align="right">
                <Button
                  onClick={() => props.handleAssignToGroup(row)}
                  variant="contained"
                  color="primary"
                  className="mx-1"
                >
                  {i18n.t("assing_to_groups")}
                </Button>
                {/* <a href={row.path} alt={row.title} target="blank"> */}
                <Button
                  onClick={() => props.downloadAsset(row)}
                  variant="contained"
                  className="mx-1"
                >
                  {i18n.t("download")}
                </Button>
                <Button
                  onClick={() => props.deleteForm(row._id)}
                  variant="contained"
                  color="secondary"
                  className="mx-1"
                >
                  {i18n.t("delete")}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <div className="row my-2">
            <TablePagination
              rows={rows}
              totalPages={totalPages}
              currentPage={currentPage}
              getPaginateData={props.getPaginateData}
            />
          </div>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
