import { Toolbar } from "@material-ui/core";
import { Button } from "@material-ui/core";
import React from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { startLogout } from "../../redux/authentication/logout/logout.actions";
import LangDropDown from "../langDropDown/langDropDown.compenent";
import LoggedInOnly from "../LoggedInOnly/LoggedInOnly";
import i18n from "../../i18n";
import LoggedOutOnly from "../LoggedOutOnly/LoggedOutOnly";
import { customHistory } from "../../routes/index";
import logo from "../../assets/Logo.png";
import { makeStyles } from "@material-ui/core/styles";
import { FooterLink } from "../../pages/Authentication/LoginForm/loginForm.styles";
const LogoWrapper = styled.div`
  cursor: pointer;
  background-color: white;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#148DFF",
    height: "80px",
    justifyContent: "space-between",
    position: "fixed",
    width: "100%",
    zIndex: "10",
  },
}));
export default function NavBar() {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const onLogOut = () => {
    dispatch(startLogout());
  };
  return (
    <Toolbar position="sticky" className={classes.root}>
      <LogoWrapper>
        <img
          onClick={() => {
            customHistory.push("/");
          }}
          height="40px"
          style={{ objectFit: "contain" }}
          src={logo}
        />
      </LogoWrapper>

      <div className="row">
        <LangDropDown />

        <LoggedInOnly>
          <Button
            style={{ color: "white", marginRight: "3em" }}
            onClick={onLogOut}
          >
            {i18n.t("logout")}
          </Button>
        </LoggedInOnly>
        <LoggedOutOnly>
          <Button
            onClick={() => {
              history.push("/");
            }}
            style={{ color: "white", marginRight: "3em" }}
          >
            {i18n.t("login")}
          </Button>
          <FooterLink
            target="_blank"
            href="https://easyweb.easy1booking.com/daycare/"
          >
            {i18n.t("visit_business_website")}
          </FooterLink>
        </LoggedOutOnly>
      </div>
    </Toolbar>
  );
}
