import { eventsActionTypes } from "./events.types";

const initialState = {
  events: [],
  active: null,
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case eventsActionTypes.GET_EVENTS: {
      return { ...state, isLoading: true };
    }
    case eventsActionTypes.SAVE_EVENTS: {
      return { ...state, events: payload, isLoading: false };
    }
    case eventsActionTypes.DELETE_EVENT: {
      const updatedState = state.events;

      const toUpdate = updatedState.filter((event) => event._id != payload);
      return { ...state, events: toUpdate, isLoading: false };
    }
    case eventsActionTypes.EDIT_EVENT: {
      const currentState = state.events;

      const toUpdate = currentState.map((event) => {
        return event._id == payload._id ? payload : event;
      });
      return { ...state, events: toUpdate, isLoading: false };
    }
    case eventsActionTypes.ADD_EVENT: {
      const currentState = state.events;
      const toUpdate = [...currentState, payload];
      return { ...state, events: toUpdate, isLoading: false };
    }
    case eventsActionTypes.EDIT_EVENT_SAGA:
    case eventsActionTypes.SET_EVENT_KIDS_SAGA:
    case eventsActionTypes.DELETE_EVENT_SAGA:
    case eventsActionTypes.ADD_EVENT_SAGA: {
      return { ...state, isLoading: true };
    }
    case eventsActionTypes.SET_EVENT_KIDS: {
      const { _id, kids } = payload;
      const active = { ...state.active, kids };
      const events = [...state.events].map((event) => {
        return event._id == _id ? { ...event, kids } : event;
      });
      return { ...state, isLoading: false, active, events };
    }
    case eventsActionTypes.SET_ACTIVE_EVENT: {
      return { ...state, active: payload };
    }
    case eventsActionTypes.CLEAR_ACTIVE_EVENT: {
      return { ...state, active: null };
    }
    default:
      return state;
  }
};
