import React from "react";
import Modal from "../../Core/Modal/Modal";
import { useSelector, useDispatch } from "react-redux";
import { closeEmployeesModal } from "../../../redux/employees/employees.actions";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {
  EmployeeModalContent,
  EmployeeModalContainer,
} from "../Employee.styles";
import DisplayEmployee from "./DisplayEmployee";
import i18n from "../../../i18n";

export default function EmployeeModal() {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.employees.employee_modal);
  return (
    <Modal
      open={open}
      closeModal={() => dispatch(closeEmployeesModal())}
      aria-labelledby="employee-information-modal"
      aria-describedby="employee-information-modal"
      title={i18n.t("update_employee_information")}
    >
      <EmployeeModalContainer>
        <>
          <DisplayEmployee />
        </>
      </EmployeeModalContainer>
    </Modal>
  );
}
