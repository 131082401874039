import React from "react";
// import Modal from "@material-ui/core/Modal";
import Modal from "../../Core/Modal/Modal";
import { useSelector, useDispatch } from "react-redux";
import { closeEmployeesAddModal } from "../../../redux/employees/employees.actions";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {
  EmployeeModalContent,
  EmployeeModalContainer,
} from "../Employee.styles";
import AddEmployee from "./AddEmployee";
import i18n from "../../../i18n";
export default function AddEmployeeModal() {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.employees.addEmployeeModal);
  return (
    <Modal
      open={open}
      closeModal={() => dispatch(closeEmployeesAddModal())}
      title={i18n.t("add_employee")}
    >
      <EmployeeModalContainer>
        <>
          <AddEmployee />
        </>
      </EmployeeModalContainer>
    </Modal>
  );
}
