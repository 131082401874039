import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { EditActions } from "../Kids/Medical/Medical.styles";

import { Row } from "./BusinessHours.styles";
import { Form, Field } from "react-final-form";
import { TextField } from "@material-ui/core";
import _ from "lodash";
import axios from "../../axios";
import Modal from "../Core/Modal/Modal";
import i18n from "../../i18n";
import PrimaryButton from "../Core/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../Core/Buttons/SecondaryButton/SecondaryButton";
import { startSavingBusinessHours } from "../../redux/businessHours/businessHours.actions";
import { useDispatch } from "react-redux";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function EditHours({ open, handleModalClose, schedules }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleAddModalCancel = () => {
    handleModalClose();
  };
  const onSubmit = async (values) => {
    const res = Object.keys(values).map((key) => {
      let obj = {
        day: key,
        active: values[key].active,
        start: values[key].start,
        end: values[key].end,
      };
      return obj;
    });
    dispatch(startSavingBusinessHours(res));
    handleModalClose();
  };

  const Day = ({ day }) => (
    <React.Fragment>
      <div className="w-100 row justify-content-between align-items-center">
        <label>{day.day}</label>
        <Field
          name={`${day.day}.active`}
          component="input"
          type="checkbox"
          className="custom-control-input"
          initialValue={day.active}
        />
      </div>
      <Field
        name={`${day.day}.start`}
        initialValue={day.start}
        render={({ input, meta }) => {
          return (
            <>
              <TextField label="start" type="time" fullWidth {...input} />
              {meta.touched && meta.error && <span>{meta.error}</span>}
            </>
          );
        }}
      />
      <Field
        name={`${day.day}.end`}
        initialValue={day.end}
        render={({ input, meta }) => (
          <>
            <TextField label="end" type="time" fullWidth {...input} />
            {meta.touched && meta.error && <span>{meta.error}</span>}
          </>
        )}
      />
    </React.Fragment>
  );

  return (
    <Modal
      className={classes.modal}
      open={open}
      closeModal={() => handleAddModalCancel()}
      title={i18n.t("change_business_hours")}
    >
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="mx-2">
            {schedules.map((day, i) => {
              return (
                <Row key={`hours-${i}`}>
                  <Day {...{ day }} />
                </Row>
              );
            })}
            <EditActions className="mt-2">
              <SecondaryButton onClick={() => handleAddModalCancel()}>
                {i18n.t("cancel")}
              </SecondaryButton>
              <PrimaryButton className="ml-1" type="submit">
                {i18n.t("submit")}
              </PrimaryButton>
            </EditActions>
          </form>
        )}
      />
    </Modal>
  );
}
