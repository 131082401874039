export const clientsActionTypes = {
  ADD_CLIENT_PERSONAL_INFO: "ADD_CLIENT_PERSONAL_INFO",
  ADD_CLIENT_PERSONAL_INFO_SAGA: "ADD_CLIENT_PERSONAL_INFO_SAGA",

  ADD_CLIENT_COMPANY_INFO: "ADD_CLIENT_COMPANY_INFO",
  ADD_CLIENT_COMPANY_INFO_SAGA: "ADD_CLIENT_COMPANY_INFO_SAGA",
  REQUEST_FAILED: "ADD_CLIENT_COMPANY_INFO_REQUEST_FAILED",

  UPDATE_CLIENT_PERSONAL_INFO: "UPDATE_CLIENT_PERSONAL_iNFO",
  UPDATE_CLIENT_COMPANY_INFO: "UPDATE_CLIENT_COMPANY_INFO",

  GET_DAYCARE: "GET_DAYCARE",
  ADD_CURRENT_DAYCARE_STATE: "ADD_CURRENT_DAYCARE_STATE",

  GET_CURRENT_CLIENT_SAGA: "GET_CURRENT_CLIENT_SAGA",
  ADD_CURRENT_CLIENT_STATE: "ADD_CURRENT_CLIENT_STATE",

  UPDATED_CLIENT_INFO: "UPDATED_CLIENT_INFO",
  UPDATED_CLIENT_COMPANY_INFO: "UPDATED_CLIENT_COMPANY_INFO",
};
