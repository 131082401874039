import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  DoctorsWrapper,
  ConditionCard,
  DoctorsControls,
  Back,
  Actions,
} from "../Medical.styles";

import {
  startRequirmentFetching,
  startAddingRequirment,
  startDeletingRequirment,
  startEditingRequirment,
} from "../../../../redux/requirments/requirments.actions";
import AddRequirement from "./AddRequirement";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditRequirement from "./EditRequirement";

import ErrorHelper from "../../../../helpers/ErrorHelper";
import SuccessHelper from "../../../../helpers/SuccessHelper";
import i18n from "../../../../i18n";
import { selectRequirmentsData } from "../../../../redux/requirments/requirments.selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import callbackHelper from "../../../../helpers/callbackHelper";

// </DoctorsWrapper>
function Requirements(props) {
  const [modalState, setModalState] = useState({ edit: false, add: false });
  const [currentEditingRequirement, setCurrentEditingRequirement] = useState(
    {}
  );
  const handleModalClose = (state) => {
    const newState = { ...modalState };
    newState[state] = false;
    setModalState(newState);
    setCurrentEditingRequirement({});
  };
  const handleConditionEdit = (currentCondition) => {
    setModalState({ ...modalState, edit: true });
    setCurrentEditingRequirement(currentCondition);
  };

  const handleAddCondition = () => {
    setModalState({ ...modalState, add: true });
  };

  const handleRequirmentDelete = (requirment) => {
    setModalState({ ...modalState, delete: true });
    callbackHelper(props.startDeletingRequirment, requirment._id, "delete");
  };

  useEffect(() => {
    callbackHelper(props.startRequirmentFetching, {
      filters: { kidId: props.kid._id },
    });
  }, []);

  return (
    <>
      <DoctorsWrapper>
        <DoctorsControls>
          <Back onClick={() => props.handleCategory("main")}>
            <KeyboardBackspaceIcon />
          </Back>
          <Back>
            <AddIcon onClick={() => handleAddCondition()} />
          </Back>
        </DoctorsControls>
        <h3>{i18n.t("requirements")}</h3>
        {props.requirments.map((requirement) => {
          return (
            <>
              <ConditionCard key={requirement._id}>
                <div>{requirement.name}</div>
                <div>{requirement.description}</div>
                <Actions>
                  <EditIcon onClick={() => handleConditionEdit(requirement)} />
                  <DeleteForeverIcon
                    onClick={() => handleRequirmentDelete(requirement)}
                  />
                </Actions>
              </ConditionCard>
            </>
          );
        })}
        {!_.isEmpty(currentEditingRequirement) && (
          <EditRequirement
            open={modalState.edit}
            handleModalClose={(state) => handleModalClose(state)}
            {...{ currentEditingRequirement }}
            startEditingRequirment={props.startEditingRequirment}
            callbackHelper={callbackHelper}
          />
        )}
        {modalState.add && (
          <AddRequirement
            open={modalState.add}
            handleModalClose={(state) => handleModalClose(state)}
            startAddingRequirment={props.startAddingRequirment}
            callbackHelper={callbackHelper}
            kid={props.kid}
          />
        )}
      </DoctorsWrapper>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  startRequirmentFetching: (callbackMap, ...args) =>
    dispatch(startRequirmentFetching(callbackMap, ...args)),
  startDeletingRequirment: (callbackMap, id) =>
    dispatch(startDeletingRequirment(callbackMap, id)),
  startAddingRequirment: (callbackMap, ...args) =>
    dispatch(startAddingRequirment(callbackMap, ...args)),
  startEditingRequirment: (callbackMap, ...args) =>
    dispatch(startEditingRequirment(callbackMap, ...args)),
});

const mapStateToProps = createStructuredSelector({
  requirments: selectRequirmentsData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Requirements);
