import { createSelector } from "reselect";

const selectTestState = (state) => state.guardians;

export const selectCartItems = createSelector(
  [selectTestState],
  (guardians) => guardians
);

export const selectGuardiansData = createSelector(
  [selectTestState],
  (guardians) => guardians.guardians
);
