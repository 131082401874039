import React, { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  startAllergyFetching,
  startEditingAllergy,
  startAddingAllergy,
  startDeletingAllergy,
} from "../../redux/allergies/allergies.actions";
import { selectCartItems } from "../../redux/allergies/allergies.selectors";
import Page from "../../components/Page/Page";
import FormModal from "../../components/Core/FormModal/FormModal";
import store from "../../redux/store";

import ErrorHelper from "../../helpers/ErrorHelper";
import SuccessHelper from "../../helpers/SuccessHelper";
import i18n from "../../i18n";

class Allergies extends Component {
  state = {
    editModalOpen: false,
    addModalOpen: false,
    editing: {},
  };
  schema = [
    { value: "name", title: i18n.t("name"), fieldType: "text" },
    {
      value: "status",
      title: i18n.t("status"),
      fieldType: "select",
      options: [
        { name: i18n.t("active"), value: "Active" },
        { name: i18n.t("inactive"), value: "Inactive" },
      ],
    },
    { value: "description", title: i18n.t("description"), fieldType: "text" },

    {
      value: "actions",
      title: i18n.t("actions"),
      fieldType: "actions",
      style: { width: 80 },
      actions: ["edit", "delete"],
    },
  ];

  async componentDidMount() {
    document.title = i18n.t("allergies");

    new Promise((success, failure) => {
      this.props.startAllergyFetching({ success, failure });
    })
      .then((res) => {})
      .catch((err) => {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  }
  validateAllergy = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = i18n.t("required");
    }
    if (!values.status) {
      errors.status = i18n.t("required");
    }

    return errors;
  };

  onAdd = (values) => {
    new Promise((success, failure) => {
      this.props.startAddingAllergy({ success, failure }, values);
    })
      .then((res) => {
        SuccessHelper.handleToastSuccess(i18n.t("successfully_created"), true);
      })
      .catch((err) => {
        this.setState({ isloading: false });
        console.log("something_went_wrong");
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  };

  onEdit = (data) => {
    new Promise((success, failure) => {
      this.props.startEditingAllergy(
        { success, failure },
        {
          _id: this.state.editing._id,
          data,
        }
      );
    })
      .then((res) => {
        SuccessHelper.handleToastSuccess(i18n.t("successfully_updated"), true);
      })
      .catch((err) => {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  };

  renderEditModal = () => {
    return (
      <FormModal
        schema={this.schema}
        open={this.state.editModalOpen}
        onSubmit={this.onEdit}
        validateForm={this.validateAllergy}
        values={this.state.editing}
        closeModal={() => {
          this.setState({
            editing: {},
            editModalOpen: false,
          });
        }}
      />
    );
  };

  renderAddModal = () => {
    return (
      <FormModal
        schema={this.schema}
        open={this.state.addModalOpen}
        onSubmit={this.onAdd}
        validateForm={this.validateAllergy}
        closeModal={() => {
          this.setState({
            addModalOpen: false,
          });
        }}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderEditModal()}
        {this.renderAddModal()}
        <Page
          title={i18n.t("allergies")}
          resource="Allergy"
          data={this.props.allergies ? this.props.allergies.allergies : []}
          onDelete={this.props?.startDeletingAllergy}
          onEdit={(data) => {
            this.setState({
              editing: data,
              editModalOpen: true,
            });
          }}
          editOnRowClick
          onCreate={() => {
            this.setState({
              editing: {},
              addModalOpen: true,
            });
          }}
          schema={this.schema}
        ></Page>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startAllergyFetching: (callbackMap) =>
    dispatch(startAllergyFetching(callbackMap)),
  startDeletingAllergy: (id, callbackMap) =>
    dispatch(startDeletingAllergy(callbackMap, id)),
  startEditingAllergy: (callbackMap, ...args) =>
    dispatch(startEditingAllergy(callbackMap, ...args)),
  startAddingAllergy: (callbackMap, ...args) =>
    dispatch(startAddingAllergy(callbackMap, ...args)),
});

const mapStateToProps = createStructuredSelector({
  allergies: selectCartItems,
});
export default connect(mapStateToProps, mapDispatchToProps)(Allergies);
