import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  setToken,
  startActiveUserFetching,
} from "../redux/activeUser/activeUser.actions";

//Listens for any changes in auth state and dispateches actions to the redux store
export function useAuthHook() {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = sessionStorage.getItem("currentUserToken");
    dispatch(setToken(token));
  }, []);

  const token = useSelector((s) => s.activeUser.token);
  useEffect(() => {
    if (token) {
      dispatch(startActiveUserFetching());
    }
  }, [token]);
}
