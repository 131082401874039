import { groupsActionTypes } from "./groups.types";

export const saveGroups = (groups) => {
  return {
    type: groupsActionTypes.SAVE_GROUPS,
    payload: groups,
  };
};

export const startGroupFetching = (callbackMap) => {
  return {
    type: groupsActionTypes.GET_GROUPS,
    callbackMap,
  };
};

export const startDeletingGroup = (id, callbackMap) => {
  return {
    type: groupsActionTypes.DELETE_GROUP_SAGA,
    callbackMap,
    groupId: id,
  };
};

export const deleteGroup = (id) => {
  return {
    type: groupsActionTypes.DELETE_GROUP,
    payload: id,
  };
};

export const startEditingGroup = (callbackMap, payload) => {
  return {
    type: groupsActionTypes.EDIT_GROUP_SAGA,
    callbackMap,
    payload,
  };
};

export const editGroup = (payload) => {
  return {
    type: groupsActionTypes.EDIT_GROUP,
    payload,
  };
};

export const startAddingGroup = (callbackMap, payload) => {
  return {
    type: groupsActionTypes.ADD_GROUP_SAGA,
    callbackMap,
    payload,
  };
};

export const addGroup = (payload) => {
  return {
    type: groupsActionTypes.ADD_GROUP,
    payload,
  };
};
