import React, { Component } from "react";
import { Form } from "react-final-form";
import i18n from "../../../i18n";

import {
  LoginForm,
  LoginWrapper,
  ForgotPassword,
  LoginButtons,
  ButtonsWrapper,
  Footers,
  FooterLink,
} from "./loginForm.styles";
import { loginUser, renderLoginFormFields } from "./LoginForm.helpers";
export default class ClientPersonalRegistration extends Component {
  componentDidMount() {
    document.title = i18n.t("login");
  }
  validateLogin = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = i18n.t("required");
    }
    if (!values.password) {
      errors.password = i18n.t("required");
    }
    return errors;
  };

  render() {
    return (
      <>
        <Form
          onSubmit={(values) => loginUser(values)}
          validate={this.validateLogin}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <LoginWrapper>
              <LoginForm onSubmit={handleSubmit}>
                <h2>Login</h2>

                {renderLoginFormFields("")}
                <ButtonsWrapper>
                  <LoginButtons
                    type="submit"
                    disabled={submitting}
                    className="primary"
                  >
                    Login
                  </LoginButtons>
                  <ForgotPassword to="./home">
                    Forgot your password
                  </ForgotPassword>
                  <ForgotPassword to="./client-registration">
                    SignUp
                  </ForgotPassword>
                </ButtonsWrapper>
              </LoginForm>
              <Footers>
                <div>&#169; 2020copyrigth Easy1.All Rights Reserved</div>
              </Footers>
            </LoginWrapper>
          )}
        />
      </>
    );
  }
}
